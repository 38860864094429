
import React, { useState, useEffect, useRef } from "react";
import ETable from "../../common/editableTable/ETable";
import PaymentModeService from "../../../services/admin/PaymentModeService";
import { Alert } from "react-bootstrap";
import Model from "../../common/editableTable/Model";
import Constants from "../../../Constants";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const Denomination = () => {    
    const axiosPrivate = useAxiosPrivate();
    const [denominations, setDenominations] = useState([]);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [modelVariant, setModelVariant] = useState();
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const autoRef = useRef(null);

    const denomination = {
        id: '',
        denomination: '',
        value: '',
        order: ''
    }

    const validate = {
        isValid: true,
        erroMsg: ''
    }

    useEffect(() => {
        getAllDenominations();
    }, [])

    const columns = [
        {
            dataField: 'denomination',
            text: 'Denomination',
            sort: true,
            type: 'text',
            isMandatory: true,
            validator: (value) => {
                return !value ? { isValid: false, erroMsg: 'Please enter denomination' } : { isValid: true, erroMsg: '' }
            },

            onChange: (e, index) => {
                let locDenominations = [];
                locDenominations = denominations.map((old, i) => {
                    if (i === index) {
                        return { ...old, ['denomination']: e.target.value.toUpperCase() };
                    }
                    return old;
                });
                setDenominations(locDenominations);
            }

        }, {
            dataField: 'value',
            text: 'Value',
            sort: true,
            type: 'text',
            validator: (value) => {
                return isNaN(parseInt(value)) ? { isValid: false, erroMsg: 'Please enter valid value in number' } : { isValid: true, erroMsg: '' }
            },
            onChange: (e, index) => {
                let locDenominations = [];
                locDenominations = denominations.map((old, i) => {
                    if (i === index) {
                        return { ...old, ['value']: e.target.value };
                    }
                    return old;
                });
                setDenominations(locDenominations);
            }
        }, {
            dataField: 'order',
            text: 'Order',
            sort: true,
            type: 'text',
            validator: (value) => {
                return isNaN(parseInt(value)) ? { isValid: false, erroMsg: 'Please enter valid order in number' } : { isValid: true, erroMsg: '' }
            },
            onChange: (e, index) => {
                let locDenominations = [];
                locDenominations = denominations.map((old, i) => {
                    if (i === index) {
                        return { ...old, ['order']: e.target.value };
                    }
                    return old;
                });
                setDenominations(locDenominations);
            }
        }
    ]

    const getAllDenominations = () => {
        setShow(false);
        PaymentModeService.getAllDenominations(axiosPrivate).
            then(response => {
                setDenominations(response.data)
            }).
            catch(e => {
                setShow(true);
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setMessage(e.response ?  e.response.data : Constants.ERROR_MSG + " denomination details");
            })
    }


    const saveDenomination = (data, index) => {
        console.log('denominatio  datadata' + data);
        setShow(false);
        PaymentModeService.saveDenomination(axiosPrivate, data).
            then(response => {
                let denominationLst = [];
                denominationLst = denominations.map((prev, i) => {
                    if (index === i) {
                        return { ...prev, ['id']: response.data.id }
                    }
                    return prev
                })
                setDenominations(denominationLst);
                setModelVariant('success')
                setMessage('Record Saved Successfully');
                autoRef.current.setModelOpen(true);
            }).
            catch(e => {
                setShow(true);
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setMessage(e.response.data);
            })
    }

    const deleteDenominatios = (data) => {
        setShow(false);
        PaymentModeService.deleteDenominations(axiosPrivate, data).
            then(response => {
                getAllDenominations();
                setModelVariant('success')
                setMessage('Record(s) Deleted Successfully');
                autoRef.current.setModelOpen(true);
            }).
            catch(e => {
                setShow(true);
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setMessage(e.response.data);
            })
    }

    const style = {
        marginLeft: '30%',
    }


    return (
        <div className='col-md-8' style={{ marginLeft: '5%' }}>
            <div className="col-md-10 p-0 mt-3 float-right p-0">
                <h4>Denominations</h4>
                <Model style={style} message={message} ref={autoRef} hideDuration={3000} variant={modelVariant}></Model>
                {show &&
                    <div className="col-md-12 float-right p-0" >
                        <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{message}</p>
                        </Alert></div>}
            </div>
            <div className="col-md-10 p-0 mt-3 float-right">
                <ETable data={denominations} columns={columns}
                    addNewRec={() => {
                        setDenominations(denominations => [...denominations, denomination]);
                    }}
                    removeRec={(index) => {
                        const list = [...denominations];
                        list.splice(index, 1);
                        setDenominations(list);
                    }}
                    onSave={(data, index) => {
                        saveDenomination(data, index);
                    }}
                    onCancel={() => {
                        autoRef.current.setModelOpen(false);
                        getAllDenominations();
                    }}
                    onDelete={(data) => {
                        deleteDenominatios(data);
                    }}
                    onEdit={() => {
                    }}
                ></ETable>
            </div></div >
    )
}

export default Denomination;