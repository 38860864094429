
const getAllDayStocks = (axiosPrivate, requestMO) => {
  return axiosPrivate.post("/day_stocks", requestMO);
};

const getAll = (axiosPrivate) => {
  return axiosPrivate.get("/daily_stocks");
};

const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/daily_stock/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/daily_stock", data);
};

const update = (axiosPrivate, id, data) => {
  return axiosPrivate.put(`/daily_stock/${id}`, data);
};

const remove = (axiosPrivate, id) => {
  return axiosPrivate.delete(`/daily_stock/${id}`);
};

const removeAll = (axiosPrivate) => {
  return axiosPrivate.delete(`/daily_stocks`);
};


export default {
  getAll,
  getAllDayStocks,
  get,
  create,
  update,
  remove,
  removeAll,
};
