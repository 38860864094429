

const getAll = (axiosPrivate) => {
  return axiosPrivate.get("/lubeComponents");
};

const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/lubeComponents/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/saveDailyLubeSales", data);
};



export default {
  get,
  getAll,
  create,
};
