import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import DailyStockDataService from "../../services/stock/DailyStockService";
import FMTable from "../common/FMTable";
import Loader from "../common/Loader";
import DatePicker from "react-datepicker";
import { parse } from 'date-fns';
import { format } from 'date-fns-tz';
import ReportService from "../../services/report/ReportService"
import useAxiosPrivate from "../../auth/useAxiosPrivate";
import { Button } from 'react-bootstrap';
import Constants from "../../Constants";

const DailyStockList = () => {
  const axiosPrivate = useAxiosPrivate();
  const [dayStocks, setDayStocks] = useState([]);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [pagination, setPagination] = useState({
    pageSelected: 1,
    sizePerPage: 10,
    filterMOs: []
  });
  const [pageMO, setPageMO] = useState({
    pageableObj: [],
    totalElements: 0,
    pagination: pagination
  });

  const [requestMO, setRequestMO] = useState({ objects: '' });
  const [dailySaleDate, setDailySaleDate] = useState("");

  useEffect(() => {
    retreiveStocks();
  }, []);

  const retreiveStocks = () => {
    handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
  }

  const columns = [{
    dataField: 'productTypeMO.type',
    text: 'Product',
    type: 'text',
    sort: true,
  }, {
    dataField: 'productTypeMO.name',
    text: 'Name',
    type: 'text',
    sort: true
  }, {
    dataField: 'productMO.name',
    text: 'Fuel/Lube Type',
    type: 'text',
    sort: true,
  }, {
    dataField: 'openingStock',
    text: 'Opening Stock',
    type: 'text',
    sort: true,
  }, {
    dataField: 'closingStock',
    text: 'Closing Stock',
    type: 'text',
    sort: true,
  }];

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleTableChange = (filters, page, sizePerPage, actionType) => {
    pagination.pageSelected = page;
    pagination.sizePerPage = sizePerPage;
    pagination.filterMOs = filters;
    retrieveDayStocks(pagination, actionType);
  }

  const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();

  const retrieveDayStocks = (page, actionType) => {
    setShow(false);
    if (actionType === "pagination") {
      handleToggle()
    }
    setTableDataRetreivalMsg(Constants.LOADING_DATA);
    var dailySaleDateVar = !!dailySaleDate?.daily_sale_date ? format(dailySaleDate.daily_sale_date, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" }) : "";
    var requestMap = { "pageMO": page, "dailySaleDate": dailySaleDateVar };
    requestMO.objects = requestMap;
    DailyStockDataService.getAllDayStocks(axiosPrivate, requestMO)
      .then(response => {
        if (response.data.pageableObj) {
          setDayStocks(response?.data?.pageableObj);
          let dailySaleDate = response?.data?.pageableObj[0]?.dailySaleDateMO;
          let saleDate = dailySaleDate ? parse(dailySaleDate?.daily_sale_date, "dd.MM.yyyy", new Date()) : dailySaleDate;
          setDailySaleDate({ ...dailySaleDate, ['daily_sale_date']: saleDate });
          setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
          handleClose();
          setTableDataRetreivalMsg(null);
          console.log(response.data.pageableObj);
        } else {
          setDayStocks(response?.data?.pageableObj);
          setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
          setTableDataRetreivalMsg(null);
          handleClose();
        }
      })
      .catch(e => {
        handleClose();
        setMessage("Error while retrieving all day stocks");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        console.log(e);
      });
  };

  const handleDateChange = (date) => {
    setDailySaleDate({ ...dailySaleDate, ['daily_sale_date']: date });
  }

  const [error, setError] = useState({
    dailySaleDateError: ''
  })

  const handleValidation = () => {
    if (!dailySaleDate?.daily_sale_date) {
      setError({ ...error, ['dailySaleDateError']: "please select date" })
      return false;
    }
    setError({ ...error, ['dailySaleDateError']: "" });
    return true;
  }

  const goAction = () => {
    if (handleValidation()) {
      retreiveStocks();
    }
  }
  const downloadDayStockSummary = () => {
    if (handleValidation()) {
      setSubmitted(true);
      var dailySaleDateVar = !!dailySaleDate?.daily_sale_date ? format(dailySaleDate.daily_sale_date, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" }) : "";
      ReportService.downloadDayStockSummmary(axiosPrivate, dailySaleDateVar)
        .then(response => {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([response.data]),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Day_Stocks_Summary_Report.pdf`,
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
          setSubmitted(false);
        })
        .catch(e => {
          setSubmitted(false);
        });
    }
  };



  return (
    <div className="list row">
      <div className="col-md-12">
        {show ? (
          <Alert className="col-md-6" variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        ) : (<div></div>)}
        <div className="row col-md-12">
          <div className="col-md-12 p-0 mt-3"><h4>Daily Stock List</h4></div>
          <div className="col-md-12 float-left p-0 mt-3">
            <div className='col-md-4 float-left p-0'><span style={{ position: 'absolute', zIndex: '1', marginTop: '-20px' }} className='errorMsg'>  {error.dailySaleDateError}</span>
              <DatePicker className="form-control float-left col-md-12" style={{ height: '45px' }} autoComplete="off" selected={dailySaleDate.daily_sale_date} name="dailySaleDate" id="dailySaleDate" dateFormat="dd/MM/yyyy" onChange={(date) => handleDateChange(date)} />
            </div>
            <div className="col-md-8 float-right">
              <Button className="update-btn" style={{ padding: '6px 15px 6px 15px' }} onClick={(e) => goAction()}>Go</Button>
            </div>
          </div>
        </div>
        <Loader openDialog={open}></Loader>
        <FMTable enableRowSerials={true} dataRetrievalMsg={tableDataRetreivalMsg}  columns={columns} dataList={dayStocks} pageMO={pageMO} handlePagination={handleTableChange} />
        <div className="col-md-12 float-right p-0 mb-5" ><div className='mt-0' style={{ height: 'auto' }}>
          <Button className="download-btn float-right"
            onClick={(e) => downloadDayStockSummary()} disabled={submitted}
          >
            {submitted ? 'Downloading....' : 'Download'}
          </Button></div></div>
      </div>
    </div>
  );
};

export default DailyStockList;
