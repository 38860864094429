import React from "react";
import { Link, useLocation } from "react-router-dom";

const LubeAddNewPriceInfoPage = props => {
    
    const { state } = props.location.state
     const location = useLocation();


    return (
        <div  className="edit-form">
            <h4>Lube Price added successfully!</h4>
         
            <Link style={{ fontWeight: 'bold' }} href="#" className="btn btn-warning" to={location.state.listRouter}>
                return to List
            </Link>
        </div>
    )

}
export default LubeAddNewPriceInfoPage;