import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useRef } from "react";
import ETable from "../common/editableTable/ETable";
import Model from "../common/editableTable/Model";
import PackageTypeService from "../../services/products/PackageTypeService";
import Constants from "../../Constants";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const PackageTypes = () => {
    const axiosPrivate = useAxiosPrivate();
    const [packageTypes, setPackageTypes] = useState([]);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [modelVariant, setModelVariant] = useState();
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const autoRef = useRef(null);

    const packageType = {
        id: '',
        type: '',
        unit: ''
    }

    const validate = {
        isValid: true,
        erroMsg: ''
    }

    useEffect(() => {
        getAllPackageTypes();
    }, [])

    const columns = [
        {
            dataField: 'type',
            text: 'Package Type',
            sort: true,
            type: 'text',
            value: '',
            isMandatory: true,
            validator: (value) =>{
                return !value ? {isValid : false, erroMsg: 'Please enter package type'} :  {isValid : true, erroMsg: ''}
            },
            onChange: (e, index) => {
                let locPackageTypes = [];
                locPackageTypes = packageTypes.map((old, i) => {
                    if (i === index) {
                        return { ...old, ['type']: e.target.value.toUpperCase() };
                    }
                    return old;
                });
                setPackageTypes(locPackageTypes);
            }

        }, {
            dataField: 'unit',
            text: 'Unit',
            sort: true,
            type: 'text',
            value: '',
            isMandatory: true,
            validator: (value) => {
                return !value ? { isValid: false, erroMsg: 'Please enter unit' } : { isValid: true, erroMsg: '' }
            },
            onChange: (e, index) => {
                let locPackageTypes = [];
                locPackageTypes = packageTypes.map((old, i) => {
                    if (i === index) {
                        return { ...old, ['unit']: e.target.value };
                    }
                    return old;
                });
                setPackageTypes(locPackageTypes);
            }
        }
    ]

    const getAllPackageTypes = () => {
        setShow(false);
        PackageTypeService.getAll(axiosPrivate).
            then(response => {
                setPackageTypes(response.data)
            }).
            catch(e => {
                setShow(true);
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setMessage(e.response ?  e.response.data : Constants.ERROR_MSG + " package types");
            })
    }


    const savePackageType = (data, index) => {
        setShow(false);
        PackageTypeService.create(axiosPrivate, data).
            then(response => {
                let packageTypeLst = [];
                packageTypeLst = packageTypes.map((prev, i) => {
                    if (index === i) {
                        return { ...prev, ['id']: response.data.id }
                    }
                    return prev
                })
                setPackageTypes(packageTypeLst);
                setModelVariant('success')
                setMessage('Record Saved Successfully');
                 autoRef.current.setModelOpen(true);

            }).
            catch(e => {
                setShow(true);
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setMessage(e.response.data);
            })
    }

    const deletePackageTypes = (data) => {
        setShow(false);
        PackageTypeService.removeAll(axiosPrivate, data).
            then(response => {
                getAllPackageTypes();
                setModelVariant('success')
                setMessage('Record(s) Deleted Successfully');
                autoRef.current.setModelOpen(true);

            }).
            catch(e => {
                setShow(true);
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setMessage(e.response.data);
            })
    }



    const [snackState, setSnackState] = useState({
        vertical: '20%',
        horizontal: '0px',
    });
    const { vertical, horizontal } = snackState;
    const style = {
        marginLeft: '30%',
    }

    return (
        <div className='col-md-8' style={{ marginLeft: '5%' }}>
            <div className="col-md-10 p-0 mt-3 float-right p-0">
                <h4>Package Types</h4>
                <Model style={style} message={message} ref={autoRef} hideDuration={3000} variant={modelVariant}></Model>
                {show &&
                    <div className="col-md-12 float-right p-0" >
                        <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{message}</p>
                        </Alert></div>}
            </div>
            <div className="col-md-10 p-0 mt-3 float-right">
                <ETable data={packageTypes} columns={columns}
                    addNewRec={() => {
                        setPackageTypes(packageTypes => [...packageTypes, packageType]);
                    }}
                    removeRec={(index) => {
                        const list = [...packageTypes];
                        list.splice(index, 1);
                        setPackageTypes(list);
                    }}
                    onSave={(data, index) => {
                        savePackageType(data, index);
                    }}
                    onCancel={() => {
                        autoRef.current.setModelOpen(false);
                        getAllPackageTypes();
                    }}
                    onDelete={(data) => {
                        deletePackageTypes(data);
                    }}
                    onEdit={() => {
                    }}
                ></ETable>
            </div></div >
    )
}

export default PackageTypes;