import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import MachineryDataService from "../../../services/admin/MachineryService";
import { Button } from "@material-ui/core";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const AddPump = ({ updateParent }) => {
    const axiosPrivate = useAxiosPrivate();
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [show, setShow] = useState(false);

    const initialPumpState = {
        id: '',
        code: '',
        serial_no: '',
        make: '',
        modelNo: '',
        displayOrder: ''
    }
    const [pump, setPump] = useState(initialPumpState);
    const handleInputChange = event => {
        const { name, value } = event.target;
        setPump({ ...pump, [name]: value });
    };

    const [pumpError, setPumpError] = useState({
        codeError: '',
        serialNoError: '',
        makeError: '',
        modelNoError: ''
    })

    const handleValidation = () => {
        if (!pump?.code) {
            setPumpError({ ...pumpError, "codeError": "Please enter code" });
            return false;
        }
        if (!pump?.modelNo) {
            setPumpError({ ...pumpError, "codeError": '', "serialNoError": "", "modelNoError": "Please enter model no" });
            return false;
        }
        if (!pump?.serial_no) {
            setPumpError({ ...pumpError, "codeError": '', "modelNoError": "", "serialNoError": "Please enter serial no" });
            return false;
        }
        if (!pump?.make) {
            setPumpError({ ...pumpError, "codeError": '', "modelNoError": "", "serialNoError": "", "makeError": "Please enter make" });
            return false;
        }
        setPumpError({ ...pumpError, "codeError": '', "serialNoError": '', "makeError": '', "modelNoError": '' });
        return true;
    }

    const savePump = () => {
        setShow(false);
        if (handleValidation()) {
            setSubmitted(true);
            MachineryDataService.savePump(axiosPrivate, pump)
                .then(response => {
                    setPump(initialPumpState);
                    updateParent(response.data);
                    setSubmitted(false);
                    setShow(true);
                    setTimeout(() => {
                        setShow(false);
                    }, 3000);
                    setAlertHeading("Success!!");
                    setAlertVariant("success");
                    setMessage("Successfully added");
                }).catch(e => {
                    setShow(true);
                    setSubmitted(false);
                    setAlertHeading("Error!");
                    setAlertVariant("danger");
                    setMessage(e.response.data);
                })
        }
    }
    return (
        <div className="p-0">


            <div className="col-md-12 float-right">


                {show &&
                    <div className="col-md-12 float-left">
                        <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{message}</p>
                        </Alert></div>}
                <div className=" col-md-6 float-left">
                    <label htmlFor="title">Code</label><span className='errorMsg'>  {pumpError.codeError}</span>
                    <input
                        type="text"
                        className="form-control"
                        id="code"
                        required
                        value={pump.code}
                        autoComplete="off"
                        onChange={handleInputChange}
                        name="code"
                    />
                </div>
                <div className=" col-md-6 float-right">
                    <label htmlFor="title">Model No</label>
                    <span className='errorMsg'> {pumpError.modelNoError}</span>
                    <input
                        type="text"
                        className="form-control"
                        id="modelNo"
                        value={pump.modelNo}
                        onChange={handleInputChange}
                        name="modelNo"
                    />
                </div>
                <div className="col-md-6 adminForm">
                    <label htmlFor="description">Serial No</label><span className='errorMsg'>  {pumpError.serialNoError}</span>
                    <input
                        type="text"
                        className="form-control"
                        id="serial_no"
                        required
                        autoComplete="off"
                        value={pump.serial_no}
                        onChange={handleInputChange}
                        name="serial_no"
                    />
                </div>
                <div className="col-md-6 adminForm">
                    <label htmlFor="description">Make</label><span className='errorMsg'>   {pumpError.makeError}</span>
                    <input
                        type="text"
                        className="form-control"
                        id="make"
                        required
                        autoComplete="off"
                        value={pump.make}
                        onChange={handleInputChange}
                        name="make"
                    />
                </div>
                <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
                    <button
                        color="primary"
                        variant="contained"
                        className="add-btn btn float-right"
                        onClick={(e) => savePump(e)} disabled={submitted}
                    >
                        {submitted ? 'Adding...' : 'Add Pump'}
                    </button>
                </div>
            </div>
        </div>
    )
}
export default AddPump;