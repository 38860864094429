
const getAll = (axiosPrivate) => {
  return axiosPrivate.get("/lubeBrands");
};

const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/lubeBrand/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/lubeBrand", data);
};

const update = (axiosPrivate, id, data) => {
  return axiosPrivate.put(`/lubeBrand/${id}`, data);
};

const remove = (axiosPrivate, id) => {
  return axiosPrivate.delete(`/lubeBrand/${id}`);
};

const removeAll = (axiosPrivate) => {
  return axiosPrivate.delete(`/lubeBrand`);
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
};
