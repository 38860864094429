import React, { useEffect, useRef, useState } from "react";
import FMTable from "../../common/FMTable";
import Loader from "../../common/Loader";
import UserService from "../../../services/admin/UserService";
import { Link } from "react-router-dom";
import { Fade, FormControlLabel, Paper, StylesProvider, Switch, Theme } from "@material-ui/core";
import { Alert, Button, Container } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import AddAppUser from "./AddAppUser";
import EditAppUser from "./EditAppUser";
import Constants from "../../../Constants";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const AppUserList = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const [appUsers, setAppUsers] = useState([]);
    const [appUser, setAppUser] = useState();
    const [open, setOpen] = useState(false);
    //alert constants
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    //alert constants
    const [pagination, setPagination] = useState({
        pageSelected: 1,
        sizePerPage: 10,
        filterMOs: []
    });

    const [pageMO, setPageMO] = useState({
        pageableObj: [],
        totalElements: 0,
        pagination: pagination
    });

    const [userRoleError, setUserRoleError] = useState();
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    const columns = [{
        dataField: 'userName',
        text: 'User Name',
        sort: true,
        type: 'text',
        value: ''
    }, {
        dataField: 'userRoleMO.role',
        text: 'Role',
        sort: true,
        type: 'text',
        value: ''
    }]

    const updateParent = () => {
        handleTableChange([], 1, pagination.sizePerPage);
    }


    const updateParentEditMode = () => {
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }

    const expandRow = {
        renderer: row => <EditAppUser data={row} updateParent={updateParentEditMode} />
    }

    const [updatePumps, setUpdatePumps] = useState([]);

    useEffect(() => {
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }, []);

    const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();

    const retreiveAppUsers = (pagination, actionType) => {
        setTableDataRetreivalMsg(Constants.LOADING_DATA)
        UserService.getAllAppUsers(axiosPrivate, pagination).
            then(response => {
                setAppUsers(response.data.pageableObj);
                setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
                setTableDataRetreivalMsg(null)
                console.log(response.data);
            })
            .catch(e => {
                setMessage(e.response ? e.response.data : Constants.ERROR_MSG + " app user details");
                setTableDataRetreivalMsg(null)
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setShow(true);
                handleClose();
            })
    }

    const handleTableChange = (filters, page, sizePerPage, actionType) => {
        pagination.pageSelected = page;
        pagination.sizePerPage = sizePerPage;
        pagination.filterMOs = filters;
        retreiveAppUsers(pagination, actionType);
    }

    const [showButton, setShowButton] = useState(true);
    const [showAddBox, setShowAddBox] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const nodeRef = useRef(null);

    const deleteAppUsers = (appUserList) => {
        UserService.deleteAppUsers(axiosPrivate, appUserList)
            .then(response => {
                handleTableChange([], 1, pagination.sizePerPage);
            })
            .catch(response => {

            })
    }

    return (
        <div className="col-md-10">
            <div className="col-md-10 float-right">
                <div className="row"><h4>App Users</h4>{showButton && (
                    <h5 className="ml-3">  <Link className="addNewHeading"
                        onClick={() => setShowAddBox(true)}

                    >
                        Add App Users

                    </Link></h5>
                )}</div>
                {show && (
                    <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{alertHeading}</Alert.Heading>
                        <p>{message}</p>
                    </Alert>

                )}

                <div className="row">

                    <CSSTransition
                        in={showAddBox}
                        nodeRef={nodeRef}
                        timeout={500}
                        classNames="newBox"
                        unmountOnExit
                        onEnter={() => setShowButton(false)}
                        onExited={() => setShowButton(true)}
                    >
                        <div className="addNewBox col-md-12 p-0"
                            ref={nodeRef}
                        ><div className="col-md-12 p-0">
                                <div className="float-left col-md-10"><h5 style={{ marginLeft: '15px', marginTop: '5px' }}>Add App User</h5> </div>
                                <div className="col-md-2 float-right p-0">
                                    <StylesProvider injectFirst>
                                        <Button className="float-right addNewClose"
                                            onClick={() => setShowAddBox(false)}
                                        >
                                            X
                                        </Button></StylesProvider>
                                </div>

                            </div>

                            <div className="p-0  col-md-12 ml-0" style={{ height: 'auto' }}>
                                <AddAppUser updateParent={updateParent} />
                            </div>

                        </div>
                    </CSSTransition>
                </div>
                <div className="row mt-3">
                    <Loader openDialog={open}></Loader>
                    <FMTable columns={columns} dataRetrievalMsg={tableDataRetreivalMsg}  showFilter={false} dataList={appUsers} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow} showDelete={true} deleteHandler={deleteAppUsers} />
                </div>
            </div>
        </div >
    )

}
export default AppUserList;