import { parse } from 'date-fns';
import { format } from 'date-fns-tz';
import addDays from "date-fns/addDays";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { StylesProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FuelPriceDataService from "../../services/dailySales/DailyFuelPriceService";
import FuelTypeDataService from "../../services/products/FuelTypeService";
import NumberInput from '../common/NumberInput';
import useAxiosPrivate from '../../auth/useAxiosPrivate';
import AlertDialog from '../common/AlertDialog';
import Constants from '../../Constants';

const EditDailyFuelPrice = ({ data, updateParent, updateParentOnDelete }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [fromDttm, setFromDtttm] = useState(null);
  const [toDttm, setToDttm] = useState(null);
  const axiosPrivate = useAxiosPrivate();

  const initialFuelPriceState = {
    id: null,
    fuel_type: {
      id: "",
      type: "",
      name: ""
    },
    price: "",
    date_of_sale: '',
    from_dttm: '',
    to_dttm: ''
  };
  const initialFuelType = {
    id: "",
    type: "",
    name: ""
  };
  const [currentFuelPrice, setCurrentFuelPrice] = useState(data);
  const [message, setMessage] = useState("");
  const [fuelTypes, setFuelTypes] = useState([]);
  const [fuelTypeSelected, setfuelTypeSelected] = useState(data.fuel_type);
  const [show, setShow] = useState(false);
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [updateSubmitted, setUpdateSubmitted] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  function isObjectEmpty(obj) {
    return Object.getOwnPropertyNames(obj).length >= 1
  }

  useEffect(() => {
    retrieveFuelTypes();
  }, []);

  useEffect(() => {
    data && setFuelPrice(data);
  }, [data]);

  const setFuelPrice = (data) => {
    setCurrentFuelPrice(data);
    setfuelTypeSelected(data.fuel_type);
    console.log(data.to_dttm);
    setStartDate(parse(data.date_of_sale, "dd.MM.yyyy", new Date()));
    let from_dttm = data.from_dttm;
    if (isObjectEmpty(from_dttm)) {
      setFromDtttm(parse(from_dttm, "dd.MM.yyyy H:mm:ss", new Date()));
    }
    let to_dttm = data.to_dttm;
    if (isObjectEmpty(to_dttm)) {
      setToDttm(parse(to_dttm, "dd.MM.yyyy H:mm:ss", new Date()));
    }

  };

  const retrieveFuelTypes = () => {
    FuelTypeDataService.getAll(axiosPrivate)
      .then(response => {
        setFuelTypes(response.data);
        response.data.map((item, index) => {
          //And make condition here, for exemple the first element
          if (item.id == currentFuelPrice.fuel_type.id) {
            //DO what u want 
            console.log(item)
            currentFuelPrice.fuel_type = item;
          }
        })
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleSelectChange = event => {
    const selectedIndex = event.target.options.selectedIndex;
    setfuelTypeSelected(fuelTypes[selectedIndex]);
    currentFuelPrice.fuel_type = fuelTypes[selectedIndex];
    console.log(event.target.value);
  };

  const handleInputChange = (value, name) => {
    setCurrentFuelPrice({ ...currentFuelPrice, [name]: value });
  };

  const initialFuelPriceError = {
    priceError: "",
    typeError: "",
    dateOfSaleError: "",
    fromDttmError: "",
    toDttmError: ""
  };
  const [fuelPriceError, setFuelPriceError] = useState(initialFuelPriceError);

  const handleValidation = () => {
    if (currentFuelPrice.fuel_type.type === "") {
      setFuelPriceError({ ...fuelPriceError, "priceError": '', "typeError": "Please select fuel type" });
      return false;
    }
    if (currentFuelPrice.price === "") {
      setFuelPriceError({ ...fuelPriceError, "priceError": "Please enter price" });
      return false;
    }
    if (startDate === "" || startDate === undefined || startDate === null) {
      setFuelPriceError({ ...fuelPriceError, "priceError": '', "typeError": "", "dateOfSaleError": "please enter date of sale" });
      return false;
    }
    if (fromDttm === "" || fromDttm === undefined || fromDttm === null) {
      setFuelPriceError({ ...fuelPriceError, "priceError": '', "typeError": "", "dateOfSaleError": "", "fromDttmError": "please enter from date and time" });
      return false;
    }
    if (toDttm === "" || toDttm === undefined || toDttm === null) {
      setFuelPriceError({ ...fuelPriceError, "priceError": '', "typeError": "", "dateOfSaleError": "", "fromDttmError": "", "toDttmError": "please enter to date and time" });
      return false;
    }
    setFuelPriceError({ ...fuelPriceError, "typeError": '', "priceError": '', "dateOfSaleError": "", "fromDttmError": "", "toDttmError": "" });
    return true;
  }

  const updateFuelPrice = () => {
    if (handleValidation()) {
      setUpdateSubmitted(true)
      currentFuelPrice.date_of_sale = format(startDate, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" });
      currentFuelPrice.from_dttm = format(fromDttm, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" });
      currentFuelPrice.to_dttm = format(toDttm, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" });
      setAlertHeading("Info");
      setAlertVariant("info");
      setMessage("Updating.....");
      FuelPriceDataService.update(axiosPrivate, currentFuelPrice.id, currentFuelPrice)
        .then(response => {
          updateParent();
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setUpdateSubmitted(false);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully updated");
        })
        .catch(e => {
          if (e.response && e.response.data) {
            setMessage(e.response.data);
            setAlertHeading("Error!");
            setAlertVariant("danger");
            setShow(true);
            setUpdateSubmitted(false)
            console.log(e.response.data); // some reason error message
          }
          console.log(e);
        });
    };

  };

  const deleteFuelPrice = () => {
    setDeleteSubmitted(true);
    FuelPriceDataService.remove(axiosPrivate, currentFuelPrice.id)
      .then(response => {
        updateParentOnDelete()
      })
      .catch(e => {
        setMessage(e.response.data);
        setDeleteSubmitted(false);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
      });
  }

  /**delete action  */

  const [dialogShow, setDialogShow] = useState(false);

  const yesBtnHandler = {
    name: 'Yes',
    //dont add deleteFuelPrice() with  open and close bracket, which will cause indefinte 
    // loop always refer function without braces
    callAction: deleteFuelPrice
  };

  const noBtnHandler = {
    name: 'No',
  };

  function deleteAction() {
    setDialogShow(true);
  }

  /**delete action  */

  return (
    <div className="p-0">

      <div className="col-md-12 float-right">
        {dialogShow && (
          <AlertDialog openDialog={true} dialogShowStateVar={setDialogShow} dialogTitle={"Delete Action!!!"}
            message={Constants.DELETE_CONTENT}
            noBtnHandler={noBtnHandler} yesBtnHandler={yesBtnHandler} />
        )}
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}

        <div className="col-md-6 float-left">
          <label htmlFor="fuelType">Fuel Type<span style={{ fontStyle: 'italic', fontSize: '12px' }}> (non editable)</span></label>
          <input className="form-control" type="text" value={fuelTypeSelected.type + " - " + fuelTypeSelected.name}></input>
        </div>
        <div className="col-md-6 float-left">
          <label htmlFor="description">Price</label><span className='errorMsg'>  {fuelPriceError.priceError}</span>
          <NumberInput autoComplete="off" id="price" name="price" isDecScaleEnabled={true} decimalScale={2}
            value={currentFuelPrice.price} className="form-control"
            onChange={(value) => handleInputChange(value, 'price')}></NumberInput>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Date of Sale</label><span className='errorMsg'>  {fuelPriceError.dateOfSaleError}</span>
          <DatePicker className="form-control" dateFormat="dd MMM yyyy" selected={startDate} name="date_of_sale" id="date_of_sale" onChange={date => {
            setStartDate(date);
            setFromDtttm(setHours(setMinutes(date, 0), 6));
            setToDttm(setHours(setMinutes(addDays(date, 1), 59), 5))
          }} />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="Date of sale">From Date Time</label><span className='errorMsg'>  {fuelPriceError.fromDttmError}</span>
          <DatePicker autoComplete="off" selected={fromDttm} timeInputLabel="Time:" name="from_dttm" id="from_dttm" className="form-control" onChange={date => setFromDtttm(date)} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput minDate={new Date(currentFuelPrice.from_dttm)}
            maxDate={new Date(fromDttm)} />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="Date of sale">To Date Time</label><span className='errorMsg'>  {fuelPriceError.toDttmError}</span>
          <DatePicker autoComplete="off" selected={toDttm} timeInputLabel="Time:" name="to_dttm" id="to_dttm" className="form-control" onChange={date => setToDttm(date)} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput minDate={new Date(currentFuelPrice.to_dttm)}
            maxDate={addDays(new Date(toDttm), 1)} />
        </div>

        <div>
          <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }} >
            <button
              className="update-btn btn ml-0 float-right"
              variant="contained" type="button"
              onClick={updateFuelPrice} disabled={updateSubmitted}
            >{updateSubmitted ? 'Updating....' : 'Update'}
            </button>
            <button
              className="delete-btn btn ml-0 float-right mr-3"
              variant="contained"
              disabled={deleteSubmitted}
              type="button"
              onClick={(e) => deleteAction()}
            >
              {deleteSubmitted ? 'Deleting...' : 'Delete'}
            </button>
          </div>
          <div className="pr-0 col-md-4 mr-0 float-right">
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDailyFuelPrice;
