import React, { useState, useEffect } from "react";
import CreditEntryDataService from "../../services/credit/CreditBookService";
import VehicleDataService from "../../services/vehicle/VehicleService";
import FuelTypeDataService from "../../services/products/FuelTypeService";
import DailyFuelPriceDataService from "../../services/dailySales/DailyFuelPriceService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Alert from 'react-bootstrap/Alert';
import NumberFormat from 'react-number-format';
import { isValid, parse } from 'date-fns';
import { format } from 'date-fns-tz';
import { Button } from "@material-ui/core";
import DailySaleDateService from "../../services/dailySales/DailySalesService";
import Autocomplete from "../common/Autocomplete";
import NumberInput from "../common/NumberInput";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const AddCreditEntry = ({ updateParent }) => {
  const [dttmOfSale, setDttmOfSale] = useState(new Date());
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [dialogShow, setDialogShow] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [fuelTypeSelected, setFuelTypeSelected] = useState('DEFAULT');
  const axiosPrivate = useAxiosPrivate();
  const initialVehilceState = {
    vhRegNo: "",
    vhOwner:{
      transport_name:''
    }
  }
  const [vehicleMO, setVehicle] = useState(initialVehilceState);
  const initialCreditEntryState = {
    id: null,
    vehicleMO: {
      id: "",
      vhRegNo: '',
      vhOwner:{
        transport_name:''
      }
    },
    price: "",
    comments: "",
    is_paid: "",
    amount_of_sale: "",
    litre_sale_volume: "",
    dttm_of_sale: '',
    priceError: "",
    amtOfSaleError: "",
    litreSaleVolError: "",
    vehicleError: "",
    fuelError: ""
  };
  const [creditEntry, setCreditEntry] = useState(initialCreditEntryState);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const initialRecentDailySaleDate = {
    id: '',
    daily_sale_date: '',
    salesPersonMO: '',
    from_dttm: '',
    to_dttm: '',
    status: ''
  }
  const [recentDailySaleDate, setRecentDailySaleDate] = useState(initialRecentDailySaleDate);
  const initialFuelTypeState = {
    id: "",
    type: ""
  }
  const [fuelType, setFuelType] = useState(initialFuelTypeState);
  const initialFuelPriceState = {
    id: "",
    fuel_type: {
      id: "",
      type: ""
    },
    price: ""
  }
  const [fuelPriceMO, setFuelPriceMO] = useState(initialFuelPriceState);

  useEffect(() => {
    retrieveVehicles();
    retrieveFuelTypes();
    getRecentDailySaleDate();
  }, []);

  const getRecentDailySaleDate = () => {
    DailySaleDateService.getRecentDailySaleDate(axiosPrivate)
      .then(response => {
        setRecentDailySaleDate(response.data);
        if(response.data?.from_dttm){
          setDttmOfSale(parse(response.data?.from_dttm, "dd.MM.yyyy H:mm:ss", new Date()));
        }
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const retrieveFuelTypes = () => {
    FuelTypeDataService.getAll(axiosPrivate)
      .then(response => {
        const fuelTypes = response.data;
        setFuelTypes(fuelTypes);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const retrieveVehicles = () => {
    VehicleDataService.getAll(axiosPrivate)
      .then(response => {
        setVehicles(response.data);
        console.log(response.data);
      })
      .catch(e => {
        setMessage("Error while retrieving all vehilces");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
      });
  };

  const handleDttmOfSale = date => {
    if (fuelPriceMO.fuel_type.type === "") {
      setCreditEntry({ ...creditEntry, "vehicleError": '', "fuelError": "Please select fuel type" });
      return false;
    }
    setDttmOfSale(date);
    setMessage("Fetching price value for this date time range ....");
    setAlertHeading("Info");
    setAlertVariant("info");
    setShow(true);
    var formattedDttm = new String(format(date, "dd MM yyyy H:mm:ss", { timeZone: "Asia/Kolkata" }));

    DailyFuelPriceDataService.findByDttmOfSale(axiosPrivate, formattedDttm, fuelType.id)
      .then(response => {
        setCreditEntry({ ...creditEntry, price: response.data.price });
        setFuelPriceMO({ ...fuelPriceMO, price: response.data.price });
        setMessage("Found price value for this date time range");
        setAlertHeading("Success");
        setAlertVariant("success");
        setShow(true);
      }).catch(e => {
        if (e.response && e.response.data) {
          creditEntry.price = "";
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setShow(true);
          console.log(e.response.data); // some reason error message
        }
        console.log(e);
      });
  };

  const handleSelectChange = event => {

    const selectedIndex = event.target.options.selectedIndex - 1;
    var locFuelType = fuelTypes[selectedIndex];
    fuelPriceMO.fuel_type = locFuelType;
    setFuelType(locFuelType);
    setCreditEntry({ ...creditEntry, "fuelError": '', "price": "" });
    setFuelTypeSelected(locFuelType === undefined ? 'DEFAULT' : locFuelType.id);
    console.log(event.target.value);
  };

  const handleInputChange = event => {
    console.log(event);
    const { name, value } = event.target;
    setCreditEntry({ ...creditEntry, [name]: value });
  };

  function renderVehicleNumber(item, value) {
    return (
      item.vhRegNo.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  const handleValidation = () => {
    if (vehicleMO.id === null || vehicleMO.id === undefined) {
      setCreditEntry({ ...creditEntry, 'vehicleError': "Please enter valid vehicle registration no" });
      return false;
    }
    if (!fuelPriceMO.fuel_type.id || fuelPriceMO.fuel_type.id === 'DEFAULT') {
      setCreditEntry({ ...creditEntry, 'vehicleError': '', "fuelError": "Please select fuel type" });
      return false;
    }
    //Price
    if (creditEntry.price === '') {
      setCreditEntry({ ...creditEntry, 'vehicleError': '', "fuelError": '', "priceError": "Price cant be empty" });
      return false;
    }
    if (creditEntry.litre_sale_volume === '') {
      setCreditEntry({ ...creditEntry, 'vehicleError': '', "fuelError": '', "priceError": '', "litreSaleVolError": "Sale volume cant be empty" });
      return false;
    }
    if (creditEntry.amount_of_sale === '') {
      setCreditEntry({ ...creditEntry, 'vehicleError': '', "fuelError": '', "priceError": '', "litreSaleVolError": '', "amtOfSaleError": "Sale Amount cant be empty" });
      return false;
    }
    setCreditEntry({ ...creditEntry, 'vehicleError': '', "fuelError": '', "priceError": '', "litreSaleVolError": '', "amtOfSaleError": '' });
    return true;
  };

  const saveCreditEntry = (e) => {

    //if submitted then avoid calling this block again
    const isValid = handleValidation();
    fuelPriceMO.fuel_type = fuelType;
    fuelPriceMO.price = creditEntry.price;
    if (isValid) {
      setSubmitted(true);
      console.log(vehicleMO);
      var data = {
        vehicleMO: vehicleMO,
        fuelPriceMO: fuelPriceMO,
        litre_sale_volume: creditEntry.litre_sale_volume,
        amount_of_sale: creditEntry.amount_of_sale,
        is_paid: creditEntry.is_paid,
        dttm_of_sale: format(dttmOfSale, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" }),
        comments: creditEntry.comments
      };
      console.log(data);
      CreditEntryDataService.create(axiosPrivate, data)
        .then(response => {
          setSubmitted(false);
          updateParent(response.data);
          setSubmitted(false);
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully added");
          newCreditEntry();
        })
        .catch(e => {
          if (e.response && e.response.data) {
            setShow(true);
            setSubmitted(false);
            setAlertHeading("Error!");
            setAlertVariant("danger");
            setMessage(e.response.data);
            console.log(e.response.data); // some reason error message
          }
          console.log(e);
        });
    }
  };

  const newCreditEntry = () => {
    setCreditEntry(initialCreditEntryState)
    setVehicle(initialVehilceState)
    setFuelPriceMO(initialFuelPriceState)
    setFuelTypeSelected('DEFAULT');
  }

  const validateNewCreditEntry = (e) => {

    //if submitted then avoid calling this block again
    const isValid = handleValidation();
    setDialogShow(false);
    fuelPriceMO.fuel_type = fuelType;
    fuelPriceMO.price = creditEntry.price;
    if (isValid) {
      setSubmitted(true);
      console.log(vehicleMO);
      var data = {
        vehicleMO: vehicleMO,
        fuelPriceMO: fuelPriceMO,
        litre_sale_volume: creditEntry.litre_sale_volume,
        amount_of_sale: creditEntry.amount_of_sale,
        is_paid: creditEntry.is_paid,
        dttm_of_sale: format(dttmOfSale, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" }),
        comments: creditEntry.comments
      };
      console.log(data);
      CreditEntryDataService.validateNewCreditEntry(axiosPrivate, data)
        .then(response => {
          //if false, then show warning dialog that credit entry is added to past date.
          //incase user wants to proceed, he clicks 'OK' to add new entry into to past date.
          if (!response.data) {
            setAlertHeading("Error!");
            setAlertVariant("danger");
            setShow(true);
            setMessage("you cant enter credit entry's date & time of sale in past date, it should be current/future sale date only");
            setSubmitted(false);
          } else {
            //if it is valid credit entry which is getting added to latest day sale, then we proceed to add it without any warning message.
            saveCreditEntry();
          }
          console.log(response.data);
        })
        .catch(e => {
          if (e.response && e.response.data) {
            setSubmitted(false);
            setAlertHeading("Error!");
            setAlertVariant("danger");
            setShow(true);
            setMessage(e.response.data);
          }
          console.log(e);
        });
    }

  };


  return (
    <div className="p-0">
      <div className="col-md-12 float-right">
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <div className="col-md-12 float-left">
          <label htmlFor="title">Vehilce No</label><span className='errorMsg'>  {creditEntry.vehicleError}</span>
          {vehicles.length > 0 && <Autocomplete
            items={vehicles}
            searchField={'vhRegNo'}
            renderItem={(item) =>
              <>
                {item.vhRegNo} - {item.vhOwner.transport_name}
              </>

            }
            setOptSelected={setVehicle}
            onChange={(event, val) => { setVehicle({ ...vehicleMO, ['vhRegNo']: val }) }}
            onSelect={(value, item) => {
              { item !== null ? setVehicle(item) : setVehicle(initialVehilceState) }
            }}
            value={(vehicleMO.vhRegNo )} />}
        </div>
        <div className=" col-md-6 adminForm">
          <label htmlFor="title">Fuel Type</label><span className='errorMsg'>  {creditEntry.fuelError}</span>
          <select className="form-control" defaultValue={'DEFAULT'} value={fuelTypeSelected} onChange={handleSelectChange} name="fuel_type" id="fuel_type">
            <option value="DEFAULT">Select Fuel Type...</option>
            {fuelTypes.map((item, index) => (
              <option
                key={index}
                value={item.id}>
                {item.type}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="Date of sale">Date & Time of Sale</label>
          <DatePicker className="form-control" autoComplete="off" selected={dttmOfSale} timeInputLabel="Time:" name="date_of_sale" id="date_of_sale" onChange={date => handleDttmOfSale(date)} maxDate={(parse(recentDailySaleDate.to_dttm, "dd.MM.yyyy H:mm:ss", new Date()))} minDate={(parse(recentDailySaleDate.from_dttm, "dd.MM.yyyy H:mm:ss", new Date()))} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput />

        </div>


        <div className="col-md-6 adminForm">
          <label htmlFor="price">Price</label><span className='errorMsg'>  {creditEntry.priceError}</span>
          <NumberFormat className="form-control" autoComplete="off" id="price" name="price" fixedDecimalScale={true} decimalScale={2} value={creditEntry.price} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹ '} onValueChange={(values) => {
            const { value } = values;
            // formattedValue = $2,223
            // value ie, 2223
            setCreditEntry({ ...creditEntry, price: value });
          }} />

        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="salevolume">Sale Volume <span style={{ fontStyle: 'italic', fontSize: '12px' }}>(in litres)</span></label><span className='errorMsg'>  {creditEntry.litreSaleVolError}</span>
          <NumberInput autoComplete="off" id="litre_sale_volume" name="litre_sale_volume" isDecScaleEnabled={true} decimalScale={2} value={creditEntry.litre_sale_volume} className="form-control" isThousandSepEnabled={true} thousandsGroupStyle="lakh"
            onChange={(value) => setCreditEntry({ ...creditEntry, litre_sale_volume: value })}></NumberInput>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="saleamount">Sale amount</label><span className='errorMsg'>  {creditEntry.amtOfSaleError}</span>
          <NumberInput autoComplete="off" id="amount_of_sale" name="amount_of_sale" isDecScaleEnabled={true} decimalScale={2} value={creditEntry.amount_of_sale} className="form-control" isThousandSepEnabled={true} thousandsGroupStyle="lakh"
            onChange={(value) => setCreditEntry({ ...creditEntry, amount_of_sale: value })}></NumberInput>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="saleamount">Comments</label>
          <textarea maxLength={50} className="form-control" id="comments" name="comments" value={creditEntry.comments} onChange={handleInputChange} />
        </div>
        <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
          <button
            color="primary"
            variant="contained"
            className="add-btn btn float-right"
            onClick={(e) => validateNewCreditEntry(e)} disabled={submitted}
          >
            {submitted ? 'Adding...' : 'Add Credit'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCreditEntry;