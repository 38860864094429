import { Table } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import { format } from 'date-fns-tz';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isValid, parse } from 'date-fns';
import NumberFormat from 'react-number-format';
import Button from "@material-ui/core/Button";
import { StylesProvider } from "@material-ui/core/styles";
import creditTransactionDataService from "../../services/credit/CreditTransactionService";
import AlertDialog from "../common/AlertDialog";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const RecallCreditTransaction = (props) => {
  const initialCreditTransactionState = {
    id: null,
    vhOwner: {
      id: ''
    },
    credit: null,
    debit: null,
    difference: '',
    creditBookMOs: [],
    credited_dttm: '',
    creditedDttmError: "",
    creditError: "",
    vehicleOwnerError: ""
  };
  const [creditTransaction, setCreditTransaction] = useState(initialCreditTransactionState);
  const [vehicleOwners, setVehicleOwnerList] = useState();
  const [message, setMessage] = useState("");
  const initialVehilceState = {
    value: "",
    vehicleOwnersLst: vehicleOwners
  }
  const axiosPrivate = useAxiosPrivate();
  const [recallSubmitted, setRecallSubmitted] = useState(false);
  const [cumulativePay, setCumulativePay] = useState(0);
  const [manualPayEntry, setManualPayEntry] = useState(0);
  const [dialogShow, setDialogShow] = useState(false);

  useEffect(() => {
    getcreditTransaction(props.match.params.id);
  }, [props.match.params.id]);

  const [totalDebit, setTotalDebit] = useState(0);

  const getcreditTransaction = (id) => {
    creditTransactionDataService.get(axiosPrivate, id)
      .then(response => {
        //setCreditTransaction(response.data);
        setCreditTransaction({
          ...creditTransaction, 'id': response.data.id,
          'credited_dttm': response.data.credited_dttm,
          'paymentModeMO': response.data.paymentModeMO,
          'vhOwner': response.data.vhOwner, 'credit': response.data.credit, 'creditBookMOs': response.data.creditBookMOs, 'difference': response.data.difference
        });
        var totalDebit = 0;
        response.data.creditBookMOs.map(creditBook => {
          totalDebit = totalDebit + creditBook.amount_of_sale;
        });
        setTotalDebit(totalDebit);
        setCumulativePay(0);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const invokeCredTransList = () => {
    props.history.push("/fmApp/creditTransactionList");
  }

  const showAlertDiaglog = () => {
    console.log(dialogShow);
    setDialogShow(true);
    setMessage("Credit Transaction once recalled, cant be reverted back, are you sure you want to recall?");
  }

  const recallCreditTransaction = () => {
    setRecallSubmitted(true);
    creditTransactionDataService.recallTransaction(axiosPrivate, creditTransaction.id)
      .then(response => {
        props.history.push({ pathname: "/fmApp/infoPage", state: { listRouter: '/fmApp/creditTransactionList', infoContent: 'Credit Transaction recalled successfully' } });
      })
      .catch(e => {
        console.log(e);
      });
    setRecallSubmitted(false);
  };

  const yesBtnHandler = {
    name: 'Yes',
    callAction: recallCreditTransaction
  };

  const noBtnHandler = {
    name: 'No',
  };

  return (
    <div className="mt-5 col-md-12" style={{ marginLeft: "120px" }}>
      {dialogShow ? (
        <AlertDialog openDialog={true} dialogShowStateVar={setDialogShow} dialogTitle={"Recall credit transaction warning!!!"} message={message}
          noBtnHandler={noBtnHandler}
          yesBtnHandler={yesBtnHandler} />
      ) : (<div></div>)}

      <div className="mt-1 col-md-12">
        <h4>Recall Credit transaction</h4>
        <div className="row mt-3">
          <div className="col-md-4">
            <label htmlFor="vehicleownername">Vehilce Owner : </label> <label><b>{creditTransaction.vhOwner.name}</b></label>
          </div>
          <div className="col-md-4  pr-0">
            <label htmlFor="saleamount">Credited Dttm : : </label> <label><b>{creditTransaction.credited_dttm}</b></label>
          </div>    
        </div>
        <div className="row mt-3">
          <div className="col-md-4">
            <label htmlFor="paymentmode">Payment mode : </label> <label><b>{creditTransaction.paymentModeMO?.mode}</b></label>
          </div>
          <div className="col-md-4  pr-0">
            <label htmlFor="saleamount">Paid : </label> <label><b>{creditTransaction.credit}</b></label>
          </div>
        </div>

      </div>
      <div className="col-md-12 pr-0">
        <div>
          <Table className="fmanTable col-md-8 pr-0">
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>S.No</th>
                <th style={{ textAlign: "left" }}>Bill No</th>
                <th style={{ textAlign: "left" }}>Vehicle No</th>
                <th style={{ textAlign: "left" }}>Fuel Type</th>
                <th style={{ textAlign: "left" }}>Price</th>
                <th style={{ textAlign: "left" }}>Sale Volume</th>
                <th style={{ textAlign: "left" }}>Sale Amount</th>
                <th style={{ textAlign: "left" }}>is Paid?</th>
              </tr>
            </thead>
            <tbody>

              {creditTransaction.creditBookMOs.map((row, index) => {
                return <tr>
                  <td>{index + 1}</td>
                  <td>{row.id}</td>
                  <td>{row.vehicleMO.vhRegNo}</td>
                  <td>{row.fuelPriceMO.fuel_type.type}</td>
                  <td>{row.fuelPriceMO.price}</td>
                  <td style={{ textAlign: "right" }}>{row.litre_sale_volume}</td>
                  <td style={{ textAlign: "right" }}>{row.amount_of_sale}</td>
                  <td style={{ textAlign: "center" }}><input type="checkbox" name="is_Paid"
                    checked={parseInt(row.is_paid) === 1 ? true : false} ></input></td>
                </tr>
              })}

              <tr>
                <td colSpan={5}>{ }</td>
                <td style={{ textAlign: "right" }}>Total</td>
                <td style={{ textAlign: "right" }}>{isNaN(parseFloat(totalDebit)) ? 0 : parseFloat(totalDebit).toFixed(2)}</td>
                <td>{ }</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div className="mt-5">

        <div className="row mt-3">
          <StylesProvider injectFirst>
            <div className="col-md-7">
              <button
                color="secondary"
                variant="contained"
                className="back-btn btn float-right"
                onClick={invokeCredTransList}>
                Back to list
              </button>
            </div>
            <div className="col-md-1 pr-0">

              <button
                className="delete-btn btn float-right"
                variant="contained"
                disabled={recallSubmitted}
                type="button"
                onClick={showAlertDiaglog}
              >
                {recallSubmitted ? 'Recalling...' : 'Recall'}
              </button>

            </div>
          </StylesProvider>
        </div>
      </div>
    </div>

  );
};

export default RecallCreditTransaction;
