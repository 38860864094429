import axios from "axios";
import useAuth from "./useAuth"
import { useEffect } from "react";
/**
 * refresh token reference link
 * https://github.com/bezkoder/react-jwt-refresh-token
 */

/*const BASE_URL = 'https://dev.fuelman.in/api/v1';*/

const BASE_URL = process.env.REACT_APP_API_URL;
/*const BASE_URL = 'http://localhost:8080/api/v1';*/

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json"
  }
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});




const requestIntercept = axios.interceptors.request.use(
  config => {
    console.log('inside useaxios interceptor -----------------')
    if (user?.productUserMO) {
      config.headers['X-TENANTID'] = `${appUserCtx.productUserMO.id}`;
    }
    return config;
  }, (error) => Promise.reject(error)
);

