import React, { useEffect, useState } from "react";
import CreditBookDataService from "../../../services/credit/CreditBookService";
import DayCreditBookEntryList from "../../credit/DayCrediBookEntryList";
import { Button } from 'react-bootstrap';
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

export const DailyCreditSummary = props => {
  const [dateOfSaleId, setDateOfSaleId] = useState();
  const [creditBookEntries, setCreditBookEntries] = useState([]);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [totalCredit, setTotatCredit] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if(props.location?.state?.id){
      setDateOfSaleId(props.location.state.id);
      retrieveDailyCreditSummary(props.location.state.id);
    }
  }, [props.location?.state?.id]);


  const retrieveDailyCreditSummary = (id) => {
    //let locDateOfSale = format(dailySaleDateMO.daily_sale_date, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" });
    CreditBookDataService.getAllDailyCreditEntries(axiosPrivate, id)
      .then(response => {
        setCreditBookEntries(response.data);
        response.data.map((creditEntry, index) => {
          setTotatCredit(prev => {
            return prev + creditEntry.amount_of_sale;
          })
        });
      })
      .catch(e => {
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        console.log(e);
      });
  };



  const invokeFuelSales = () => {
    //navigation.next()
    props.history.push({ pathname: "/fmApp/dailyLubeSales", state: { id: dateOfSaleId } });
  }

  const saveAndProceed = () => {
    setSubmitted(true);
    CreditBookDataService.updateCreditBookWithDailySaleDate(axiosPrivate, dateOfSaleId)
      .then(response => {
        setSubmitted(false);
        props.history.push({ pathname: "/fmApp/dailyCollection", state: { id: dateOfSaleId } });
      }).catch(e => {
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setSubmitted(false);
        setShow(true);
        console.log(e);
      });

  }

  return (
    <div className="sales-form list row">

    {dateOfSaleId && <DayCreditBookEntryList dailySaleDateId={dateOfSaleId}></DayCreditBookEntryList>}
      

      <div className="col-md-12 p-0 mb-5 list float-right" >

        <button
          color="primary"
          variant="contained"
          className="next-btn btn float-right"
          onClick={() => saveAndProceed()}
          disabled={submitted}>
          {submitted ? 'SAVING....' : 'NEXT'}
        </button>
        <button
          color="secondary"
          variant="contained"
          className="back-btn btn float-right"
          style={{ marginRight: "1rem" }}
          onClick={() => invokeFuelSales()}
        >
          BACK
        </button>
      </div>
    </div>

  );
};
export default DailyCreditSummary;
