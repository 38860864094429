import React, { useEffect, useRef, useState } from "react";
import FMTable from "../../common/FMTable";
import Loader from "../../common/Loader";
import UserService from "../../../services/admin/UserService";
import { Link } from "react-router-dom";
import { Fade, FormControlLabel, Paper, StylesProvider, Switch, Theme } from "@material-ui/core";
import { Alert, Button, Container } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import Constants from "../../../Constants";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const UserRoleList = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const [userRoles, setUserRoles] = useState([]);
    const [open, setOpen] = useState(false);

    //alert constants
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    //alert constants

    const columns = [{
        dataField: 'role',
        text: 'User Role',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'isAdmin',
        text: 'Admin Access',
        sort: true,
        type: 'text',
        value: '',
        rowFormatter: (data) => (
            <span>{data === 1 ? 'Yes' : 'No'}</span>
        )
    }]

    useEffect(() => {
        retreiveUserRoles();
    }, []);

    const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();
    const retreiveUserRoles = () => {
        setTableDataRetreivalMsg(Constants.LOADING_DATA);
        UserService.getAllUserRoles(axiosPrivate).
            then(response => {
                setUserRoles(response.data);
                console.log(response.data);
                setTableDataRetreivalMsg(null);
            })
            .catch(e => {
                setMessage(e.response ? e.response.data : Constants.ERROR_MSG + " user role details");
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setShow(true);
                setTableDataRetreivalMsg(null);
            })
    }

    return (
        <div className="col-md-10">
            <div className="col-md-10 float-right">
                <div className="row"><h4>User Roles</h4></div>
                {show && (
                    <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{alertHeading}</Alert.Heading>
                        <p>{message}</p>
                    </Alert>

                )}
                <div className="row mt-3">
                    <Loader openDialog={open}></Loader>
                    <FMTable columns={columns} dataRetrievalMsg={tableDataRetreivalMsg} showFilter={false} dataList={userRoles} />
                </div>
            </div>
        </div >
    )

}
export default UserRoleList;