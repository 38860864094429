
//url 




const LOGIN_URL =  '/login';
const LIST_FUELPRICE = '/fmApp/fuelPriceList';
const ERROR_PAGE =  '/errorPage';
const INFO_PAGE =  '/actionSuccessPage';
const ERROR_MSG = 'Error while retrieving';
const LOADING_DATA = 'Loading data.....';
const DELETE_CONTENT = "Delete action cant be reverted back, are you sure want to continue?"
export default {
    LOGIN_URL,
    LIST_FUELPRICE,
    ERROR_PAGE,
    ERROR_MSG,
    LOADING_DATA,
    INFO_PAGE,
    DELETE_CONTENT
};