import http from "../../auth/http-common";

const getAllSalesPerson = (axiosPrivate, pageMO) => {
  return axiosPrivate.post("/getAllSalesPerson", pageMO);
};

const getSalesPersons = (axiosPrivate) => {
  return axiosPrivate.get(`/salesPersons`);
};

const saveSalesPerson = (axiosPrivate, data) => {
  return axiosPrivate.post("/saveSalesPerson", data);
};

const editSalesPerson = (axiosPrivate, data) => {
  return axiosPrivate.put("/editSalesPerson", data);
};

const deleteSalesPersons = (axiosPrivate, data) => {
  return axiosPrivate.put("/deleteSalesPersons", data);
};

const getAllUserRoles = (axiosPrivate) => {
  return axiosPrivate.get("/getAllUserRoles");
};

const getAllAppUsers = (axiosPrivate, pageMO) => {
  return axiosPrivate.post(`/getAllAppUsers`, pageMO);
};

const saveAppUser = (axiosPrivate, data) => {
  return axiosPrivate.post("/saveAppUser", data);
};

const updateAppUser = (axiosPrivate, data) => {
  return axiosPrivate.put("/updateAppUser", data);
};

const deleteAppUsers = (axiosPrivate, data) => {
  return axiosPrivate.put("/deleteAppUsers", data);
};

const verifyLogin = (data) => {
  return http.post("/login", data, {
    headers: {
      'X-TENANTID': data.productUserMO.id
    }
  })
}

const refreshToken = (data) => {
  return http.post('/refreshtoken', data, {
    headers: {
      'X-TENANTID': data.productUserMO.id
    }
  });
}

const logoutAction = (axiosPrivate, data) => {
  return axiosPrivate.put('/logoutAction', data);
}

const getCustomerDetails = (id) => {
  return http.get(`/customerLogin/${id}`);
};

const resetPassword = (requestMO) => {
  return http.post(`/forgotPassword`, requestMO);
};

export default {
  getAllSalesPerson,
  getSalesPersons,
  saveSalesPerson,
  editSalesPerson,
  deleteSalesPersons,
  getAllUserRoles,
  getAllAppUsers,
  saveAppUser,
  updateAppUser,
  deleteAppUsers,
  verifyLogin,
  refreshToken,
  logoutAction,
  getCustomerDetails,
  resetPassword,
};