
import React, { useState, useRef } from "react";
import '../../assets/css/login.css';
import UserService from '../../services/admin/UserService';
import Constants from "../../Constants"
import useAxiosPrivate from "../../auth/useAxiosPrivate";
import { Link, StylesProvider } from "@material-ui/core";

const Logout = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const logoutAction = () => {
        const user = JSON.parse(localStorage.getItem('authUser'));
        UserService.logoutAction(axiosPrivate, user).
            then(response => {
                //redirect to login page after succesfull logout
                 //remove user 
                localStorage.removeItem('authUser');
                window.location.href = '/fmApp/customer/'+user.productUserMO.customerId;
            })
            .catch(e => {
                localStorage.removeItem('authUser');
                localStorage.setItem('errorMsg', e.response.data);
                window.location.href = Constants.ERROR_PAGE;
            })

    }
    return (
       
            <div  className="logout" style={{ marginTop: '6px', marginLeft: '10px' }}><a onClick={e => logoutAction()}>Logout</a></div>
    )

}

export default Logout;