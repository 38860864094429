const getAll = (axiosPrivate) => {
  return axiosPrivate.get("/lubeTypes");
};

const getAllLubricantTypes = (axiosPrivate, page) => {
  return axiosPrivate.post("/lubeTypes", page);
};

const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/lubeType/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/lubeType", data);
};

const update = (axiosPrivate, id, data) => {
  return axiosPrivate.put(`/lubeType/${id}`, data);
};

const updatePrice = (axiosPrivate, id, data) => {
  return axiosPrivate.put(`/lubeType/updatePrice/${id}`, data);
};

const remove = (axiosPrivate, id) => {
  return axiosPrivate.put(`/lubeType/delete/${id}`);
};

const removeAll = (axiosPrivate) => {
  return axiosPrivate.delete(`/lubeTypes`);
};


export default {
  getAll,
  getAllLubricantTypes,
  get,
  create,
  update,
  updatePrice,
  remove,
  removeAll,
};
