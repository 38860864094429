import { Button, Table } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import { format } from 'date-fns-tz';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import CreditTransactionDataService from "../../services/credit/CreditTransactionService";
import VehicleOwnerDataService from "../../services/vehicle/VehicleOwnerService";
import Alert from 'react-bootstrap/Alert';
import Autocomplete from "../common/Autocomplete";
import NumberInput from "../common/NumberInput";
import PaymentModeService from "../../services/admin/PaymentModeService";
import useAxiosPrivate from "../../auth/useAxiosPrivate";
const AddCreditTransaction = props => {
  const initialCreditTransactionState = {
    id: null,
    vhOwner: {
      id: ''
    },
    credit: 0.0,
    debit: 0.0,
    difference: 0,
    debitDiffAmountMO: {
      symbol: '',
      diffValue: 0.0
    },
    totalCreditBillAmount: '',
    creditBookMOs: [],
    credited_dttm: new Date(),
    creditedDttmError: "",
    creditError: "",
    cumulativePayError: "",
    vehicleOwnerError: "",
    paymentModeError: ""
  };
  const axiosPrivate = useAxiosPrivate();
  const [creditTransaction, setCreditTransaction] = useState(initialCreditTransactionState);
  const [vehicleOwners, setVehicleOwnerList] = useState();
  const initialVehilceOwnerState = {
    name: "",
  }
  const [vehicleOwner, setVehicleOwner] = useState(initialVehilceOwnerState);
  const [submitted, setSubmitted] = useState(false);

  const [cumulativePay, setCumulativePay] = useState(0);
  const [manualPayEntry, setManualPayEntry] = useState(0);
  const [totalOutStanding, setTotalOutStanding] = useState(0);
  const [totalSaleValue, setTotalSaleValue] = useState(0);
  const [show, setShow] = useState(false);
  const [infoShow, setInfoShow] = useState(false);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [paymentModes, setPaymentModes] = useState([]);
  const [paymentMode, setPaymentMode] = useState();

  useEffect(() => {
    retrieveVehicleOwner();
    getAllPaymentModes();
  }, []);


  const getAllPaymentModes = () => {
    setShow(false);
    PaymentModeService.getAllPaymentModesWithCash(axiosPrivate).
      then(response => {
        setPaymentModes(response.data)
      }).
      catch(e => {
        setShow(true);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setMessage(e?.response?.data?.message);
      })
  }


  const retrieveCreditEntries = (item) => {
    setShow(false);
    setInfoShow(false);
    setSubmitted(false);
    CreditTransactionDataService.getByVhOwnerId(axiosPrivate, item.id)
      .then(response => {
        console.log(" object key lenght is " + Object.keys(response.data).length);
        if (!(Object.keys(response.data).length === 0)) {
          setCreditTransaction({
            ...creditTransaction,
            'vhOwner': response.data.vhOwner, 'debit': response.data.debit, 'totalCreditBillAmount': response.data.totalCreditBillAmount, 'creditBookMOs': response.data.creditBookMOs, 'difference': response.data.debitDiffAmountMO.actualDiffvalue, 'debitDiffAmountMO': response.data.debitDiffAmountMO
          });
          setTotalOutStanding(response.data.debit);
          setManualPayEntry(response.data.credit);
          setCumulativePay(0);
          setTotalSaleValue(0);
          if (response.data.creditBookMOs.length === 0) {
            setInfoShow(true);
            setAlertHeading("Info !");
            setAlertVariant("info");
            setMessage('No transaction found for this vehicle owner');
          };
          console.log(response.data);
        } else {
          setInfoShow(true);
          setAlertHeading("Info !");
          setAlertVariant("info");
          setMessage('No transaction found for this vehicle owner');
          setCreditTransaction(initialCreditTransactionState);
          setTotalOutStanding(0);
          setManualPayEntry(0);
          setCumulativePay(0);
          setTotalSaleValue(0);
        }
      })
      .catch(e => {
        setInfoShow(false);
        setShow(true);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setMessage(e.response?.data);
        console.log(e);
      });
  };

  const retrieveVehicleOwner = () => {
    VehicleOwnerDataService.getAll(axiosPrivate)
      .then(response => {
        setVehicleOwnerList(response.data);
        console.log(response.data);
      })
      .catch(e => {
      });
  };

  const handleManualPaymentEntry = (value) => {
    setTotalSaleValue(0);
    var totalCredit = 0;
    if (null != creditTransaction.creditBookMOs) {
      let creditBookEntries = creditTransaction.creditBookMOs.map((creditEntry, index) => {
        totalCredit = +totalCredit.toFixed(2) + +creditEntry.amount_of_sale.toFixed(2)
        var enteredCredit = value ? +value : 0;
        var cashBalance = +creditTransaction.difference;
        console.log('entered credit  - ' + enteredCredit + ' cash balance - ' + cashBalance)
        var totalEnteredCredit = +enteredCredit + +cashBalance;
        console.log(' ceiled total credit before flooring ' + totalCredit)
        let ceiledTotalCredit = +totalCredit.toFixed(2);

        console.log('totalEnteredCredit - ' + totalEnteredCredit + ' totalCredit - ' + ceiledTotalCredit)
        if (totalEnteredCredit >= ceiledTotalCredit) {
          creditEntry.is_paid = 1
        } else {
          creditEntry.is_paid = 0
        }
        return creditEntry
      });
      setCreditTransaction({ ...creditTransaction, "creditError": '', 'creditBookMOs': creditBookEntries });
    }
    setManualPayEntry(value ? +value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : 0);
    setCumulativePay(value ? +value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] : 0);
  }


  const handleCheckChange = (event, row) => {
    if (isNaN(cumulativePay)) setCumulativePay(0);
    console.log(cumulativePay)
    if (parseFloat(manualPayEntry) > 0) {
      setCreditTransaction({ ...creditTransaction, "creditError": "please make payments '0' before clicking checkboxes" });
    } else {
      const checked = event.target.checked;
      setCreditTransaction({ ...creditTransaction, "creditError": '' });
      if (checked) {
        row.is_paid = 1;
        var totalValue = +parseFloat(totalSaleValue).toFixed(2) + +parseFloat(row.amount_of_sale).toFixed(2);
        setTotalSaleValue(totalValue.toFixed(2));
        let cumulativePay = +parseFloat(totalValue).toFixed(2) - +parseFloat(creditTransaction.difference).toFixed(2);
        setCumulativePay(+cumulativePay.toFixed(2));
      } else {
        row.is_paid = 0;
        var totalValue = totalSaleValue - parseFloat(row.amount_of_sale).toFixed(2);
        setTotalSaleValue(+(totalValue).toFixed(2));
        if (totalValue <= 0) {
          setCumulativePay(0);
        } else {
          let cumulativePay = +parseFloat(totalValue).toFixed(2) - +parseFloat(creditTransaction.difference).toFixed(2);
          setCumulativePay(+cumulativePay.toFixed(2));
        }
      }
    }
  }

  const handleStartDate = date => {
    setCreditTransaction({ ...creditTransaction, 'credited_dttm': date });
  }

  function formatVehicle(item, value) {
    return (
      item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }


  const handleValidation = () => {
    if (!vehicleOwner) {
      setCreditTransaction({ ...creditTransaction, vehicleOwnerError: "please select vehicle owner from drop list" });
      return false;
    }
    if (!paymentMode?.id) {
      setCreditTransaction({ ...creditTransaction, vehicleOwnerError: "", paymentModeError: "please select payment mode" });
      return false;
    }
    if (creditTransaction.credited_dttm === null || creditTransaction.credited_dttm === '') {
      setCreditTransaction({ ...creditTransaction, paymentModeError: '', vehicleOwnerError: "", creditedDttmError: "credited date time cant be empty" });
      return false;
    }
    if (null != creditTransaction.creditBookMOs) {
      if (cumulativePay === 0 && manualPayEntry === 0) {
        setCreditTransaction({ ...creditTransaction, paymentModeError: '', 'creditedDttmError': '', 'cumulativePayError': '', 'creditError': "please enter credit amount" });
        return false;
      }
      if (manualPayEntry < 0) {
        setCreditTransaction({ ...creditTransaction, 'creditedDttmError': '', 'cumulativePayError': '', 'creditError': "please enter payment greater than '0'" });
        return false;
      }
      if (manualPayEntry > +creditTransaction.debit) {
        setCreditTransaction({ ...creditTransaction, 'creditedDttmError': '', 'cumulativePayError': '', 'creditError': "please enter payment less than or equal to total payable amount " + creditTransaction.debit });
        return false;
      }
      if (cumulativePay < 0) {
        setCreditTransaction({ ...creditTransaction, 'creditedDttmError': '', 'creditError': '', 'cumulativePayError': "Paying amount should be greater than or equal to '0'  " });
        return false;
      }
    }
    setCreditTransaction({ ...creditTransaction, 'vehicleOwnerError': '', 'creditedDttmError': '', 'creditError': '', 'cumulativePayError': '' });
    return true;
  };

  const invokeCredTransList = () => {
    props.history.push("/fmApp/creditTransactionList");
  }


  const clearDropDown = () => {
    setInfoShow(false);
    setVehicleOwner(initialVehilceOwnerState);
    setCreditTransaction(initialCreditTransactionState);
    setTotalOutStanding(0);
    setManualPayEntry(0);
    setCumulativePay(0);
    setTotalSaleValue(0);
  }

  const saveCreditTransaction = (e) => {
    setShow(false);
    if (handleValidation()) {

      setSubmitted(true);
      var data = {
        vhOwner: vehicleOwner,
        credit: cumulativePay,
        creditBookMOs: creditTransaction.creditBookMOs,
        paymentModeMO: paymentMode,
        debit: creditTransaction.debit,
        difference: creditTransaction.difference,
        credited_dttm: format(creditTransaction.credited_dttm, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" })
      };
      CreditTransactionDataService.create(axiosPrivate, data)
        .then(response => {
          console.log(response.data);
          props.history.push({ pathname: "/fmApp/infoPage", state: { addRouter: '/fmApp/addCreditTransaction', listRouter: '/fmApp/creditTransactionList', infoContent: 'Credit Added successfully' } });
        })
        .catch(e => {
          setSubmitted(false);
          setShow(true);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setMessage(e.response.data);
        });
    }
  };

  return (
    <div className="credit-form ml-5  mb-5">

      {show ? (
        <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{alertHeading}</Alert.Heading>
          <p>{message}</p>
        </Alert>

      ) : (<div style={{ width: '100%', marginLeft: "10%" }}>

        <div className="col-md-12">
          <h4>Add Payments</h4>

          <div className="col-md-4 mt-3 p-0">

            <label htmlFor="title">Select a Vehilce Owner</label><span className='errorMsg'>  {creditTransaction.vehicleOwnerError}</span>

            <Autocomplete items={vehicleOwners}
              searchField={'name'}
              renderItem={(item) =>
                <>
                  {item.name} - {item.transport_name}
                </>

              }
              onChange={(event, value) => { setVehicleOwner({ ...vehicleOwner, ['name']: value }) }}
              onSelect={(value, item) => {
                setVehicleOwner(item ? item : undefined);
                item && retrieveCreditEntries(item)
              }}
              value={vehicleOwner?.name} />
          </div>
        </div>
        {(creditTransaction.creditBookMOs.length > 0) ? <div>
          <div className="col-md-12 mt-5">
            <div>
              <Table className="fmanTable col-md-8">
                <thead>
                  <tr>
                    <th style={{ textAlign: "left" }}>S.No</th>
                    <th style={{ textAlign: "left" }}>Bill No</th>
                    <th style={{ textAlign: "left" }}>Vehicle No</th>
                    <th style={{ textAlign: "left" }}>Fuel Type</th>
                    <th style={{ textAlign: "left" }}>Price</th>
                    <th style={{ textAlign: "left" }}>Sale Date</th>
                    <th style={{ textAlign: "left" }}>Sale Volume</th>
                    <th style={{ textAlign: "left" }}>Sale Amount</th>
                    <th style={{ textAlign: "left" }}>is Paid?</th>
                  </tr>
                </thead>
                <tbody>

                  {(null != creditTransaction.creditBookMOs) ? (creditTransaction.creditBookMOs.map((row, index) => {
                    return <tr>
                      <td>{index + 1}</td>
                      <td>{row.id}</td>
                      <td>{row.vehicleMO.vhRegNo}</td>
                      <td>{row.fuelPriceMO.fuel_type.type}</td>
                      <td>{row.fuelPriceMO.price}</td>
                      <td>{row.dttm_of_sale}</td>
                      <td style={{ textAlign: "right" }}>{row.litre_sale_volume}</td>
                      <td style={{ textAlign: "right" }}>{row.amount_of_sale}</td>
                      <td style={{ textAlign: "center" }}><input type="checkbox" name="is_Paid"
                        checked={parseInt(row.is_paid) === 1 ? true : false} onChange={(e) => handleCheckChange(e, row)}></input></td>
                    </tr>
                  })) : (<div></div>)}
                  <tr>
                    <td style={{ textAlign: "right" }} colSpan={7}>Total Bill Amount</td>
                    <td style={{ textAlign: "right" }}> {isNaN(parseFloat(creditTransaction.totalCreditBillAmount)) ? 0 : +creditTransaction.totalCreditBillAmount}</td>
                    <td>{ }</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }} colSpan={7}>Balance</td>
                    <td style={{ textAlign: "right" }}> {"("} {creditTransaction.debitDiffAmountMO.symbol} {")"} {isNaN(parseFloat(creditTransaction.debitDiffAmountMO.diffValue)) ? 0 : +creditTransaction.debitDiffAmountMO.diffValue}</td>
                    <td>{ }</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }} colSpan={7}>Total Payable Amount is</td>
                    <td style={{ textAlign: "right" }}>{isNaN(parseFloat(totalOutStanding)) ? 0 : totalOutStanding}</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "right" }} colSpan={8}><span className='errorMsg'> {creditTransaction.cumulativePayError}</span>Paying Amount is</td>
                    <td style={{ textAlign: "right" }}>{isNaN(parseFloat(cumulativePay)) ? 0 : +cumulativePay}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row mt-3">
              <div className="col-md-4 float-left">
                <label htmlFor="title">Payment Mode</label><span className='errorMsg'>  {creditTransaction.paymentModeError}</span>
                <Autocomplete items={paymentModes}
                  searchField={'mode'}
                  renderItem={(item) =>
                    <>
                      {item.mode}
                    </>
                  }
                  onChange={(event, value) => { setPaymentMode({ ...paymentMode, ['mode']: value }) }}
                  onSelect={(value, item) => {
                    setPaymentMode(item ? item : '')
                  }}
                  value={paymentMode?.mode} />
              </div>
            </div>
            <div className="row mt-3">

              <div className="col-md-4">
                <label htmlFor="Date of sale">Credited Dttm</label><span className='errorMsg'>  {creditTransaction.creditedDttmError}</span>
                <DatePicker autoComplete="off" placeholderText="Select creditted date and time" selected={creditTransaction.credited_dttm} name="creditedDttm" id="creditedDttm" className="form-control" openToDate={new Date()} onChange={date => handleStartDate(date)} timeInputLabel="Time:" showTimeInput dateFormat="dd/MM/yyyy h:mm aa" />

              </div>
              <div className="col-md-4 float-right pr-1">
                <label htmlFor="saleamount">Payment <span className='errorMsg'>  {creditTransaction.creditError}</span></label>
                <NumberInput autoComplete="off" className='form-control' id="manualPaymentEntry" name="manualPaymentEntry" isDecScaleEnabled={true} decimalScale={2} value={manualPayEntry}
                  isThousandSepEnabled={true} thousandsGroupStyle="lakh" onChange={(value) => handleManualPaymentEntry(value)}></NumberInput>

                <label><span style={{ fontStyle: 'italic', fontSize: '12px' }}>(enter payment or select "is Bill Paid" check box)</span></label>
              </div>
            </div>
            <div className="row mt-5 mb-5" >
              <div className="col-md-8 float-right pr-0 ">
                <button
                  color="primary"
                  variant="contained"
                  className="add-btn btn float-right"
                  onClick={(e) => saveCreditTransaction(e)} disabled={submitted}
                >
                  {submitted ? 'Adding....' : 'Add Payments'}
                </button>
                <button
                  color="secondary"
                  variant="contained"
                  className="back-btn btn float-right"
                  onClick={invokeCredTransList}
                  style={{ marginRight: "1rem" }}>
                  Back to list
                </button>

              </div>

            </div>

          </div>
        </div> : <div className="col-md-6" style={{ marginTop: '100px' }}> {infoShow ? <Alert variant={alertVariant} onClose={() => setInfoShow(false)} dismissible>
          <Alert.Heading>{alertHeading}</Alert.Heading>
          <p>{message}</p>
        </Alert> : <div> </div>}</div>} </div>)}
    </div>
  );
};

export default AddCreditTransaction;
