const getAll = (axiosPrivate) => {
  return axiosPrivate.get("/fueltypes");
};

const getAllFuelTypes = (axiosPrivate, page) => {
  return axiosPrivate.post("/getfueltypes", page);
};
const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/fueltypes/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/fueltypes", data);
};

const update = (axiosPrivate, id, data) => {
  return axiosPrivate.put(`/fueltypes/${id}`, data);
};

const remove = (axiosPrivate, id) => {
  return axiosPrivate.delete(`/fueltypes/${id}`);
};

const removeAll = (axiosPrivate) => {
  return axiosPrivate.delete(`/fueltypes`);
};

const deleteFuelTypes = (axiosPrivate, data) => {
  return axiosPrivate.put("/deleteFuelTypes", data);
};

export default {
  getAll,
  getAllFuelTypes,
  get,
  create,
  update,
  remove,
  removeAll,
  deleteFuelTypes
};
