import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import RetailOutletDataService from "../../../services/admin/RetailOutletService";
import { Button } from "@material-ui/core";
import Constants from "../../../Constants";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const BankDetails = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const initialBankDetailsState = {
    id: "",
    accountName: "",
    accountNo: "",
    bankName: "",
    branch: "",
    ifscCode: "",
    isDeleted: "",
  };
  const [bankDetails, setBankDetails] = useState(initialBankDetailsState);

  const bankDetailsErr = {
    accountNameErr: "", accountNoErr: '', bankNameError: "", branchError: "", ifscCodeError: ""
  };
  const [bankDetailsError, setBankDetailsError] = useState(bankDetailsErr);
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setBankDetails({ ...bankDetails, [name]: value });
  };

  useEffect(() => {
    retreiveBankDetails();
  }, []);



  const retreiveBankDetails = () => {
    RetailOutletDataService.getBankDetails(axiosPrivate).then(response => {
      setBankDetails(response.data);
    })
      .catch(e => {
        setSubmitted(false);
        setMessage(e.response ?  e.response.data : Constants.ERROR_MSG + " bank details");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        console.log(e);
      });
  }


  const handleValidation = () => {
    if (!bankDetails.accountName) {
      setBankDetailsError({ ...bankDetailsError, "accountNameErr": "Please enter account name" });
      return false;
    }
    if (!bankDetails.accountNo) {
      setBankDetailsError({ ...bankDetailsError, "accountNameErr": "", "accountNoErr": "please enter account no" });
      return false;
    }
    if (!bankDetails.bankName) {
      setBankDetailsError({ ...bankDetailsError, "accountNameErr": "", "accountNoErr": '', "bankNameError": "Please enter bank name" });
      return false;
    }
    if (!bankDetails.branch) {
      setBankDetailsError({ ...bankDetailsError, "accountNameErr": "", "accountNoErr": "", "bankNameError": "", "branchError": "Please enter branch details" });
      return false;
    }
    if (!bankDetails.ifscCode) {
      setBankDetailsError({ ...bankDetailsError, "accountNameErr": "", "accountNoErr": '', "bankNameError": "", "branchError": "", "ifscCodeError": "Please enter ifsc code" });
      return false;
    }

    return true;
  };

  const saveBankDetails = (e) => {
    setShow(false);
    if (handleValidation()) {
      setSubmitted(true);
     

      RetailOutletDataService.saveBankDetails(axiosPrivate, bankDetails)
        .then(response => {
          setSubmitted(false);
          setMessage("Details saved successfully...");
          setAlertHeading("Success");
          setAlertVariant("success");
          setShow(true);
        })
        .catch(e => {
          setSubmitted(false);
          setMessage(e.response ?  e.response.data : "Error while saving bank details");
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setShow(true);
          console.log(e);
        });
    }
  };

  return (
    <div className="col-md-10">
      <div className="col-md-10 float-right">
        <div className="col-md-12 float-left pb-3 pt-3">
          <h4>Bank Details</h4>
        </div>
        {show ? (
          <div className="col-md-12 float-left">
            <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
              <Alert.Heading>{alertHeading}</Alert.Heading>
              <p>{message}</p>
            </Alert></div>

        ) : (<div></div>)}
        <div className=" col-md-6 float-left">
          <label htmlFor="title">Account Name</label><span className='errorMsg'>  {bankDetailsError.accountNameErr}</span>
          <input
            type="text"
            className="form-control"
            id="accountName"
            required
            value={bankDetails.accountName}
            autoComplete="off"
            onChange={(e) => handleInputChange(e)}
            name="accountName"
          />
        </div>
        <div className=" col-md-6 float-right">
          <label htmlFor="title">Account No</label>
          <span className='errorMsg'>  {bankDetailsError.accountNoErr}</span>
          <input
            type="text"
            className="form-control"
            id="accountNo"
            value={bankDetails.accountNo}
            onChange={(e) => handleInputChange(e)}
            name="accountNo"
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Bank Name</label><span className='errorMsg'>  {bankDetailsError.bankNameError}</span>
          <input
            type="text"
            className="form-control"
            id="bankName"
            required
            autoComplete="off"
            value={bankDetails.bankName}
            onChange={(e) => handleInputChange(e)}
            name="bankName"
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Branch</label><span className='errorMsg'>  {bankDetailsError.branchError}</span>
          <input
            type="text"
            className="form-control"
            id="branch"
            required
            autoComplete="off"
            value={bankDetails.branch}
            onChange={(e) => handleInputChange(e)}
            name="branch"
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">IFSC Code</label><span className='errorMsg'>  {bankDetailsError.ifscCodeError}</span>
          <input
            type="text"
            className="form-control"
            id="ifscCode"
            required
            autoComplete="off"
            value={bankDetails.ifscCode}
            onChange={(e) => handleInputChange(e)}
            name="ifscCode"
          />
        </div>
        <div className="col-md-12 float-left mt-2">
          <button
            color="primary"
            variant="contained"
            className="add-btn btn float-right"
            onClick={(e) => saveBankDetails(e)} disabled={submitted}
          >
            {submitted ? 'Adding..' : 'Add Bank Details'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
