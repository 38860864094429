import { Table } from "@material-ui/core";
import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import DailyCollectionDateService from "../../../services/dailySales/DailyCollectionService";
import NumberInput from "../../common/NumberInput";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

export const Collection = props => {

  const [dateOfSaleId, setDateOfSaleId] = useState();
  const [dailyCollectionMOs, setDailyCollectionMOs] = useState([]);
  const [cashCollectionMOs, setCashCollectionMOs] = useState([]);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  var sno = 0;
  var cashNo = 0;
  useEffect(() => {
    if (props.location?.state?.id) {
      setDateOfSaleId(props.location.state.id);
      retrieveCollectionComponent(props.location.state.id);
    }
  }, [props.location?.state?.id]);


  const retrieveCollectionComponent = (id) => {
    //let locDateOfSale = format(dailySaleDateMO.daily_sale_date, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" });
    DailyCollectionDateService.get(axiosPrivate, id)
      .then(response => {
        setCashCollectionMOs(response.data.cashCollectionMOs);
        setDailyCollectionMOs(response.data.dailyCollectionMOs);
      })
      .catch(e => {
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        console.log(e);
      });
  };

  const onChange = (value, row, rowIndex, columnId) => {
    setDailyCollectionMOs(old =>
      old.map((dailyCollectionMO, index) => {
        if (index === rowIndex && columnId === 'amount') {
          dailyCollectionMO.amount = parseFloat(value);
        }
        return dailyCollectionMO;
      }))
  }


  const onDenominationChange = (value, row, rowIndex, columnId) => {
    console.log(" value " + value);
    if (isNaN(parseFloat(value))) {
      value = 0;
      console.log(" inside if value " + value);
    } else {
      console.log(" inside else value " + value);
      value = parseFloat(value);
    }

    setCashCollectionMOs(old =>
      old.map((cashCollectionMO, index) => {
        if (index === rowIndex && columnId === 'count') {
          cashCollectionMO.amount = value * parseFloat(cashCollectionMO.denominationMO.value);
          cashCollectionMO.count = value;
          console.log("cashCollectionMO.amount   " + cashCollectionMO.amount + " cashCollectionMO.count  " + cashCollectionMO.count);
        }
        var totalCashAmount = 0;
        cashCollectionMOs.forEach((cashCollectionMO, cashIndex) => {
          totalCashAmount = parseFloat(totalCashAmount) + parseFloat(cashCollectionMO.amount);
          dailyCollectionMOs.forEach((dailyCollectionMO, cashIndex) => {
            if (dailyCollectionMO.collectionModeMO.mode === 'CASH') {
              console.log("dailyCollectionMO.amount   " + totalCashAmount);
              dailyCollectionMO.amount = totalCashAmount;
            }
          });
        });
        return cashCollectionMO;
      }))
  }

  const invokeFuelSales = () => {
    //navigation.next()
    props.history.push({ pathname: "/fmApp/dailyCreditSummary", state: { id: dateOfSaleId } });
  }

  const saveAndProceed = () => {
    if (submitted) {
      return
    }
    setSubmitted(true);
    setMessage("adding ....");
    var dailyCollectionCompMO = {
      dailyCollectionMOs: dailyCollectionMOs,
      cashCollectionMOs: cashCollectionMOs,
      dailySaleDateMO: {
        id: dateOfSaleId,
      }
    };
    DailyCollectionDateService.create(axiosPrivate, dailyCollectionCompMO)
      .then(response => {
        props.history.push({ pathname: "/fmApp/dailySalesSummary", state: { id: dateOfSaleId, isSummaryOnly: 0 } });
      }).catch(e => {
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
      });

  }

  return (
    <div className="sales-form list row">
      <div className="col-md-12" style={{ marginLeft: '15%' }}>
        <h3>Collection</h3>
        {show ? (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        ) : (<div></div>)}
        <div>
          <Table className="fmanTable col-md-4">
            <thead>
              <tr>
                <th>No</th>
                <th>Mode</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {dailyCollectionMOs.map((row, index) => {
                if (row.collectionModeMO.mode !== 'CASH') {
                  return <tr>
                    <td>{sno = sno + 1}</td>
                    <td>{row.collectionModeMO.mode}</td>
                    <td>
                      <NumberInput autoComplete="off" style={{ textAlign: 'right' }} id="amount" name="amount" isDecScaleEnabled={true} decimalScale={2} value={(!row.amount) ? 0 : row.amount}
                        onChange={(value) => onChange(value, row, index, 'amount')}></NumberInput>

                    </td>
                  </tr>
                }
              })}

            </tbody>
          </Table>
        </div>
        <div>
          <Table className="fmanTable col-md-6">
            <thead>
              <tr>
                <th>No</th>
                <th>Denomination</th>
                <th>Count</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {dailyCollectionMOs.map((row, index) => {
                if (row.collectionModeMO.mode === 'CASH') {
                  return cashCollectionMOs.map((cashRow, cashIndex) => {
                    return <tr>
                      <td>{cashNo = cashNo + 1}</td>
                      <td>{cashRow.denominationMO.denomination}</td>
                      <td>
                        <NumberInput autoComplete="off" id="count" name="count" isDecScaleEnabled={false} value={!cashRow.count ? 0 : cashRow.count}
                          onChange={(value) => onDenominationChange(value, cashRow, cashIndex, 'count')}></NumberInput>
                      </td>
                      <td style={{ textAlign: "right" }} name='amount'>{isNaN(parseFloat(cashRow.amount)) ? 0 : parseFloat(cashRow.amount).toFixed(2)}</td>
                    </tr>
                  })
                }
              })}
              {dailyCollectionMOs.map((row, index) => {
                if (row.collectionModeMO.mode === 'CASH') {
                  return <tr>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>Total Cash</td>
                    <td style={{ textAlign: "right" }} name='totalCash'>{isNaN(parseFloat(row.amount)) ? 0 : parseFloat(row.amount).toFixed(2)}</td>
                  </tr>
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6 p-0 mt-5 mb-5 float-left">
            <button
              color="primary"
              variant="contained"
              className="next-btn btn float-right"
              onClick={() => saveAndProceed()} disabled={submitted}
            >
              {submitted ? 'SAVING....' : 'NEXT'}
            </button>
            <button
              color="secondary"
              variant="contained"
              className="back-btn btn float-right"
              style={{ marginRight: "1rem" }}
              onClick={() => invokeFuelSales()}
            >
              BACK
            </button>

          </div>
        </div>
      </div>

    </div>
  );
};
export default Collection;
