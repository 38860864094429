import React, { useEffect, useRef, useState } from "react";
import FMTable from "../../common/FMTable";
import Loader from "../../common/Loader";
import MachineryService from "../../../services/admin/MachineryService";
import { Link } from "react-router-dom";
import { StylesProvider } from "@material-ui/core";
import { Alert, Button } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import EditPump from "./EditPump";
import AddPump from "./AddPump";
import Constants from "../../../Constants";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const Pumplist = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const [pumps, setPumps] = useState([]);
    const [open, setOpen] = useState(false);

    //alert constants
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    //alert constants

    const [pagination, setPagination] = useState({
        pageSelected: 1,
        sizePerPage: 10,
        filterMOs: []
    });

    const [pageMO, setPageMO] = useState({
        pageableObj: [],
        totalElements: 0,
        pagination: pagination
    });
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    const columns = [{
        dataField: 'code',
        text: 'Pump Code',
        sort: true,
        type: 'text',
        value: ''
    }, {
        dataField: 'modelNo',
        text: 'Model No',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'serial_no',
        text: 'Serial No',
        sort: true,
        type: 'text',
        value: ''
    }, {
        dataField: 'make',
        text: 'Make',
        sort: true,
        type: 'text',
        value: ''
    }, {
        dataField: 'displayOrder',
        text: 'Order',
        sort: true,
        type: 'text',
        value: ''
    }
    ]

    const updateParent = () => {
        setPumps([]);
        handleTableChange([], 1, pagination.sizePerPage);
    }

    const updateParentEditMode = () => {
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }

    const expandRow = {
        renderer: row => <EditPump data={row} updateParent={updateParentEditMode} />
    }

    const [updatePumps, setUpdatePumps] = useState([]);

    useEffect(() => {
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }, []);

    const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();

    const retreivePumpDetails = (pagination, actionType) => {
        console.log(pagination);
        console.log(actionType);
        if (actionType === "pagination") {
            handleToggle()
        }
        setTableDataRetreivalMsg(Constants.LOADING_DATA)
        MachineryService.getAllPumps(axiosPrivate, pagination).
            then(response => {
                setPumps(response.data.pageableObj);
                setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
                handleClose();
                setTableDataRetreivalMsg(null)
                console.log(response.data);
            })
            .catch(e => {
                setMessage(e.response ?  e.response.data : Constants.ERROR_MSG + " pump details");
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setShow(true);
                setTableDataRetreivalMsg(null)
                handleClose();
            })
    }

    const handleTableChange = (filters, page, sizePerPage, actionType) => {
        //const currentIndex = (page - 1) * sizePerPage;
        filters.map((filter) => {
            console.log("field --- " + filter.field + " filter value  --------------" + filter.value);
        })
        pagination.pageSelected = page;
        pagination.sizePerPage = sizePerPage;
        pagination.filterMOs = filters;
        retreivePumpDetails(pagination, actionType);
    }

    const deletePumps = (pumpList) => {
        MachineryService.deletePumps(axiosPrivate, pumpList)
            .then(response => {
                handleTableChange([], 1, pagination.sizePerPage);
            })
            .catch(response => {

            })
    }



    const [showButton, setShowButton] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const nodeRef = useRef(null);



    return (
        <div className="col-md-10">
            <div className="col-md-10 float-right">
                <div className="row"><h4>Pump Details</h4>{showButton && (
                    <h5 className="ml-3">  <Link className="addNewHeading"
                        onClick={() => setShowMessage(true)}

                    >
                        Add Pump

                    </Link></h5>
                )
                }</div>
                 {show && (
                    <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{alertHeading}</Alert.Heading>
                        <p>{message}</p>
                    </Alert>

                )}

                <div className="row">

                    <CSSTransition
                        in={showMessage}
                        nodeRef={nodeRef}
                        timeout={500}
                        classNames="newBox"
                        unmountOnExit
                        onEnter={() => setShowButton(false)}
                        onExited={() => setShowButton(true)}
                    >
                        <div className="addNewBox"
                            ref={nodeRef}
                        ><div className="col-md-12 p-0">
                                <div className="float-left col-md-10"><h5 style={{ marginLeft: '15px', marginTop: '5px' }}>Add Pump</h5> </div>
                                <div className="col-md-2 float-right p-0">
                                    <StylesProvider injectFirst>
                                        <Button className="float-right addNewClose"
                                            onClick={() => setShowMessage(false)}
                                        >
                                            X
                                        </Button></StylesProvider>
                                </div>
                            </div>
                            <div className="p-0  ml-0" style={{ height: 'auto' }}>
                                <AddPump updateParent={updateParent} />
                            </div>


                        </div>
                    </CSSTransition>
                </div>
                <div className="row mt-3">
                    <Loader openDialog={open}></Loader>
                    <FMTable dataRetrievalMsg={tableDataRetreivalMsg} columns={columns} dataList={pumps} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow} showFilter={false} showDelete={true} deleteHandler={deletePumps} />
                </div>
            </div>
        </div >
    )

}
export default Pumplist;