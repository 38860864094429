import React, { useState } from "react";
import { useEffect } from "react";
import { Upload as UploadIcon } from 'react-bootstrap-icons';

function Upload({ heading, setFile, file }) {


    const [seletedFile, setSelectedFile] = useState();
    const showFile = file;
    useEffect(() => {
        if (file != undefined) {
            var base64Image = `data:image/jpeg;base64,${file}`;
            setSelectedFile(base64Image);
        }

    }, [showFile]);


    function handleChange(e) {

        if (e.target.files.length > 0) {
            console.log(e.target.files);
            setSelectedFile(URL.createObjectURL(e.target.files[0]));
            const file = e.target.files[0];
            setFile({ ...file, ['file']: file, ['status']: true });
        }

    }



    return (
        <div className="col-md-12 p-0">
            <div className="col-md-4 float-left p-0">
                <h5>{heading}</h5>

                <label className="custom-file-upload" onChange={(e) => handleChange(e)}>
                    <input type="file" />
                    <UploadIcon color="white" title='upload from disc' size={20} />  Choose a file
                </label>

            </div>
            <div className="col-md-8 float-right">
                <img src={seletedFile} className="borderItem upload-box" style={{ height: '80px', width: '80px' }} />
            </div>

        </div>

    );
}

export default Upload;