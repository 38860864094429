
import { useState } from 'react';
import '../../assets/css/forgotpassword.css';
import UserService from '../../services/admin/UserService';
import Constants from '../../Constants';
import { Button } from 'react-bootstrap';

const ForgotPassword = props => {

    const [submitted, setSubmitted] = useState(false);
    const [productUserMO, setProductUserMO] = useState({
        customerId: '',
        emailId: ''
    });

    const [productUserErr, setProductUserErr] = useState({
        customerIdErr: '',
        emailIdErr: ''
    })

    const [requestMO, setRequestMO] = useState({ objects: '' });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setProductUserMO({ ...productUserMO, [name]: value })
    }

    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    const handleValidation = () => {
        if (!productUserMO?.customerId) {
            setProductUserErr({ ...productUserErr, 'customerIdErr': 'please enter customer id' });
            return false;
        }
        if (!productUserMO.emailId) {
            setProductUserErr({ ...productUserErr, 'customerIdErr': '', 'emailIdErr': 'please enter email id' });
            return false;
        } else if (!productUserMO.emailId.match(isValidEmail)) {
            setProductUserErr({ ...productUserErr, 'customerIdErr': '', 'emailIdErr': "'" + productUserMO.emailId + "' is not a valid email address" });
            return false;
        }
        setProductUserErr({ ...productUserErr, 'customerIdErr': '', 'emailIdErr': "" });
        return true;
    }

    const resetPassword = (e) => {
        if (handleValidation()) {
            setSubmitted(true);
            var requestMap = { "customerId": productUserMO.customerId, "emailId": productUserMO.emailId };
            requestMO.objects = requestMap;
            UserService.resetPassword(requestMO)
                .then(response => {
                    setSubmitted(false);
                    props.history.push({ pathname: Constants.INFO_PAGE, 
                        state: { infoContent: 'your new password has been sent to your registered mail id, please login again with new password', class : 'infoContentSuccess' } });
                })
                .catch(e => {
                    setSubmitted(false);
                    localStorage.setItem('errorMsg', e.response.data);
                    window.location.href = Constants.ERROR_PAGE;
                })
        }
    }

    return (
        <div className='forgotpwd col-md-12'>
            <div className='col-md-5 forgotpwdBox'>
                <h2>Reset Password</h2>
                <div className="forgotpwdComp">
                    <label>Customer Id   <span className='errorMsg'>{productUserErr.customerIdErr}</span></label>
                    <input name="customerId" onChange={(e) => handleInputChange(e)} type="text"></input>
                </div>
                <div className="forgotpwdComp">
                    <label>email Id  <span className='errorMsg'>{productUserErr.emailIdErr}</span></label>
                    <input name="emailId" onChange={(e) => handleInputChange(e)} type="text"></input>
                </div>
                <div className="forgotpwdComp">
                    <Button name="reset" disabled={submitted} onClick={(e) => resetPassword(e)} type="text">
                        {submitted ? 'reseting password...' : 'reset password'} </Button>
                </div>
            </div></div>
    )
}

export default ForgotPassword;