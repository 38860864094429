import React, { useState } from 'react';
import './assets/css/App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import FMApp from './FMApp';
import Admin from './Admin';
//login
import Login from './components/access/Login';
import CustomerLogin from './components/access/CustomerLogin';
//login
//global error page
import ErrorPage from './ErrorPage';
import Constants from './Constants'
import ActionSuccessPage from './ActionSuccessPage';

//forgot password
import ForgotPassword from './components/access/ForgotPassword';
import Home from './home'

function App() {
    return (
            <Switch>
              <Route exact path="/" component={Home} />
                <Route path={['/admin/*', '/admin']} component={Admin} />
              /**Login */
                <Route exact path={'/fmApp/customer/:id'} component={CustomerLogin} />
                <Route path={'/fmApp/forgotpassword'} component={ForgotPassword} />
                <Route path={'/fmApp/*'} component={FMApp} />
                <Route exact path={Constants.ERROR_PAGE} component={ErrorPage} />
                <Route exact path={Constants.INFO_PAGE} component={ActionSuccessPage} />
            </Switch>
    );
}

export default App;