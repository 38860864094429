const getAll = (axiosPrivate) => {
  return axiosPrivate.get("/fuel_stocks");
};

const getAllFuelStocks = (axiosPrivate, page) => {
  return axiosPrivate.post("/fuel_stocks", page);
};

const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/fuel_stock/${id}`);
};

const stockPrerequisites = (axiosPrivate) => {
  return axiosPrivate.get(`/stockPrerequisites`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/fuel_stock", data);
};

const update = (axiosPrivate, id, data) => {
  return axiosPrivate.put(`/fuel_stock/${id}`, data);
};

const remove = (axiosPrivate, id) => {
  return axiosPrivate.put(`/fuel_stock/delete/${id}`);
};

const removeAll = (axiosPrivate) => {
  return axiosPrivate.delete(`/fuel_stock`);
};


export default {
  getAll,
  getAllFuelStocks,
  get,
  create,
  update,
  remove,
  removeAll,
  stockPrerequisites
};
