import React, { useEffect, useState, useRef } from "react";
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import VehicleDataService from "../../services/vehicle/VehicleService";
import FMTable from "../common/FMTable";
import Loader from "../common/Loader";
import AddVehicle from "./AddVehicle";
import EditVehicle from "./EditVehicle";
import { CSSTransition } from "react-transition-group";
import { StylesProvider } from "@material-ui/core";
import { Button } from "react-bootstrap";
import useAxiosPrivate from "../../auth/useAxiosPrivate";
import Constants from "../../Constants";

const VehicleList = () => {
  const axiosPrivate = useAxiosPrivate();
  const [vehicles, setVehicles] = useState([]);
  const [currentVehicle, setCurrentVehicle] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchTitle, setSearchTitle] = useState("");
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);

  // pagination and filter constants//
  const [pagination, setPagination] = useState({
    pageSelected: 1,
    sizePerPage: 10,
    filterMOs: []
  });
  const [pageMO, setPageMO] = useState({
    pageableObj: [],
    totalElements: 0,
    pagination: pagination
  });

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  // pagination and filter constants//

  useEffect(() => {
    retrieveVehicles(pagination);
  }, []);

  const columns = [{
    dataField: 'vhRegNo',
    text: 'Vehicle No',
    type: 'text',
    sort: true,
  }, {
    dataField: 'vhType.type',
    text: 'Type',
    type: 'text',
    sort: true,
  }, {
    dataField: 'vhOwner.name',
    text: 'Owner',
    type: 'text',
    sort: true,
  }, {
    dataField: 'vhOwner.transport_name',
    text: 'Transport Name',
    type: 'text',
    sort: true,
  }];

  const updateParent = () => {
    //reload list when there is add new item
    setVehicles([]);
    handleTableChange([], 1, pagination.sizePerPage);
  }

  const updateParentEditMode = () => {
    handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
  }

  const updateParentDeleteMode = () => {
    //reload list when there is delete item
    setVehicles([]);
    handleTableChange(pagination.filterMOs, pagination.pageSelected, pagination.sizePerPage);
  }

  const expandRow = {
    renderer: row => <EditVehicle data={row} updateParentOnEdit={updateParentEditMode} updateParentOnDelete={updateParentDeleteMode} />
  };

  const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();

  const retrieveVehicles = (pagination, actionType) => {
    if (actionType === "pagination" || actionType == 'clearFilter') {
      handleToggle()
    }
    setTableDataRetreivalMsg(Constants.LOADING_DATA);
    setShow(false);
    VehicleDataService.getAllVehicles(axiosPrivate, pagination)
      .then(response => {
        setVehicles(response.data.pageableObj);
        setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
        handleClose();
        console.log(response.data);
        setTableDataRetreivalMsg(null);
      })
      .catch(e => {
        setMessage("Error while retrieving all vehicles");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        setTableDataRetreivalMsg(null);
        handleClose();
      });
  };

  const refreshList = () => {
    retrieveVehicles();
    setCurrentVehicle(null);
    setCurrentIndex(-1);
  };

  const setActiveVehicle = (vehilceType, index) => {
    setCurrentVehicle(vehilceType);
    setCurrentIndex(index);
  };

  const handleTableChange = (filters, page, sizePerPage, actionType) => {
    pagination.pageSelected = page;
    pagination.sizePerPage = sizePerPage;
    console.log(filters)
    pagination.filterMOs = filters;
    retrieveVehicles(pagination, actionType);
  }

  const [showButton, setShowButton] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const nodeRef = useRef(null);

  return (
    <div className="list row" style={{ width: '100%' }}>
      <div className="col-md-12">
        <div className="row"><h4>Vehicle List</h4>{showButton && (
          <h5 className="ml-3">  <Link className="addNewHeading"
            onClick={() => setShowMessage(true)}

          >
            Add Vehicle

          </Link></h5>
        )
        }</div>

        <div className="row">
          {show && (
            <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
              <Alert.Heading>{alertHeading}</Alert.Heading>
              <p>{message}</p>
            </Alert>

          )}
          <CSSTransition
            in={showMessage}
            nodeRef={nodeRef}
            timeout={500}
            classNames="newBox"
            unmountOnExit
            onEnter={() => setShowButton(false)}
            onExited={() => setShowButton(true)}
          >
            <div className="addNewBox" style={{width:'100%'}}
              ref={nodeRef}
            ><div style={{width:'100%'}}>
                <div className="float-left col-md-10"><h5 style={{ marginLeft: '15px', marginTop: '5px' }}>Add Vehicle</h5> </div>
                <div className="col-md-2 float-right p-0">
                  <StylesProvider injectFirst>
                    <Button className="float-right addNewClose"
                      onClick={() => setShowMessage(false)}
                    >
                      X
                    </Button></StylesProvider>
                </div>

                <div className="p-0  ml-0" style={{ height: 'auto' }}>
                  <AddVehicle updateParent={updateParent} />
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className="row mt-3">
          <Loader openDialog={open}></Loader>
          <FMTable columns={columns} dataRetrievalMsg={tableDataRetreivalMsg} dataList={vehicles} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow} />
        </div>
      </div>
    </div>
  );
};

export default VehicleList;
