
const getAllFuelComponent = (axiosPrivate, id) => {
  return axiosPrivate.get(`/fuelComponent?id=${id}`);
};

const getDailyFuelDiscountComp = (axiosPrivate, id) => {
  return axiosPrivate.get(`/getDailyFuelDiscountComp/${id}`);
};

const getAllNewPriceSale = (axiosPrivate, id) => {
  return axiosPrivate.get(`/newPriceSaleDetails/${id}`);
};

const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/dailySaleDateDetails/${id}`);
};

const saveDailyFuleSales = (axiosPrivate, data) => {
  return axiosPrivate.post('/saveDailyFuelSales', data);
};

const saveDailyFuelDiscount = (axiosPrivate, data) => {
  return axiosPrivate.post('/saveDailyFuelDiscount', data);
};

const createDailySaleDate = (axiosPrivate, data) => {
  return axiosPrivate.post('/dailySaleDate', data);
};



export default {
  getAllFuelComponent,
  getDailyFuelDiscountComp,
  getAllNewPriceSale,
  get,
  saveDailyFuleSales,
  saveDailyFuelDiscount,
  createDailySaleDate,
};
