import { Table } from "@material-ui/core";
import { Button } from 'react-bootstrap';
import React, { useEffect, useState, useRef } from "react";
import Alert from 'react-bootstrap/Alert';
import DailyFuelSalesDataService from '../../../services/dailySales/DailyFuelSalesService';
import NumberInput from "../../common/NumberInput";
import Model from "../../common/editableTable/Model";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

export const FuelSales = props => {
  const axiosPrivate = useAxiosPrivate();
  //const { dailySaleDateMO, lastName, nickName } = formData;
  const [dateOfSaleId, setDateOfSaleId] = useState();
  const [pumpSales, setPumpSales] = useState([]);
  const [newPriceSales, setNewPriceSales] = useState([]);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [isNewPriceSale, setIsNewPriceSale] = useState(false);
  const [newPriceErroShow, setNewPriceErrorShow] = useState(false);
  const [data, setData] = React.useState()
  const [submitted, setSubmitted] = useState(false);
  const [modelVariant, setModelVariant] = useState();
  const autoRef = useRef(null);

  const initialFuelSalesMOError = {
    pumpError: '',
    salesPersonError: '',
    fromDttmError: '',
    toDttmError: '',
  }
  const [fuelSalesMOError, setFuelSalesMOError] = useState(initialFuelSalesMOError);
  function isArrayObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    //fix issue while navigation to admin page from here, id becomes undefined, hence check added
    if (props.location?.state?.id) {
      setDateOfSaleId(props.location.state.id);
      retrieveFuelComponent(props.location.state.id);
    }
  }, [props.location?.state?.id]);

  const onNewPriceChange = (value, row, rowIndex, columnId) => {
    setNewPriceSales(old =>
      old.map((newPriceSale, index) => {
        if (index === rowIndex && columnId === 'totalSaleVolume') {
          newPriceSale.totalSaleVolume = parseFloat(value);
          newPriceSale.totalSaleAmount = parseFloat(value) * (parseFloat(newPriceSale.newFuelPriceMO.price) - parseFloat(newPriceSale.currentFuelPriceMO.price));
        }
        return newPriceSale
      }))
  };

  const onChange = (value, currentPump, currentNozzle, rowIndex, columnId) => {
    setPumpSales(old =>
      old.map((pump, pumpIndex) => {
        let nozzles = pump.nozzleSalesMOs
        nozzles.map((nozzle, nozzleIndex) => {
          if (pump.pumpMO.code === currentPump.pumpMO.code && nozzleIndex === rowIndex) {
            if (nozzle.dailyFuelSalesMO === null || nozzle.dailyFuelSalesMO === 'undefined') {
              nozzle.dailyFuelSalesMO = { closingReading: 0, openingReading: 0, totalSaleVolume: 0, totalSaleAmount: 0, test: 0 }
            }
            if (columnId === 'dailyFuelSalesMO.closingReading') {
              nozzle.dailyFuelSalesMO.closingReading = value;
              nozzle.dailyFuelSalesMO.totalSaleVolume = parseFloat((nozzle.dailyFuelSalesMO.closingReading) - +parseFloat(nozzle.dailyFuelSalesMO.openingReading, 3))
              nozzle.dailyFuelSalesMO.totalSaleVolume = nozzle.dailyFuelSalesMO.totalSaleVolume - +parseFloat(nozzle.dailyFuelSalesMO.test, 3)
              nozzle.dailyFuelSalesMO.totalSaleAmount = nozzle.dailyFuelSalesMO.totalSaleVolume * nozzle.fuelPriceMO.price
            } else if (columnId === 'dailyFuelSalesMO.openingReading') {
              nozzle.dailyFuelSalesMO.openingReading = value;
              nozzle.dailyFuelSalesMO.totalSaleVolume = parseFloat((nozzle.dailyFuelSalesMO.closingReading) - +parseFloat(nozzle.dailyFuelSalesMO.openingReading, 3))
              nozzle.dailyFuelSalesMO.totalSaleVolume = nozzle.dailyFuelSalesMO.totalSaleVolume - +parseFloat(nozzle.dailyFuelSalesMO.test, 3)
              nozzle.dailyFuelSalesMO.totalSaleAmount = nozzle.dailyFuelSalesMO.totalSaleVolume * nozzle.fuelPriceMO.price
            } else if (columnId === 'dailyFuelSalesMO.test') {
              nozzle.dailyFuelSalesMO.test = +parseFloat(nozzle.dailyFuelSalesMO.test)
              if (+parseFloat(nozzle.dailyFuelSalesMO.test) > 0) {
                nozzle.dailyFuelSalesMO.totalSaleVolume = +parseFloat((parseFloat((nozzle.dailyFuelSalesMO.closingReading) - +parseFloat(nozzle.dailyFuelSalesMO.openingReading), 3)) + parseFloat(nozzle.dailyFuelSalesMO.test), 3)
              }
              nozzle.dailyFuelSalesMO.test = value
              nozzle.dailyFuelSalesMO.test = +parseFloat((value === '' ? 0 : value))
              nozzle.dailyFuelSalesMO.totalSaleVolume = +parseFloat((parseFloat((nozzle.dailyFuelSalesMO.closingReading) - +parseFloat(nozzle.dailyFuelSalesMO.openingReading), 3)) - parseFloat(nozzle.dailyFuelSalesMO.test), 3)
              nozzle.dailyFuelSalesMO.totalSaleAmount = nozzle.dailyFuelSalesMO.totalSaleVolume * nozzle.fuelPriceMO.price

            }
            //nozzle.dailyFuelSalesMO.totalSaleVolume = parseFloat((nozzle.dailyFuelSalesMO.closingReading) - parseFloat(nozzle.dailyFuelSalesMO.openingReading), 3)
            //nozzle.dailyFuelSalesMO.totalSaleAmount = nozzle.dailyFuelSalesMO.totalSaleVolume * nozzle.fuelPriceMO.price

            //  nozzle = {...nozzle, [columnId] : value, [nozzle.dailyFuelSalesMO.totalSaleVolume] : totalSaleVolume}
            // nozzle.dailyFuelSalesMO.openingReading = value

            //nozzles.nozzleMOs = {...nozzle, 'code' : value}
            return nozzle
          }


          return nozzle
        })
        pump.nozzleMOs = nozzles;
        pump.totalPumpSaleVolume = 0
        pump.totalPumpSaleAmount = 0
        pump.nozzleMOs.map((nozzleMO, nozzleIndex) => {
          if (nozzleMO.dailyFuelSalesMO !== null && !isNaN(parseFloat(nozzleMO.dailyFuelSalesMO.totalSaleVolume))) {
            pump.totalPumpSaleVolume = pump.totalPumpSaleVolume + nozzleMO.dailyFuelSalesMO.totalSaleVolume
            pump.totalPumpSaleAmount = pump.totalPumpSaleAmount + nozzleMO.dailyFuelSalesMO.totalSaleAmount
          }
        })
        return pump
      })
    )
  };

  const retrieveFuelComponent = (id) => {
    //let locDateOfSale = format(dailySaleDateMO.daily_sale_date, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" });
    DailyFuelSalesDataService.getAllFuelComponent(axiosPrivate, id)
      .then(response => {
        setPumpSales(response.data.pumpSalesMOs);
        if (response.data.newPriceSaleMOs !== null) {
          setIsNewPriceSale(true);
          setNewPriceSales(response.data.newPriceSaleMOs);
        }
        setData(response.data.pumpSalesMOs);

      })
      .catch(e => {
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        console.log(e);
      });
  };

  const handleCheckChange = (event) => {
    const checked = event.target.checked;
    if (checked) {
      setIsNewPriceSale(true);
      DailyFuelSalesDataService.getAllNewPriceSale(axiosPrivate, dateOfSaleId)
        .then(response => {
          setNewPriceSales(response.data);
          console.log(response.data);
        })
        .catch(e => {
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setNewPriceErrorShow(true);
          console.log(e);
        });
    } else if (!checked) {
      setIsNewPriceSale(false);
      setNewPriceSales([]);
    }
  };

  const handleValidation = () => {
    var isValid = false;
    pumpSales.map((pump, pumpIndex) => {
      let nozzles = pump.nozzleSalesMOs
      nozzles.map((nozzle, nozzleIndex) => {
        if (nozzle.dailyFuelSalesMO === null || (nozzle.dailyFuelSalesMO.closingReading === "" || nozzle.dailyFuelSalesMO.closingReading === undefined || nozzle.dailyFuelSalesMO.closingReading === null || nozzle.dailyFuelSalesMO.closingReading === 0)) {
          setFuelSalesMOError({ ...fuelSalesMOError, ["pumpError"]: "Please enter closing reading for Pump " + pump.pumpMO.code + " --> Nozzle " + nozzle.nozzleMO.code });
          setModelVariant('error')
          setMessage("Please enter closing reading for Pump " + pump.pumpMO.code + " --> Nozzle " + nozzle.nozzleMO.code);
          autoRef.current.setModelOpen(true);
          isValid = false;
          return false;
        } else if (nozzle.dailyFuelSalesMO === null || (nozzle.dailyFuelSalesMO.openingReading === "" || nozzle.dailyFuelSalesMO.openingReading === undefined || nozzle.dailyFuelSalesMO.openingReading === null || nozzle.dailyFuelSalesMO.openingReading === 0)) {
          setFuelSalesMOError({ ...fuelSalesMOError, ["pumpError"]: "Please enter opening reading for Pump " + pump.pumpMO.code + " --> Nozzle " + nozzle.nozzleMO.code });
          isValid = false;
          setModelVariant('error')
          setMessage("Please enter opening reading for Pump " + pump.pumpMO.code + " --> Nozzle " + nozzle.nozzleMO.code);
          autoRef.current.setModelOpen(true);
          return false;
        } else if (nozzle.dailyFuelSalesMO === null || nozzle.dailyFuelSalesMO.totalSaleVolume < 0) {
          setFuelSalesMOError({ ...fuelSalesMOError, ["pumpError"]: "Sale Volume for Pump " + pump.pumpMO.code + " --> Nozzle " + nozzle.nozzleMO.code + " shoule not be negative" });
          isValid = false;
          setModelVariant('error')
          setMessage("Sale Volume for Pump " + pump.pumpMO.code + " --> Nozzle " + nozzle.nozzleMO.code + " shoule not be negative");
          autoRef.current.setModelOpen(true);
          return false;
        } else if (pumpIndex === 0 && nozzleIndex === 0) {
          isValid = true;
          return true;
        }
      });
    });

    return isValid;
  }
  const saveAndProceed = () => {
    if (handleValidation()) {
      if (submitted) {
        return
      }
      setNewPriceErrorShow(false);
      setSubmitted(true);
      setFuelSalesMOError({ ...fuelSalesMOError, ["pumpError"]: "" });
      var dailyFuelCompMO = {
        pumpSalesMOs: pumpSales,
        newPriceSaleMOs: newPriceSales,
        dailySaleDateMO: {
          id: dateOfSaleId,
        }
      };
      DailyFuelSalesDataService.saveDailyFuleSales(axiosPrivate, dailyFuelCompMO)
        .then(response => {
          //  console.log(response.data);
          props.history.push({ pathname: "/fmApp/dailyFuelDiscount", state: { id: dateOfSaleId } });
        })
        .catch(e => {
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setShow(true);
          console.log(e);
        });
      setMessage("adding ....");
    }
  }

  const invokeDateOfSale = () => {
    //navigation.back()
    props.history.push({ pathname: "/fmApp/dailySaleDateDetails", state: { id: dateOfSaleId } });
  }

  const style = {
    marginLeft: '10%',
    width: '70%',
    zIndex: 1,
    position: 'absolute'
  }

  const closeAlertBoxAction = () => {
    setShow(false);
    setSubmitted(false);
  }

  return (

    <div className="sales-form list row">
      <div className="col-md-12"><Model style={style} message={message} ref={autoRef} hideDuration={6000} variant={modelVariant}></Model>
        {show ? (
          <Alert variant={alertVariant} onClose={(e) => closeAlertBoxAction()} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        ) : (<div>
          {pumpSales.map((pumpSales, index) => {
            return <Table className="fmanTable">
              <thead>
                <tr style={{ border: 'none' }}><th id="caption" style={{ backgroundColor: '#343a40', border: '1px solid #343a40' }}>{pumpSales.pumpMO.code}
                </th></tr>
                <tr>
                  <th>Nozzle No</th>
                  <th>Fuel Type</th>
                  <th>Price</th>
                  <th>Opening Reading</th>
                  <th>Closing Reading</th>
                  <th>Test</th>
                  <th>Sale Volume</th>
                  <th>Sale Amount</th>
                </tr>
              </thead>
              <tbody>
                {pumpSales.nozzleSalesMOs.map((nozzle, index) => {
                  return <tr>
                    <td>{nozzle.nozzleMO.code}</td>
                    <td>{nozzle.nozzleMO.fuelTypeMO.type}</td>
                    <td>{nozzle.fuelPriceMO.price}</td>
                    <td>
                      <NumberInput autoComplete="off" id="dailyFuelSalesMO.openingReading" name="dailyFuelSalesMO.openingReading" isDecScaleEnabled={true} decimalScale={3} value={!nozzle.dailyFuelSalesMO?.openingReading ? 0 : +nozzle.dailyFuelSalesMO.openingReading}
                        onChange={(value) => onChange(value, pumpSales, nozzle, index, 'dailyFuelSalesMO.openingReading')}></NumberInput>
                    </td>
                    <td>
                      <NumberInput autoComplete="off" id="dailyFuelSalesMO.closingReading" name="dailyFuelSalesMO.closingReading" isDecScaleEnabled={true} decimalScale={3} value={!nozzle.dailyFuelSalesMO?.closingReading ? 0 : +nozzle.dailyFuelSalesMO.closingReading}
                        onChange={(value) => onChange(value, pumpSales, nozzle, index, 'dailyFuelSalesMO.closingReading')}></NumberInput>
                    </td>
                    <td style={{ width: "10%" }}>
                      <NumberInput autoComplete="off" style={{ width: '100%' }} id="dailyFuelSalesMO.test" name="dailyFuelSalesMO.test" isDecScaleEnabled={true} decimalScale={3} value={!nozzle.dailyFuelSalesMO?.test ? 0 : +nozzle.dailyFuelSalesMO.test}
                        onChange={(value) => onChange(value, pumpSales, nozzle, index, 'dailyFuelSalesMO.test')}></NumberInput>
                    </td>
                    <td name='dailyFuelSalesMO.totalSaleVolume'>{!nozzle.dailyFuelSalesMO || isNaN(parseFloat(nozzle.dailyFuelSalesMO?.totalSaleVolume)) ? 0 : (parseFloat(nozzle.dailyFuelSalesMO?.totalSaleVolume).toFixed(3))}</td>
                    <td name='dailyFuelSalesMO.totalSaleAmount'>{!nozzle.dailyFuelSalesMO || isNaN(parseFloat(nozzle.dailyFuelSalesMO?.totalSaleAmount)) ? 0 : (parseFloat(nozzle.dailyFuelSalesMO?.totalSaleAmount).toFixed(3))}</td>
                  </tr>
                })}
                <tr>
                  <td>{ }</td>
                  <td>{ }</td>
                  <td>{ }</td>
                  <td>{ }</td>
                  <td>{ }</td>
                  <td>Total</td>
                  <td name='totalPumpSaleVolume'>{isNaN(parseFloat(pumpSales.totalPumpSaleVolume)) ? 0 : parseFloat(pumpSales.totalPumpSaleVolume).toFixed(3)}</td>
                  <td name='totalPumpSaleAmount'>{isNaN(parseFloat(pumpSales.totalPumpSaleAmount)) ? 0 : parseFloat(pumpSales.totalPumpSaleAmount).toFixed(3)}</td>
                </tr>
              </tbody>
            </Table>
          })
          }
          {
            pumpSales.length > 0 &&
            <> <input type="checkbox" name="isNewPriceSale" id="isNewPriceSale"
              checked={newPriceSales?.length > 0 ? true : false}
              onChange={handleCheckChange}
            /><span><lable>{ }is New Price Sale?</lable></span></>
          }

          <div>
            {newPriceErroShow ? (
              <Alert variant={alertVariant} onClose={() => newPriceErroShow(false)} dismissible>
                <Alert.Heading>{alertHeading}</Alert.Heading>
                <p>{message}</p>
              </Alert>

            ) : (<div></div>)}
            <Table className="fmanTable">
              {(!isArrayObjectEmpty(newPriceSales)) ? (
                <thead>
                  <tr>
                    <th>Fuel Type</th>
                    <th>Current Fuel Price</th>
                    <th>New Fuel Price</th>
                    <th>Difference</th>
                    <th>Sale Volume</th>
                    <th>Sale Amount</th>
                  </tr>
                </thead>)
                : (<div></div>)}
              <tbody>
                {newPriceSales?.map((row, index) => {
                  return <tr>
                    <td>{row.currentFuelPriceMO.fuel_type.type}</td>
                    <td>{row.currentFuelPriceMO.price}</td>
                    <td>{row.newFuelPriceMO.price}</td>
                    <td>{parseFloat(parseFloat(row.newFuelPriceMO.price) - parseFloat(row.currentFuelPriceMO.price)).toFixed(3)}</td>
                    <td>
                      <NumberInput autoComplete="off" id="totalSaleVolume" name="totalSaleVolume" isDecScaleEnabled={true} decimalScale={3} value={!row.totalSaleVolume ? 0 : row.totalSaleVolume}
                        onChange={(value) => onNewPriceChange(value, row, index, 'totalSaleVolume')}></NumberInput>
                    </td>
                    <td>{isNaN(parseFloat(row.totalSaleAmount)) ? 0 : parseFloat(row.totalSaleAmount).toFixed(3)}</td>
                  </tr>
                })}

              </tbody>
            </Table>
          </div>

          < div className="col-md-12 p-0 mt-5 mb-5 float-left">
            <button
              color="primary"
              variant="contained"
              className="next-btn btn float-right"
              onClick={() => saveAndProceed()} disabled={submitted}
            >
              {submitted ? 'SAVING....' : 'NEXT'}
            </button>
            <button
              color="secondary"
              className="back-btn btn float-right"
              variant=" contained"
              style={{ marginRight: "1rem" }}
              onClick={() => invokeDateOfSale()}
            >
              BACK
            </button>

          </div>
        </div>)}
      </div>
    </div >
  );
};
export default FuelSales;