import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import { StylesProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import VehicleOwnerDataService from "../../services/vehicle/VehicleOwnerService";
import useAxiosPrivate from "../../auth/useAxiosPrivate";
import Constants from "../../Constants";
import AlertDialog from "../common/AlertDialog";

const EditVehicleOwner = ({ data, updateParentOnEdit, updateParentOnDelete }) => {
  const axiosPrivate = useAxiosPrivate();
  const initialVehicleOwnerState = {
    id: null,
    name: "",
    mobile_no: "",
    transport_name: "",
    is_deleted: ""
  };
  const [currentVehicleOwner, setCurrentVehicleOwner] = useState(data);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [updateSubmitted, setUpdateSubmitted] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  const initialVhOwnerError = {
    mobileError: "",
    nameError: "",
    transportNameError: ""
  };
  const [vehicleOwnerError, setVehicleOwnerError] = useState(initialVhOwnerError);

  const getVehicleOwner = id => {
    VehicleOwnerDataService.get(axiosPrivate, id)
      .then(response => {
        setCurrentVehicleOwner(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };



  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentVehicleOwner({ ...currentVehicleOwner, [name]: value });
  };

  const handleValidation = () => {
    if (currentVehicleOwner.name === null || currentVehicleOwner.name === '') {
      setVehicleOwnerError({ ...vehicleOwnerError, 'nameError': "Please enter name" });
      return false;
    }
    if (currentVehicleOwner.mobile_no === null || currentVehicleOwner.mobile_no === '') {
      setVehicleOwnerError({ ...vehicleOwnerError, 'nameError': '', 'mobileError': "Please enter mobile number" });
      return false;
    } else if (currentVehicleOwner.mobile_no.length !== 10) {
      setVehicleOwnerError({ ...vehicleOwnerError, 'nameError': '', 'mobileError': "Invalid: please enter '10' digit mobile number" });
      return false;
    }
    if (currentVehicleOwner.transport_name === null || currentVehicleOwner.transport_name == '') {
      setVehicleOwnerError({ ...vehicleOwnerError, 'nameError': '', 'mobileError': "", 'transportNameError': "Please enter transport name" });
      return false;
    }
    setVehicleOwnerError({ ...vehicleOwnerError, 'nameError': '', 'mobileError': '', 'transportNameError': "" });
    return true;
  };

  const updateVehicleOwner = () => {
    if (handleValidation()) {
      setUpdateSubmitted(true)
      VehicleOwnerDataService.update(axiosPrivate, currentVehicleOwner.id, currentVehicleOwner)
        .then(response => {
          console.log(response.data);
          updateParentOnEdit();
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setUpdateSubmitted(false);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully updated");
          setUpdateSubmitted(false);
        })
        .catch(e => {
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setUpdateSubmitted(false);
          setShow(true);
        });
    }
  };

  const deleteVehicleOwner = () => {
    currentVehicleOwner.is_deleted = 1;
    setDeleteSubmitted(true);
    VehicleOwnerDataService.deleteVehOwner(axiosPrivate, currentVehicleOwner.id, currentVehicleOwner)
      .then(response => {
        updateParentOnDelete();
      })
      .catch(e => {
        setMessage(e.response.data);
        setDeleteSubmitted(false);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
      });
  };
  /**delete action  */

  const [dialogShow, setDialogShow] = useState(false);

  const yesBtnHandler = {
    name: 'Yes',
    //dont add deleteFuelPrice() with  open and close bracket, which will cause indefinte 
    // loop always refer function without braces
    callAction: deleteVehicleOwner
  };

  const noBtnHandler = {
    name: 'No',
  };

  function deleteAction() {
    setDialogShow(true);
  }

  /**delete action  */
  return (
    <div className="p-0">
      <div className="col-md-12 float-right">
        {dialogShow && (
          <AlertDialog openDialog={true} dialogShowStateVar={setDialogShow} dialogTitle={"Delete Action!!!"}
            message={Constants.DELETE_CONTENT}
            noBtnHandler={noBtnHandler} yesBtnHandler={yesBtnHandler} />
        )}
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <form>
          <div className="col-md-6 float-left">
            <label htmlFor="description">Name</label><span className='errorMsg'>  {vehicleOwnerError.nameError}</span>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={currentVehicleOwner.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-6 float-right">
            <label htmlFor="title">Mobile No</label><span className='errorMsg'>  {vehicleOwnerError.mobileError}</span>
            <input
              type="number"
              className="form-control"
              id="mobile_no"
              name="mobile_no"
              value={currentVehicleOwner.mobile_no}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-6 adminForm">
            <label htmlFor="title">Transport Name</label><span className='errorMsg'>  {vehicleOwnerError.transportNameError}</span>
            <input
              type="text"
              className="form-control"
              id="transport_name"
              name="transport_name"
              value={currentVehicleOwner.transport_name}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
            <div className="pr-0 mr-0 float-right">  <button
              className="update-btn btn ml-0 float-right"
              variant="contained" type="button"
              onClick={updateVehicleOwner} disabled={updateSubmitted}
            >{updateSubmitted ? 'Updating....' : 'Update'}
            </button>
            </div>
            <div className="float-right mr-3">
              <button
                className="delete-btn btn float-right"
                variant="contained"
                disabled={deleteSubmitted}
                type="button"
                onClick={(e) => deleteAction()}
              >
                {deleteSubmitted ? 'Deleting...' : 'Delete'}
              </button>
            </div>
          </div>
        </form>
      </div>

    </div>
  );
};

export default EditVehicleOwner;
