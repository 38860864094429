import React, { useEffect, useState, useRef } from "react";
import { Alert, Button } from "react-bootstrap";
import VehicleOwnerDataService from "../../services/vehicle/VehicleOwnerService";
import FMTable from "../common/FMTable";
import Loader from "../common/Loader";
import EditVehicleOwner from "./EditVehicleOwner";
import AddVehilceOwner from "./AddVehicleOwner";
import { Link, StylesProvider } from "@material-ui/core";
import { CSSTransition } from "react-transition-group";
import useAxiosPrivate from "../../auth/useAxiosPrivate";
import Constants from "../../Constants";

const VehilceOwnerList = () => {
  const axiosPrivate = useAxiosPrivate();
  const [vehicleowner, setVehicleOwners] = useState([]);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  // pagination and filter constants//
  const [pagination, setPagination] = useState({
    pageSelected: 1,
    sizePerPage: 10,
    filterMOs: []
  });
  const [pageMO, setPageMO] = useState({
    pageableObj: [],
    totalElements: 0,
    pagination: pagination
  });

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  // pagination and filter constants//

  const columns = [{
    dataField: 'name',
    text: 'Name',
    type: 'text',
    sort: true
  }, {
    dataField: 'mobile_no',
    text: 'Mobile',
    type: 'text',
    sort: true
  },
  {
    dataField: 'transport_name',
    text: 'Transport Name',
    type: 'text',
    sort: true,
    headerStyle: (column, colIndex) => {
      return { width: '40%' };
    }
  }];

  const updateParent = () => {
    //reload list when there is add new item
    setVehicleOwners([]);
    handleTableChange([], 1, pagination.sizePerPage);
  }

  const updateParentEditMode = () => {
    handleTableChange(pagination.filterMOs, pagination.pageSelected, pagination.sizePerPage);
  }

  const updateParentDeleteMode = () => {
    //reload list when there is delete item
    setVehicleOwners([]);
    handleTableChange(pagination.filterMOs, pagination.pageSelected, pagination.sizePerPage);
  }

  const expandRow = {
    renderer: row => <EditVehicleOwner data={row} updateParentOnEdit={updateParentEditMode} updateParentOnDelete={updateParentDeleteMode}/>
  };

  const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
    }
  };

  const handleTableChange = (filters, page, sizePerPage, actionType) => {
    pagination.pageSelected = page;
    pagination.sizePerPage = sizePerPage;
    console.log(filters)
    pagination.filterMOs = filters;
    retrieveVehicleOwners(pagination, actionType);
  }

  useEffect(() => {
    retrieveVehicleOwners(pagination);
  }, []);

  const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();

  const retrieveVehicleOwners = (pagination, actionType) => {
    if (actionType === "pagination" || actionType == 'clearFilter') {
      handleToggle()
    }
    setTableDataRetreivalMsg(Constants.LOADING_DATA);
    setShow(false);
    VehicleOwnerDataService.getAllVehicleOwners(axiosPrivate, pagination)
      .then(response => {
        setVehicleOwners(response.data.pageableObj);
        setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
        handleClose();
        setTableDataRetreivalMsg(null);
      })
      .catch(e => {
        setMessage("Error while retrieving all vehicle owners");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        setTableDataRetreivalMsg(null);
      });
  };

  const [showButton, setShowButton] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const nodeRef = useRef(null);

  return (
    <div className="list row" style={{ width: '100%' }}>

      <div className="col-md-12">
        <div className="row"><h4>Vehicle Owners</h4>{showButton && (
          <h5 className="ml-3">  <a className="addNewHeading"
            onClick={() => setShowMessage(true)}

          >
            Add Vehicle Owner

          </a></h5>
        )
        }</div>
        {show && (
          <Alert variant={alertVariant} className="col-md-6" onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <div className="row">
          <CSSTransition
            in={showMessage}
            nodeRef={nodeRef}
            timeout={500}
            classNames="newBox"
            unmountOnExit
            onEnter={() => setShowButton(false)}
            onExited={() => setShowButton(true)}
          >
            <div className="addNewBox"
              ref={nodeRef}
            ><div>
                <div className="float-left col-md-10"><h5 style={{ marginLeft: '15px', marginTop: '5px' }}>Add Vehicle Owner</h5> </div>
                <div className="col-md-2 float-right p-0">
                  <StylesProvider injectFirst>
                    <Button className="float-right addNewClose"
                      onClick={() => setShowMessage(false)}
                    >
                      X
                    </Button></StylesProvider>
                </div>
              </div>
              <div className="p-0  ml-0" style={{ height: 'auto' }}>
                <AddVehilceOwner updateParent={updateParent} />
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className="row mt-3">
          <Loader openDialog={open}></Loader>
          <FMTable columns={columns} dataRetrievalMsg={tableDataRetreivalMsg} dataList={vehicleowner} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow} />
        </div>
      </div>

    </div >
  );
};

export default VehilceOwnerList;
