
const getAll = (axiosPrivate, pageMO) => {
  return axiosPrivate.post(`/creditTransactions`, pageMO);
};

const getAllByVhOwnerId = (axiosPrivate, page, sizePerPage, vhOwnerId) => {
  return axiosPrivate.get(`/creditTransactionsByVhOwnerId/${page}/${sizePerPage}/${vhOwnerId}`);
};

const getAllCreditReceipt = (axiosPrivate, page, sizePerPage) => {
  return axiosPrivate.get(`/getAllCreditReceipt/${page}/${sizePerPage}`);
};

const getCreditReceiptsForVhOwnerId = (axiosPrivate, page, sizePerPage, vhOwnerId) => {
  return axiosPrivate.get(`/getCreditReceiptsForVhOwnerId/${page}/${sizePerPage}/${vhOwnerId}`);
};

const getAllOutstandingCredits = (axiosPrivate, page) => {
  return axiosPrivate.post("/outstandingCredits", page);
};

const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/creditTransaction/${id}`);
};

const getByVhOwnerId = (axiosPrivate, id) => {
  return axiosPrivate.get(`/creditTransactionByVhOwner/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/creditTransaction", data);
};

const update = (axiosPrivate, data) => {
  return axiosPrivate.put(`/updateTransaction`, data);
};

const remove = (axiosPrivate, id) => {
  return axiosPrivate.delete(`/creditTransaction/${id}`);
};

const removeAll = (axiosPrivate) => {
  return axiosPrivate.delete(`/creditTransaction`);
};

const recallTransaction = (axiosPrivate, id) => {
  return axiosPrivate.put(`/recallTransaction/${id}`);
};

export default {
  getAll,
  getAllByVhOwnerId,
  getAllOutstandingCredits,
  get,
  getByVhOwnerId,
  create,
  update,
  remove,
  removeAll,
  getCreditReceiptsForVhOwnerId,
  recallTransaction,
  getAllCreditReceipt
};
