import React, { useEffect, useState, useRef } from "react";
import { Alert, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import FuelTypeDataService from "../../services/products/FuelTypeService";
import FMTable from '../common/FMTable';
import Loader from '../common/Loader';
import { StylesProvider } from '@material-ui/core';
import AddFuelType from './AddFuelType';
import { CSSTransition } from "react-transition-group";
import EditFuelType from './EditFuelType';
import Constants from "../../Constants";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const FuelTypesList = () => {
  const [fuelTypes, setFuelTypes] = useState([]);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  // pagination and filter constants//
  const [pagination, setPagination] = useState({
    pageSelected: 1,
    sizePerPage: 10,
    filterMOs: []
  });
  const [pageMO, setPageMO] = useState({
    pageableObj: [],
    totalElements: 0,
    pagination: pagination
  });

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  // pagination and filter constants//
  useEffect(() => {
    retrieveFuelTypes(pagination);
  }, []);

  const columns = [{
    dataField: 'type',
    text: 'Type',
    type: 'text',
    sort: true,
  }, {
    dataField: 'name',
    text: 'Name',
    type: 'text',
    sort: true,
  },
  {
    dataField: 'productCode',
    text: 'Product Code',
    type: 'text',
    sort: true,
  }, {
    dataField: 'minAllowableStock',
    text: 'Min Allowable Stock',
    type: 'text',
    sort: true,
  }];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
    }
  };

  const updateParentEditMode = () => {
    handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
  }


  const expandRow = {
    renderer: row => <EditFuelType data={row} items={fuelTypes} updateParent={updateParentEditMode} />
  };

  const handleTableChange = (filters, page, sizePerPage, actionType) => {
    pagination.pageSelected = page;
    pagination.sizePerPage = sizePerPage;
    console.log(filters)
    pagination.filterMOs = filters;
    retrieveFuelTypes(pagination, actionType);
  }

  const updateParent = () => {
    setFuelTypes([]);
    handleTableChange([], 1, pagination.sizePerPage);
  }

  const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();

  const retrieveFuelTypes = (pagination, actionType) => {
    if (actionType === "pagination" || actionType == 'clearFilter') {
      handleToggle()
    }
    setTableDataRetreivalMsg(Constants.LOADING_DATA)
    FuelTypeDataService.getAllFuelTypes(axiosPrivate, pagination)
      .then(response => {
        setFuelTypes(response.data.pageableObj);
        setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
        handleClose();
        setTableDataRetreivalMsg(null)
        console.log(response.data);
      })
      .catch(e => {
        handleClose();
        setTableDataRetreivalMsg(null)
        setMessage(e.response ?  e.response.data : Constants.ERROR_MSG + " fuel types");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        console.log(e);
      });
  };

  const deleteFuelTypes = (fuelTypeList) => {
    FuelTypeDataService.deleteFuelTypes(axiosPrivate, fuelTypeList)
      .then(response => {
        handleTableChange([], 1, pagination.sizePerPage);
      })
      .catch(e => {
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
      })
  }


  const [showMessage, setShowMessage] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const nodeRef = useRef(null);

  return (
    <div className="list row">
      <div className="col-md-10">
        <div className="row"><h4>Fuel Types</h4>{showButton && (
          <h5 className="ml-3">  <Link className="addNewHeading"
            onClick={() => setShowMessage(true)}

          >
            Add Fuel Type

          </Link></h5>
        )
        }</div>
        {show && (
          <Alert className="col-md-12" variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}

        <div className="row">

          <CSSTransition
            in={showMessage}
            nodeRef={nodeRef}
            timeout={500}
            classNames="newBox"
            unmountOnExit
            onEnter={() => setShowButton(false)}
            onExited={() => setShowButton(true)}
          >
            <div className="addNewBox"
              ref={nodeRef}
            ><div className="col-md-12 p-0">
                <div className="col-md-2 float-right p-0">
                  <StylesProvider injectFirst>
                    <Button className="float-right addNewClose"
                      onClick={() => setShowMessage(false)}
                    >
                      X
                    </Button></StylesProvider>
                </div>
              </div>
              <div className="p-0  ml-0" style={{ height: 'auto' }}>
                <AddFuelType dataList={fuelTypes} updateParent={updateParent} />
              </div>


            </div>
          </CSSTransition>
        </div>
        <div className="row mt-3">
          <Loader openDialog={open}></Loader>
          <FMTable dataRetrievalMsg={tableDataRetreivalMsg} columns={columns} dataList={fuelTypes} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow}
            showDelete={true} deleteHandler={deleteFuelTypes} />
        </div>

      </div>
    </div>
  );
};

export default FuelTypesList;
