import 'bootstrap/dist/css/bootstrap.min.css';
import { format } from 'date-fns-tz';
import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReportService from "../../services/report/ReportService";
import VehicleOwnerDataService from "../../services/vehicle/VehicleOwnerService";
import Autocomplete from '../common/Autocomplete';
import useAxiosPrivate from "../../auth/useAxiosPrivate";
import FMDatePicker from '../common/FMDatePicker';

const CreditInvoice = props => {
    const axiosPrivate = useAxiosPrivate();
    const [vehicleOwners, setVehicleOwnerList] = useState([]);
    const initialVehilceOwnerState = {
        name: "",
    }
    const [vehicleOwner, setVehicleOwner] = useState(initialVehilceOwnerState);

    const initialcreditInvoiceState = {
        vehicleOwnerMO: "",
        fromDate: "",
        toDate: "",
        toDateOnly: "",
        vhOwnerError: "",
        fromDateError: "",
        toDateError: "",
        withToDateError: ""
    }
    const [creditInvoice, setCreditInvoice] = useState(initialcreditInvoiceState);
    const [submitted, setSubmitted] = useState(false);
    const [toDateSubmitted, setToDateSubmitted] = useState(false);

    useEffect(() => {
        retrieveVehicleOwner();
    }, []);

    function renderVehicleNumber(item, value) {
        return (
            item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
    }

    const handleFromDate = date => {
        setCreditInvoice({ ...creditInvoice, toDateOnly: '', fromDate: date });
    }

    const handleToDate = date => {
        setCreditInvoice({ ...creditInvoice, toDateOnly: '', toDate: date });
    }


    const handleToDateOnly = date => {
        setCreditInvoice({ ...creditInvoice, fromDate: '', toDate: '', toDateOnly: date });
    }

    const retrieveVehicleOwner = () => {
        VehicleOwnerDataService.getAll(axiosPrivate)
            .then(response => {
                setVehicleOwnerList(response.data);
            })
            .catch(e => {
            });
    };

    const clearDropDown = () => {
        setVehicleOwner({ ...vehicleOwner, value: '', vehicleOwnersLst: undefined });
    }

    const handleValidation = () => {
        if (!vehicleOwner?.id) {
            setCreditInvoice({ ...creditInvoice, toDateOnly: '', toDateError: "", vhOwnerError: "please select vehicle owner from drop list" });
            return false;
        }
        if (!creditInvoice?.fromDate) {
            setCreditInvoice({ ...creditInvoice, toDateOnly: '', toDateError: "", vhOwnerError: "", fromDateError: "please select from date" });
            return false;
        }
        if (!creditInvoice?.toDate) {
            setCreditInvoice({ ...creditInvoice, toDateOnly: '', toDateError: "", vhOwnerError: "", fromDateError: "", toDateError: "please select to date" });
            return false;
        }
        return true;
    }


    const handleValidationWithToDate = () => {
        if (!vehicleOwner?.id) {
            setCreditInvoice({ ...creditInvoice, fromDate: '', toDate: '', fromDateError: "", toDateError: "", vhOwnerError: "please select vehicle owner from drop list" });
            return false;
        }

        if (!creditInvoice?.toDateOnly) {
            setCreditInvoice({ ...creditInvoice, fromDate: '', toDate: '', fromDateError: "", toDateError: "", vhOwnerError: "", withToDateError: "please select to date" });
            return false;
        }
        return true;
    }

    const generateCreditInvoiceWithToDate = () => {
        setCreditInvoice({ ...creditInvoice, vhOwnerError: "", fromDateError: "", toDateError: "", withToDateError: "" });
        if (handleValidationWithToDate()) {

            if (toDateSubmitted) {
                return
            }
            setToDateSubmitted(true);

            var toDate = format(creditInvoice.toDateOnly, "dd-MM-yyyy", { timeZone: "Asia/Kolkata" });
            var vhOwnerName = vehicleOwner.name.replaceAll(' ', '-');
            ReportService.genCreditBillWithToDate(axiosPrivate, vehicleOwner.id, toDate)
                .then(response => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([response.data]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        vhOwnerName + `_credit_bill_report.pdf`,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    setToDateSubmitted(false);
                })
                .catch(e => {
                });
        }
    };


    const generateCreditInvoice = () => {
        setCreditInvoice({ ...creditInvoice, vhOwnerError: "", fromDateError: "", toDateError: "", withToDateError: "" });
        if (handleValidation()) {

            if (submitted) {
                return
            }
            setSubmitted(true);
            var fromDate = format(creditInvoice.fromDate, "dd-MM-yyyy", { timeZone: "Asia/Kolkata" });
            var vhOwnerName = vehicleOwner.name.replaceAll(' ', '-');
            var toDate = format(creditInvoice.toDate, "dd-MM-yyyy", { timeZone: "Asia/Kolkata" });
            ReportService.genCreditBill(axiosPrivate, vehicleOwner.id, fromDate, toDate)
                .then(response => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([response.data]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        vhOwnerName +  `_credit_bill_report.pdf`,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    setSubmitted(false);
                })
                .catch(e => {
                });
        }
    };


    return (

        <div className="list">

            <div className="col-md-12 mt-4">
                <h4>Generate Credit Bill</h4>
                <div className="p-0 mt-3" style={{ width: '48%' }}>
                    <label htmlFor="title">Select Vehicle Owner</label><span className='errorMsg'>   {creditInvoice.vhOwnerError}</span>
                    <Autocomplete items={vehicleOwners}
                        searchField={'name'}
                        renderItem={(item) =>
                            <>
                                {item.name}
                            </>

                        }
                        // onChange={(event, value) => { setVehicleOwner({ ...vehicleOwner, ['name']: value }) }}
                        onSelect={(value, item) => {
                            setVehicleOwner(item);
                        }}
                        value={!!vehicleOwner && vehicleOwner.name} />
                </div>
                    <div className="list row mt-3 col-md-12 p-0">
                        <div className="mt-3 col-md-6 float-left pl-0">
                            <label htmlFor="Date of sale">From Date</label><span className='errorMsg'>   {creditInvoice.fromDateError}</span>
                            <FMDatePicker
                             autoComplete="off" 
                             placeholderText="Select from date"
                             date={creditInvoice.fromDate}
                             onChange={date => handleFromDate(date)}
                             dateFormat="dd/MM/yyyy"
                             ></FMDatePicker>
                        </div>
                        <div className="mt-3 col-md-6 float-right" >
                            <label htmlFor="Date of sale">To Date</label><span className='errorMsg'>  { }</span><span className='errorMsg'>   {creditInvoice.toDateError}</span>
                            <FMDatePicker
                             autoComplete="off" 
                             placeholderText="Select from date"
                             date={creditInvoice.toDate}
                             onChange={date => handleToDate(date)}
                             dateFormat="dd/MM/yyyy"
                             ></FMDatePicker>
                        </div>
                        <div className="mt-3 col-md-12 pl-0">
                            <button
                                className="download-btn btn"
                                onClick={(e) => generateCreditInvoice(e)} disabled={submitted}
                            >
                                {submitted ? 'Downloading....' : 'Download'}
                            </button>
                        </div>
                        <div className="mt-5"><p>-------------------------- OR ------------------------</p></div>
                    </div>


                    <div className="list row mt-2 col-md-10 p-0" >
                        <div className="mt-3 col-md-6 float-left pl-0 ">
                            <label htmlFor="Date of sale">To Date</label><span className='errorMsg'>  { }</span><span className='errorMsg'>   {creditInvoice.withToDateError}</span>
                            <FMDatePicker
                             autoComplete="off" 
                             placeholderText="Select to date"
                             onChange={date => handleToDateOnly(date)}
                             date={creditInvoice.toDateOnly}
                             dateFormat="dd/MM/yyyy"
                             ></FMDatePicker>
                        </div>

                        <div className="col-md-12 pl-0 mt-3">

                            <button
                                className="download-btn btn"
                                onClick={(e) => generateCreditInvoiceWithToDate(e)} disabled={toDateSubmitted}
                            >
                                {toDateSubmitted ? 'Downloading....' : 'Download'}
                            </button>

                        </div>
                    </div>
            </div>
        </div>
    )

}
export default CreditInvoice;