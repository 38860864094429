import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import DailySalesService from "../../../services/dailySales/DailySalesService";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const DailySaleDateInfo = props => {
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [message, setMessage] = useState("");
    const [appError, setAppError] = useState(false);
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        validateDailySaleDate(props.location.state.id);
    }, [props.location.state.id]);

    const validateDailySaleDate = (id) => {
        DailySalesService.get(axiosPrivate, id)
            .then(response => {
                props.history.push({ pathname: "/fmApp/dailySaleDateDetails", state: { id: id } });
            }).catch(e => {
                setAppError(true);
                setMessage(e.response.data);
                setAlertHeading("Error!");
                setAlertVariant("danger");
            });
    }
    return (
        <div>
            {appError ? (<div style={{marginLeft:'15%', width:'60%'}} >
                <Alert variant={alertVariant}>
                    <Alert.Heading>{alertHeading}</Alert.Heading>
                    <p style={{overflowWrap: 'true'}}>{message}</p>
                </Alert>
                <Link style={{ fontWeight: 'bold' }} href="#" className="btn btn-warning" to={"/fmApp/dailySalesList"}>
                    return to List
                </Link>
            </div >) : (<div className="form-group" ><Alert variant='info'>
                <Alert.Heading>Info</Alert.Heading>
                <p>Loading .........</p>
            </Alert>
            </div>)
            }

        </div>)

}
export default DailySaleDateInfo;