import React, { useState } from "react";
import Alert from 'react-bootstrap/Alert';
import { Button, StylesProvider } from "@material-ui/core";
import VehicleTypeDataService from "../../services/vehicle/VehicleTypeService";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const AddVehicleType = ({ updateParent }) => {
  const axiosPrivate = useAxiosPrivate();
  const initialVehicleTypeState = {
    id: null,
    type: "",
    name: ""
  };
  const [vehicletype, setVehicleType] = useState(initialVehicleTypeState);
  const initialVehicleTypeErrorState = {
    typeError: "",
    nameError: "",
  };
  const [vehicleTypeError, setVehicleTypeError] = useState(initialVehicleTypeErrorState);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setVehicleType({ ...vehicletype, [name]: value });
  };

  const handleValidation = () => {
    if (!vehicletype.type) {
      setVehicleTypeError({ ...vehicleTypeError, 'typeError': "Please enter vehilce type" });
      return false;
    }
    if (!vehicletype.name) {
      setVehicleTypeError({ ...vehicleTypeError, 'typeError': '', 'nameError': "Please enter description" });
      return false;
    }
    setVehicleTypeError({ ...vehicleTypeError, 'typeError': '', 'nameError': '' });
    return true;
  };

  const saveVehicleType = () => {
    //if submitted then avoid calling this block again
    if (!submitted) {
      if (handleValidation()) {
        setSubmitted(true);
        var data = {
          type: vehicletype.type,
          name: vehicletype.name
        };
        VehicleTypeDataService.create(axiosPrivate, data)
          .then(response => {
            setVehicleType(initialVehicleTypeState);
            updateParent();
            setSubmitted(false);
            setShow(true);
            setTimeout(() => {
              setShow(false);
            }, 3000);
            setAlertHeading("Success!!");
            setAlertVariant("success");
            setMessage("Successfully added");
          })
          .catch(e => {
            setMessage(e.response.data);
            setAlertHeading("Error!");
            setAlertVariant("danger");
            setShow(true);
            setSubmitted(false);
            console.log(e);
          });
      }
    }
  };


  return (
    <div className="p-0">
      <div className="col-md-12">
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <div className="float-left col-md-6">
          <label htmlFor="title">Type</label><span className='errorMsg'>  {vehicleTypeError.typeError}</span>
          <input autoComplete="off"
            type="text"
            className="form-control"
            id="type"
            value={vehicletype.type}
            onChange={(e) => handleInputChange(e)}
            name="type"
          />
        </div>
        <div className="float-right col-md-6">
          <label htmlFor="description">Description</label><span className='errorMsg'>  {vehicleTypeError.nameError}</span>
          <input autoComplete="off"
            type="text"
            className="form-control"
            id="name"
            value={vehicletype.name}
            onChange={(e) => handleInputChange(e)}
            name="name"
          />
        </div>
        <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
          <button
            color="primary"
            variant="contained"
            className="add-btn btn float-right"
            onClick={(e) => saveVehicleType(e)} disabled={submitted}
          >
            {submitted ? 'Adding...' : 'Add Vehicle type'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddVehicleType;
