import React, { useEffect, useRef, useState } from "react";
import FMTable from "../../common/FMTable";
import Loader from "../../common/Loader";
import MachineryService from "../../../services/admin/MachineryService";
import { Link } from "react-router-dom";
import { Fade, FormControlLabel, Paper, StylesProvider, Switch, Theme } from "@material-ui/core";
import { Alert, Button, Container } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import EditPump from "./EditPump";
import AddPump from "./AddPump";
import AddNozzle from "./AddNozzle";
import EditNozzle from "./EditNozzle";
import Constants from "../../../Constants";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const NozzleList = (props) => {
    const axiosPrivate= useAxiosPrivate();
    const [pumps, setPumps] = useState([]);
    const [nozzles, setNozzles] = useState([]);
    const [pump, setPump] = useState();
    const [open, setOpen] = useState(false);
    //alert constants
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    //alert constants
    const [pagination, setPagination] = useState({
        pageSelected: 1,
        sizePerPage: 10,
        filterMOs: []
    });

    const [pageMO, setPageMO] = useState({
        pageableObj: [],
        totalElements: 0,
        pagination: pagination
    });

    const [pumpError, setPumpError] = useState();
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    const columns = [{
        dataField: 'code',
        text: 'Nozzle No',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'fuelTypeMO.type',
        text: 'Fuel Type',
        sort: true,
        type: 'text',
        value: ''
    }, {
        dataField: 'pumpMO.code',
        text: 'Pump',
        sort: true,
        type: 'text',
        value: ''
    }, {
        dataField: 'openingReading',
        text: 'Open reading',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'closingReading',
        text: 'Close reading',
        sort: true,
        type: 'text',
        value: ''
    }]

    const updateParent = () => {
        setNozzles([]);
        handleTableChange([], 1, pagination.sizePerPage);
    }


    const updateParentEditMode = () => {
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }

    const expandRow = {
        renderer: row => <EditNozzle data={row} updateParent={updateParentEditMode} />
    }

    const [updatePumps, setUpdatePumps] = useState([]);

    useEffect(() => {
        retreivePumps();
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }, []);

   

    const handleSelectChange = event => {
        const selectedIndex = event.target.options.selectedIndex - 1;
        var selectedPump = pumps[selectedIndex];
        console.log('-------selected pump ---------' + selectedPump);
        (selectedPump === undefined && setShowAddBox(false));
        setPump(selectedPump);
    };

    const retreivePumps = () => {
        MachineryService.getPumps(axiosPrivate).
            then(response => {
                setPumps(response.data);
                console.log(response.data);
            })
            .catch(response => {
                handleClose();
            })
    }
    const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();

    const retreiveNozzleDetails = (pagination, actionType) => {
        console.log(pagination);
        console.log(actionType);
        if (actionType === "pagination") {
            handleToggle()
        }
        setTableDataRetreivalMsg(Constants.LOADING_DATA)
        MachineryService.getAllNozzles(axiosPrivate, pagination).
            then(response => {
                setNozzles(response.data.pageableObj)
                setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
                handleClose();
                setTableDataRetreivalMsg(null)
                console.log(response.data);
            })
            .catch(e => {
                setMessage(e.response ?  e.response.data : Constants.ERROR_MSG + " nozzle details");
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setShow(true);
                setTableDataRetreivalMsg(null)
                handleClose();
            })
    }

    const handleTableChange = (filters, page, sizePerPage, actionType) => {
        //const currentIndex = (page - 1) * sizePerPage;
        filters.map((filter) => {
            console.log("field --- " + filter.field + " filter value  --------------" + filter.value);
        })
        pagination.pageSelected = page;
        pagination.sizePerPage = sizePerPage;
        pagination.filterMOs = filters;
        retreiveNozzleDetails(pagination, actionType);
    }



    const [showButton, setShowButton] = useState(true);
    const [showAddBox, setShowAddBox] = useState(false);
    const nodeRef = useRef(null);

    const choosePump = () => {
        setPumpError(pump === undefined ? 'please select pump to add nozzle' : null);
        setShowAddBox(pump === undefined ? false : true)
    }


    const deleteNozzle = (nozzleList) => {
        MachineryService.deleteNozzles(axiosPrivate, nozzleList)
            .then(response => {
                handleTableChange([], 1, pagination.sizePerPage);
            })
            .catch(response => {

            })
    }




    return (
        <div className="col-md-10">
            <div className="col-md-10 float-right">
                <div className="row"><h4>Nozzles</h4></div>
                {show && (
                    <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{alertHeading}</Alert.Heading>
                        <p>{message}</p>
                    </Alert>

                )}
                <div className="row">
                    <div className="form-group col-md-4 float-left p-0">
                        <label htmlFor="title">Pump</label><span className='errorMsg'>  {pumpError}</span>
                        <select className="form-control" defaultValue={'ALL'} onChange={handleSelectChange} name="pump" id="pump">
                            <option value="ALL">Select Pump</option>
                            {pumps.map((item, index) => (
                                <option
                                    key={index}
                                    value={index}>
                                    {item.code}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        {showButton && (
                            <h5 className="ml-3" style={{ marginTop: '30px' }}>  <Link className="addNewHeading"
                                onClick={() => choosePump()}

                            >
                                Add Nozzle

                            </Link></h5>
                        )
                        }
                    </div>
                </div>
                <div className="row">

                    <CSSTransition
                        in={showAddBox}
                        nodeRef={nodeRef}
                        timeout={500}
                        classNames="newBox"
                        unmountOnExit
                        onEnter={() => setShowButton(false)}
                        onExited={() => setShowButton(true)}
                    >
                        <div className="addNewBox col-md-12 p-0"
                            ref={nodeRef}
                        ><div className="col-md-12 p-0">
                                <div className="float-left col-md-10"><h5 style={{ marginLeft: '15px', marginTop: '5px' }}>Add Nozzle</h5> </div>
                                <div className="col-md-2 float-right p-0">
                                    <StylesProvider injectFirst>
                                        <Button className="float-right addNewClose"
                                            onClick={() => setShowAddBox(false)}
                                        >
                                            X
                                        </Button></StylesProvider>
                                </div>

                            </div>

                            <div className="p-0  col-md-12 ml-0" style={{ height: 'auto' }}>
                                <AddNozzle selectedPump={pump} updateParent={updateParent} />
                            </div>

                        </div>
                    </CSSTransition>
                </div>
                <div className="row mt-3">
                    <Loader openDialog={open}></Loader>
                    <FMTable columns={columns} dataRetrievalMsg={tableDataRetreivalMsg} dataList={nozzles} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow} showDelete={true} deleteHandler={deleteNozzle} />
                </div>
            </div>
        </div >
    )

}
export default NozzleList;