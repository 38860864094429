import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import UserService from "../../../services/admin/UserService";
import { Button } from "@material-ui/core";
import Autocomplete from "../../common/Autocomplete";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const AddSalesPerson = ({ dataList, updateParent }) => {
    const axiosPrivate = useAxiosPrivate();
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [show, setShow] = useState(false);

    const initialSalespersonState = {
        id: '',
        name: '',
        mobileNo: '',
        designation: '',
        salary: ''
    }
    const [salesPerson, setSalesPerson] = useState(initialSalespersonState);
    const [salesPersons, setSalesPersons] = useState([]);
    const handleInputChange = event => {
        const { name, value } = event.target;
        setSalesPerson({ ...salesPerson, [name]: value });
    };

    const [salesPersonError, setSalesPersonError] = useState({
        nameError: '',
        mobileNoError: '',
        designationError: '',
        salaryError: ''
    })

    useEffect(() => {
        setSalesPersons(dataList);
    }, [dataList]);

    const handleValidation = () => {
        if (salesPerson.name === "") {
            setSalesPersonError({ ...salesPersonError, "nameError": "Please enter name" });
            return false;
        }
        if (salesPerson.mobileNo === "") {
            setSalesPersonError({ ...salesPersonError, "nameError": '', "mobileNoError": "Please enter mobile no" });
            return false;
        } else if (salesPerson.mobileNo.length !== 10) {
            setSalesPersonError({ ...salesPersonError, 'nameError': '', 'mobileNoError': "Invalid: please enter '10' digit mobile number" });
            return false;
        }

        setSalesPersonError({ ...salesPersonError, "nameError": '', "mobileNoError": '' });
        return true;
    }

    const saveSalesPerson = () => {
        setShow(false);
        if (handleValidation()) {
            setSubmitted(true);
            UserService.saveSalesPerson(axiosPrivate, salesPerson)
                .then(response => {
                    setSalesPerson(initialSalespersonState);
                    setSalesPersons(salesPersons => [...salesPersons, salesPerson]);
                    updateParent();
                    setSubmitted(false);
                    setShow(true);
                    setTimeout(() => {
                        setShow(false);
                    }, 3000);
                    setAlertHeading("Success!!");
                    setAlertVariant("success");
                    setMessage("Successfully added");
                }).catch(e => {
                    setShow(true);
                    setSubmitted(false);
                    setAlertHeading("Error!");
                    setAlertVariant("danger");
                    setMessage(e.response.data);
                })
        }
    }
    const [value, setValue] = useState('');
    return (
        <div className="p-0">
            <div className="col-md-12 float-right">
                {show &&
                    <div className="col-md-12 float-left">
                        <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{message}</p>
                        </Alert></div>}
                <div className=" col-md-6 float-left">
                    <label htmlFor="title">Name</label><span className='errorMsg'>  {salesPersonError.nameError}</span>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        required
                        value={salesPerson.name}
                        autoComplete="off"
                        onChange={handleInputChange}
                        name="name"
                    />
                </div>
                <div className=" col-md-6 float-right">
                    <label htmlFor="title">Mobile No</label>
                    <span className='errorMsg'> {salesPersonError.mobileNoError}</span>
                    <input
                        type="number"
                        className="form-control"
                        id="mobileNo"
                        value={salesPerson.mobileNo}
                        onChange={handleInputChange}
                        name="mobileNo"
                    />
                </div>

                <div className="col-md-6 adminForm">
                    <label htmlFor="title">Role</label>
                    <Autocomplete items={salesPersons}
                    searchField={'designation'}
                    renderItem={(item) =>
                        <>
                            {item.designation}
                        </>

                    }
                    onChange={(event, value) => { setSalesPerson({ ...salesPerson, ['designation']: value }) }}
                    onSelect={(value, item) => {
                        setSalesPerson({ ...salesPerson, ['designation']: (item  ? item.designation :'') })
                    }}
                    value={salesPerson.designation} />
                </div>

                <div className="col-md-6 adminForm">
                    <label htmlFor="description">Salary</label><span className='errorMsg'>   {salesPersonError.salaryError}</span>
                    <input
                        type="number"
                        className="form-control"
                        id="salary"
                        required
                        autoComplete="off"
                        value={salesPerson.salary}
                        onChange={handleInputChange}
                        name="salary"
                    />
                </div>
                <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
                    <button
                        color="primary"
                        variant="contained"
                        className="add-btn btn float-right"
                        onClick={(e) => saveSalesPerson(e)} disabled={submitted}
                    >
                        {submitted ? 'Adding...' : 'Add Sales Person'}
                    </button>
                </div>
            </div>
        </div>
    )
}
export default AddSalesPerson;