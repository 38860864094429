import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { parse, parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import addDays from "date-fns/addDays";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import addMinutes from "date-fns/addMinutes";
import subDays from "date-fns/subDays";
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DailyFuelSalesDataService from '../../../services/dailySales/DailyFuelSalesService';
import DailySalesService from '../../../services/dailySales/DailySalesService'
import UserService from "../../../services/admin/UserService";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const DailySaleDate = props => {
  const axiosPrivate = useAxiosPrivate();
  const { navigation } = props;
  const initialDailySaleDate = {
    id: '',
    daily_sale_date: '',
    salesPersonMO: '',
    from_dttm: '',
    to_dttm: '',
    cashDifference: ''
  }
  const [dailySaleDateMO, setDailySaleDateMO] = useState(initialDailySaleDate);
  const initialDailySaleDateMOError = {
    dailySaleDateError: '',
    salesPersonError: '',
    fromDttmError: '',
    toDttmError: '',
  }
  const [dailySaleDateMOError, setDailySaleDateMOError] = useState(initialDailySaleDateMOError);
  // const [dateOfSale, setDateOfSale] = useState(dailySaleDateMO.daily_sale_date);
  const [salesPersons, setSalesPersons] = useState([]);
  const [show, setShow] = useState(false);
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [message, setMessage] = useState("");
  const [dropdownDefaultVal, setDropDownDefaultVal] = useState("DEFAULT");
  const [submitted, setSubmitted] = useState(false);
  const [nextFromDttm, setNextFromDttm] = useState();
  const initialRecentDailySaleDate = {
    id: '',
    daily_sale_date: '',
    salesPersonMO: '',
    from_dttm: '',
    to_dttm: '',
    status: ''
  }
  const [recentDailySaleDateMO, setRecentDailySaleDateMO] = useState(initialRecentDailySaleDate);
  const [dailySaleDateId, setDailySaleDateId] = useState();

  useEffect(() => {
    //fix issue while navigation to admin page from here, id becomes undefined, hence check added
    if (props.location?.state?.id != undefined) {
      const dailySaleDateId = parseInt(props.location.state.id);
      setDailySaleDateId(props.location.state.id);
      if (parseInt(dailySaleDateId) !== 0) {
        retrieveDailySaleDate(props.location.state.id);
      } else {
        getRecentDailySaleDateWithDoneStatus();
      }
    }
  }, [props.location?.state?.id]);

  useEffect(() => {
    retrieveAllSalesPreson();

  }, []);

  const retrieveAllSalesPreson = (id) => {
    //let locDateOfSale = format(dailySaleDateMO.daily_sale_date, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" });
    setMessage("Pre-requisite check is happening at background..., Please wait...");
    setAlertHeading("Info!");
    setAlertVariant("info");
    UserService.getSalesPersons(axiosPrivate)
      .then(response => {
        setSalesPersons(response.data);

      }).catch(e => {

      });
  };

  const getRecentDailySaleDateWithDoneStatus = () => {
    //let locDateOfSale = format(dailySaleDateMO.daily_sale_date, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" });
    // will get next start shiftimings in increment of mins , for eg - previous sale date with status as "Done" and start timing is 24.01.2023 11:30 then
    // this method set new shift timings to  24.01.2023 11:31, with increment of '1' min
    DailySalesService.getRecentDailySaleDateDoneStatus(axiosPrivate)
      .then(response => {
        setRecentDailySaleDateMO({
          id: response.data?.id,
          daily_sale_date: response.data ? parse(response.data?.daily_sale_date, "dd.MM.yyyy", new Date()) : new Date(),
          salesPersonMO: response.data?.salesPersonMO,
          from_dttm: response.data ? parse(response.data?.from_dttm, "dd.MM.yyyy H:mm:ss", new Date()) : new Date(),
          to_dttm: response.data ? parse(response.data?.to_dttm, "dd.MM.yyyy H:mm:ss", new Date()) : new Date(),
          status: response.data?.status,
          cashDifference: response.data?.cashDifference
        })
        if (response.data) {
          var dailySaleDate = parse(response.data?.daily_sale_date, "dd.MM.yyyy", new Date());
          var nextFromDttmLoc = parse(response.data?.to_dttm, "dd.MM.yyyy H:mm:ss", new Date());
          nextFromDttmLoc = setHours(nextFromDttmLoc?.setMinutes(nextFromDttmLoc?.getMinutes() + 1), nextFromDttmLoc?.getHours());
          setNextFromDttm(nextFromDttmLoc);
          var toDttm = addDays(new Date(nextFromDttmLoc), 1);
          setDailySaleDateMO({ ...dailySaleDateMO, daily_sale_date: nextFromDttmLoc, from_dttm: nextFromDttmLoc, to_dttm: toDttm });
        } else {
          setDailySaleDateMO({ ...dailySaleDateMO, daily_sale_date: new Date(), from_dttm: '', to_dttm: '' });
        }
      }).catch(e => {
        console.log(e);
      });

  };

  const retrieveDailySaleDate = (id) => {
    //let locDateOfSale = format(dailySaleDateMO.daily_sale_date, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" });
    DailyFuelSalesDataService.get(axiosPrivate, id)
      .then(response => {
        setDailySaleDateMO({
          id: response.data?.id,
          daily_sale_date: parse(response.data?.daily_sale_date, "dd.MM.yyyy", new Date()),
          salesPersonMO: response.data?.salesPersonMO,
          from_dttm: parse(response.data?.from_dttm, "dd.MM.yyyy H:mm:ss", new Date()),
          to_dttm: parse(response.data?.to_dttm, "dd.MM.yyyy H:mm:ss", new Date()),
          status: response.data?.status,
          cashDifference: response.data?.cashDifference
        });
        setNextFromDttm(parse(response.data?.from_dttm, "dd.MM.yyyy H:mm:ss", new Date()));
        setDropDownDefaultVal(response.data?.salesPersonMO.id);
      }).catch(e => {
        console.log(e);
      });
  };

  const handleDateChange = date => {
    if (date !== null) {
      let nextDttm = nextFromDttm ? nextFromDttm : setHours(date?.setMinutes(date?.getMinutes() + 1), date?.getHours());
      setDailySaleDateMO({ ...dailySaleDateMO, daily_sale_date: date, from_dttm: nextDttm, salesPersonMO: '', to_dttm: setHours(setMinutes(addDays(date, 1), 15), 9) });
    }
    setDropDownDefaultVal("DEFAULT");
  }

  const handleSelectChange = event => {
    const selectedIndex = event.target.options.selectedIndex - 1;
    const selectedValue = event.target.options.selectedValue;
    const salesPerson = salesPersons[selectedIndex];
    setDailySaleDateMO({ ...dailySaleDateMO, salesPersonMO: salesPerson })
    setDropDownDefaultVal(selectedValue);
    //currentFuelPrice.fuel_type = fuelTypesselectedIndex;
    console.log(event.target.value);
  };

  const handleValidation = () => {
    if (dailySaleDateMO.daily_sale_date === "" || dailySaleDateMO.daily_sale_date === null) {
      setDailySaleDateMOError({ ...dailySaleDateMOError, "dailySaleDateError": "Please enter date of sale" });
      return false;
    }
    if (dailySaleDateMO.salesPersonMO === '' || dailySaleDateMO.salesPersonMO === null) {
      setDailySaleDateMOError({ ...dailySaleDateMOError, "dailySaleDateError": '', "salesPersonError": "Please select sales person" });
      return false;
    }
    if (dailySaleDateMO.from_dttm === '' || dailySaleDateMO.from_dttm === null) {
      setDailySaleDateMOError({
        ...dailySaleDateMOError, "dailySaleDateError": '', "salesPersonError": "",
        "fromDttmError": "Please enter shift from timings"
      });
      return false;
    }
    if (dailySaleDateMO.to_dttm === '' || dailySaleDateMO.to_dttm === null) {
      setDailySaleDateMOError({
        ...dailySaleDateMOError, "dailySaleDateError": '', "salesPersonError": "",
        "fromDttmError": "", "toDttmError": "Please enter shift end timings"
      });
      return false;
    }
    setDailySaleDateMOError({
      ...dailySaleDateMOError, "dailySaleDateError": '', "salesPersonError": "",
      "fromDttmError": "", "toDttmError": ""
    });
    //setLubricantType({ ...lubricantType, "nameError": '', "typeError": '', "brandError": '', "packageTypeError": '' });
    return true;
  };

  const saveAndProceed = (e) => {
    setShow(false);

    //if submitted then avoid calling this block again
    if (handleValidation()) {
      if (submitted) {
        return
      }
      setSubmitted(true);
      setMessage("adding ....");
      console.log(dailySaleDateMO);
      var data = {
        id: dailySaleDateMO.id,
        salesPersonMO: dailySaleDateMO.salesPersonMO,
        daily_sale_date: format(dailySaleDateMO.daily_sale_date, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" }),
        from_dttm: format(dailySaleDateMO.from_dttm, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" }),
        to_dttm: format(dailySaleDateMO.to_dttm, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" }),
        status: dailySaleDateMO.status,
        cashDifference: dailySaleDateMO.cashDifference
      };
      DailyFuelSalesDataService.createDailySaleDate(axiosPrivate, data)
        .then(response => {
          console.log(response.data);
          props.history.push({ pathname: "/fmApp/fuelComponent", state: { id: response.data.id } });
        })
        .catch(e => {
          if (e.response && e.response.data) {
            setSubmitted(false);
            setMessage(e.response.data);
            setAlertHeading("Error!");
            setAlertVariant("danger");
            setShow(true);
            console.log(e.response.data); // some reason error message
          }
          console.log(e);
        });
    }
  };
  return (
    <div>
      <div className="submit-form">
        <h3>Sale Date & Shift Timings</h3>
        {show ? (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        ) : (<div></div>)}
        <div className="form-grou">
          <label htmlFor="Date of sale">Date of Sale</label><span className='errorMsg'>  {dailySaleDateMOError.dailySaleDateError}</span>
          <DatePicker autoComplete="off" selected={dailySaleDateMO.daily_sale_date} name="daily_sale_date" id="daily_sale_date" className="form-control" onChange={(date) => handleDateChange(date)} dateFormat="dd/MM/yyyy" minDate={dailySaleDateMO.id === '' ? recentDailySaleDateMO.to_dttm : dailySaleDateMO.daily_sale_date} maxDate={dailySaleDateMO.id === '' ? recentDailySaleDateMO.to_dttm : dailySaleDateMO.daily_sale_date} />
        </div>

        <div className="form-group">
          <label htmlFor="title">Sales Person</label><span className='errorMsg'>  {dailySaleDateMOError.salesPersonError}</span>
          <select className="form-control" defaultValue={dropdownDefaultVal} value={dropdownDefaultVal} onChange={handleSelectChange} >
            <option value="DEFAULT" disabled hidden>Select Sales Person...</option>
            {salesPersons.map((item, index) => (
              <option
                key={index}
                value={item.id}
              >
                {item.name}

              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="Date of sale">Shift start timings</label><span className='errorMsg'>  {dailySaleDateMOError.fromDttmError}</span>

          < DatePicker autoComplete="off" selected={dailySaleDateMO.from_dttm} name="from_dttm" id="from_dttm" className="form-control" onChange={(date) => setDailySaleDateMO({ ...dailySaleDateMO, 'from_dttm': date })} dateFormat="dd/MM/yyyy h:mm aa" timeInputLabel="Time:" showDisabledMonthNavigation showTimeInput minDate={dailySaleDateMO.daily_sale_date}
            maxDate={addDays(dailySaleDateMO.daily_sale_date, 1)} />
        </div>



        <div className="form-group">
          <label htmlFor="Date of sale">Shift end timings</label><span className='errorMsg'>  {dailySaleDateMOError.toDttmError}</span>
          <DatePicker autoComplete="off" selected={dailySaleDateMO.to_dttm} name="to_dttm" id="to_dttm" className="form-control" onChange={(date) => setDailySaleDateMO({ ...dailySaleDateMO, 'to_dttm': date })} dateFormat="dd/MM/yyyy h:mm aa" timeInputLabel="Time:" showDisabledMonthNavigation showTimeInput minDate={dailySaleDateMO.daily_sale_date}
            maxDate={addDays(dailySaleDateMO.daily_sale_date, 100)} />
        </div>
        <div className="col-md-12 p-0 mt-5 mb-5 float-left">
          <button
            color="primary"
            variant="contained" type="button"
            className="next-btn btn float-right"
            onClick={(e) => saveAndProceed(e)} disabled={submitted}
          >{submitted ? 'SAVING....' : 'NEXT'}

          </button>
          <button
            color="secondary"
            variant="contained"
            className="back-btn btn float-right"
            style={{ marginRight: "1rem" }} type="button"
            onClick={() => props.history.push("/fmApp/dailySalesList")}
          >
            BACK
          </button>

        </div>
      </div>
    </div>
  );
};
export default DailySaleDate;