
const getAll = (axiosPrivate) => {
  return axiosPrivate.get("/packageTypes");
};

const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/packageType/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/savePackageType", data);
};

const update = (axiosPrivate, data) => {
  return axiosPrivate.put(`/updatePackageType`, data);
};

const remove = (axiosPrivate, id) => {
  return axiosPrivate.delete(`/packageType/${id}`);
};

const removeAll = (axiosPrivate, data) => {
  return axiosPrivate.put(`/deletePackageTypes`, data);
};

export default {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
};
