import React, { useEffect, useState } from "react";
import CreditTransactionDataService from "../../services/credit/CreditTransactionService";
import ReportDataService from "../../services/report/ReportService";
import { Button, StylesProvider } from "@material-ui/core";
import { Alert } from "react-bootstrap";
import Loader from "../common/Loader";
import FMTable from "../common/FMTable";
import useAxiosPrivate from "../../auth/useAxiosPrivate";
import Constants from "../../Constants";

const CreditOutstandingReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const intialCreditOutstanding = {
    totalDebit: 0,
    totalCredit: 0,
    totalOutstanding: 0
  }
  const [creditOutstanding, setCreditOutstanding] = useState(intialCreditOutstanding);
  const [creditTransactions, setCreditTransactions] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  // pagination and filter constants//
  const [pagination, setPagination] = useState({
    pageSelected: 1,
    sizePerPage: 10,
    filterMOs: []
  });
  const [pageMO, setPageMO] = useState({
    pageableObj: [],
    totalElements: 0,
    pagination: pagination
  });

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  // pagination and filter constants//
  const columns = [{
    dataField: 'vhOwner.name',
    text: 'Customer Name',
    sort: true,
    type: 'text',
    footer: ''
  }, {
    dataField: 'debit',
    text: 'Total Outstanding',
    sort: true,
    type: 'text',
  }, {
    dataField: 'credit',
    text: 'Credit',
    sort: true,
    type: 'text',
  }];



  useEffect(() => {
    retrieveCreditEntries(pagination);
  }, []);


  const handleTableChange = (filters, page, sizePerPage, actionType) => {
    pagination.pageSelected = page;
    pagination.sizePerPage = sizePerPage;
    console.log(filters)
    pagination.filterMOs = filters;
    retrieveCreditEntries(pagination, actionType);
  }

  const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();

  const retrieveCreditEntries = (pagination, actionType) => {
    handleToggle()
    setTableDataRetreivalMsg(Constants.LOADING_DATA);
    CreditTransactionDataService.getAllOutstandingCredits(axiosPrivate, pagination)
      .then(response => {
        setCreditOutstanding(response.data)
        setPageMO({ ...pageMO, ['pageableObj']: response.data.pageMO.pageableObj, ['totalElements']: response.data.pageMO.totalElements, ['pagination']: pagination });
        setCreditTransactions(response.data.creditTransactionMOs);
        handleClose();
        setTableDataRetreivalMsg(null);
        console.log(response.data);
      })
      .catch(e => {
        setTableDataRetreivalMsg(null);
        handleClose();
        console.log(e);
      });
  };



  const numberFormat = (value) =>
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(value);

  const CaptionElement = () => <h5 style={{ textAlign: 'center', color: 'red', marginLeft: '100px' }}>Total Credit Outstanding is {numberFormat(creditOutstanding.totalOutstanding)}
  </h5>;


  const generateCrdOustandingReport = () => {
    if (submitted) {
      return
    }
    setSubmitted(true);
    
    ReportDataService.genCreditOutstandingReport(axiosPrivate)
      .then(response => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([response.data]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `credit_outstanding_report.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setSubmitted(false);
      }).catch(e => {
        setMessage("Error while retrieving all lubricant types");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        console.log(e);
      });
  }

  function DownloadButton(name) {
    return <div className='mt-0' style={{ height: 'auto' }}>
        <button name={name} id={name}
          className="download-btn btn float-right"
          onClick={(e) => generateCrdOustandingReport(e)} disabled={submitted}
        >
          {submitted ? 'Downloading....' : 'Download'}
        </button></div>
  }


  return (
    <div className="list row col-md-12">
      {show ? (
        <Alert className="col-md-8" variant={alertVariant} onClose={() => setShow(false)} dismissible>
          <Alert.Heading>{alertHeading}</Alert.Heading>
          <p>{message}</p>
        </Alert>

      ) : (<div></div>)}
      <div className="col-md-12 p-0">
        <div className='col-md-12 p-0' style={{ height: 'auto' }}>
          <div className='col-md-12 p-0'><h4>Credit Outstanding</h4></div>
          <div className='col-md-12 p-0'><div className='col-md-10 float-left'><CaptionElement /> </div>
          </div>
        </div>
        <Loader openDialog={open}></Loader>
        <FMTable dataRetrievalMsg={tableDataRetreivalMsg} enableRowSerials={true} columns={columns} dataList={creditTransactions} pageMO={pageMO} handlePagination={handleTableChange} showFilter={false} />
        <div className="col-md-12 float-right p-0" ><DownloadButton name='button2' /></div>


      </div>
    </div>
  );
};

export default CreditOutstandingReport;
