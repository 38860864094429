import { parse } from 'date-fns';
import { format } from 'date-fns-tz';
import React, { useEffect, useRef, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import LubricantTypeDataService from "../../services/products/LubricantTypeService";
import LubricantStockDataService from "../../services/stock/LubricantStockService";
import { StylesProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import NumberInput from '../common/NumberInput';
import useAxiosPrivate from '../../auth/useAxiosPrivate';
import AlertDialog from '../common/AlertDialog';
import Constants from '../../Constants';

const EditLubricantStock = ({ data, updateParentOnEdit, updateParentOnDelete }) => {
  const axiosPrivate = useAxiosPrivate();
  const initialLubricantStockState = {
    id: null,
    lubricantTypeMO: {
      type: "",
      name: ""
    },
    bill_dttm: "",
    packageTypeMO: "",
    invoice_no: "",
    unit: 0,
    quantity: 0,
    totalQuantity: 0,
    unitError: "",
    quantityError: "",
    totQuantityError: "",
    billDttmError: "",
  };
  const [lubricantTypes, setLubricantTypes] = useState([]);
  const initialLubricantType = {
    id: ""
  }
  const [lubricantType, setLubricantType] = useState(initialLubricantType);
  const [lubricantstock, setLubricantStock] = useState(initialLubricantStockState);
  const initialPackageType = {
    type: ""
  }
  const [packageType, setPackageType] = useState(initialPackageType);
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [addedDTTM, setAddedDTTM] = useState(new Date());
  const firstRenderRef = useRef(true);
  const [updateSubmitted, setUpdateSubmitted] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  useEffect(() => {
    getLubricantStock(data);
  }, [data]);

  const getLubricantStock = data => {
    firstRenderRef.current = true;
    setLubricantStock(data);
    setLubricantType(data.lubricantTypeMO);
    setAddedDTTM(parse(data.bill_dttm, "dd.MM.yyyy H:mm:ss", new Date()));
    setPackageType(data.lubricantTypeMO.packageTypeMO);
    console.log(data);
  };

  useEffect(() => {
    retrieveLubricantTypes();
  }, []);

  const retrieveLubricantTypes = () => {
    LubricantTypeDataService.getAll(axiosPrivate)
      .then(response => {
        setLubricantTypes(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleInputChange = (value, name) => {
    setLubricantStock({ ...lubricantstock, [name]: value });
  };

  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(count + 1);
    if (count > 1) {
      let totalQuantity = lubricantstock.unit * lubricantstock.quantity;
      setLubricantStock({ ...lubricantstock, 'totalQuantity': totalQuantity });
    }
  }, [lubricantstock.quantity, lubricantstock.unit]);

  const handleDttmOfSale = date => {
    setShow(false);
    setAddedDTTM(date);
  }

  const handleValidation = () => {
    if (addedDTTM === "") {
      setLubricantStock({ ...lubricantstock, 'billDttmError': "Please added date and time" });
      return false;
    }
    console.log('invoice no is ' + lubricantstock.invoice_no);
    if (lubricantstock.invoice_no === undefined || lubricantstock.invoice_no === '' || lubricantstock.invoice_no === null) {
      setLubricantStock({ ...lubricantstock, 'lubricantTypeError': "", 'billDttmError': '', 'invoiceNoError': "Please enter invoice number" });
      return false;
    }
    if (parseInt(lubricantstock.unit) === 0 || lubricantstock.unit === '') {
      setLubricantStock({ ...lubricantstock, 'billDttmError': '', 'invoiceNoError': '', 'unitError': "Please enter unit" });
      return false;
    }
    if (parseInt(lubricantstock.quantity) === 0 || lubricantstock.quantity === '') {
      setLubricantStock({ ...lubricantstock, 'billDttmError': '', 'invoiceNoError': '', 'unitError': '', 'quantityError': "Please enter quantity" });
      return false;
    }
    if (parseInt(lubricantstock.totalQuantity) === 0 || !lubricantstock.totalQuantity) {
      setLubricantStock({ ...lubricantstock, 'lubricantTypeError': "", 'invoiceNoError': '', 'billDttmError': '', 'unitError': '', 'quantityError': '', 'totQuantityError': "total quantity should be greater than '0'" });
      return false;
    }
    setLubricantStock({ ...lubricantstock, 'billDttmError': '', 'invoiceNoError': '', 'unitError': '', 'quantityError': '', 'totQuantityError': '' });
    return true;
  };

  const updateLubricantStock = () => {
    if (handleValidation()) {
      setUpdateSubmitted(true);
      setShow(false);
      lubricantstock.bill_dttm = format(addedDTTM, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" });
      setMessage("Updating.....");
      setAlertHeading("Info");
      setAlertVariant("info");
      setShow(true);
      LubricantStockDataService.update(axiosPrivate, lubricantstock.id, lubricantstock)
        .then(response => {
          updateParentOnEdit();
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully updated");
          setUpdateSubmitted(false);
          newLubricantStock();
          console.log(response.data);
        })
        .catch(e => {
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setUpdateSubmitted(false);
          setShow(true);
          console.log(e);
        });
    }
  };
  const newLubricantStock = () => {
    setLubricantStock(initialLubricantStockState);
    setLubricantType(initialLubricantType);
    setPackageType(initialPackageType)

  }

  const deleteLubricantStock = () => {
    setDeleteSubmitted(true);
    LubricantStockDataService.remove(axiosPrivate, lubricantstock.id, lubricantstock)
      .then(response => {
        console.log(response.data);
        updateParentOnDelete()
      })
      .catch(e => {
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setDeleteSubmitted(false);
        setShow(true);
      });
  };

  /**delete action  */

  const [dialogShow, setDialogShow] = useState(false);

  const yesBtnHandler = {
    name: 'Yes',
    //dont add deleteFuelPrice() with  open and close bracket, which will cause indefinte 
    // loop always refer function without braces
    callAction: deleteLubricantStock
  };

  const noBtnHandler = {
    name: 'No',
  };

  function deleteAction() {
    setDialogShow(true);
  }

  /**delete action  */


  return (
    <div className="p-0">
      <div className="col-md-12 float-right">
        {dialogShow && (
          <AlertDialog openDialog={true} dialogShowStateVar={setDialogShow} dialogTitle={"Delete Action!!!"}
            message={Constants.DELETE_CONTENT}
            noBtnHandler={noBtnHandler} yesBtnHandler={yesBtnHandler} />
        )}
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <div className="col-md-6 float-left">
          <label htmlFor="price">Lubricant Type<span style={{ fontStyle: 'italic', fontSize: '12px' }}> (non editable)</span></label>
          <input className="form-control" type="text" value={lubricantType.type + " - " + lubricantType.name}></input>
        </div>
        <div className="col-md-6 float-right">
          <label htmlFor="description">Bill Date</label><span className='errorMsg'>  {lubricantstock.billDttmError}</span>
          <DatePicker selected={addedDTTM} placeholderText="Select a date" timeInputLabel="Time:" name="added_dttm" id="added_dttm" className="form-control" onChange={date => handleDttmOfSale(date)} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="invoice_no">Invoice no</label><span className='errorMsg'>  {lubricantstock.invoiceNoError}</span>
          <input autoComplete="off"
            type="text"
            className="form-control"
            id="invoice_no"
            required
            value={lubricantstock.invoice_no}
            onChange={(e) => handleInputChange(e.target.value, 'invoice_no')}
            name="invoice_no"
          />
        </div>

        <div className="col-md-6 adminForm">
          <label htmlFor="title">Unit in   <i>{packageType?.type ? " in " + packageType.type : ""}  </i></label>
          <span className='errorMsg'>  {lubricantstock.unitError}</span>
          <NumberInput autoComplete="off" id="unit" name="unit" isDecScaleEnabled={true} decimalScale={2}
            value={lubricantstock.unit} className="form-control"
            onChange={(value) => handleInputChange(value, "unit")}></NumberInput>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Quantity <i>{packageType?.unit ? " in " + packageType.unit + "(s)/" + packageType.type : ""}  </i></label>
          <span className='errorMsg'>  {lubricantstock.quantityError}</span>
          <NumberInput autoComplete="off" id="quantity" name="quantity" isDecScaleEnabled={true} decimalScale={2}
            value={lubricantstock.quantity} className="form-control"
            onChange={(value) => handleInputChange(value, "quantity")}></NumberInput>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Total Quantity <i>{packageType?.unit ? " in " + packageType.unit : ""}  </i></label>
          <span className='errorMsg'>  {lubricantstock.totQuantityError}</span>
          <NumberInput autoComplete="off" id="totalQuantity" name="totalQuantity" isDecScaleEnabled={true} decimalScale={2}
            value={lubricantstock.totalQuantity} className="form-control"
            onChange={(value) => handleInputChange(value, "totalQuantity")}></NumberInput>
        </div>
        <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
          <div className="pr-0 mr-0 float-right">  <button
            className="update-btn ml-0 btn float-right"
            variant="contained" type="button"
            onClick={updateLubricantStock} disabled={updateSubmitted}
          >{updateSubmitted ? 'Updating....' : 'Update'}
          </button>
          </div>
          <div className="float-right  mr-3">
            <button
              className="delete-btn-editmode btn float-right"
              variant="contained"
              disabled={deleteSubmitted}
              type="button"
              onClick={(e) => deleteAction()}
            >
              {deleteSubmitted ? 'Deleting...' : 'Delete'}
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default EditLubricantStock;