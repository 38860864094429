import useAuth from './useAuth';
import UserService from '../services/admin/UserService'
import Constants from '../Constants';

const useRefreshToken = () => {
    const { appUserCtx, setAppUserCtx } = useAuth();

    const refresh = async () => {
        let refreshToken = '';
        const user = JSON.parse(localStorage.getItem('authUser'));
        await UserService.refreshToken(user).
            then(response => {
                localStorage.setItem('authUser', JSON.stringify(response.data));
                setAppUserCtx(prev => {
                    console.log(JSON.stringify(prev));
                    console.log(response.data.token);
                    return { ...prev, token: response.data.token }
                });
                refreshToken = response.data.token;
            }).catch(error => {
                if (error?.response?.status === 403) {
                    localStorage.setItem('errorMsg', error.response.data);
                    window.location.href = Constants.ERROR_PAGE;
                }
                return Promise.reject(error);
            });
        return refreshToken;
    }
    return refresh;
};

export default useRefreshToken;