import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import FuelTypeDataService from "../../services/products/FuelTypeService";
import { StylesProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const EditFuelType = ({ data, updateParent }) => {
  const axiosPrivate = useAxiosPrivate();
  const initialFuelTypeState = {
    id: null,
    name: "",
    type: "",
    minAllowableStock: "",
    productCode: '',
    displayOrder: 0
  };
  const [currentFuelType, setCurrentFuelType] = useState(initialFuelTypeState);
  const fuelTypeError = {
    typeError: "",
    nameError: "",
    minAllowableStockError: "",
  };
  const [fueltypeError, setFuelTypeError] = useState(fuelTypeError);
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [updateSubmitted, setUpdateSubmitted] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  const getFuelType = id => {
    FuelTypeDataService.get(axiosPrivate, id)
      .then(response => {
        setCurrentFuelType(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    setCurrentFuelType(data);
  }, []);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentFuelType({ ...currentFuelType, [name]: value });
  };

  const handleValidation = () => {
    if (currentFuelType.type === "") {
      setFuelTypeError({ ...fuelTypeError, 'typeError': "Please enter type" });
      return false;
    }
    if (currentFuelType.name === "") {
      setFuelTypeError({ ...fuelTypeError, 'typeError': '', 'nameError': "Please enter name" });
      return false;
    }
    if (currentFuelType.minAllowableStock === "" || currentFuelType.minAllowableStock <= 0) {
      setFuelTypeError({ ...fuelTypeError, 'typeError': '', 'nameError': "", 'minAllowableStockError': "Please enter minimum allowable stock" });
      return false;
    }
    setFuelTypeError({ ...fuelTypeError, 'typeError': '', 'nameError': '', 'minAllowableStockError': '' });
    return true;
  };

  const updateFuelType = () => {
    if (handleValidation()) {
      setUpdateSubmitted(true)
      FuelTypeDataService.update(axiosPrivate, currentFuelType.id, currentFuelType)
        .then(response => {
          console.log(response.data);
          setMessage("The Fuel type was updated successfully!");
          updateParent();
          setAlertHeading("Success");
          setAlertVariant("success");
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setUpdateSubmitted(false)
        })
        .catch(e => {
          setUpdateSubmitted(false)
          console.log(e);
        });
    }

  };


  return (
    <div className="p-0">

      <div className="col-md-12 float-right">
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <div className="col-md-6 float-left">
          <label htmlFor="title">Type</label><span className='errorMsg'>  {fueltypeError.typeError}</span>
          <input
            type="text"
            className="form-control"
            id="type"
            name="type"
            value={currentFuelType.type}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-6 float-right">
          <label htmlFor="description">Name</label><span className='errorMsg'>  {fueltypeError.nameError}</span>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={currentFuelType.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Product Code</label>
          <input
            type="text"
            className="form-control"
            id="productCode"
            required
            autoComplete="off"
            value={currentFuelType.productCode}
            onChange={handleInputChange}
            name="productCode"
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="title">Min Allowable Stock</label>
          <span className='errorMsg'>  {fueltypeError.minAllowableStockError}</span>
          <input
            type="number" min="0"
            className="form-control"
            id="minAllowableStock"
            required
            value={currentFuelType.minAllowableStock}
            onChange={handleInputChange}
            name="minAllowableStock"
          />
        </div>
        <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px', paddingTop: '10px' }}>
          <button color="primary"
            variant="contained"
            className="update-btn btn float-right"
            onClick={updateFuelType} disabled={updateSubmitted}
          >{updateSubmitted ? 'Updating....' : 'Update'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditFuelType;
