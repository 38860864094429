

import React, { useEffect, useState } from "react";
import RetailOutletDataService from "../../services/admin/RetailOutletService";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const ROHeader = () => {
    const [retailOutlet, setRetailOutlet] = useState();
    const [fileDetails, setFileDetails] = useState();
    const [logo, setLogo] = useState();
    const axiosPrivate = useAxiosPrivate();
    useEffect(() => {
        retreiveRetailOutlet();
    }, []);

    const retreiveRetailOutlet = () => {
        RetailOutletDataService.getRetailOutlet(axiosPrivate).then(response => {
            setRetailOutlet(response.data);
            setFileDetails({ ...fileDetails, ['file']: response.data.logo });
            if (response.data.logo != undefined) {
                var base64Image = `data:image/jpeg;base64,${response.data.logo}`;
                setLogo(base64Image);
            }
        })
            .catch(e => {
                
            });
    }

    return (
        <div className='p-0 float-right mr-3 roheader'>
            <div className='float-left' style={{ width: '80%' }}>
                <span className='float-left' >{retailOutlet?.name}</span>
                <span className='float-left' > {retailOutlet?.dealerName && 'Dealer :'}  {retailOutlet?.dealerName}</span>
            </div>
           {retailOutlet?.logo &&  <div className="float-right" style={{ width: '20%' }}> <img src={logo} style={{ height: '40px', width: '40px' }} /></div>}
        </div>
    )
}

export default ROHeader;