import React, { useState } from "react";
import DatePicker from "react-datepicker";
import constants from "../../constants/constants";
import ReportDataService from "../../services/report/ReportService";
import { Button, StylesProvider } from "@material-ui/core";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const FuelSalesReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const durationInitialState = {
    month: 0,
    year: 0
  }
  const [submitted, setSubmitted] = useState(false);
  const [duration, setDuration] = useState(new Date());


  const genFuelSalesReport = () => {
    if (submitted) {
      return
    }
    setSubmitted(true);
    ReportDataService.genFuelSalesReport(axiosPrivate, duration.getMonth() + 1, duration.getFullYear())
      .then(response => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([response.data]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          'Fuel_Sales_Report' + '_' + constants.MONTH_NAMES[duration.getMonth()] + '_' + duration.getFullYear() + '.pdf',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setSubmitted(false);
      });
  }

  return (
    <div className="list row col-md-12">
      <div className="col-md-12 mt-3 p-0 ">
        <h4 className="float-left">Fuel Sales Report</h4>
      </div>
      <div className="mt-3 p-0 col-md-12 d-flex ">
        <label className="col-md-6 pl-0" htmlFor="price">Select month and year to generate fuel sales report</label>
        <DatePicker className="col-md-6 float-left pr-0"
          selected={duration}
          onChange={date => setDuration(date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
        />
      </div>
      <div className="col-md-9 p-0 mt-3">
          <button
            className="float-right download-btn btn"
            variant="contained"
            onClick={(e) => genFuelSalesReport(e)} disabled={submitted}
          >
            {submitted ? 'Downloading....' : 'Download'}
          </button>
      </div>
    </div>
  );
};

export default FuelSalesReport;
