import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import LubricantBrandDataService from "../../services/products/LubricantBrandService";
import PackageTypeDataService from "../../services/products/PackageTypeService";
import LubricantTypeDataService from "../../services/products/LubricantTypeService";
import { Button, StylesProvider } from "@material-ui/core";
import NumberInput from "../common/NumberInput";
import Autocomplete from "../common/Autocomplete";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const AddLubricantType = ({ items, updateParent, props }) => {
  const axiosPrivate = useAxiosPrivate();
  const initialLubricantTypeState = {
    id: null,
    type: "",
    name: "",
    purchasePrice: 0,
    sellingPrice: 0,
    nameError: "",
    lubricantBrand : "",
    typeError: "",
    brandError: "",
    packageTypeError: "",
    purchasePriceError: "",
    sellingPriceError: ""
  };
  const [lubricantType, setLubricantType] = useState(initialLubricantTypeState);
  const [lubricantBrands, setLubricantBrands] = useState([]);
  const initialLubricantBrand = {
    id: '',
  }
  const [lubricantTypes, setLubricantTypes] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const initialPackageType = {
    id: '',
    unit: '',
  }
  const [packageType, setPackageType] = useState(initialPackageType);
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);


  useEffect(() => {
    retrieveLubricantBrands();
    retrievePackageTypes();
    setLubricantTypes(items);
  }, []);

  const retrieveLubricantBrands = () => {
    LubricantBrandDataService.getAll(axiosPrivate)
      .then(response => {
        setLubricantBrands(response.data);
        setSubmitted(false);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const retrievePackageTypes = () => {
    PackageTypeDataService.getAll(axiosPrivate)
      .then(response => {
        setPackageTypes(response.data);
        setSubmitted(false);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setLubricantType({ ...lubricantType, [name]: value });
  };

  const handleInputChangeWithNameValue = (name, value) => {
    setLubricantType({ ...lubricantType, [name]: value });
  };

  const handleValidation = () => {
    if (!lubricantType?.name) {
      setLubricantType({ ...lubricantType, 'nameError': "Please enter name" });
      return false;
    }
    if (!lubricantType?.type) {
      setLubricantType({ ...lubricantType, 'nameError': '', 'typeError': "Please enter lube type" });
      return false;
    }
    if (!lubricantType?.lubricantBrand) {
      setLubricantType({ ...lubricantType, 'nameError': '', 'typeError': '', 'brandError': "Please enter/select lube brand" });
      return false;
    }
    if (!packageType?.id) {
      setLubricantType({ ...lubricantType, 'nameError': '', 'typeError': '', 'brandError': '', 'packageTypeError': "Please select package type" });
      return false;
    }
    if (!lubricantType?.purchasePrice) {
      setLubricantType({ ...lubricantType, 'nameError': '', 'typeError': '', 'brandError': '', 'packageTypeError': "", 'purchasePriceError': "Please enter purchase price" });
      return false;
    }
    if (!lubricantType?.sellingPrice) {
      setLubricantType({ ...lubricantType, 'nameError': '', 'typeError': '', 'brandError': '', 'packageTypeError': "", 'purchasePriceError': "", 'sellingPriceError': "Please enter selling price" });
      return false;
    }
    setLubricantType({ ...lubricantType, 'nameError': '', 'typeError': '', 'brandError': '', 'packageTypeError': '', 'purchasePriceError': "", 'sellingPriceError': "" });
    return true;
  };


  const saveLubricantType = (e) => {
    if (handleValidation()) {
      setSubmitted(true);
      setShow(false);
      var data = {
        type: lubricantType.type,
        name: lubricantType.name,
        lubricantBrand: lubricantType.lubricantBrand,
        packageTypeMO: packageType,
        purchasePrice: lubricantType.purchasePrice,
        sellingPrice: lubricantType.sellingPrice
      };
      setMessage("Adding.....");
      setAlertHeading("Info");
      setAlertVariant("info");
      setShow(true);
      LubricantTypeDataService.create(axiosPrivate, data)
        .then(response => {
          setSubmitted(false);
          setLubricantTypes(lubricantTypes => [...lubricantTypes, response.data]);
          updateParent();
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully added");
          newLubricantType()
        })
        .catch(e => {
          setSubmitted(false);
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setShow(true);
          console.log(e);
        });
    }
  };

  const newLubricantType = () => {
    setPackageType(initialPackageType);
    setLubricantType(initialLubricantTypeState);
    setSubmitted(false);
  };

  return (
    <div className="p-0">
      <div className="col-md-12 float-right">
        {show ? (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        ) : (<div></div>)}
        <div className="col-md-6 float-left">
          <label htmlFor="description">Name</label> <span className='errorMsg'>  {lubricantType.nameError}</span>
          <input autoComplete="off"
            type="text"
            className="form-control"
            id="name"
            required
            value={lubricantType.name}
            onChange={handleInputChange}
            name="name"
          />
        </div>
        <div className="col-md-6 float-left">
          <label htmlFor="title">Type</label> <span className='errorMsg'>  {lubricantType.typeError}</span>
          <input autoComplete="off"
            type="text"
            className="form-control"
            id="type"
            required
            value={lubricantType.type}
            onChange={handleInputChange}
            name="type"
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="title">Brand</label> <span className='errorMsg'>  {lubricantType.brandError}</span>
          <Autocomplete items={lubricantTypes}
            searchField={'lubricantBrand'}
            renderItem={(item) =>
              <>
                {item.lubricantBrand}
              </>
            }
            onChange={(event, value) => { setLubricantType({ ...lubricantType, ['lubricantBrand']: value }) }}
            onSelect={(value, item) => {
              setLubricantType({ ...lubricantType, ['lubricantBrand']: item ? item.lubricantBrand : '' })
            }}
            value={lubricantType.lubricantBrand} />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="title">Package Type</label> <span className='errorMsg'>  {lubricantType.packageTypeError}</span>
          <Autocomplete items={packageTypes}
            searchField={'type'}
            renderItem={(item) =>
              <>
                {item.type}
              </>
            }
            onChange={(event, value) => { setPackageType({ ...packageType, ['type']: value }) }}
            onSelect={(value, item) => {
              setPackageType(item ? item : '')
            }}
            value={packageType.type} />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Purchase Price <i>{packageType.unit ? 'per' : ''} {packageType.unit}</i></label>
          <span className='errorMsg'>  {lubricantType.purchasePriceError}</span>
          <NumberInput className="form-control" autoComplete="off" id="purchasePrice" name="purchasePrice" isDecScaleEnabled={true}
            decimalScale={2} value={lubricantType.purchasePrice}
            onChange={(value) => {
              handleInputChangeWithNameValue("purchasePrice", value);
            }}></NumberInput>

        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Selling Price <i>{packageType.unit ? 'per' : ''} {packageType.unit}</i></label>
          <span className='errorMsg'>  {lubricantType.sellingPriceError}</span>
          <NumberInput className="form-control" autoComplete="off" id="sellingPrice" name="sellingPrice" isDecScaleEnabled={true}
            decimalScale={2} value={lubricantType.sellingPrice}
            onChange={(value) => {
              handleInputChangeWithNameValue("sellingPrice", value);
            }}></NumberInput>
        </div>
        <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
          <button
            color="primary"
            variant="contained"
            className="add-btn btn float-right"
            onClick={(e) => saveLubricantType(e)} disabled={submitted}
          >
            {submitted ? 'Adding...' : 'Add Lube'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddLubricantType;
