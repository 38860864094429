
const getAll = (axiosPrivate, pageMO) => {
  return axiosPrivate.post(`/creditEntries`, pageMO);
};

const getAllDailyCreditEntries = (axiosPrivate, id) =>{
  return axiosPrivate.get( `/dailyCreditEntries/${id}`);
 
}

const getCreditBookValueForDailySaleDate = (axiosPrivate, id) =>{
  return axiosPrivate.get(`/creditEntryValue/${id}`);
}

const getAllCreditEntriesForDaySaleDate = (axiosPrivate, requestMO) => {
  return axiosPrivate.post(`/getAllCreditEntriesForDaySaleDate`, requestMO);
}
const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/creditEntry/${id}`);
};

const validateNewCreditEntry = (axiosPrivate, data) => {
  return axiosPrivate.post(`/validateNewCreditEntry`, data);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/creditEntry", data);
};

const update = (axiosPrivate, id, data) => {
  return axiosPrivate.put(`/creditEntry/${id}`, data);
};

const updateCreditBookWithDailySaleDate = (axiosPrivate, id) => {
  return axiosPrivate.put(`/updateCreditEntryWithDailySaleDate/${id}`);
};

const remove = (axiosPrivate, data) => {
  return axiosPrivate.put("/creditEntry", data);
};

const removeAll = (axiosPrivate) => {
  return axiosPrivate.delete(`/creditEntry`);
};

const addCreditPrerequisites = (axiosPrivate) => {
  return axiosPrivate.get(`/addCreditPrerequisites`);
};


export default {
  getAll,
  getAllDailyCreditEntries,
  get,
  create,
  update,
  remove,
  removeAll,
  validateNewCreditEntry,
  updateCreditBookWithDailySaleDate,
  getAllCreditEntriesForDaySaleDate,
  getCreditBookValueForDailySaleDate,
  addCreditPrerequisites
};
