import { Table } from "@material-ui/core";
import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import DailyFuelDiscountDateService from "../../../services/dailySales/DailyFuelSalesService";
import NumberInput from "../../common/NumberInput";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

export const DailyFuelDiscount = props => {
  const [dateOfSaleId, setDateOfSaleId] = useState();
  const [dailyFuelDiscountMOs, setDailyFuelDiscountMOs] = useState([]);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [totatDiscount, setTotatDiscount] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  function isArrayObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    if(props.location?.state?.id){
      setDateOfSaleId(props.location.state.id);
      retrieveDailyFuelDiscount(props.location.state.id);
    }
  }, [props.location?.state?.id]);


  const retrieveDailyFuelDiscount = (id) => {
    //let locDateOfSale = format(dailySaleDateMO.daily_sale_date, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" });
    DailyFuelDiscountDateService.getDailyFuelDiscountComp(axiosPrivate, id)
      .then(response => {
        setDailyFuelDiscountMOs(response.data);
        response.data.map((dailyFuelDiscount, index) => {
          setTotatDiscount(prev => {
            return prev + dailyFuelDiscount.discountAmount;
          })
        });
      })
      .catch(e => {
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        console.log(e);
      });
  };

  const onChange = (value, row, rowIndex, columnId) => {
    setDailyFuelDiscountMOs(old =>
      old.map((dailyFuelDiscountMO, index) => {
        if (index === rowIndex && columnId === 'discountValue') {
          dailyFuelDiscountMO.discountValue = parseFloat(value);
          dailyFuelDiscountMO.discountAmount = parseFloat(dailyFuelDiscountMO.saleVolume) * parseFloat(value);
        }
        if (index === rowIndex && columnId === 'saleVolume') {
          dailyFuelDiscountMO.saleVolume = parseFloat(value);
          dailyFuelDiscountMO.discountAmount = parseFloat(dailyFuelDiscountMO.discountValue) * parseFloat(value);
        }
        if (index === rowIndex && columnId === 'discountAmount') {
          dailyFuelDiscountMO.discountAmount = parseFloat(value);
        }
        if (index === rowIndex && columnId === 'description') {
          dailyFuelDiscountMO.description = value;
        }
        var totalDiscount = 0;
        dailyFuelDiscountMOs.map(dailyFuelDiscountMO => {
          totalDiscount = parseFloat(totalDiscount) + parseFloat(dailyFuelDiscountMO.discountAmount);
          setTotatDiscount(totalDiscount);
        });
        return dailyFuelDiscountMO;
      }))
  }


  const invokeFuelSales = () => {
    //navigation.next()
    props.history.push({ pathname: "/fmApp/fuelComponent", state: { id: dateOfSaleId} });
  }

  const saveAndProceed = () => {
    if (submitted) {
      return
    }
    setSubmitted(true);
    DailyFuelDiscountDateService.saveDailyFuelDiscount(axiosPrivate, dailyFuelDiscountMOs)
      .then(response => {
        props.history.push({ pathname: "/fmApp/dailyLubeSales", state: { id: dateOfSaleId} });
      }).catch(e => {
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
      })
  }

  return (
    <div className="sales-form list row">
      <div className="col-md-12">
        <h3>Fuel Discounts</h3>
        {show ? (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        ) : (<div></div>)}
        <div className="col-md-12 p-0">
          <Table className="fmanTable col-md-10 p-0">
            <thead>
              <tr>
                <th style={{ textAlign: "left" }}>Fuel Type</th>
                <th style={{ textAlign: "left" }}>Discount Value</th>
                <th style={{ textAlign: "left" }}>Sale Volume <i> in litres</i></th>
                <th style={{ textAlign: "left" }}>Discount Amount</th>
                <th style={{ textAlign: "left" }}>Description</th>
              </tr>
            </thead>
            <tbody>

              {dailyFuelDiscountMOs.map((row, index) => {
                return <tr>
                  <td>{row.fuelTypeMO.type}</td>
                  <td>
                    <NumberInput autoComplete="off" id="discountValue" name="discountValue" isDecScaleEnabled={true} decimalScale={2} value={!row.discountValue  ? 0 : row.discountValue}
                      onChange={(value) => onChange(value, row, index, 'discountValue')}></NumberInput>
                  </td>
                  <td>
                    <NumberInput autoComplete="off" id="saleVolume" name="saleVolume" isDecScaleEnabled={true} decimalScale={2} value={!row.saleVolume  ? 0 : row.saleVolume}
                      onChange={(value) => onChange(value, row, index, 'saleVolume')}></NumberInput>
                  </td>
                  <td>
                    <NumberInput autoComplete="off" id="discountAmount" name="discountAmount" isDecScaleEnabled={true} decimalScale={2} value={!row.discountAmount  ? 0 : row.discountAmount}
                      onChange={(value) => onChange(value, row, index, 'discountAmount')}></NumberInput>
                  </td>
                  <td><input type='text' name='description' onChange={(e) => onChange(e.target.value, row, index, 'description')} value={row.description}></input></td>
                </tr>
              })}
              <tr>
                <td>{ }</td>
                <td>{ }</td>
                <td>Total Discount</td>
                <td style={{ textAlign: "right" }}>{totatDiscount}</td>
                <td>{ }</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="col-md-10 p-0 mt-5 mb-5 float-left">

          <button
            color="primary"
            variant="contained"
            className="next-btn btn float-right"
            onClick={() => saveAndProceed()} disabled={submitted}
          >
            {submitted ? 'SAVING....' : 'NEXT'}
          </button>
          <button
            color="secondary"
            className="back-btn btn float-right"
            variant="contained"
            style={{ marginRight: "1rem" }}
            onClick={() => invokeFuelSales()}
          >
            BACK
          </button>
        </div>

      </div>

    </div>
  );
};
export default DailyFuelDiscount;