import { StylesProvider, Table } from "@material-ui/core";
import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import DailySalesSummaryService from "../../../services/dailySales/DailySalesSummaryService";
import ReportService from "../../../services/report/ReportService"
import useAxiosPrivate from "../../../auth/useAxiosPrivate";


export const DailySalesSummary = props => {
  const [dateOfSaleId, setDateOfSaleId] = useState();
  const [isSummaryOnly, setIsSummaryOnly] = useState(0);
  const [fuelSalesSummaryMOs, setFuelSalesSummaryMOs] = useState([]);
  const [newPriceSaleMOs, setNewPriceSaleMOs] = useState([]);
  const [dailyLubricantSalesMOs, setDailyLubricantSalesMOs] = useState([]);
  const [creditBookMOs, setCreditBookMOs] = useState([]);
  const [dailyElectronicCollectionMOs, setDailyElectronicCollectionMOs] = useState([]);
  const [dailyCashCollection, setDailyCashCollection] = useState([]);
  const [dailyFuelDiscountMOs, setDailyFuelDiscountMOs] = useState([]);
  const [totalReceivables, setTotalReceivables] = useState(0);
  const [totalDeductables, setTotalDeductables] = useState(0);
  const [totalCashReceivable, setTotalCashReceivable] = useState(0);
  const [totalCashReceived, setTotalCashReceived] = useState(0);
  const [cashDifference, setCashDifference] = useState(0);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [dailySalesSummary, setDailySalesSummary] = useState();
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    if(props.location?.state?.id){
      setIsSummaryOnly(parseInt(props.location.state.isSummaryOnly));
      setDateOfSaleId(props.location.state.id);
      retrieveDailySalesSummary(props.location.state.id);
    }
  }, [props.location?.state?.id]);


  const retrieveDailySalesSummary = (id) => {
    //navigation.next()
    DailySalesSummaryService.get(axiosPrivate, id)
      .then(response => {
        console.log(response.data.fuelSalesSummaryMOs);
        setDailySalesSummary(response.data);
        setFuelSalesSummaryMOs(response.data.fuelSalesSummaryMOs);
        setNewPriceSaleMOs(response.data.newPriceSaleMOs);
        setDailyLubricantSalesMOs(response.data.dailyLubricantSalesMOs);
        setCreditBookMOs(response.data.creditBookMOs);
        setDailyElectronicCollectionMOs(response.data.dailyElectronicCollectionMOs);
        setDailyFuelDiscountMOs(response.data.dailyFuelDiscountMOs);
      }).catch(e => {
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
      });
  }

  const invokeDailyCollection = () => {
    props.history.push({ pathname: "/fmApp/dailyCollection", state: { id: dateOfSaleId} });
  }

  const saveAndProceed = () => {
    //navigation.next()
    props.history.push("/fmApp/dailySalesList");
  }

  const downloadSalesSummary = () => {
    setSubmitted(true);
    ReportService.downloadSalesSummmary(axiosPrivate, dateOfSaleId)
      .then(response => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          new Blob([response.data]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Daily_Sales_Summary_Report.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setSubmitted(false);
      })
      .catch(e => {
        setSubmitted(false);
      });
  };

  return (
    <div className="sales-form list row">
      <div className="col-md-12" style={{ marginLeft: '15%' }}>
        {show ? (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        ) : (<div></div>)}
        <div>
          <Table className="fmanTable col-md-8">
            <thead>
              <tr>
                <th style={{ textAlign: "left", paddingLeft: "10px" }}>Particulars</th>
                <th style={{ textAlign: "left", paddingLeft: "10px" }}>Receivables</th>
                <th style={{ textAlign: "left", paddingLeft: "10px" }}>Deductables</th>
              </tr>
            </thead>
            <tbody>
              <tr><td colSpan={3}><h4>Fuel Sales</h4></td></tr>
              {fuelSalesSummaryMOs.map((row, index) => {
                return <tr>
                  <td>{row.fuelTypeMO.type} {"--->"} {parseFloat(row.totalDailySaleVolume).toFixed(3)} X {row.fuelPriceMO.price} </td>
                  <td style={{ textAlign: "right" }}>{parseFloat(row.totalDailySaleAmount).toFixed(3)}</td>
                  <td>{ }</td>
                </tr>
              })}
              <tr><td colSpan={3}><h4>New Price Sale</h4></td></tr>
              {newPriceSaleMOs.map((row, index) => {
                return <tr>
                  <td>{row.fuelTypeMO.type} {"--->"} {row.totalSaleVolume} X {+(+(row.newFuelPriceMO.price) - +(row.currentFuelPriceMO.price)).toFixed(3)} </td>
                  <td style={{ textAlign: "right" }}>{parseFloat(row.totalSaleAmount).toFixed(3)}</td>
                  <td>{ }</td>
                </tr>
              })}
              <tr><td colSpan={3}><h4>Fuel Discount Sales</h4></td></tr>
              {dailyFuelDiscountMOs.map((row, index) => {
                return <tr>
                  <td>{row.fuelTypeMO.type} {"--->"} {row.discountValue} X {row.saleVolume} </td>
                  <td>{ }</td>
                  <td style={{ textAlign: "right" }}>{parseFloat(row.discountAmount).toFixed(3)}</td>
                </tr>
              })}
              <tr><td colSpan={3}><h4>Lube Sales</h4></td></tr>
              {dailyLubricantSalesMOs.map((row, index) => {
                return <tr>
                  <td>{row.lubricantTypeMO.type} {"--->"} {row.saleVolume} X {row.lubricantTypeMO.sellingPrice} </td>
                  <td style={{ textAlign: "right" }}>{parseFloat(row.saleAmount).toFixed(3)}</td>
                  <td>{ }</td>
                </tr>
              })}

              <tr><td colSpan={3}><h4>Credit Entries</h4></td></tr>
              {creditBookMOs.map((row, index) => {
                return <tr>
                  <td>{row.vehicleMO.vhRegNo} {"--->"} {row.litre_sale_volume} X {row.fuelPriceMO.price} </td>
                  <td>{ }</td>
                  <td style={{ textAlign: "right" }}>{parseFloat(row.amount_of_sale).toFixed(3)}</td>
                </tr>
              })}

              <tr><td colSpan={3}><h4>Electronic Transactions</h4></td></tr>
              {dailyElectronicCollectionMOs.map((row, index) => {
                return <tr>
                  <td>{row.collectionModeMO.mode}</td>
                  <td>{ }</td>
                  <td style={{ textAlign: "right" }}>{parseFloat(row.amount).toFixed(3)}</td>
                </tr>
              })}
              <tr>
                <td>Total</td>
                <td style={{ textAlign: "right" }}>{parseFloat(dailySalesSummary?.totalReceivables).toFixed(3)}</td>
                <td style={{ textAlign: "right" }}>{parseFloat(dailySalesSummary?.totalDeductables).toFixed(3)}</td>
              </tr>
              <tr>
                <td>Total after deduction</td>
                <td style={{ textAlign: "right" }}>{parseFloat(dailySalesSummary?.totalCashReceivable).toFixed(3)}</td>
                <td>{ }</td>
              </tr>
              <tr>
                <td>Cash Received</td>
                <td style={{ textAlign: "right" }}>{parseFloat(dailySalesSummary?.totalCashReceived).toFixed(3)}</td>
                <td>{ }</td>
              </tr>
              <tr>
                <td>{dailySalesSummary?.cashCollectionState}</td>
                <td style={{ textAlign: "right" }}>{parseFloat(dailySalesSummary?.cashDifference).toFixed(3)}</td>
                <td>{ }</td>
              </tr>
            </tbody>
          </Table>
            {(isSummaryOnly !== 1 ? <div className="mt-5 mb-5 float-left col-md-8 p-0">
              <button className="download-btn btn float-right"
                variant="contained" 
                onClick={(e) => downloadSalesSummary()} disabled={submitted}
              >
                {submitted ? 'DOWNLOADING...' : 'DOWNLOAD'}
              </button>
              <button color="primary" className="next-btn btn float-right"
                variant="contained" style={{ marginRight: "1rem" }}
                onClick={() => saveAndProceed()}
              >
                DONE
              </button><button
                color="secondary"
                variant="contained" className="back-btn btn float-right"
                style={{ marginRight: "1rem" }}
                onClick={() => invokeDailyCollection()}
              >
                BACK
              </button>

            </div> : <div className="mt-5 mb-5 float-left col-md-8 p-0">
              <button
                className="download-btn btn float-right"
                onClick={(e) => downloadSalesSummary()} disabled={submitted}
              >
                {submitted ? 'DOWNLOADING...' : 'DOWNLOAD'}
              </button>
            </div>)
            }
        </div>
      </div>
    </div >
  );
};
export default DailySalesSummary;