import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import FuelTypeDataService from "../../services/products/FuelTypeService";
import { Button } from "@material-ui/core";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const AddFuelType = ({ updateParent, props }) => {
  const initialFuelTypeState = {
    id: null,
    type: "",
    name: "",
    productCode:"",
    minAllowableStock: "",
    displayOrder: 0
  };
  const [fueltype, setFuelType] = useState(initialFuelTypeState);
  const axiosPrivate = useAxiosPrivate();
  const fuelTypeError = {
    typeError: "",
    nameError: "",
    minAllowableStockError: "",
  };
  const [fueltypeError, setFuelTypeError] = useState(fuelTypeError);
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [fuelTypes, setFuelTypes] = useState([]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFuelType({ ...fueltype, [name]: value });
  };


  const handleValidation = () => {
    if (fueltype.type === "") {
      setFuelTypeError({ ...fuelTypeError, "typeError": "Please enter type" });
      return false;
    }
    if (fueltype.name === "") {
      setFuelTypeError({ ...fuelTypeError, "typeError": '', "nameError": "Please enter name" });
      return false;
    }
    if (fueltype.minAllowableStock === "" || fueltype.minAllowableStock === 0) {
      setFuelTypeError({ ...fuelTypeError, "typeError": '', "nameError": "", "minAllowableStockError": "Please enter minimum allowable stock" });
      return false;
    }
    setFuelTypeError({ ...fuelTypeError, "typeError": '', "nameError": '', "minAllowableStockError": '' });
    return true;
  };

  const saveFuelType = (e) => {

    if (handleValidation()) {
      setSubmitted(true);
      var data = {
        type: fueltype.type.toUpperCase(),
        name: fueltype.name,
        productCode: fueltype.productCode,
        minAllowableStock: fueltype.minAllowableStock,
        displayOrder: fueltype.displayOrder
      };

      FuelTypeDataService.create(axiosPrivate, data)
        .then(response => {
          setSubmitted(false);
          updateParent();
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully added");
          setFuelType(initialFuelTypeState);
        })
        .catch(e => {
          setSubmitted(false);
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setShow(true);
          console.log(e);
        });
    }

  };

  const newFuelType = () => {
    setFuelType(initialFuelTypeState);
    setSubmitted(false);
  };

  return (
    <div className="p-0">
      <div className="col-md-12 float-right">
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <div className="col-md-6 float-left">
          <label htmlFor="title">Type</label><span className='errorMsg'>  {fueltypeError.typeError}</span>
          <input
            type="text"
            className="form-control"
            id="type"
            required
            value={fueltype.type.toUpperCase()}
            autoComplete="off"
            onChange={handleInputChange}
            name="type"
          />
        </div>

        <div className="col-md-6 float-right">
          <label htmlFor="description">Name</label><span className='errorMsg'>  {fueltypeError.nameError}</span>
          <input
            type="text"
            className="form-control"
            id="name"
            required
            autoComplete="off"
            value={fueltype.name}
            onChange={handleInputChange}
            name="name"
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Product Code</label>
          <input
            type="text"
            className="form-control"
            id="productCode"
            required
            autoComplete="off"
            value={fueltype.productCode}
            onChange={handleInputChange}
            name="productCode"
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="title">Min Allowable Stock</label>
          <span className='errorMsg'>  {fueltypeError.minAllowableStockError}</span>
          <input
            type="number" min="0"
            className="form-control"
            id="minAllowableStock"
            required
            value={fueltype.minAllowableStock}
            onChange={handleInputChange}
            name="minAllowableStock"
          />
        </div>
        <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
          <button
            color="primary"
            variant="contained"
            className="add-btn btn float-right"
            onClick={(e) => saveFuelType(e)} disabled={submitted}
          >
            {submitted ? 'Adding...' : 'Add Fuel Type'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddFuelType;
