import * as React from 'react';
import { useState, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import '../../assets/css/fm-autocomplete.css'
import { Button } from 'react-bootstrap';

const Autocomplete = forwardRef((props, ref) => {
    const [display, setDisplay] = useState(false);
    const [options, setOptions] = useState([]);
    const [search, setSearch] = useState(props.value);
    const wrapperRef = useRef(null);
    const optionsRef = useRef({});
    const inputRef = useRef(null);
    const [cursor, setCursor] = useState(-1);

    useImperativeHandle(ref, () => {
        return {
            updateOptions: updateOptions
        };
    });

    useEffect(() => {
        setSearch(!props.value ? "" : props.value);
        constructOptions();
    }, [props.items, props.value]);

    const updateOptions = (items) => {
        // to remove duplicates from the array
        let optionList = [];
        if (items !== undefined) {
            let uniqueArr = items.filter((ele, ind) => ind === items.findIndex(elem => elem[props.searchField] === ele[props.searchField]))
            uniqueArr.map((obj, index) => {
                if (!!obj[props.searchField]) {
                    optionList.push(obj);
                }
            })
        }
        setOptions(optionList);
    }

    const constructOptions = () => {
        //
        options.length = 0;
        // to remove duplicates from the array
        if (props.items !== undefined) {
            let uniqueArr = props.items.filter((ele, ind) => ind === props.items.findIndex(elem => elem[props.searchField] === ele[props.searchField]))
            uniqueArr.map((obj, index) => {
                if (!!obj[props.searchField]) {
                    setOptions(options => [...options, obj]);
                }
            })
        }
    }

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const handleClickOutside = event => {
        const { current: wrap } = wrapperRef;
        if (wrap && !wrap.contains(event.target)) {
            setDisplay(false);
            setCursor(-1);
        }
    };

    const updateValue = (value, item) => {
        setSearch(item[props.searchField]);
        props.onSelect(value, item);
        setDisplay(false);
    };

    const onChangeAuto = (event) => {
        setDisplay(true);
        setSearch(event.target.value);
        !!props.onChange && props.onChange(event, event.target.value);

    }

    const onClickInput = (event) => {
        setDisplay(true);
    }

    const clear = (e, value) => {
        setDisplay(true);
        setSearch(value);
        !!props.onChange && props.onChange(e, value);
        props.onSelect(value, null);
        setCursor(-1);
        const { current: input } = inputRef;
        input.focus();
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            const currentItem = optionsRef.current[cursor];
            if (!!currentItem) {
                console.log("set search" + currentItem.children[0].textContent);
                setSearch(currentItem.children[0].textContent);
                setDisplay(false);
                let filOptions = options.filter((item, ind) => item[props.searchField]?.toLowerCase().indexOf(search?.toLowerCase()) > -1).map(el => { return el });
                const item = filOptions.filter((item, index) => { return index === cursor && item })
                console.log("item is " + item);
                props.onSelect(item && item[0][props.searchField], item && item[0]);
            }
        }
        // arrow up/down button should select next/previous list element
        if (e.keyCode === 38 && cursor > 0) {
            let count = cursor - 1;
            setCursor(count)
            const prevItem = optionsRef.current[cursor - 1];
            prevItem && prevItem.scrollIntoView({ block: "end", inline: "nearest" });
        } else if (e.keyCode === 40 && cursor < options.length - 1) {
            setDisplay(true);
            let count = cursor + 1;
            const nextItem = optionsRef.current[cursor + 1];
            nextItem && nextItem.scrollIntoView({ block: "end", inline: "nearest" });
            setCursor(count)
        }
    }

    return (
        <div ref={wrapperRef} style={{ width: '100%' }} className="flex-container flex-column pos-rel">
            <input autoComplete='off' ref={inputRef}
                id="auto"
                onClick={(e) => onClickInput(e)}
                placeholder="Type to search"
                value={search} onKeyDown={(e) => handleKeyDown(e)}
                onChange={event => onChangeAuto(event)}
            /><Button onClick={(e) => clear(e, '')}>X</Button>
            {display && (
                <div className="autoContainer">
                    {options.length > 0 && options
                        .filter((item, ind) => item[props.searchField]?.toLowerCase().indexOf(search?.toLowerCase()) > -1)
                        .map((item, i) => {
                            return (
                                <div id='option' ref={(ref) => {
                                    optionsRef.current = { ...optionsRef.current, [i]: ref };
                                }}
                                    onClick={(e) => updateValue(e.target.value, item)}
                                    className={cursor === i ? 'active' : 'option'}
                                    key={i}
                                    tabIndex="0"
                                >
                                    <span>{props.renderItem(item)}</span>
                                </div>
                            );
                        })}
                </div>
            )}
        </div>
    );
});
export default Autocomplete;