import React, { useEffect, useRef, useState } from "react";
import FMTable from "../../common/FMTable";
import Loader from "../../common/Loader";
import UserService from "../../../services/admin/UserService";
import { Link } from "react-router-dom";
import { Fade, FormControlLabel, Paper, StylesProvider, Switch, Theme } from "@material-ui/core";
import { Alert, Button, Container } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import EditSalesPerson from "./EditSalesPerson";
import AddSalesPerson from "./AddSalesPerson";
import Constants from "../../../Constants";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const SalesPersonlList = (props) => {
    const axiosPrivate = useAxiosPrivate();
    // general variables//
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    // general variables//

    const [salesPersons, setSalesPersons] = useState([]);

    const [open, setOpen] = useState(false);
    const [pagination, setPagination] = useState({
        pageSelected: 1,
        sizePerPage: 10,
        filterMOs: []
    });

    const [pageMO, setPageMO] = useState({
        pageableObj: [],
        totalElements: 0,
        pagination: pagination
    });

    
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    const columns = [{
        dataField: 'name',
        text: 'Name',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'mobileNo',
        text: 'Mobile NO',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'designation',
        text: 'designation',
        sort: true,
        type: 'text',
        value: ''
    },
    {
        dataField: 'salary',
        text: 'Salary',
        sort: true,
        type: 'text',
        value: ''
    }]

    const updateParent = () => {
        handleTableChange([], 1, pagination.sizePerPage);
    }

    const updateParentEditMode = (data) => {
       handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }

    const expandRow = {
        renderer: row => <EditSalesPerson data={row} items={salesPersons} updateParent={updateParentEditMode} />
    }

    const [updatePumps, setUpdatePumps] = useState([]);

    useEffect(() => {
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }, []);

    useEffect(() => {
        handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
    }, [updatePumps.length]);

    const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();

    const retreiveSalesPersons = (pagination, actionType) => {
        if (actionType === "pagination") {
            handleToggle()
        }
        setTableDataRetreivalMsg(Constants.LOADING_DATA);
        UserService.getAllSalesPerson(axiosPrivate, pagination).
            then(response => {
                const updatedList = response.data.pageableObj;
                setSalesPersons(updatedList)
                setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
                handleClose();
                setTableDataRetreivalMsg(null);
            })
            .catch(e => {
                setMessage(e.response ?  e.response.data : Constants.ERROR_MSG + " sales person details");
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setShow(true);
                setLoading(false);
                handleClose();
                setTableDataRetreivalMsg(null);
            })
    }

    const handleTableChange = (filters, page, sizePerPage, actionType) => {
        pagination.pageSelected = page;
        pagination.sizePerPage = sizePerPage;
        pagination.filterMOs = filters;
        retreiveSalesPersons(pagination, actionType);
    }

    const deleteSalesPersons = (pumpList) => {
        UserService.deleteSalesPersons(axiosPrivate, pumpList)
            .then(response => {
                handleTableChange([], 1, pagination.sizePerPage);
            })
            .catch(response => {

            })
    }



    const [showButton, setShowButton] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const nodeRef = useRef(null);



    return (
        <div className="col-md-10">
            <div className="col-md-10 float-right">
                <div className="row"><h4>Sales Persons</h4>{showButton && (
                    <h5 className="ml-3">  <Link className="addNewHeading"
                        onClick={() => setShowMessage(true)}
                    >
                        Add Salesperson
                    </Link></h5>
                )
                }</div>
                {show && (
                    <Alert className="col-md-6" variant={alertVariant} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{alertHeading}</Alert.Heading>
                        <p>{message}</p>
                    </Alert>

                )}
                <div className="row">

                    <CSSTransition
                        in={showMessage}
                        nodeRef={nodeRef}
                        timeout={500}
                        classNames="newBox"
                        unmountOnExit
                        onEnter={() => setShowButton(false)}
                        onExited={() => setShowButton(true)}
                    >
                        <div className="addNewBox"
                            ref={nodeRef}
                        ><div className="col-md-12 p-0">
                                <div className="float-left col-md-10"><h5 style={{ marginLeft: '15px', marginTop: '5px' }}>Add Salesperson</h5> </div>
                                <div className="col-md-2 float-right p-0">
                                    <StylesProvider injectFirst>
                                        <Button className="float-right addNewClose"
                                            onClick={() => setShowMessage(false)}
                                        >
                                            X
                                        </Button></StylesProvider>
                                </div>
                            </div>
                            <div className="p-0  ml-0" style={{ height: 'auto' }}>
                                <AddSalesPerson dataList={salesPersons} updateParent={updateParent} />
                            </div>


                        </div>
                    </CSSTransition>
                </div>
                <div className="row mt-3">
                    <Loader openDialog={open}></Loader>
                    <FMTable columns={columns} dataRetrievalMsg={tableDataRetreivalMsg} dataList={salesPersons} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow} showFilter={false} showDelete={true} deleteHandler={deleteSalesPersons} />
                </div>
            </div>
        </div >
    )

}
export default SalesPersonlList;