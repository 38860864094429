import 'bootstrap/dist/css/bootstrap.min.css';
import { isValid, parse } from 'date-fns';
import { format } from 'date-fns-tz';
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import DatePicker from "react-datepicker";
import Button from "@material-ui/core/Button";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import { Link } from "react-router-dom";
import CreditEntryDataService from "../../services/credit/CreditBookService";
import DailyFuelPriceDataService from "../../services/dailySales/DailyFuelPriceService";
import FuelTypeDataService from "../../services/products/FuelTypeService";
import VehicleDataService from "../../services/vehicle/VehicleService";
import { StylesProvider } from "@material-ui/core/styles";
import { FlashOnRounded } from '@material-ui/icons';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import { getDefaultNormalizer } from '@testing-library/react';
import Autocomplete from '../common/Autocomplete';
import NumberInput from '../common/NumberInput';
import useAxiosPrivate from '../../auth/useAxiosPrivate';
import AlertDialog from '../common/AlertDialog';
import Constants from '../../Constants';


const EditCreditEntry = ({ data, updateParent, updateParentOnDelete }) => {
  const [dttmOfSale, setDttmOfSale] = useState(new Date());
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const initialVehilceState = {
    vhRegNo: "",
    vhOwner: {
      transport_name: ''
    }
  }
  const intialCreditBookWrapperMO = {
    isRecentCreditEntry: false,
    creditBookMO: "",
    minAllowableDate: ""
  }
  const axiosPrivate = useAxiosPrivate();
  const [creditBookWrapperMO, setCreditBookWrapperMO] = useState(intialCreditBookWrapperMO);
  const [selectedVehicleMO, setVehicle] = useState(data.vehicleMO);
  const initialCreditEntryState = {
    id: null,
    selectedVehicleMO: {
      id: "",
      vhRegNo: '',
      vhOwner: {
        transport_name: ''
      }
    },
    price: "",
    comments: "",
    is_paid: "",
    amount_of_sale: "",
    litre_sale_volume: "",
    dttm_of_sale: "",
    priceError: "",
    amtOfSaleError: "",
    litreSaleVolError: "",
    dailySaleDateMO: "",
    vehicleError: "",
    dttmOfSaleError: ""
  };
  const [creditEntry, setCreditEntry] = useState(data);
  const [submitted, setSubmitted] = useState(false);
  const [fuelTypes, setFuelTypes] = useState([]);
  const initialFuelTypeState = {
    id: "",
    type: ""
  }
  const [fuelType, setFuelType] = useState(data.fuelPriceMO.fuel_type);
  const initialFuelPriceState = {
    id: "",
    fuel_type: {
      id: "",
      type: ""
    },
    price: ""
  }
  const [fuelPriceMO, setFuelPriceMO] = useState(data.fuelPriceMO);
  const [updateSubmitted, setUpdateSubmitted] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  useEffect(() => {
    retrieveVehicles();
    retrieveFuelTypes();
    getCreditEntry(data.id);
  }, [data.id]);

  const getCreditEntry = id => {
    CreditEntryDataService.get(axiosPrivate, id)
      .then(response => {
        var creditBookMO = response.data.creditBookMO;
        setCreditBookWrapperMO(response.data);
        setCreditEntry(creditBookMO);
        setVehicle(creditBookMO.vehicleMO);
        setFuelPriceMO(creditBookMO.fuelPriceMO);
        setFuelType(creditBookMO.fuelPriceMO.fuel_type);
        setDttmOfSale(parse(creditBookMO.dttm_of_sale, "dd.MM.yyyy H:mm:ss", new Date()));
        console.log(" daily sale status is   " + creditBookMO.dailySaleDateMO.status + " is paid is  " + creditBookMO.data.is_paid);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const retrieveFuelTypes = () => {
    FuelTypeDataService.getAll(axiosPrivate)
      .then(response => {
        const fuelTypes = response.data;
        setFuelTypes(fuelTypes);
        setSubmitted(false);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };


  const retrieveVehicles = () => {
    VehicleDataService.getAll(axiosPrivate)
      .then(response => {
        setVehicles(response.data);
        console.log(response.data);
      })
      .catch(e => {
        setMessage("Error while retrieving all vehilces");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
      });
  };

  const handleDttmOfSale = date => {
    setDttmOfSale(date);
    setMessage("Fetching price value for this date time range ....");
    setAlertHeading("Info");
    setAlertVariant("info");
    setShow(true);
    var formattedDttm = new String(format(date, "dd MM yyyy H:mm:ss", { timeZone: "Asia/Kolkata" }));
    DailyFuelPriceDataService.findByDttmOfSale(axiosPrivate, formattedDttm, fuelType.id)
      .then(response => {
        setCreditEntry({ ...creditEntry, price: response.data.price });
        setFuelPriceMO({ ...fuelPriceMO, price: response.data.price });
        setMessage("Found price value for this date time range");
        setAlertHeading("Success");
        setAlertVariant("success");
        setShow(true);
      }).catch(e => {
        if (e.response && e.response.data) {
          creditEntry.price = "";
          setFuelPriceMO({ ...fuelPriceMO, price: '' });
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setShow(true);
          console.log(e.response.data); // some reason error message
        }
        console.log(e);
      });
  };

  const handleSelectChange = event => {
    setDttmOfSale("");
    fuelPriceMO.price = '';
    const selectedIndex = event.target.options.selectedIndex;
    var locFuelType = fuelTypes[selectedIndex];
    fuelPriceMO.fuel_type = locFuelType;
    setFuelType(locFuelType);
    setCreditEntry({ ...creditEntry, "price": '' });
    console.log(event.target.value);
  };

  const handleInputChange = event => {
    console.log(event);
    const { name, value } = event.target;
    setCreditEntry({ ...creditEntry, [name]: value });
  };

  const handleValidation = () => {
    if (selectedVehicleMO.id === null || selectedVehicleMO.id === undefined || selectedVehicleMO.id === '') {
      setCreditEntry({ ...creditEntry, "vehicleError": "Please select valid vehicle registration no" });
      return false;
    }
    //date time of sale validation
    if (!isValid(dttmOfSale)) {
      setCreditEntry({ ...creditEntry, "vehicleError": '', "dttmOfSaleError": "Date and Time of sale is required or invalid" });
      return false;
    }
    if (creditEntry.price === '') {
      setCreditEntry({ ...creditEntry, "vehicleError": '', "dttmOfSaleError": "", "priceError": "Price cant be empty" });
      return false;
    }
    if (creditEntry.litre_sale_volume === '') {
      setCreditEntry({ ...creditEntry, "vehicleError": '', "dttmOfSaleError": "", "priceError": '', "litreSaleVolError": "Sale volume cant be empty" });
      return false;
    }
    if (creditEntry.amount_of_sale === '') {
      setCreditEntry({ ...creditEntry, "vehicleError": '', "dttmOfSaleError": "", "priceError": '', "litreSaleVolError": '', "amtOfSaleError": "Sale Amount cant be empty" });
      return false;
    }
    setCreditEntry({ ...creditEntry, "vehicleError": '', "dttmOfSaleError": "", "priceError": '', "litreSaleVolError": '', "amtOfSaleError": '' });
    return true;
  };

  function renderVehicleNumber(item, value) {
    return (
      item.vhRegNo.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }


  const updateCreditEntry = () => {
    const isValid = handleValidation();
    fuelPriceMO.fuel_type = fuelType;
    if (isValid) {
      setUpdateSubmitted(true);
      setShow(false);
      console.log(selectedVehicleMO);
      var data = {
        id: creditEntry.id,
        vehicleMO: selectedVehicleMO,
        fuelPriceMO: fuelPriceMO,
        dailySaleDateMO: creditEntry.dailySaleDateMO,
        litre_sale_volume: creditEntry.litre_sale_volume,
        amount_of_sale: creditEntry.amount_of_sale,
        is_paid: creditEntry.is_paid,
        dttm_of_sale: format(dttmOfSale, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" }),
        comments: creditEntry.comments
      };
      console.log(data);
      CreditEntryDataService.update(axiosPrivate, creditEntry.id, data)
        .then(response => {
          updateParent();
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setUpdateSubmitted(false);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully updated");
        })
        .catch(e => {
          if (e.response && e.response.data) {
            setMessage(e.response.data);
            setAlertHeading("Error!");
            setAlertVariant("danger");
            setUpdateSubmitted(false);
            setShow(true);
            console.log(e.response.data); // some reason error message
          }
          console.log(e);
        });
    }
  };

  const deleteCreditEntry = () => {
    setDeleteSubmitted(true);
    CreditEntryDataService.remove(axiosPrivate, creditEntry)
      .then(response => {
        updateParentOnDelete();
      }).catch(e => {
        if (e.response && e.response.data) {
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setDeleteSubmitted(false);
          setShow(true);
          console.log(e.response.data); // some reason error message
        }
        console.log(e);
      });
  };

  /**delete action  */

  const [dialogShow, setDialogShow] = useState(false);

  const yesBtnHandler = {
    name: 'Yes',
    callAction: deleteCreditEntry
  };

  const noBtnHandler = {
    name: 'No',
  };

  function deleteAction() {
    setDialogShow(true);
  }

  /**delete action  */

  return (

    <div className="p-0">
      <div className="col-md-12 float-right">
        {dialogShow && (
          <AlertDialog openDialog={true} dialogShowStateVar={setDialogShow} dialogTitle={"Delete Action!!!"}
            message={Constants.DELETE_CONTENT}
            noBtnHandler={noBtnHandler} yesBtnHandler={yesBtnHandler} />
        )}
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        {creditEntry?.is_paid !== 1 ? <div id="container">      <div className="autocomplete-wrapper" >

          <div className="col-md-12 float-left" >
            <label htmlFor="title">Vehilce No</label><span className='errorMsg'>  {creditEntry.vehicleError}</span>
            {vehicles.length > 0 && <Autocomplete
              items={vehicles}
              searchField={'vhRegNo'}
              renderItem={(item) =>
                <>
                  {item.vhRegNo} - {item.vhOwner.transport_name}
                </>

              }
              onChange={(event, val) => { setVehicle({ ...selectedVehicleMO, ['vhRegNo']: val }) }}
              onSelect={(value, item) => {
                { item !== null ? setVehicle(item) : setVehicle(initialVehilceState) }
              }}

              value={(selectedVehicleMO.vhRegNo)} />}
          </div>
        </div>
        </div>
          : <div className="col-md-12 float-left">
            <label htmlFor="price">Vehilce No <span style={{ fontStyle: 'italic', fontSize: '12px' }}>(non editable)</span></label>

            <input disabled={true} className="form-control" type="text" value={selectedVehicleMO.vhRegNo}></input>
          </div>}


        <div className="col-md-6 adminForm">
          <label htmlFor="title">Fuel Type</label>
          <select disabled={creditEntry.is_paid === 1 ? true : false} className="form-control" value={fuelType.id} onChange={handleSelectChange} name="fuel_type" id="fuel_type">
            {fuelTypes.map((item, index) => (
              <option
                key={index}
                value={item.id}>
                {item.type}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="Date of sale">Date & Time of Sale</label><span className='errorMsg'>  {creditEntry.dttmOfSaleError}</span>
          {(creditBookWrapperMO?.minAllowableDate ? <DatePicker autoComplete="off" selected={dttmOfSale} timeInputLabel="Time:" name="date_of_sale" id="date_of_sale" className="form-control" onChange={date => handleDttmOfSale(date)} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput minDate={(parse(creditBookWrapperMO?.minAllowableDate?.from_dttm, "dd.MM.yyyy H:mm:ss", new Date()))}
            maxDate={parse(creditBookWrapperMO?.minAllowableDate?.to_dttm, "dd.MM.yyyy H:mm:ss", new Date())} /> :
            <DatePicker autoComplete="off" selected={dttmOfSale} timeInputLabel="Time:" name="date_of_sale" id="date_of_sale" className="form-control" onChange={date => handleDttmOfSale(date)} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput />
          )}

        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="price">Price</label><span className='errorMsg'>  {creditEntry.priceError}</span>
          <NumberFormat disabled={creditEntry.is_paid === 1 ? true : false} autoComplete="off" id="price" name="price" fixedDecimalScale={true} decimalScale={2} value={fuelPriceMO.price} className="form-control" thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} onValueChange={(values) => {
            const { formattedValue, value } = values;
            // formattedValue = $2,223
            // value ie, 2223
            setFuelPriceMO({ ...fuelPriceMO, price: value });
          }} />

        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="salevolume">Sale Volume <span style={{ fontStyle: 'italic', fontSize: '12px' }}>(in litres)</span></label><span className='errorMsg'>  {creditEntry.litreSaleVolError}</span>
          <NumberInput disabled={creditEntry.is_paid === 1 ? true : false} autoComplete="off" id="litre_sale_volume" name="litre_sale_volume" isDecScaleEnabled={true} decimalScale={2} value={creditEntry.litre_sale_volume} className="form-control" isThousandSepEnabled={true} thousandsGroupStyle="lakh"
            onChange={(value) => setCreditEntry({ ...creditEntry, litre_sale_volume: value })}></NumberInput>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="saleamount">Sale amount</label><span className='errorMsg'>  {creditEntry.amtOfSaleError}</span>
          <NumberInput disabled={creditEntry.is_paid === 1 ? true : false} autoComplete="off" id="amount_of_sale" name="amount_of_sale" isDecScaleEnabled={true} decimalScale={2} value={creditEntry.amount_of_sale} className="form-control" isThousandSepEnabled={true} thousandsGroupStyle="lakh"
            onChange={(value) => setCreditEntry({ ...creditEntry, amount_of_sale: value })}></NumberInput>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="saleamount">Comments</label>
          <textarea maxLength={50} className="form-control" id="comments" name="comments" value={creditEntry.comments === null ? '' : creditEntry.comments} onChange={handleInputChange} />
        </div>
        <div style={{ width: "100%" }}>
          {(creditEntry.is_paid === 0 && creditBookWrapperMO.isRecentCreditEntry ? <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }} >

            <div className="pr-0 mr-0 float-right">  <button
              className="update-btn btn ml-0 float-right"
              variant="contained" type="button"
              onClick={updateCreditEntry} disabled={updateSubmitted}
            >{updateSubmitted ? 'Updating....' : 'Update'}
            </button>
            </div>
            <div className="float-right mr-3"  >

              <button
                className="delete-btn btn float-right"
                variant="contained"
                disabled={deleteSubmitted}
                type="button"
                onClick={(e) => deleteAction()}
              >
                {deleteSubmitted ? 'Deleting...' : 'Delete'}
              </button>
            </div>

          </div>


            : <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>  <button
              className="update-btn btn ml-0  float-right"
              variant="contained" type="button"
              onClick={updateCreditEntry} disabled={updateSubmitted}
            >{updateSubmitted ? 'Updating....' : 'Update'}
            </button>
            </div>)}
        </div>
      </div>
    </div>
  );
};

export default EditCreditEntry;