
const getAllPaymentModes = (axiosPrivate) => {
  return axiosPrivate.get("/getAllPaymentModes");
};

const getAllPaymentModesWithCash = (axiosPrivate) => {
  return axiosPrivate.get("/getAllPaymentModesWithCash");
};

const savePaymentMode = (axiosPrivate, data) => {
  return axiosPrivate.post("/savePaymentMode", data);
}

const deletePaymentModes = (axiosPrivate, data) => {
  return axiosPrivate.put("/deletePaymentModes", data);
}

const getAllDenominations = (axiosPrivate) => {
  return axiosPrivate.get("/getAllDenominations");
};

const saveDenomination = (axiosPrivate, data) => {
  return axiosPrivate.post("/saveDenomination", data);
}

const deleteDenominations = (axiosPrivate, data) => {
  return axiosPrivate.put("/deleteDenominations", data);
}

export default {
    getAllPaymentModes,
    getAllPaymentModesWithCash,
    savePaymentMode,
    deletePaymentModes,
    getAllDenominations,
    saveDenomination,
    deleteDenominations
}