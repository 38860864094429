import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import { useHistory } from "react-router-dom";
import { Collection } from "./stepForm/DailyCollection";
import DailySaleDate from "./stepForm/DailySaleDate";
import DailySalesList from "./stepForm/DailySalesList";
import { DailySalesSummary } from "./stepForm/DailySalesSummary";
import { FuelSales } from "./stepForm/FuelSales";
import { LubeSales } from "./stepForm/LubeSales";

const defaultData = {
  dailySaleDateMO: {
    daily_sale_date: '',
    salesPersonMO: '',
    from_dttm:'',
    to_dttm:'',
    pumps: ''
  },
  pumps:[],
  dateOfSale:"",
  lastName: "",
  nickName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
};

const steps = [
  { id: "dailySaleDateList" },
  { id: "dailySaleDate" },
  { id: "fuelSales" },
  { id: "lubeSales" },
  { id: "collection" },
  { id: "submit" },
];

export const MultiStepForm = () => {
  const [formData, setForm] = useForm(defaultData);
  let history = useHistory();
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = { formData, setForm, navigation, history };

  switch (step.id) {
    case "dailySaleDateList":
      return <DailySalesList {...props} />;
    case "fuelSales":
      return <FuelSales {...props} />;
    case "lubeSales":
      return <LubeSales {...props} />;
    case "dailySaleDate":
      return <DailySaleDate {...props} />;
    case "collection":
      return <Collection {...props} />;
    case "submit":
      return <DailySalesSummary {...props} />;
  }

  return (
    <div>
      <h1>Multi step form</h1>
    </div>
  );
};
export default MultiStepForm;