
const getAll = (axiosPrivate) => {
  return axiosPrivate.get("/collectionMode");
};

const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/getDailySalesSummary/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/saveCollections", data);
};

export default {
  get,
  getAll,
  create,
};
