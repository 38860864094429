import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import RetailOutletDataService from "../../../services/admin/RetailOutletService";
import { Button } from "@material-ui/core";
import Upload from "../../common/Upload";
import NumberInput from "../../common/NumberInput";
import Constants from "../../../Constants";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const CashDiffLimit = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const initialCashDiffLimitState = {
    id: "",
    max: "",
    min: "",
  };
  const [cashDiffLimit, setCashDiffLimit] = useState(initialCashDiffLimitState);

  const cashDiffErr = {
    maxError: "",
    minError: "",
  };
  const [cashDiffError, setCashDiffError] = useState(cashDiffErr);
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);

  const handleInputChange = (value, name) => {
    setCashDiffLimit({ ...cashDiffLimit, [name]: value });
  };

  useEffect(() => {
    retreiveCashDiffLimit();
  }, []);



  const retreiveCashDiffLimit = () => {
    RetailOutletDataService.getCashDiffLimit(axiosPrivate).then(response => {
      setCashDiffLimit(response.data);
    })
      .catch(e => {
        setSubmitted(false);
        setMessage(e.response ?  e.response.data : Constants.ERROR_MSG + " cash difference limit details");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        console.log(e);
      });
  }

  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);


  const handleValidation = () => {
    if (!cashDiffLimit?.min || isNaN(parseInt(cashDiffLimit?.min))) {
      setCashDiffError({ ...cashDiffError, "minError": "Please enter min allowable value in numbers" });
      return false;
    }
    if (!cashDiffLimit?.max || isNaN(parseInt(cashDiffLimit?.max))) {
      setCashDiffError({ ...cashDiffError, "minError": "", "maxError": "please enter max allowable value in numbers" });
      return false;
    }
    if (+cashDiffLimit.min >= +cashDiffLimit.max) {
      setCashDiffError({ ...cashDiffError, "minError": "Min allowable limit should be less than max value", "maxError": "" });
      return false;
    }
    setCashDiffError({ ...cashDiffError, "minError": "", "maxError": "" });
    return true;
  };

  const saveCashDiffLimitDetails = (e) => {
    setShow(false);
    if (handleValidation()) {
      setSubmitted(true);
      var data = {
        id: cashDiffLimit.id,
        max: cashDiffLimit.max,
        min: cashDiffLimit.min
      };

      RetailOutletDataService.saveCashDiffLimit(axiosPrivate, data)
        .then(response => {
          setSubmitted(false);
          setMessage("Details saved successfully...");
          setAlertHeading("Success");
          setAlertVariant("success");
          setShow(true);


        })
        .catch(e => {
          setSubmitted(false);
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setShow(true);
          console.log(e);
        });
    }

  };


  return (
    <div className="col-md-10">
      <div className="col-md-10 float-right">
        <div className="col-md-12 float-left pb-3 pt-3">
          <h4>Cash Difference Limit</h4>
        </div>
        {show ? (
          <div className="col-md-12 float-left">
            <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
              <Alert.Heading>{alertHeading}</Alert.Heading>
              <p>{message}</p>
            </Alert></div>

        ) : (<div></div>)}
        <div className=" col-md-6 float-left">
          <label htmlFor="title">Min Allowable Limit</label><span className='errorMsg'>  {cashDiffError.minError}</span>
          <NumberInput autoComplete="off" id="min" name="min" isDecScaleEnabled={true} decimalScale={2}
            value={cashDiffLimit.min} className="form-control" allowNegative={true}
            onChange={(value) => handleInputChange(value, "min")}></NumberInput>
        </div>
        <div className=" col-md-6 float-right">
          <label htmlFor="title">Max Allowable Limit</label>
          <span className='errorMsg'>  {cashDiffError.maxError}</span>
          <NumberInput autoComplete="off" id="max" name="max" isDecScaleEnabled={true} decimalScale={2}
            value={cashDiffLimit.max} className="form-control" allowNegative={true}
            onChange={(value) => handleInputChange(value, "max")}></NumberInput>
        </div>
        <div className="col-md-12 float-left mt-3">
          <button
            color="primary"
            variant="contained"
            className="add-btn btn float-right"
            onClick={(e) => saveCashDiffLimitDetails(e)} disabled={submitted}
          >
            {submitted ? 'Adding..' : 'Add Cash Diff'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CashDiffLimit;
