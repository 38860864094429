const get = (axiosPrivate,id) => {
  return axiosPrivate.get(`/validateDailySales/${id}`);
};

const getRecentDailySaleDate = (axiosPrivate) => {
  return axiosPrivate.get(`/getRecentDailySaleDate`);
}

const getRecentDailySaleDateDoneStatus = (axiosPrivate) => {
  return axiosPrivate.get(`/getRecentDailySaleDateDoneStatus`);
}

const getAll = (axiosPrivate, pageMO) => {
  return axiosPrivate.post(`/dailySaleDateDetails`, pageMO);
};


export default {
  getAll,
  get,
  getRecentDailySaleDate,
  getRecentDailySaleDateDoneStatus
};
