import { createContext, useState } from "react";

const AppUserContext = createContext();

export const AuthProvider = ({ children }) => {
    const [appUserCtx, setAppUserCtx] = useState();
    return (
        <AppUserContext.Provider value={{ appUserCtx, setAppUserCtx }}>
            {children}
        </AppUserContext.Provider>
    )
}
export default AppUserContext;