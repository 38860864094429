import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Alert from 'react-bootstrap/Alert';
import MailService from "../../services/mail/MailService";
import { Button } from "@material-ui/core";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const MailToAuditor = () => {
  const durationInitialState = {
    month: 0,
    year: 0
  };
  const mailMOInitialState = {
    fromAddress: '',
    toAddress: '',
    subject: '',
    bodyText: ''
  };
  const axiosPrivate = useAxiosPrivate();
  const [mailMO, setMailMO] = useState(mailMOInitialState);
  const [submitted, setSubmitted] = useState(false);
  const [duration, setDuration] = useState(new Date());
  const initialMailErrorState = {
    toAddressError: "",
    durationError: ""
  };
  const [mailError, setMailError] = useState(initialMailErrorState);
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");

  const handleValidation = () => {
    if (duration === null) {
      setMailError({ ...mailError, 'durationError': "Please select month and year of the sales" });
      return false;
    }
    if (mailMO.toAddress === '' || mailMO.toAddress === null) {
      setMailError({ ...mailError, 'durationError': '', 'toAddressError': "Please enter email address" });
      return false;
    }
    var isValid = false;
    var emailArray = mailMO.toAddress.split(',');
    emailArray.map((email, index) => {
      if (!email.match(isValidEmail)) {
        setMailError({ ...mailError, 'durationError': '', 'toAddressError': "'" + email + "' is not a valid email address" });
        isValid = false;

      } else {
        isValid = true;

      }

    });
    if (!isValid) {
      return false;
    }

    setMailError({ ...mailError, 'durationError': '', 'toAddressError': '' });
    return true;
  };

  const handleInputChange = event => {
    console.log(event);
    const { name, value } = event.target;
    setMailMO({ ...mailMO, [name]: value });
  };


  const sendMailToAuditor = () => {
    setShow(false);
    if (handleValidation()) {
      setSubmitted(true);
      console.log(duration.getMonth());
      console.log(duration.getFullYear());
      console.log(mailMO.toAddress);
      var data = {
        month: duration.getMonth() + 1,
        year: duration.getFullYear(),
        toAddress: mailMO.toAddress
      };
      MailService.sendMailToAuditor(axiosPrivate, data)
        .then(response => {
          setAlertHeading("Success");
          setMessage("mail has been send successfully!");
          setAlertVariant("success");
          setShow(true);
          setSubmitted(false);
        }).catch(e => {
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setSubmitted(false);
          setShow(true);
        });
    }

  }

  return (
    <div className=" list col-md-12">
      <div className="row ml-0 col-md-12">
        <div className="mt-3"><h4 className="float-left">Mail</h4></div>
        <div className="ml-5">
          {show ? (
            <Alert className=" float-right" variant={alertVariant} onClose={() => setShow(false)} dismissible>
              <Alert.Heading>{alertHeading}</Alert.Heading>
              <p>{message}</p>
            </Alert>

          ) : (<div></div>)}
        </div>
      </div>

      <div className="form-group mt-3 ml-0 p-0 col-md-12 d-flex">
        <div className="col-md-6 ml-0"><label className=" mt-3 ml-0" htmlFor="toAddress">Select month and year of the sales (fuel and lubes)  report</label></div>
        <div className="wrapper col-md-6">
          <div className="vert first "> <span className='errorMsg'>  {mailError.durationError}</span></div>
          <div className="vert second" >

            <DatePicker className="col-md-6 float-left"
              selected={duration}
              onChange={date => setDuration(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </div>
        </div>

      </div>
      <div className="form-group mt-3 p-0 col-md-12 d-flex " style={{ height: "100px" }}>
        <div className="col-md-6 ml-0"><label className="mt-3 ml-0" htmlFor="toAddress">Email Address</label></div>
        <div className="wrapper col-md-6 p-0">
          <div className="vert first"> <span className='errorMsg'>  {mailError.toAddressError}</span></div>
          <div className="vert second p-0 " ><input
            type="text"
            className="ml-0 col-md-12 float-left"
            id="toAddress"
            required
            value={mailMO.toAddress}
            onChange={handleInputChange}
            name="toAddress"
          />
            <div> <label><span style={{ fontStyle: 'italic', fontSize: '12px' }}>(valid mail format is hellofuelman@gmail.com;hellouser@fuelman.com, for multiple mail ids, seperate with ";")</span></label>
            </div>
          </div>
        </div>

      </div>
      <div className="form-group col-md-12 p-0 float-right">
        <button
          color="primary"
          className="add-btn btn float-right mr-0"
          variant="contained"
          onClick={(e) => sendMailToAuditor(e)} disabled={submitted}
        >
          {submitted ? 'Sending....' : 'Send'}
        </button>
        
      </div>
    </div>
  );
};

export default MailToAuditor;
