import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import "react-datepicker/dist/react-datepicker.css";
import { StylesProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import VehicleOwnerDataService from "../../services/vehicle/VehicleOwnerService";
import VehicleDataService from "../../services/vehicle/VehicleService";
import VehicleTypeDataService from "../../services/vehicle/VehicleTypeService";
import Autocomplete from '../common/Autocomplete';
import useAxiosPrivate from '../../auth/useAxiosPrivate';
import AlertDialog from '../common/AlertDialog';
import Constants from '../../Constants';

const EditVehicle = ({ data, updateParentOnEdit, updateParentOnDelete }) => {
  const axiosPrivate = useAxiosPrivate();
  const initialVehicleState = {
    id: "",
    vhType: {
      id: "",
      type: "",
      name: ""
    },
    vhOwner: {
      id: "",
      name: ""
    },
    vhRegNo: ""
  };


  const initialVehicleType = {
    id: "",
    type: "",
    name: ""
  };

  const initialVehicleOwner = {
    id: "",
    transport_name: "",
    name: ""
  };
  const initialVehicleErrorState = {
    vhTypeError: "",
    vhOwnerError: "",
    vhRegNoError: ""
  };
  const [vehicleError, setVehicleError] = useState(initialVehicleErrorState);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vhOwners, setVehicleOwners] = useState([]);
  const [currentVehicle, setCurrentVehicle] = useState(data);
  const [message, setMessage] = useState("");
  const [vehicleTypeSelected, setvehicleTypeSelected] = useState(data.vhType);
  const [vhOwnerSelected, setVehicleOwnerSelected] = useState(data.vhOwner);
  const [show, setShow] = useState(false);
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [updateSubmitted, setUpdateSubmitted] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  const getVehicle = id => {
    VehicleDataService.get(axiosPrivate, id)
      .then(response => {
        console.log(React.version);
        setCurrentVehicle(response.data);
        setvehicleTypeSelected(response.data.vhType);
        setVehicleOwnerSelected(response.data.vhOwner);
      })
      .catch(e => {
        console.log(e);;
      });
  };

  useEffect(() => {
    retrieveVehicleTypes();
    retrieveVehicleOwner();
  }, []);



  const retrieveVehicleTypes = () => {
    VehicleTypeDataService.getAllVehicleTypes(axiosPrivate)
      .then(response => {
        setVehicleTypes(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const retrieveVehicleOwner = () => {
    VehicleOwnerDataService.getAll(axiosPrivate)
      .then(response => {
        setVehicleOwners(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };
  const handleVhOwnerSelectChange = event => {
    const selectedIndex = event.target.options.selectedIndex;
    setVehicleOwnerSelected(vhOwners[selectedIndex]);
    currentVehicle.vhOwner = vhOwners[selectedIndex];
    console.log(event.target.value);
  };

  const handleSelectChange = event => {
    const selectedIndex = event.target.options.selectedIndex;
    setvehicleTypeSelected(vehicleTypes[selectedIndex]);
    currentVehicle.vhType = vehicleTypes[selectedIndex];
    console.log(event.target.value);
  };

  const handleInputChange = event => {
    event.target.value = event.target.value.toUpperCase();
    const { name, value } = event.target;
    setCurrentVehicle({ ...currentVehicle, [name]: value });
  };

  const handleValidation = () => {
    if (!currentVehicle.vhType) {
      setVehicleError({ ...vehicleError, 'vhTypeError': "Please select vehilce type" });
      return false;
    }
    if (!currentVehicle.vhOwner) {
      setVehicleError({ ...vehicleError, 'vhTypeError': '', 'vhOwnerError': "Please select vehilce owner" });
      return false;
    }
    if (!currentVehicle.vhRegNo) {
      setVehicleError({ ...vehicleError, 'vhTypeError': '', 'vhOwnerError': '', 'vhRegNoError': "Please enter vehilce number" });
      return false;
    }

    setVehicleError({ ...vehicleError, 'vhTypeError': '', 'vhOwnerError': '', 'vhRegNoError': '' });
    return true;
  };

  const updateVehicle = () => {
    if (handleValidation()) {
      setUpdateSubmitted(true);
      VehicleDataService.update(axiosPrivate, currentVehicle.id, currentVehicle)
        .then(response => {
          updateParentOnEdit();
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setUpdateSubmitted(false);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully updated");
          setUpdateSubmitted(false);
        })
        .catch(e => {
          if (e.response && e.response.data) {
            setMessage(e.response.data);
            setAlertHeading("Error!");
            setAlertVariant("danger");
            setShow(true);
            setUpdateSubmitted(false);
            console.log(e.response.data); // some reason error message
          }
          console.log(e);
        });
    }
  };

  const deleteVehicle = () => {
    currentVehicle.is_deleted = 1;
    setDeleteSubmitted(true);
    VehicleDataService.deleteVehicle(axiosPrivate, currentVehicle.id)
      .then(response => {
        console.log(response.data);
        updateParentOnDelete();
      })
      .catch(e => {
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setDeleteSubmitted(false);
        setShow(true);
      });
  };
  /**delete action  */

  const [dialogShow, setDialogShow] = useState(false);

  const yesBtnHandler = {
    name: 'Yes',
    //dont add deleteFuelPrice() with  open and close bracket, which will cause indefinte 
    // loop always refer function without braces
    callAction: deleteVehicle
  };

  const noBtnHandler = {
    name: 'No',
  };

  function deleteAction() {
    setDialogShow(true);
  }

  /**delete action  */

  return (
    <div className="p-0">
      <div className="col-md-12 float-right">
        {dialogShow && (
          <AlertDialog openDialog={true} dialogShowStateVar={setDialogShow} dialogTitle={"Delete Action!!!"}
            message={Constants.DELETE_CONTENT}
            noBtnHandler={noBtnHandler} yesBtnHandler={yesBtnHandler} />
        )}
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>
        )}
        <div className="col-md-6 float-left">
          <label htmlFor="vehicle owner">Vehicle Owner</label><span className='errorMsg'>  {vehicleError.vhOwnerError}</span>
          <input className='form-control' disabled={true} value={currentVehicle.vhOwner.name}></input>
        </div>
        <div className="col-md-6 float-right">
          <label htmlFor="vehicle owner">Vehicle Type</label><span className='errorMsg'>  {vehicleError.vhTypeError}</span>
          <Autocomplete items={vehicleTypes}
            searchField={'type'}
            renderItem={(item) =>
              <>
                {item.type}
              </>
            }
            onChange={(event, value) => { setCurrentVehicle({ ...currentVehicle, ['vhType.type']: value }) }}
            onSelect={(value, item) => {
              setCurrentVehicle({ ...currentVehicle, ['vhType']: item ? item : undefined })
            }}
            value={currentVehicle.vhType?.type} />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="vhRegNo">Registration No</label><span className='errorMsg'>  {vehicleError.vhRegNoError}</span>
          <input
            type="text"
            className="form-control"
            id="vhRegNo"
            name="vhRegNo"
            value={currentVehicle.vhRegNo}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
          <div className="pr-0 mr-0 float-right">  <button
            className="update-btn btn ml-0 float-right"
            variant="contained" type="button"
            onClick={updateVehicle} disabled={updateSubmitted}
          >{updateSubmitted ? 'Updating....' : 'Update'}
          </button>
          </div>
          <div className="float-right mr-3">
            <button
              className="delete-btn btn float-right"
              variant="contained"
              disabled={deleteSubmitted}
              type="button"
              onClick={(e) => deleteAction()}
            >
              {deleteSubmitted ? 'Deleting...' : 'Delete'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditVehicle;
