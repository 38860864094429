import React from "react";
import { Link, useLocation } from "react-router-dom";

const ActionSuccessPage = props => {

    const { state } = props.location.state
    const location = useLocation();


    return (
        <div className="actionSuccess-page mt-5">
            <h4 className={location.state.class}>{location.state.infoContent} !</h4>
        </div>
    )

}
export default ActionSuccessPage;