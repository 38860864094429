
const getAllPumps = (axiosPrivate, pageMO) => {
  return axiosPrivate.post("/getAllPump", pageMO);
};
const getAllNozzles = (axiosPrivate, pageMO) => {
  return axiosPrivate.post("/getAllNozzles", pageMO);
};
const getPumps = (axiosPrivate) => {
  return axiosPrivate.get("/getAllPump");
};
const savePump = (axiosPrivate, data) => {
  return axiosPrivate.post("/savePump", data);
};
const saveNozzle = (axiosPrivate, data) => {
  return axiosPrivate.post("/saveNozzle", data);
};
const deletePumps = (axiosPrivate, pumpList) => {
  return axiosPrivate.put("/deletePumps", pumpList);
};
const deleteNozzles = (axiosPrivate, nozzleList) => {
  return axiosPrivate.put("/deleteNozzles", nozzleList);
};
const updatePump = (axiosPrivate, data) => {
  return axiosPrivate.put("/updatePump", data);
};
const updateNozzle = (axiosPrivate, data) => {
  return axiosPrivate.put("/updateNozzle", data);
};
export default {
  getAllPumps,
  getAllNozzles,
  getPumps,
  savePump,
  saveNozzle,
  deletePumps,
  deleteNozzles,
  updatePump,
  updateNozzle
};