import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import DailySalesService from '../../../services/dailySales/DailySalesService';
import FMTable from "../../common/FMTable";
import Loader from "../../common/Loader";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const DailySaleDatesList = () => {
  const [dailySalesSummary, setDailySalesSummary] = useState([]);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const selectRef = useRef(null);
  // pagination and filter constants//
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    pageSelected: 1,
    sizePerPage: 10,
    filterMOs: []
  });
  const [pageMO, setPageMO] = useState({
    pageableObj: [],
    totalElements: 0,
    pagination: pagination
  });
  const axiosPrivate = useAxiosPrivate();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  // pagination and filter constants//

  const [saleStatus, setSaleStatus] = useState();

  const options = [{ value: '', label: "None" }, { value: "0", label: "Started" },
  { value: "1", label: "In Progress" }, { value: "2", label: "Done" }];


  useEffect(() => {
    retrieveDailySaleDates(pagination);
  }, []);



  const columns = [{
    dataField: 'dailySaleDateMO.daily_sale_date',
    text: 'Sale Date',
    type: 'text',
    sort: true,
  }, {
    dataField: 'dailySaleDateMO.salesPersonMO.name',
    text: 'Sales Person',
    type: 'text',
    sort: true,
  }, {
    dataField: 'dailySaleDateMO.from_dttm',
    text: 'Shift Start Timings',
    type: 'text',
    sort: true,
  }, {
    dataField: 'totalCashReceivable',
    text: 'Cash Receivable',
    type: 'text',
    sort: true,
    columnFormatter: (handleFilter) => (
      <div></div>
    ),
  }, {
    dataField: 'totalCashReceived',
    text: 'Cash Received',
    type: 'text',
    sort: true,
    columnFormatter: (handleFilter) => (
      <div></div>
    ),
  }, {
    dataField: 'cashDifference',
    text: 'Difference',
    type: 'text',
    sort: true,
    placeholder: ' > or < or ='
  }, {
    dataField: 'dailySaleDateMO.status',
    text: 'Status',
    type: 'select',
    sort: true,
    clearFilter: () => {
      selectRef.current.value = ''
    },

    columnFormatter: (handleFilter, index) => (
      <div className="custom-select">
        <select ref={selectRef}
          id="saleStatus"
          onChange={(e) => onSaleStatusChange(handleFilter, e)}
        >
          {options.map(option => {
            return <option value={option.value}>{option.label}</option>
          })}

        </select></div>
    ),
  }];

  const onSaleStatusChange = (handleFilter, e) => {
    return handleFilter(e);
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
    }
  };



  const expandRow = {
    renderer: row => (
      <div>
        <h4>Daily Sales Details</h4>
        <div>
          <label>
            <strong>Date of Sale :</strong>
          </label>{" "}
          {row.dailySaleDateMO.daily_sale_date}
        </div>
        <div>
          <label>
            <strong>Sales Person :</strong>
          </label>{" "}
          {row.dailySaleDateMO.salesPersonMO.name}
        </div>
        <div>
          <label>
            <strong>Shift Start Time :</strong>
          </label>{" "}
          {row.dailySaleDateMO.from_dttm}
        </div>
        <div>
          <label>
            <strong>Shift End Time :</strong>
          </label>{" "}
          {row.dailySaleDateMO.to_dttm}
        </div>
        <div className="col-md-12 p-0" style={{ display: 'flex', align: 'left' }}>
          {((row.dailySaleDateMO.isEditable === 1 && pagination.pageSelected === 1) ? <div className="pr-2"> <h4 style={{ align: 'left', marginLeft: '0px' }}><Link
            to={{
              pathname: "/fmApp/dailySaleDateDetails",
              state: {
                id: row.dailySaleDateMO.id
              }
            }}
            className="badge badge-warning"
          >
            Edit
          </Link></h4> </div> : <div></div>)
          }{
            (row.dailySaleDateMO.status === 'DONE') &&
            <div className="p-0 ml-0">
              <h4 className="col-md-2 p-0"><Link
                to={{
                  pathname: "/fmApp/dailySalesSummary",
                  state: {
                    id: row.dailySaleDateMO.id,
                    isSummaryOnly: 1
                  }
                }}
                className="badge badge-primary"
              >
                Summary
              </Link></h4></div>}
        </div>
      </div>
    )
  };

  const handleTableChange = (filters, page, sizePerPage, actionType) => {
    pagination.pageSelected = page;
    pagination.sizePerPage = sizePerPage;
    pagination.filterMOs = filters;
    retrieveDailySaleDates(pagination, actionType);
  }

  const retrieveDailySaleDates = (pagination, actionType) => {
    if (actionType === "pagination" || actionType == 'clearFilter') {
      handleToggle()
    }
    DailySalesService.getAll(axiosPrivate, pagination)
      .then(response => {
        setDailySalesSummary(response.data.pageableObj);
        setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
        setLoading(false);
        handleClose();
      })
      .catch(e => {
        setMessage("Error while retrieving all daily sale details");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        setLoading(false);
        handleClose();
      });
  };

  return (
    <div className="list row">
      <div className="col-md-12">
        {show && (
          <Alert className="col-md-6" variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <div className="row col-md-12">
          <h4>Daily Sales</h4>
          <h5><Link to={{ pathname: "/fmApp/dailySalesInfo", state: { id: 0 } }} className="m-1 badge badge-success">
            Add
          </Link></h5></div>
        {loading ? <div className="mt-5 d-grid gap-2">
          <Button variant="primary" size="lg" className="text-black" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="visually-hidden">  please wait, we are fetching the daily sales details...</span>
          </Button>
        </div> : <div>
          <Loader openDialog={open}></Loader>
          <FMTable columns={columns} dataList={dailySalesSummary} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow} />

        </div>}
      </div>
    </div >
  );
};

export default DailySaleDatesList;
