import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';

const Model = forwardRef((props, ref) => {

    const styles = {
        show: {
            visibility: 'visible',
        },
        hide: {
            visibility: 'hidden',
        },
    };

    const [showElement, setShowElement] = useState(true);
    const [modelOpen, setModelOpen] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            setModelOpen: setModelOpen
        };
    });

    useEffect(() => {
        if (modelOpen) {
            setShowElement(true);
            setTimeout(function () {
                setShowElement(false)
                setModelOpen(false)
            }, props.hideDuration);
        }
    }, [modelOpen])

    return (
        (
            showElement && <div style={props.style}
                className={modelOpen ? 'Model-show-' + props.variant : 'Model-hide'} >
                <div ><span> <CheckCircleOutline></CheckCircleOutline> {props.message} </span> <span style={{ marginRight: '0px', float: 'right' }}><ClearIcon className='cancel' onClick={(e) => setModelOpen(false)} /></span></div>
            </div>)
    )
})

export default Model;