import 'bootstrap/dist/css/bootstrap.min.css';
import { parse } from 'date-fns';
import { format } from 'date-fns-tz';
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import DailyFuelPriceDataService from "../../services/dailySales/DailyFuelPriceService";
import FuelTypeDataService from "../../services/products/FuelTypeService";
import FuelStockDataService from "../../services/stock/FuelStockService";
import { StylesProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import NumberInput from '../common/NumberInput';
import useAxiosPrivate from '../../auth/useAxiosPrivate';
import AlertDialog from '../common/AlertDialog';
import Constants from '../../Constants';

const EditFuelStock = ({ data, updateParentOnEdit, updateParentOnDelete }) => {
  const axiosPrivate = useAxiosPrivate();
  const initialFuelStockState = {
    id: "",
    volume_added: "",
    added_dttm: new Date(),
    fuelPriceMO: "",
    commission: "",
    fuelTypeMO: {
      id: "",
      type: "",
      name: ""
    },
    volumeErrror: "",
    fuelPriceError: "",
    addedDttmError: "",
    commissionError: ""
  };
  const [currentFuelStock, setCurrentFuelStock] = useState(data);
  const [message, setMessage] = useState("");
  const [fuelTypes, setFuelTypes] = useState([]);
  const initialFuelType = {
    id: "",
    type: "",
    name: ""
  };
  const [fuelTypeSelected, setFuelTypeSelected] = useState(data.fuelTypeMO);
  const [addedDTTM, setAddedDTTM] = useState(new Date());
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const initialFuelPriceState = {
    id: "",
    fuel_type: {
      id: "",
      type: ""
    },
    price: ""
  }
  const [fuelPriceMO, setFuelPriceMO] = useState(data.fuelPriceMO);
  const [isDeleted, setIsDeleted] = useState(false);
  const [updateSubmitted, setUpdateSubmitted] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);


  useEffect(() => {
    retrieveFuelTypes();
  }, []);

  function isObjectEmpty(obj) {
    return Object.getOwnPropertyNames(obj).length >= 1
  }

  const getFuelStock = id => {
    FuelStockDataService.get(axiosPrivate, id)
      .then(response => {
        setCurrentFuelStock(response.data);
        setFuelTypeSelected(response.data.fuelTypeMO);
        setFuelPriceMO(response.data.fuelPriceMO);
        if (isObjectEmpty(response.data.added_dttm)) {
          setAddedDTTM(parse(response.data.added_dttm, "dd.MM.yyyy H:mm:ss", new Date()));
        }
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };


  const retrieveFuelTypes = () => {
    FuelTypeDataService.getAll(axiosPrivate)
      .then(response => {
        setFuelTypes(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };
  const handleInputChange = (value, name) => {
    setCurrentFuelStock({ ...currentFuelStock, [name]: value });
  };

  const handleSelectChange = event => {
    setShow(false);
    setAddedDTTM("");
    setFuelPriceMO({ ...fuelPriceMO, price: 0 });
    const selectedIndex = event.target.options.selectedIndex;
    var locFuelType = fuelTypes[selectedIndex];
    fuelPriceMO.fuel_type = locFuelType;
    setFuelTypeSelected(locFuelType);
    setCurrentFuelStock({ ...currentFuelStock, fuelPrice: 0, volume_added: "", fuelTypeMO: locFuelType });

    console.log(event.target.value);
  };

  const handleDttmOfSale = date => {
    setShow(false);
    setAddedDTTM(date);
    setFuelPriceMO({ ...fuelPriceMO, price: 0 });
    setCurrentFuelStock({ ...currentFuelStock, fuelPrice: 0, 'volumeErrror': '', 'fuelPriceError': '', 'commissionError': "", 'addedDttmError': '', added_dttm: addedDTTM });
    setMessage("Fetching price value for this date time range ....");
    setAlertHeading("Info");
    setAlertVariant("info");
    setShow(true);
    var formattedDttm = new String(format(date, "dd MM yyyy H:mm:ss", { timeZone: "Asia/Kolkata" }));
    DailyFuelPriceDataService.findByDttmOfSale(axiosPrivate, formattedDttm, currentFuelStock.fuelTypeMO.id)
      .then(response => {
        setFuelPriceMO({ ...fuelPriceMO, price: response.data.price });
        setMessage("Found price value for this date time range");
        setAlertHeading("Success");
        setAlertVariant("success");
        setShow(true);
      }).catch(e => {
        if (e.response && e.response.data) {
          setFuelPriceMO({ ...fuelPriceMO, price: 0 });
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setShow(true);
          console.log(e.response.data); // some reason error message
        }
        console.log(e);
      });
  };

  const handleValidation = () => {
    if (currentFuelStock.volume_added === '') {
      setCurrentFuelStock({ ...currentFuelStock, 'volumeErrror': "Please enter volume added" });
      return false;
    }
    if (addedDTTM === "" || addedDTTM === null) {
      setCurrentFuelStock({ ...currentFuelStock, 'volumeErrror': '', 'addedDttmError': "Please added date and time" });
      return false;
    }
    if (fuelPriceMO.price === "" || fuelPriceMO.price === 0) {
      setCurrentFuelStock({ ...currentFuelStock, 'volumeErrror': '', 'addedDttmError': '', 'fuelPriceError': "Please enter fuel price" });
      return false;
    }
    if (currentFuelStock.commission === "" || currentFuelStock.commission === 0) {
      setCurrentFuelStock({ ...currentFuelStock, 'volumeErrror': '', 'addedDttmError': '', 'fuelPriceError': "", 'commissionError': "Please enter fuel sales commision" });
      return false;
    }
    setCurrentFuelStock({ ...currentFuelStock, 'volumeErrror': '', 'fuelPriceError': '', 'addedDttmError': '', 'commissionError': "" });
    return true;
  };


  const updateFuelStock = () => {
    if (handleValidation()) {
      setUpdateSubmitted(true);
      setShow(false);
      currentFuelStock.added_dttm = format(addedDTTM, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" });
      currentFuelStock.fuelPriceMO.price = fuelPriceMO.price;
      FuelStockDataService.update(axiosPrivate, currentFuelStock.id, currentFuelStock)
        .then(response => {
          console.log(response.data);
          updateParentOnEdit();
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully updated");
          setUpdateSubmitted(false);
        })
        .catch(e => {
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setShow(true);
          setUpdateSubmitted(false);
          console.log(e);
        });
    }
  };

  const deleteFuelStock = () => {
    setDeleteSubmitted(true);
    FuelStockDataService.remove(axiosPrivate, currentFuelStock.id)
      .then(response => {
        console.log(response.data);
        setIsDeleted(true);
        updateParentOnDelete();
      })
      .catch(e => {
        setDeleteSubmitted(false);
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
      });
  };

    /**delete action  */

    const [dialogShow, setDialogShow] = useState(false);

    const yesBtnHandler = {
      name: 'Yes',
      //dont add deleteFuelPrice() with  open and close bracket, which will cause indefinte 
      // loop always refer function without braces
      callAction: deleteFuelStock
    };
  
    const noBtnHandler = {
      name: 'No',
    };
  
    function deleteAction() {
      setDialogShow(true);
    }
  
    /**delete action  */

  return (
    <div className="p-0">
      <div className="col-md-12 float-right">
        {dialogShow && (
          <AlertDialog openDialog={true} dialogShowStateVar={setDialogShow} dialogTitle={"Delete Action!!!"}
            message={Constants.DELETE_CONTENT}
            noBtnHandler={noBtnHandler} yesBtnHandler={yesBtnHandler} />
        )}
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <div className="col-md-6 float-left">
          <label htmlFor="price">Fuel Type<span style={{ fontStyle: 'italic', fontSize: '12px' }}> (non editable)</span></label>
          <input className="form-control" type="text" value={fuelTypeSelected.type + " - " + fuelTypeSelected.name}></input>
        </div>
        <div className="col-md-6 float-right">
          <label htmlFor="volume_added">Volume Added</label>
          <span className='errorMsg'>  {currentFuelStock.volumeErrror}</span>

          <NumberInput autoComplete="off" id="volume_added" name="volume_added" isDecScaleEnabled={true} decimalScale={2}
            value={currentFuelStock.volume_added} className="form-control"
            onChange={(value) => handleInputChange(value, "volume_added")}></NumberInput>

        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="added_dttm">Added DTTM</label><span className='errorMsg'>  {currentFuelStock.addedDttmError}</span>
          <DatePicker selected={addedDTTM} placeholderText="Select a date" timeInputLabel="Time:" name="added_dttm" id="added_dttm" className="form-control" onChange={date => handleDttmOfSale(date)} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput />

        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="fuelPrice">Price</label>
          <span className='errorMsg'>  {currentFuelStock.fuelPriceError}</span>
          <NumberInput autoComplete="off" id="fuelPrice" name="fuelPrice" isDecScaleEnabled={true} decimalScale={2}
            value={fuelPriceMO.price} className="form-control"
            onChange={(value) => setFuelPriceMO({ ...fuelPriceMO, price: value })}></NumberInput>

        </div>
        <div className="col-md-6 adminFormReducedPadding">
          <label htmlFor="commission">Commission</label><span className='errorMsg'>  {currentFuelStock.commissionError}</span>
          <NumberInput autoComplete="off" id="commission" name="commission" isDecScaleEnabled={true} decimalScale={2}
            value={currentFuelStock.commission} className="form-control"
            onChange={(value) => handleInputChange(value, "commission")}></NumberInput>
        </div>
        <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
          <div className="pr-0 mr-0 float-right">  <button
            className="update-btn btn ml-0 float-right"
            variant="contained" type="button"
            onClick={updateFuelStock} disabled={updateSubmitted}
          >{updateSubmitted ? 'Updating....' : 'Update'}
          </button>
          </div>
          <div className="float-right  mr-3" >
            <button
              className="delete-btn-editmode btn float-right"
              variant="contained"
              disabled={deleteSubmitted}
              type="button"
              onClick={(e) => deleteAction()}
            >
              {deleteSubmitted ? 'Deleting...' : 'Delete'}
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default EditFuelStock;
