import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import VehicleTypeDataService from "../../services/vehicle/VehicleTypeService";
import { StylesProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import useAxiosPrivate from "../../auth/useAxiosPrivate";
import Constants from "../../Constants";
import AlertDialog from "../common/AlertDialog";

const EditVehicleType = ({ data, updateParentOnEdit, updateParentOnDelete }) => {
  const axiosPrivate = useAxiosPrivate();
  const initialVehicleTypeState = {
    id: null,
    name: null,
    type: null
  };
  const [currentVehicleType, setCurrentVehicleType] = useState(data);
  const initialVehicleTypeErrorState = {
    typeError: "",
    nameError: "",
  };
  const [vehicleTypeError, setVehicleTypeError] = useState(initialVehicleTypeErrorState);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [updateSubmitted, setUpdateSubmitted] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  const getVehicleType = id => {
    VehicleTypeDataService.get(id)
      .then(response => {
        setCurrentVehicleType(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };



  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentVehicleType({ ...currentVehicleType, [name]: value });
  };


  const handleValidation = () => {
    if (currentVehicleType.type === undefined || currentVehicleType.type === null || currentVehicleType.type === '') {
      setVehicleTypeError({ ...vehicleTypeError, 'typeError': "Please enter vehilce type" });
      return false;
    }
    if (currentVehicleType.name === undefined || currentVehicleType.name === null || currentVehicleType.name === '') {
      setVehicleTypeError({ ...vehicleTypeError, 'typeError': '', 'nameError': "Please enter description" });
      return false;
    }
    setVehicleTypeError({ ...vehicleTypeError, 'typeError': '', 'nameError': '' });
    return true;
  };

  const updateVehicleType = () => {
    setShow(false);
    setUpdateSubmitted(true)
    if (handleValidation()) {
      VehicleTypeDataService.update(axiosPrivate, currentVehicleType.id, currentVehicleType)
        .then(response => {
          updateParentOnEdit()
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setUpdateSubmitted(false);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully updated");
          setUpdateSubmitted(false)
        })
        .catch(e => {
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setUpdateSubmitted(false)
          setShow(true);
        });
    }

  };

  const deleteVehicleType = () => {
    currentVehicleType.is_deleted = 1;
    setShow(false);
    setDeleteSubmitted(true);
    VehicleTypeDataService.deleteVehicleType(axiosPrivate, currentVehicleType.id)
      .then(response => {
        updateParentOnDelete()
      })
      .catch(e => {
        setDeleteSubmitted(false);
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
      });
  };

    /**delete action  */

    const [dialogShow, setDialogShow] = useState(false);

    const yesBtnHandler = {
      name: 'Yes',
      //dont add deleteFuelPrice() with  open and close bracket, which will cause indefinte 
      // loop always refer function without braces
      callAction: deleteVehicleType
    };
  
    const noBtnHandler = {
      name: 'No',
    };
  
    function deleteAction() {
      setDialogShow(true);
    }
  
    /**delete action  */

  return (
    <div className="p-0">
      <div className="col-md-12 float-right">
        <div>
          {dialogShow && (
            <AlertDialog openDialog={true} dialogShowStateVar={setDialogShow} dialogTitle={"Delete Action!!!"}
              message={Constants.DELETE_CONTENT}
              noBtnHandler={noBtnHandler} yesBtnHandler={yesBtnHandler} />
          )}
          {show && (
            <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
              <Alert.Heading>{alertHeading}</Alert.Heading>
              <p>{message}</p>
            </Alert>

          )}
          <form>
            <div className="col-md-6 float-left">
              <label htmlFor="title">Type</label><span className='errorMsg'>  {vehicleTypeError.typeError}</span>
              <input
                type="text"
                className="form-control"
                id="type"
                name="type"
                value={currentVehicleType.type}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-6 float-right">
              <label htmlFor="description">Description</label><span className='errorMsg'>  {vehicleTypeError.nameError}</span>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={currentVehicleType.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
              <div className="pr-0 mr-0 float-right">  <button
                className="update-btn btn ml-0 float-right"
                variant="contained" type="button"
                onClick={updateVehicleType} disabled={updateSubmitted}
              >{updateSubmitted ? 'Updating....' : 'Update'}
              </button>
              </div>
              <div className="float-right mr-3">
                <button
                  className="delete-btn btn float-right"
                  variant="contained"
                  disabled={deleteSubmitted}
                  type="button"
                  onClick={(e) => deleteAction()}
                >
                  {deleteSubmitted ? 'Deleting...' : 'Delete'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditVehicleType;
