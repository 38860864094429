
const getAllVehicles = (axiosPrivate, page) => {
  return axiosPrivate.post("/vehicles", page);
};

const getAll = (axiosPrivate) => {
  return axiosPrivate.get("/vehicles");
};

const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/vehicle/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/vehicle", data);
};

const update = (axiosPrivate, id, data) => {
  return axiosPrivate.put(`/vehicle/${id}`, data);
};

const deleteVehicle = (axiosPrivate, id) => {
  return axiosPrivate.put(`/deleteVehicle/${id}`);
};

const removeAll = (axiosPrivate) => {
  return axiosPrivate.delete(`/vehicle`);
};

const searchByVehilceNo = (axiosPrivate, number_plate) => {
  return axiosPrivate.get(`/vehicle?number_plate=${number_plate}`);
};

export default {
  getAll,
  getAllVehicles,
  get,
  create,
  update,
  deleteVehicle,
  removeAll,
  searchByVehilceNo
};
