import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import MachineryDataService from "../../../services/admin/MachineryService";
import FuelTypeDataService from "../../../services/products/FuelTypeService";
import { Button } from "@material-ui/core";
import MachineryService from "../../../services/admin/MachineryService";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const AddNozzle = ({ selectedPump, updateParent }) => {
    const axiosPrivate = useAxiosPrivate();
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [show, setShow] = useState(false);
    const [fuelTypeSelected, setFuelTypeSelected] = useState('DEFAULT');

    const initialNozzleState = {
        id: '',
        code: '',
        fuelTypeMO: {
            id: ''
        },
        openingReading: 0,
        closingReading: 0
    }
    const [nozzle, setNozzle] = useState(initialNozzleState);
    const [fuelTypes, setFuelTypes] = useState([]);
    const handleInputChange = event => {
        const { name, value } = event.target;
        setNozzle({ ...nozzle, [name]: value });
    };

    const [nozzleError, setNozzleError] = useState({
        codeError: '',
        fuelTypeError: ''
    })

    const handleValidation = () => {
        if (nozzle.code === "") {
            setNozzleError({ ...nozzleError, "codeError": "Please enter code" });
            return false;
        }
        if (!nozzle.fuelTypeMO?.id || nozzle.fuelTypeMO?.id === 'DEFAULT') {
            setNozzleError({ ...nozzleError, "codeError": '', "fuelTypeError": "Please select fuel type" });
            return false;
        }

        setNozzleError({ ...nozzleError, "codeError": '', "fuelTypeError": '' });
        return true;
    }

    useEffect(() => {
        retrieveFuelTypes();
    }, []);



    const retrieveFuelTypes = () => {
        FuelTypeDataService.getAll(axiosPrivate)
            .then(response => {
                const fuelTypes = response.data;
                setFuelTypes(fuelTypes);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };


    const handleSelectChange = event => {
        const selectedIndex = event.target.options.selectedIndex - 1;
        var locFuelType = fuelTypes[selectedIndex];
        setNozzle({ ...nozzle, "fuelTypeMO": locFuelType });
        setFuelTypeSelected(locFuelType === undefined ? 'DEFAULT' : locFuelType.id);
    };

    const saveNozzle = () => {
        nozzle.pumpMO = selectedPump
        setShow(false);
        if (handleValidation()) {
            setSubmitted(true);
            MachineryDataService.saveNozzle(axiosPrivate, nozzle)
                .then(response => {
                    updateParent(response.data);
                    setNozzle(initialNozzleState);
                    setFuelTypeSelected('DEFAULT');
                    setSubmitted(false);
                    setShow(true);
                    setTimeout(() => {
                        setShow(false);
                    }, 3000);
                    setAlertHeading("Success!!");
                    setAlertVariant("success");
                    setMessage("Successfully added");
                }).catch(e => {
                    setShow(true);
                    setSubmitted(false);
                    setAlertHeading("Error!");
                    setAlertVariant("danger");
                    setMessage(e.response.data);
                })
        }
    }
    return (
        <div className="p-0">
            <div className="col-md-12 float-right">
                {show &&
                    <div className="col-md-12 float-left">
                        <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{message}</p>
                        </Alert></div>}
                <div className="col-md-6 float-left">
                    <label htmlFor="title">Code</label><span className='errorMsg'>  {nozzleError.codeError}</span>
                    <input
                        type="text"
                        className="form-control"
                        id="code"
                        required
                        value={nozzle.code}
                        autoComplete="off"
                        onChange={handleInputChange}
                        name="code"
                    />
                </div>
                <div className="form-group col-md-6 float-left">
                    <label htmlFor="title">Fuel Type</label><span className='errorMsg'>  {nozzleError.fuelTypeError}</span>
                    <select className="form-control" defaultValue={'DEFAULT'} value={fuelTypeSelected} onChange={handleSelectChange} name="fuel_type" id="fuel_type">
                        <option value="DEFAULT">Select Fuel Type...</option>
                        {fuelTypes.map((item, index) => (
                            <option
                                key={index}
                                value={item.id}>
                                {item.type}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-6 float-left">
                    <label htmlFor="title">Open Reading</label><span className='errorMsg'>  { }</span>
                    <input
                        type="number"
                        className="form-control"
                        id="openingReading"
                        required
                        value={nozzle.openingReading}
                        autoComplete="off"
                        onChange={handleInputChange}
                        name="openingReading"
                        defaultValue={0}
                    />
                </div>
                <div className="col-md-6 float-left">
                    <label htmlFor="title">Close Reading</label><span className='errorMsg'>  { }</span>
                    <input
                        type="number"
                        className="form-control"
                        id="closingReading"
                        required
                        value={nozzle.closingReading}
                        autoComplete="off"
                        onChange={handleInputChange}
                        name="closingReading" defaultValue={0}
                    />
                </div>
                <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
                    <button
                        color="primary"
                        variant="contained"
                        className="add-btn btn float-right"
                        onClick={(e) => saveNozzle(e)} disabled={submitted}
                    >
                        {submitted ? 'Adding...' : 'Add Nozzle'}
                    </button>
                </div>
            </div>
        </div>
    )
}
export default AddNozzle;