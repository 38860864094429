
const genCreditOutstandingReport = (axiosPrivate) => {
  return axiosPrivate.get("/creditOutstndgReport", { responseType: 'arraybuffer' });
};

const genFuelSalesReport = (axiosPrivate, month, year) => {
  return axiosPrivate.get(`/fuelSalesReport/${month}/${year}`, { responseType: 'arraybuffer' });
};

const genLubeSalesReport = (axiosPrivate, month, year) => {
  return axiosPrivate.get(`/lubeSalesReport/${month}/${year}`, { responseType: 'arraybuffer' });
};

const genCreditBill = (axiosPrivate, vhOwnerId, fromDate, toDate) => {
  return axiosPrivate.get(`/creditBillReport/${vhOwnerId}/${fromDate}/${toDate}`, { responseType: 'arraybuffer' });
};

const genCreditBillWithToDate = (axiosPrivate, vhOwnerId, toDate) => {
  return axiosPrivate.get(`/creditBillReport/${vhOwnerId}/${toDate}`, { responseType: 'arraybuffer' });
};

const generateCreditReceipt = (axiosPrivate, id) => {
  return axiosPrivate.get(`/creditReceiptReport/${id}`, { responseType: 'arraybuffer' });
};

const generateConsCreditBillReport = (axiosPrivate, vhOwnerId, fromDate, toDate) => {
  return axiosPrivate.get(`/consCreditBillReport/${vhOwnerId}/${fromDate}/${toDate}`, { responseType: 'arraybuffer' });
};

const generateConsCreditBillTillToDateReport = (axiosPrivate, vhOwnerId, toDate) => {
  return axiosPrivate.get(`/consCreditBillTillToDateReport/${vhOwnerId}/${toDate}`, { responseType: 'arraybuffer' });
};

const downloadSalesSummmary = (axiosPrivate, dailySaleDateId) => {
  return axiosPrivate.get(`/dailySalesSummary/${dailySaleDateId}`, { responseType: 'arraybuffer' });
};

const downloadDayStockSummmary = (axiosPrivate, dailySaleDate) => {
  return axiosPrivate.get(`/dayStockSummary/${dailySaleDate}`, { responseType: 'arraybuffer' });
};

export default {
  genCreditOutstandingReport,
  genFuelSalesReport,
  genLubeSalesReport,
  generateConsCreditBillReport,
  generateCreditReceipt,
  genCreditBill,
  genCreditBillWithToDate,
  generateConsCreditBillTillToDateReport,
  downloadSalesSummmary,
  downloadDayStockSummmary
};
