import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import CreditBookDataService from "../../services/credit/CreditBookService";
import FMTable from "../common/FMTable";
import { CSSTransition } from "react-transition-group";
import { StylesProvider } from "@material-ui/core";
import { Alert, Button } from "react-bootstrap";
import Loader from "../common/Loader";
import EditCreditEntry from "./EditCreditEntry";
import AddCreditEntry from "./AddCreditEntry";
import useAxiosPrivate from "../../auth/useAxiosPrivate";
import Constants from "../../Constants";

export const DayCreditBookEntryList = ({dailySaleDateId}) => {

  const [requestMO, setRequestMO] = useState({ objects: '' });
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [totalCreditValue, setTotalCreditValue] = useState();
  const [daySaleDateId, setDaySaleDateId]  = useState(dailySaleDateId);
  const selectRef = useRef(null);
  const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();
  const [pagination, setPagination] = useState({
    pageSelected: 1,
    sizePerPage: 10,
    filterMOs: []
  });
  const axiosPrivate = useAxiosPrivate();
  const [pageMO, setPageMO] = useState({
    pageableObj: [],
    totalElements: 0,
    pagination: pagination
  });
  const [creditBookEntries, setCreditBookEntries] = useState([]);

  const options = [{ value: '', label: "None" }, { value: "1", label: "Paid" },
  { value: "0", label: "Unpaid" }];

  const [isPaid, setIsPaid] = useState();


  const [isPaidSelect, setIsPaidSelect] = useState({
    name: 'React',
    data: options,
    selected: ''
  });

  const columns = [{
    dataField: 'vehicleMO.vhRegNo',
    text: 'Vehicle Number',
    sort: true,
    type: 'text',
    value: ''
  }, {
    dataField: 'fuelPriceMO.fuel_type.type',
    text: 'Fuel Type',
    sort: true,
    type: 'text',
    value: ''
  }, {
    dataField: 'dttm_of_sale',
    text: 'Date & Time of Sale',
    sort: true,
    type: 'text',
    value: ''
  }, {
    dataField: 'fuelPriceMO.price',
    text: 'Sale Price',
    sort: true,
    type: 'text',
    placeholder: ' > or < or =',
    value: ''
  }, {
    dataField: 'litre_sale_volume',
    text: 'Sale Volume',
    sort: true,
    type: 'text',
    placeholder: ' > or < or =',
    value: ''
  }, {
    dataField: 'amount_of_sale',
    text: 'Sale Amount',
    sort: true,
    type: 'text',
    placeholder: ' > or < or =',
    value: ''
  }];


  const updateParent = () => {
    //reload list when there is add new item
    setCreditBookEntries([])
    handleTableChange([], 1, pagination.sizePerPage);
  }

  const updateParentEditMode = () => {
    handleTableChange(pagination.filterMOs, pagination.pageSelected, pagination.sizePerPage);
  }

  const updateParentDeleteMode = () => {
    //reload list when there is add new item
    setCreditBookEntries([])
    handleTableChange(pagination.filterMOs, pagination.pageSelected, pagination.sizePerPage);
  }

  const onIsPaidChange = (handleFilter, e) => {
    return handleFilter(e);
  }

  const resetIsPaidMenu = () => {
    let option = options.filter((item) => item.value === '');
    console.log("clear filter value is  " + option[0].value);
    setIsPaid(option[0].value);
    // setIsPaidSelect({...isPaidSelect, ['selected']:''});
  }

  const handleTableChange = (filters, page, sizePerPage, actionType) => {
    //const currentIndex = (page - 1) * sizePerPage;
    pagination.pageSelected = page;
    pagination.sizePerPage = sizePerPage;
    pagination.filterMOs = filters;
    getTotalCreditEntryValue();
    retrieveCreditEntries(pagination, actionType);
  }

  const getTotalCreditEntryValue = () => {
    setShow(false);
    CreditBookDataService.getCreditBookValueForDailySaleDate(axiosPrivate, dailySaleDateId)
      .then(response => {
        setTotalCreditValue(response.data);
        handleClose();
      }
      ).catch(e => {
        setMessage("Error while retrieving all credit entries");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        handleClose();
      })
  }
  const expandRow = {
    renderer: row => <EditCreditEntry data={row} updateParent={updateParentEditMode} updateParentOnDelete={updateParentDeleteMode} />
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setActiveCreditBookEntry(row, rowIndex);
    }
  };

  const [showButton, setShowButton] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const nodeRef = useRef(null);

  useEffect(() => {
    setIsPaid('');
    handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
  }, [dailySaleDateId]);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const retrieveCreditEntries = (page, actionType) => {
    setShow(false);
    console.log(actionType);
    if (actionType === "pagination") {
      handleToggle()
    }
    setTableDataRetreivalMsg(Constants.LOADING_DATA)
    console.log('daily saled id is ---------' + daySaleDateId)
    var requestMap = { "pageMO": page, "dailySaleDateId": daySaleDateId };
    requestMO.objects = requestMap;
    CreditBookDataService.getAllCreditEntriesForDaySaleDate(axiosPrivate, requestMO)
      .then(response => {
        setCreditBookEntries(response.data.pageableObj);
        setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
        handleClose();
        console.log(response.data);
        setTableDataRetreivalMsg(null)
      })
      .catch(e => {
        setMessage("Error while retrieving all credit entries");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        handleClose();
        setTableDataRetreivalMsg(null)
        console.log(e);
      });
  };

  const addCreditEntryAction = () => {
    setShow(false);
    CreditBookDataService.addCreditPrerequisites(axiosPrivate)
      .then(response => {
        setShowMessage(true);
      }).catch(e => {
        setMessage(e.response.data);
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
      });
  }

  return (
    <div className="list row " style={{ width: '100%' }}>
      <div className="col-md-12">
        <div className="row"><h4>Credit Entries</h4>{showButton && (
          <h5 className="ml-3">  <Link className="addNewHeading"
            onClick={() => addCreditEntryAction()}

          >
            Add Credit Entry

          </Link></h5>
        )
        }</div>
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}

        <div className="row">

          <CSSTransition
            in={showMessage}
            nodeRef={nodeRef}
            timeout={500}
            classNames="newBox"
            unmountOnExit
            onEnter={() => setShowButton(false)}
            onExited={() => setShowButton(true)}
          >
            <div className="addNewBox"
              ref={nodeRef}
            ><div className="col-md-12 p-0">
                <div className="float-left col-md-10"><h5 style={{ marginLeft: '15px', marginTop: '5px' }}>Add Credit</h5> </div>
                <div className="col-md-2 float-right p-0">
                  <StylesProvider injectFirst>
                    <Button className="float-right addNewClose"
                      onClick={() => setShowMessage(false)}
                    >
                      X
                    </Button></StylesProvider>
                </div>
              </div>
              <div className="p-0  ml-0" style={{ height: 'auto' }}>
                <AddCreditEntry updateParent={updateParent} />
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className="row mt-3">
          <Loader openDialog={open}></Loader>
          <FMTable dataRetrievalMsg={tableDataRetreivalMsg}  columns={columns} dataList={creditBookEntries} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow} />
        </div>
      </div>
      <div className="col-md-12 p-0 mb-5 list float-right" >
        <span className="float-right"><label className="float-right" style={{ fontSize: '15px', fontWeight: 'bold' }}> Total Credit Value  :  {totalCreditValue} </label></span>
      </div>
    </div>
  );
};

export default DayCreditBookEntryList;