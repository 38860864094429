import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
//import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import App from "./App";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
const container = document.getElementById('root');
const root = createRoot(container);
import { AuthProvider } from './auth/context/AppUserContext';

root.render(
        <Router>
                <AuthProvider>
                        <Switch>
                                <Route path="/*" component={App} />
                        </Switch>
                </AuthProvider>
        </Router>
);