import React, { useEffect, useState, useRef } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import LubricantTypeDataService from "../../services/products/LubricantTypeService";
import FMTable from "../common/FMTable";
import Loader from "../common/Loader";
import AddLubricantType from "./AddLubricantType";
import { CSSTransition } from "react-transition-group";
import { StylesProvider } from "@material-ui/core";
import EditLubricantType from "./EditLubricantType";
import { FilePlusFill } from 'react-bootstrap-icons';
import Constants from "../../Constants";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const LubricantTypesList = () => {
  const axiosPrivate = useAxiosPrivate();
  const [lubricantTypes, setLubricantTypes] = useState([]);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const [showPriceUpdate, setShowPriceUpdate] = useState(false);
  const [newPriceLubricantType, setNewPriceLubricantType] = useState();
  // pagination and filter constants//
  const [pagination, setPagination] = useState({
    pageSelected: 1,
    sizePerPage: 10,
    filterMOs: []
  });
  const [pageMO, setPageMO] = useState({
    pageableObj: [],
    totalElements: 0,
    pagination: pagination
  });

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  // pagination and filter constants//

  useEffect(() => {
    retrieveLubricantTypes(pagination);
  }, []);

 

const handleNewPriceAction= (id) => {
   let lubricantType = lubricantTypes.filter(obj => obj.id === id)
   setNewPriceLubricantType(lubricantType[0]);
   setShowPriceUpdate(true);
}


  const newPriceChange = (id) => {
    return (
      <div>  <Button type="button" className="ml-3 background-transparent" style={{ border: '0' }}
        onClick={(e) => handleNewPriceAction(id)} >
        <FilePlusFill color="#303F9F" title='add new price'
          size={20} />
      </Button> </div>
    );
  };

  const columns = [{
    dataField: 'name',
    text: 'Name',
    type: 'text',
    sort: true,
  }, {
    dataField: 'type',
    text: 'Type',
    type: 'text',
    sort: true,
  }, {
    dataField: 'lubricantBrand',
    text: 'Brand',
    type: 'text',
    sort: true,
  }, {
    dataField: 'packageTypeMO.type',
    text: 'Package Type',
    type: 'text',
    sort: true,
  }, {
    dataField: 'purchasePrice',
    text: 'Purchase Price',
    type: 'text',
    sort: true,
  }, {
    dataField: 'sellingPrice',
    text: 'Selling Price',
    type: 'text',
    sort: true,
  }, {
    dataField: "id",
    type: 'text',
    text: "Add New Price",
    columnFormatter: (handleFilter) => (
      <div></div>
    ),
    rowFormatter: newPriceChange
  }];




  const rowEvents = {
    onClick: (e, row, rowIndex) => {
    }
  };

  const expandRow = {
    renderer: row => <EditLubricantType items={lubricantTypes} data={row}  priceUpdate={0} updateParent={updateParentEditMode} updateParentOnDelete={updateParentDeleteMode} />
  };

  const updateParent = () => {
    setLubricantTypes([])
    handleTableChange([], 1, pagination.sizePerPage);
  }

  const updateParentDeleteMode = () => {
    setLubricantTypes([])
    handleTableChange(pagination.filterMOs, pagination.pageSelected, pagination.sizePerPage);
  }

  const updateParentEditMode = () => {
    handleTableChange(pagination.filterMOs, pagination.pageSelected, pagination.sizePerPage);
  }

  const handleTableChange = (filters, page, sizePerPage, actionType) => {
    pagination.pageSelected = page;
    pagination.sizePerPage = sizePerPage;
    console.log(filters)
    pagination.filterMOs = filters;
    retrieveLubricantTypes(pagination, actionType);
  }
  const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();

  const retrieveLubricantTypes = (pagination, actionType) => {
    if (actionType === "pagination" || actionType == 'clearFilter') {
      handleToggle()
    }
    setTableDataRetreivalMsg(Constants.LOADING_DATA)
    LubricantTypeDataService.getAllLubricantTypes(axiosPrivate, pagination)
      .then(response => {
        setLubricantTypes(response.data.pageableObj);
        setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
        handleClose();
        setTableDataRetreivalMsg(null)
        console.log(response.data);
      })
      .catch(e => {
        handleClose();
        setTableDataRetreivalMsg(null)
        setMessage(e.response ?  e.response.data : Constants.ERROR_MSG + " lubricant types");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        console.log(e);
      });
  };

  const [showMessage, setShowMessage] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const nodeRef = useRef(null);

  return (
    <div className="list row">
      <div
        className="modal show"
        style={{ display: 'block', position: 'initial' }}
      >
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter"
          centered show={showPriceUpdate} onHide={(e) => setShowPriceUpdate(false)}>
          <Modal.Header closeButton>
            <Modal.Title className="ml-5">Add New Price</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <EditLubricantType data={newPriceLubricantType} items={lubricantTypes} priceUpdate={1} updateParent={updateParentEditMode} updateParentOnDelete={updateParentDeleteMode} />
          </Modal.Body>
        </Modal>
      </div>
      <div className="col-md-12">
        <div className="row"><h4>Lubricant Types</h4>{showButton && (
          <h5 className="ml-3">  <Link className="addNewHeading"
            onClick={() => setShowMessage(true)}
          >
            Add Lubricant Types
          </Link></h5>
        )
        }</div>
        {show && (
          <Alert className="col-md-12" variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>
        )}
        <div className="row">
          <CSSTransition
            in={showMessage}
            nodeRef={nodeRef}
            timeout={500}
            classNames="newBox"
            unmountOnExit
            onEnter={() => setShowButton(false)}
            onExited={() => setShowButton(true)}
          >
            <div className="addNewBox"
              ref={nodeRef}
            ><div className="col-md-12 p-0">
                <div className="col-md-2 float-right p-0">
                  <StylesProvider injectFirst>
                    <Button className="float-right addNewClose"
                      onClick={() => setShowMessage(false)}
                    >
                      X
                    </Button></StylesProvider>
                </div>
              </div>
              <div className="p-0  ml-0" style={{ height: 'auto' }}>
                <AddLubricantType items={lubricantTypes} updateParent={updateParent} />
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className="row mt-3">
          <Loader openDialog={open}></Loader>
          <FMTable columns={columns} dataRetrievalMsg={tableDataRetreivalMsg} dataList={lubricantTypes} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow} />
        </div>
      </div>
    </div>
  );
};
export default LubricantTypesList;
