
const getAll = (axiosPrivate) => {
  return axiosPrivate.get("/lube_stocks");
};

const getAllLubricantStocks = (axiosPrivate, page) => {
  return axiosPrivate.post("/lube_stocks", page);
};

const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/lube_stock/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/lube_stock", data);
};

const update = (axiosPrivate, id, data) => {
  return axiosPrivate.put(`/lube_stock/${id}`, data);
};

const remove = (axiosPrivate, id) => {
  return axiosPrivate.put(`/lube_stock/delete/${id}`);
};

const removeAll = (axiosPrivate) => {
  return axiosPrivate.delete(`/lube_stocks`);
};

export default {
  getAll,
  getAllLubricantStocks,
  get,
  create,
  update,
  remove,
  removeAll,
};
