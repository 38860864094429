import 'bootstrap/dist/css/bootstrap.min.css';
import { format } from 'date-fns-tz';
import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReportService from "../../services/report/ReportService";
import VehicleOwnerDataService from "../../services/vehicle/VehicleOwnerService";
import addDays from "date-fns/addDays";
import Autocomplete from '../common/Autocomplete';
import useAxiosPrivate from "../../auth/useAxiosPrivate";
import FMDatePicker from '../common/FMDatePicker';

const ConsolidatedCreditReport = props => {
    const axiosPrivate = useAxiosPrivate();
    const [vehicleOwners, setVehicleOwnerList] = useState();
    const initialVehilceOwnerState = {
        name: ""
    }
    const [vehicleOwner, setVehicleOwner] = useState(initialVehilceOwnerState);

    const initialConsCreditBillState = {
        vehicleOwnerMO: "",
        fromDate: "",
        toDate: "",
        toDateOnly: "",
        vhOwnerError: "",
        fromDateError: "",
        toDateError: "",
        toDateOnlyError: ""
    }
    const [consCreditBill, setConsCreditBill] = useState(initialConsCreditBillState);
    const [submitted, setSubmitted] = useState(false);
    const [toDateSubmitted, setToDateSubmitted] = useState(false);

    useEffect(() => {
        retrieveVehicleOwner();
    }, []);

    function renderVehicleNumber(item, value) {
        return (
            item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
    }

    const handleFromDate = date => {
        setConsCreditBill({ ...consCreditBill, 'fromDate': date, 'toDate': '' });
    }

    const handleToDate = date => {
        setConsCreditBill({ ...consCreditBill, 'toDate': date });
    }

    const handleToDateOnly = date => {
        setConsCreditBill({ ...consCreditBill, 'toDateOnly': date });
    }

    const retrieveVehicleOwner = () => {
        VehicleOwnerDataService.getAll(axiosPrivate)
            .then(response => {
                setVehicleOwnerList(response.data);
            })
            .catch(e => {
            });
    };

    const clearDropDown = () => {
        setVehicleOwner({ ...vehicleOwner, value: '' });
    }

    const handleValidation = () => {
        if (!vehicleOwner?.id) {
            setConsCreditBill({ ...consCreditBill, vhOwnerError: "please select vehicle owner from drop list" });
            return false;
        }
        if (!consCreditBill?.fromDate) {
            setConsCreditBill({ ...consCreditBill, vhOwnerError: "", fromDateError: "please select from date" });
            return false;
        }
        if (!consCreditBill?.toDate) {
            setConsCreditBill({ ...consCreditBill, vhOwnerError: "", fromDateError: "", toDateError: "please select to date" });
            return false;
        }
        if (consCreditBill?.toDate.getTime() <= consCreditBill?.fromDate.getTime()) {
            setConsCreditBill({ ...consCreditBill, vhOwnerError: "", fromDateError: "", toDateError: "please select to date greater than from date" });
            return false;
        }
        setConsCreditBill({ ...consCreditBill, vhOwnerError: "", fromDateError: "", toDateError: "" });
        return true;
    }

    const handleValidationForTillToDate = () => {
        if (!vehicleOwner?.id) {
            setConsCreditBill({ ...consCreditBill, vhOwnerError: "please select vehicle owner from drop list" });
            return false;
        }
        if (!consCreditBill?.toDateOnly) {
            setConsCreditBill({ ...consCreditBill, vhOwnerError: "", toDateOnlyError: "please select to date" });
            return false;
        }
        setConsCreditBill({ ...consCreditBill, vhOwnerError: "", toDateOnlyError: "" });
        return true;
    }

    const generateConsCreditBill = () => {
        if (handleValidation()) {
            setConsCreditBill({ ...consCreditBill, 'toDateOnly': '', vhOwnerError: "", fromDateError: "", toDateError: "", toDateOnlyError: "" });
            setSubmitted(true);
            var toDate = format(consCreditBill.toDate, "dd-MM-yyyy", { timeZone: "Asia/Kolkata" });
            var fromDate = format(consCreditBill.fromDate, "dd-MM-yyyy", { timeZone: "Asia/Kolkata" });
            var vhOwnerName = vehicleOwner.name.replaceAll(' ', '-');
            ReportService.generateConsCreditBillReport(axiosPrivate, vehicleOwner.id, fromDate, toDate)
                .then(response => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([response.data]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        vhOwnerName + `_cons_credit_bill_report.pdf`,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    setSubmitted(false);
                })
                .catch(e => {
                });
        }
    };


    const generateConsCreditBillTillToDate = () => {

        if (handleValidationForTillToDate()) {
            setConsCreditBill({ ...consCreditBill, 'fromDate': '', 'toDate': '', vhOwnerError: "", fromDateError: "", toDateError: "" });

            setToDateSubmitted(true);
            var toDate = format(consCreditBill.toDateOnly, "dd-MM-yyyy", { timeZone: "Asia/Kolkata" });
            var vhOwnerName = vehicleOwner.name.replaceAll(' ', '-');
            ReportService.generateConsCreditBillTillToDateReport(axiosPrivate, vehicleOwner.id, toDate)
                .then(response => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                        new Blob([response.data]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        vhOwnerName + `_cons_credit_bill_report.pdf`,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    setToDateSubmitted(false);
                })
                .catch(e => {
                });
        }
    };

    return (
        <div className="list">
            <div className="col-md-12 mt-4">

                <div className="container mt-3">
                    <h4>Generate Consolidated Credit Report</h4>
                    <div className="mt-3">
                        <lable>Select a Vehicle Owner</lable> <span className='errorMsg'>  {consCreditBill.vhOwnerError}</span>
                    </div>
                    <div className="col-md-6 pl-0 float-left"> <Autocomplete items={vehicleOwners}
                        searchField={'name'}
                        renderItem={(item) =>
                            <>
                                {item.name}
                            </>
                        }
                        // onChange={(event, value) => { setVehicleOwner({ ...vehicleOwner, ['name']: value }) }}
                        onSelect={(value, item) => {
                            setVehicleOwner(item);
                        }}
                        value={!!vehicleOwner && vehicleOwner.name} />
                    </div>

                    <div className="list row mt-5 col-md-12 p-0">
                        <div className="mt-3 col-md-6 pl-0 float-left">
                            <label htmlFor="Date of sale">From Date <i>(please select date on which credit entry created)</i></label><span className='errorMsg'>   {consCreditBill.fromDateError}</span>
                            <FMDatePicker
                             autoComplete="off" 
                             placeholderText="Select from date"
                             onChange={date => handleFromDate(date)}
                             date={consCreditBill.fromDate}
                             dateFormat="dd/MM/yyyy"
                             ></FMDatePicker>
                        </div>
                        <div className="mt-3 col-md-6 float-left p-0">
                            <label htmlFor="Date of sale">To Date <i>(please select date on which credit entry created)</i></label><span className='errorMsg'>  {consCreditBill.toDateError}</span>
                            <FMDatePicker
                             autoComplete="off" 
                             placeholderText="Select to date"
                             onChange={date => handleToDate(date)}
                             date={consCreditBill.toDate}
                             dateFormat="dd/MM/yyyy"
                             ></FMDatePicker>
                        </div>
                        <div className="mt-3 col-md-12 pl-0" >
                            <Button
                                variant="contained"
                                className="download-btn"
                                onClick={(e) => generateConsCreditBill(e)} disabled={submitted}
                            >
                                {submitted ? 'Downloading....' : 'Download'}
                            </Button>
                        </div>
                        <div className="mt-5"><p>-------------------------- OR --------------------------</p></div>
                    </div>

                    <div className="list row mt-3 col-md-10 p-0">

                        <div className="mt-3 col-md-6 float-left pl-0">
                            <label htmlFor="Date of sale">To Date <i>(please select date on which credit entry created)</i></label><span className='errorMsg'>  {consCreditBill.toDateOnlyError}</span>
                            <FMDatePicker
                             autoComplete="off" 
                             placeholderText="Select to date"
                             onChange={date => handleToDateOnly(date)}
                             dateFormat="dd/MM/yyyy"
                             date={consCreditBill.toDateOnly}
                             ></FMDatePicker>
                        </div>
                        <div className="col-md-12 pl-0 mt-3" >
                                <button
                                    className="download-btn btn"
                                    onClick={(e) => generateConsCreditBillTillToDate(e)} disabled={toDateSubmitted}
                                >
                                    {toDateSubmitted ? 'Downloading....' : 'Download'}
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ConsolidatedCreditReport;