import React, { useEffect, useState } from "react";
import Autocomplete from "react-autocomplete";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from "react-router-dom";
import CreditTransactionDataService from "../../services/credit/CreditTransactionService";
import VehicleOwnerDataService from "../../services/vehicle/VehicleOwnerService";
import FMTable from "../common/FMTable";
import Loader from "../common/Loader";
import useAxiosPrivate from "../../auth/useAxiosPrivate";
import Constants from "../../Constants";

const CreditTransactionList = () => {
  const [creditTransactionEntries, setCreditTransactionEntries] = useState([]);

  const [pagination, setPagination] = useState({
    pageSelected: 1,
    sizePerPage: 10,
    filterMOs: []
  });
  const [pageMO, setPageMO] = useState({
    pageableObj: [],
    totalElements: 0,
    pagination: pagination
  });
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const axiosPrivate = useAxiosPrivate();

  const [vehicleOwners, setVehicleOwnerList] = useState();
  const initialVehilceOwnerState = {
    value: "",
    vehicleOwnersLst: vehicleOwners
  }
  const [vehicleOwner, setVehicleOwner] = useState(initialVehilceOwnerState);
  const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();

  const columns = [{
    dataField: 'vhOwner.name',
    text: 'Customer Name',
    type: 'text',
    sort: true
  }, {
    dataField: 'debit',
    text: 'Debit',
    type: 'text',
    sort: true,
    placeholder: ' > or < or ='
  }, {
    dataField: 'credit',
    text: 'Credit',
    type: 'text',
    sort: true,
    placeholder: ' > or < or ='
  }, {
    dataField: 'credited_dttm',
    text: 'Credited DTTM',
    type: 'text',
    sort: true,
  }, {
    dataField: 'vhOwner.transport_name',
    text: 'Transport Name',
    type: 'text',
    sort: true,
  }];



  const expandRow = {
    renderer: row => (
      <div className="col-md-8">

        <div>
          <h4>Credit Transaction Details</h4>
          <div>
            <label>
              <strong>Owner:</strong>
            </label>{" "}
            {row.vhOwner.name}
          </div>
          <div>
            <label>
              <strong>Debit:</strong>
            </label>{" "}
            {row.debit}
          </div>
          <div>
            <label>
              <strong>Credit:</strong>
            </label>{" "}
            {row.credit}
          </div>
          <div>
            <label>
              <strong>Created Date Time:</strong>
            </label>{" "}
            {row.created_dttm}
          </div>
          {((parseFloat(row.debit) > 0 && (parseFloat(row.credit) < 0 || parseFloat(row.credit)) > 0) ? <h4><Link
            to={"/fmApp/recallCreditTransaction/" + row.id}
            className="badge badge-warning"
          >
            View / Recall
          </Link></h4> : <div></div>)}

        </div>
      </div>
    )
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setActiveVehicleOwner(row, rowIndex);
    }
  };

  useEffect(() => {
    retrieveVehicleOwner();
    retrieveCreditEntries(pagination);

  }, []);

  const retrieveVehicleOwner = () => {
    VehicleOwnerDataService.getAll(axiosPrivate)
      .then(response => {
        setVehicleOwnerList(response.data);
        console.log(response.data);
      })
      .catch(e => {
      });
  };



  const retrieveCreditEntries = (pagination, actionType) => {
    if (actionType === "pagination" || actionType == 'clearFilter') {
      handleToggle()
    }
    setTableDataRetreivalMsg(Constants.LOADING_DATA);
    CreditTransactionDataService.getAll(axiosPrivate, pagination)
      .then(response => {
        setCreditTransactionEntries(response.data.pageableObj);
        setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
        handleClose();
        setTableDataRetreivalMsg(null)
        console.log(response.data);
      })
      .catch(e => {
        setTableDataRetreivalMsg(null)
        console.log(e);
      });
  };


  const retrieveCreditEntriesByVhOwnerId = (axiosPrivate, pagination, vhOwnerId) => {
    setTableDataRetreivalMsg(Constants.LOADING_DATA)
    CreditTransactionDataService.getAllByVhOwnerId(pagination.selectedPage, pagination.sizePerPage, vhOwnerId)
      .then(response => {
        setCreditTransactionEntries(response.data.pageableObj);
        setTableDataRetreivalMsg(null)
        setPageMO(response.data);
        console.log(response.data);
      })
      .catch(e => {
        setTableDataRetreivalMsg(null)
        console.log(e);
      });
  };


  const setActiveVehicleOwner = (creditTransactionEntries, index) => {
  };

  const handleTableChange = (filters, page, sizePerPage, actionType) => {
    pagination.pageSelected = page;
    pagination.sizePerPage = sizePerPage;
    console.log(filters)
    pagination.filterMOs = filters;
    setCreditTransactionEntries([]);
    setTableDataRetreivalMsg(Constants.LOADING_DATA)
    if (vehicleOwner.value != "") {
      const vhOwnerId = vehicleOwner.vehicleOwnersLst[0].id;
      retrieveCreditEntriesByVhOwnerId(pagination, vhOwnerId);
    } else {
      retrieveCreditEntries(pagination, actionType);
    }

  }

  return (
    <div className="list row">

      <div className="col-md-12">
        <div className="row col-md-12">
          <h4>Credit Payments</h4>
          <h5><Link to={"/fmApp/addCreditTransaction"} className="m-1 badge badge-success">
            Add Payments
          </Link></h5>
        </div>

        <div className="mt-2">
          <Loader openDialog={open}></Loader>
          <FMTable columns={columns}  dataRetrievalMsg={tableDataRetreivalMsg} dataList={creditTransactionEntries} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow} />
        </div>
      </div>
    </div>
  );
};

export default CreditTransactionList;
