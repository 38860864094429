import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import RetailOutletDataService from "../../../services/admin/RetailOutletService";
import { Button } from "@material-ui/core";
import Upload from "../../common/Upload";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";
import NumberInput from "../../common/NumberInput";

const RODetails = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const fileDetailsInit = {
    status: false,
    file: "",
  };
  const [fileDetails, setFileDetails] = useState(fileDetailsInit);
  const initialRetailOutletState = {
    id: "",
    name: "",
    mobile_no: "",
    address1: "",
    address2: "",
    email: "",
    gst_no: "",
    pincode: "",
    is_deleted: "",
    logo: "",
    dealerName: "",
  };
  const [retailOutlet, setRetailOutlet] = useState(initialRetailOutletState);

  const retailOutletErr = {
    mobileError: "",
    nameError: "",
    address1Error: "",
    address2Error: "",
    gstError: "",
    pincodeError: "",
    emailError: "",
    dealerNameError: "",
    logoError: ""
  };
  const [retailOutletError, setRetailOutletError] = useState(retailOutletErr);
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setRetailOutlet({ ...retailOutlet, [name]: value });
  };

  const handleInputChangeRaw = (value, name) => {
    setRetailOutlet({ ...retailOutlet, [name]: value });
  };

  useEffect(() => {
    retreiveRetailOutlet();
  }, []);

  const [showFile, setShowFile] = useState();


  const retreiveRetailOutlet = () => {
    RetailOutletDataService.getRetailOutlet(axiosPrivate).then(response => {

      setRetailOutlet(response.data);
      setFileDetails({ ...fileDetails, ['file']: response.data?.logo });
      setShowFile(response.data?.logo);
    })
      .catch(e => {
        setSubmitted(false);
        setMessage(e.response ? e.response.data : "Error while retrieving ro details");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        console.log(e);
      });
  }

  const [errorMsg, setErrorMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const validateSelectedFile = () => {
    const MIN_FILE_SIZE = 1024 // 1MB
    const MAX_FILE_SIZE = 5120 // 5MB
    let file = fileDetails.file;


    if (!file) {
      setRetailOutletError({ ...retailOutletError, "dealerNameError": "", "mobileError": '', "nameError": '', "address1Error": '', "address2Error": "", "gstError": "", "pincodeError": "", "logoError": "Please choose a file" });
      return false
    }
    if (!file?.name?.match(/\.(jpg|jpeg|png|gif)$/)) {
      setRetailOutletError({ ...retailOutletError, "dealerNameError": "", "mobileError": '', "nameError": '', "address1Error": '', "address2Error": "", "gstError": "", "pincodeError": "", "logoError": "select valid image of ext [jpg, jpeg, png or gif]" });
      return false;
    }
    const fileSizeKiloBytes = file.size / 1024;

    // if (fileSizeKiloBytes < MIN_FILE_SIZE) {
    //   setRetailOutletError({ ...retailOutletError, "dealerNameError": "", "mobileError": '', "nameError": '', "address1Error": '', "address2Error": "", "gstError": "", "pincodeError": "", "logoError": "File size " + fileSizeKiloBytes + "KB is less than minimum limit of 1MB" });
    //   return false
    // }
    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setRetailOutletError({ ...retailOutletError, "dealerNameError": "", "mobileError": '', "nameError": '', "address1Error": '', "address2Error": "", "gstError": "", "pincodeError": "", "logoError": "File size " + fileSizeKiloBytes + "KB is greater than maximum limit of 5MB" });
      return false
    }

    setRetailOutletError({ ...retailOutletError, "dealerNameError": "", "mobileError": '', "nameError": '', "address1Error": '', "address2Error": "", "gstError": "", "pincodeError": "", "logoError": "" });

    return true;
  };

  const handleValidation = () => {
    if (!retailOutlet.name) {
      setRetailOutletError({ ...retailOutletError, "nameError": "Please enter retail outlet name" });
      return false;
    }
    if (retailOutlet.dealerName === "" || retailOutlet.dealerName === 0 || retailOutlet.dealerName === null) {
      setRetailOutletError({ ...retailOutletError, "nameError": "", "dealerNameError": "please enter dealer name" });
      return false;
    }
    if (!retailOutlet.address1) {
      setRetailOutletError({ ...retailOutletError, "dealerNameError": "", "nameError": '', "mobileError": "", "address1Error": "Please enter address1" });
      return false;
    }
    if (!retailOutlet.address2) {
      setRetailOutletError({ ...retailOutletError, "dealerNameError": "", "nameError": "", "address1Error": "", "address2Error": "Please enter address2" });
      return false;
    }
    if (!retailOutlet.pincode) {
      setRetailOutletError({ ...retailOutletError, "dealerNameError": "", "mobileError": '', "nameError": "", "address1Error": "", "address2Error": "", "gstError": "", "pincodeError": "Please enter pincode" });
      return false;
    }

    if (retailOutlet.pincode.length > 6) {
      setRetailOutletError({ ...retailOutletError, "dealerNameError": "", "mobileError": '', "nameError": "", "address1Error": "", "address2Error": "", "gstError": "", "pincodeError": "Please enter '6' character pincode" });
      return false;
    }
    if (!retailOutlet.mobile_no) {
      setRetailOutletError({ ...retailOutletError, "pincodeError": "", "nameError": "", "address1Error": "", "address2Error": "", "gstError": "", "mobileError": "Please enter mobile no" });
      return false;
    } else if (retailOutlet.mobile_no.length != 10) {
      setRetailOutletError({ ...retailOutletError, "pincodeError": "", "nameError": "", "address1Error": "", "address2Error": "", "gstError": "", "mobileError": "Invalid: please enter '10' digit mobile number" });
      return false;
    }

    if (!retailOutlet.email) {
      setRetailOutletError({ ...retailOutletError, "pincodeError": "", "mobileError": '', "nameError": "", "address1Error": "", "address2Error": "", "gstError": "", "pincodeError": "", "dealerNameError": "", "emailError": "please enter email" });
      return false;
    }
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!retailOutlet?.email.match(isValidEmail)) {
      setRetailOutletError({ ...retailOutletError, "pincodeError": "", "mobileError": '', "nameError": "", "address1Error": "", "address2Error": "", "gstError": "", "pincodeError": "", "dealerNameError": "", "emailError": "please enter valid email address" });
      return false;

    }
    if (!retailOutlet.gst_no) {
      setRetailOutletError({ ...retailOutletError, "pincodeError": "", "mobileError": '', "nameError": "", "address1Error": "", "address2Error": "", "gstError": "", "pincodeError": "", "dealerNameError": "", "emailError": "", "gstError": "please enter gst no" });
      return false;
    }
    if (fileDetails.status === true && !validateSelectedFile()) {
      return false;
    }
    setRetailOutletError({ ...retailOutletError, "pincodeError": "", "mobileError": '', "nameError": "", "address1Error": "", "address2Error": "", "gstError": "", "pincodeError": "", "dealerNameError": "", "emailError": "", "gstError": "" });
    return true;
  };




  const saveRODetails = (e) => {
    setShow(false);
    if (handleValidation()) {
      setSubmitted(true);
      var data = {
        id: retailOutlet.id,
        mobile_no: retailOutlet.mobile_no,
        name: retailOutlet.name,
        address1: retailOutlet.address1,
        address2: retailOutlet.address2,
        email: retailOutlet.email,
        pincode: retailOutlet.pincode,
        gst_no: retailOutlet.gst_no,
        dealerName: retailOutlet.dealerName,
      };

      RetailOutletDataService.create(axiosPrivate, data)
        .then(response => {
          setRetailOutlet(response.data);
          if (fileDetails.status === true) {
            saveLogo(response.data.id);
          } else {
            setSubmitted(false);
            setMessage("Details saved successfully...");
            setAlertHeading("Success");
            setAlertVariant("success");
            setShow(true);
          }

        })
        .catch(e => {
          setSubmitted(false);
          setMessage(e.response?.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setShow(true);
          console.log(e);
        });
    }

  };

  const saveLogo = (id) => {
    RetailOutletDataService.uploadLogo(axiosPrivate, fileDetails.file, id).
      then(response => {
        setSubmitted(false);
        setMessage("Details saved successfully...");
        setAlertHeading("Success");
        setAlertVariant("success");
        setShow(true);
      })
      .catch(e => {
        setSubmitted(false);
        setShow(true);
        setMessage(e?.response?.data?.message);
        setAlertHeading("Error!");
        setAlertVariant("danger");

        console.log(e);
      });
  }



  return (
    <div className="col-md-10">


      <div className="col-md-10 float-right">

        <div className="col-md-12 float-left pb-3 pt-3">
          <h4>RO Details</h4>
        </div>
        {show ? (
          <div className="col-md-12 float-left">
            <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
              <Alert.Heading>{alertHeading}</Alert.Heading>
              <p>{message}</p>
            </Alert></div>

        ) : (<div></div>)}
        <div className=" col-md-6 float-left">
          <label htmlFor="title">RO Name</label><span className='errorMsg'>  {retailOutletError.nameError}</span>
          <input
            type="text"
            className="form-control"
            id="name"
            required
            value={retailOutlet.name}
            autoComplete="off"
            onChange={(e) => handleInputChange(e)}
            name="name"
          />
        </div>
        <div className=" col-md-6 float-right">
          <label htmlFor="title">OMC Name <i>('IOCL', 'Nayara', 'Reliance' etc)</i></label>
          <span className='errorMsg'>  {retailOutletError.dealerNameError}</span>
          <input
            type="text"
            className="form-control"
            id="dealerName"
            value={retailOutlet.dealerName}
            onChange={(e) => handleInputChange(e)}
            name="dealerName"
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Address 1</label><span className='errorMsg'>  {retailOutletError.address1Error}</span>
          <input
            type="text"
            className="form-control"
            id="address1"
            required
            autoComplete="off"
            value={retailOutlet.address1}
            onChange={(e) => handleInputChange(e)}
            name="address1"
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Address 2</label><span className='errorMsg'>  {retailOutletError.address2Error}</span>
          <input
            type="text"
            className="form-control"
            id="address2"
            required
            autoComplete="off"
            value={retailOutlet.address2}
            onChange={(e) => handleInputChange(e)}
            name="address2"
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Pincode</label><span className='errorMsg'>  {retailOutletError.pincodeError}</span>
          <input
            type="text"
            className="form-control"
            id="pincode"
            required
            autoComplete="off"
            value={retailOutlet.pincode}
            onChange={(e) => handleInputChange(e)}
            name="pincode"
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Mobile No</label><span className='errorMsg'>  {retailOutletError.mobileError}</span>

          <NumberInput autoComplete="off" id="mobile_no" name="mobile_no" isDecScaleEnabled={false} decimalScale={0}
            value={retailOutlet.mobile_no} className="form-control"
            onChange={(value) => handleInputChangeRaw(value, "mobile_no")}></NumberInput>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Email id</label><span className='errorMsg'>  {retailOutletError.emailError}</span>
          <input
            type="text"
            className="form-control"
            id="email"
            required
            autoComplete="off"
            value={retailOutlet.email}
            onChange={(e) => handleInputChange(e)}
            name="email"
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">GST No</label><span className='errorMsg'>  {retailOutletError.gstError}</span>
          <input
            type="text"
            className="form-control"
            id="gst_no"
            required
            autoComplete="off"
            value={retailOutlet.gst_no}
            onChange={(e) => handleInputChange(e)}
            name="gst_no"
          />
        </div>


        <div className="col-md-12 float-left" style={{ paddingTop: '20px' }}><span className='errorMsg'>  {retailOutletError.logoError}</span>
          <Upload heading='Upload dealer logo' setFile={setFileDetails} file={showFile}></Upload>
        </div>

        <div className="col-md-12 float-left">
          <button
            color="primary"
            variant="contained"
            className="add-btn btn float-right"
            onClick={(e) => saveRODetails(e)} disabled={submitted}
          >
            {submitted ? 'Adding..' : 'Add RO Details'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RODetails;
