import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './assets/css/home/style.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ncm1 from './assets/images/new-cars-model/ncm1.png';
import ncm2 from './assets/images/new-cars-model/ncm2.png';
import ncm3 from './assets/images/new-cars-model/ncm3.png';

import c1 from './assets/images/clients/c1.jpg';
import c2 from './assets/images/clients/c2.jpg';
import c3 from './assets/images/clients/c3.jpg';

import { useState } from "react";
import GeneralService from './services/general/GeneralService';
import { useStep } from 'react-hooks-helper';
import { Alert } from 'react-bootstrap';

const Home = (props) => {

    const testiMonials = [
        {
            heading: 'Day Sales',
            description_0: ' Day sales captures pump sales, new price change, fuel discounts, lubricant sales, credit to vehicle owners and different type of payments. It provides clear view on daily sales with cash received, sales man on particular day and status of each sales...',
            description_1: ' It provides day sales history where pump owners can navigate to past sales over any period with ease and can generate report on individual day sales.',
            img: ncm1
        },
        {
            heading: 'Credit Management',
            description_0: 'Managing credit is very critical to run business smoothly, credit entries are tied to daily sales, which makes easy to maintain credit with accuracy and any credit discrepancies are identified instantly. Maintaining credit entries using physical means are prone to errors and it will affect smooth running of your business.',
            description_1: 'Provides various reports such as credit receipts, bills and consolidated credit report for auditing and sharing with customers.',
            img: ncm2
        },
        {
            heading: 'Stock Management',
            description_0: 'Stocks need to be managed on daily basis for proper maintainces of stock availability, our software provides day-day stock availability to avoid out of stock situtations and pump owners can add stocks based on its availability without visitng stock-room for identifying actual stocks.',
            description_1: 'View stocks on any particular day, track sales volumn on monthly basis, generate reports for auditing purpose.',
            img: ncm3
        },

    ]
    //Owl Carousel Settings
    const options = {
        loop: true,
        center: true,
        items: 1,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            }

        }
    };
    const [customerIdInputErr, setCustomerIdInputErr] = useState();
    const [customerIdInput, setCustomerIdInput] = useState();
    const invokeFmAppp = () => {
        if (!customerIdInput) {
            setCustomerIdInputErr("please enter customer id");
        } else {
            window.location.href = "/fmApp/customer/" + customerIdInput;
        }

    }

    const [showCustomerPanel, setShowCustomerPanel] = useState(false);

    const togglerCustomerPanel = () => {
        setShowCustomerPanel(!showCustomerPanel);
    }

    const [show, setShow] = useState(false);
    const [enquire, setEnquire] = useState({
        id: '',
        firstName: '',
        lastName: '',
        mobileNo: ''
    })

    const [enquireErr, setEnquireErr] = useState({
        firstNameErr: '',
        lastNameErr: '',
        mobileNoErr: ''
    })

    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEnquire({ ...enquire, [name]: value });
    }

    const handleValidation = () => {
        if (!enquire.firstName) {
            setEnquireErr({ ...enquireErr, "firstNameErr": "Please enter first name" });
            return false;
        }
        if (!enquire.lastName) {
            setEnquireErr({ ...enquireErr, "firstNameErr": '', "lastNameErr": "Please enter last name" });
            return false;
        }
        if (enquire.mobileNo === "") {
            setEnquireErr({ ...enquireErr, "firstNameErr": '', "lastNameErr": '', "mobileNoErr": "Please enter mobile no" });
            return false;
        } else if (enquire.mobileNo.length !== 10) {
            setEnquireErr({ ...enquireErr, "firstNameErr": '', "lastNameErr": '', 'mobileNoErr': "Invalid: please enter '10' digit mobile number" });
            return false;
        }
        setEnquireErr({ ...enquireErr, "firstNameErr": '', "lastNameErr": '', "mobileNoErr": '' });
        return true;
    }

    const createEnquire = () => {
        if (handleValidation()) {
            GeneralService.createEnquire(enquire)
                .then((response) => {
                    setShow(true);
                    setMessage("We have received your message, our team will contact you soon...");
                    setAlertHeading("Thanks!!!");
                    setAlertVariant("success");
                }).catch((e) => {

                })
        }

    }

    const clientsSayDisable = false;
    return (
        <div>
            <section id="home" className="welcome-hero">

                {/* top-area Start */}
                <div className="top-area">
                    <div className="header-area">
                        {/* Start Navigation */}
                        <nav className="navbar navbar-default bootsnav  navbar-sticky navbar-scrollspy"
                            data-minus-value-desktop="70" data-minus-value-mobile="55" data-speed="1000">

                            <div className="container" style={{position:'relative'}}>

                                {/* Start Header Navigation */}
                                <div className="navbar-header">

                                    <a className="navbar-brand" href="index.html">Fuelman<span></span></a>

                                </div>{/* /.navbar-header- */}
                                {/* End Header Navigation */}
                                <div >
                                    <ul className="nav">
                                        <li className="scroll">
                                            <a href="#home">Home</a>
                                        </li>
                                        <li className="scroll">
                                            <a href="#app-features">app features</a>
                                        </li>
                                        <li className="scroll">
                                            <a href="#pricing">pricing</a>
                                        </li>
                                        <li className="scroll">
                                            <a href="#enquiry">enquire</a>
                                        </li>
                                        <li className="scroll">
                                            <a href="#contact">contact</a>
                                        </li>
                                        <li className="scroll">
                                            <a href="#" onClick={(e) => togglerCustomerPanel()}>customer login</a>
                                        </li>
                                    </ul>
                                    {showCustomerPanel &&
                                        <div className="cutomer-login" >
                                            <div>
                                                <label id="customerIdLabel" className="customerIdLabel">Customer Id</label><span className="customerIdErr">{customerIdInputErr}</span>
                                                <input type="text" id="customerId" value={customerIdInput} onChange={(e) => setCustomerIdInput(e.target.value)} className="form-control" />
                                                <div className="customer-login-btn-box">
                                                    <button type="button" id="goCustomerBtn" onClick={(e) => invokeFmAppp()}> Go to Customer page</button>

                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </nav>
                    </div>

                    <div className="clearfix"></div>
                    <div className="welcome-hero-txt">
                        <h2>get your fuel station at your finger tips</h2>
                        <p>
                            Manage your fuel station's day to day activities at one stop; right from daily sales, credit / stock
                            management, reports, auditing and more..
                        </p>
                        <button className="welcome-btn" onClick={(e) => window.location.href = '#contact'}>contact us</button>
                    </div>
                </div>
            </section>

            <section id="app-features" className="new-cars">
                <div className="container">
                    <div className="section-header">
                        <h2>App Features</h2>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <OwlCarousel id="new-cars-carousel" className="owl-carousel owl-theme" {...options}>
                                {
                                    testiMonials.map(obj => {
                                        return <div className="item">
                                            <div className="shadow-effect">
                                                <div className="col-md-7" style={{ float: 'left' }}>
                                                    <img className="img-circle" src={obj.img} />
                                                </div>
                                                <div className="col-md-5" style={{ float: 'right', paddingTop: '0px' }}>
                                                    <div className="new-cars-txt">
                                                        <h2>{obj.heading}</h2>
                                                        <p>
                                                            {obj.description_0}
                                                        </p>
                                                        <p className="new-cars-para2">
                                                            {obj.description_1}

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })

                                }
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
            <section id="pricing" className="pricing co-sm-12" style={{height:'350px'}}>
                <div id="container" className="container col-sm-12" style={{float:'left', paddingBottom:'30px'}}>
                    <div className="section-header">
                        <h2>Pricing</h2>
                    </div>
                    <div className="mytable col-md-2" style={{float:'left'}}><span><br/></span> </div>
                    <div className="mytable col-md-8" style={{float:'left'}}>
                        <div className='borderItem'>
                            <div className='pricingConentBorderLeft pricingConentBorderTop pricingConentBorderRight pricingConentBorderBottom'><label>Free Tier</label></div>
                            <div className='pricingConentBorderTop pricingConentBorderRight pricingConentBorderBottom'><label>Gold Tier</label></div>

                        </div>
                        <div>
                            <div className="pricingConentBorderLeft pricingConentBorderRight pricingConentBorderBottom"><span>1 Month <span className='hightlight'>FREE</span> trail period</span></div>
                            <div className="pricingConentBorderRight pricingConentBorderBottom"><span>1 Year Subscription <span className='hightlight'>&#8377;4999/-</span></span></div>
                        </div>
                        <div></div>
                    </div>
                </div>
            </section>

            {/*  clients-say strat   */}
            {clientsSayDisable &&
                <section id="clients-say" className="clients-say">
                    <div className="container">
                        <div className="section-header">
                            <h2>what our clients say</h2>
                        </div>{/* /.section-header */}
                        <div className="row">
                            <OwlCarousel className="owl-carousel testimonial-carousel">
                                <div className="col-md-12 col-sm-8 col-xs-12">
                                    <div className="single-testimonial-box">
                                        <div className="testimonial-description">
                                            <div className="testimonial-info">
                                                <div className="testimonial-img">
                                                    <img src={c1} alt="image of clients person" />
                                                </div>{/* /.testimonial-img */}
                                            </div>{/* /.testimonial-info */}
                                            <div className="testimonial-comment">
                                                <p>
                                                    before fuelman, we were using note books to have our daily sales, credit, stocks
                                                    etc. after using fuelman, our daily sales are made easy and we maintain everything in one app, which is
                                                    very convinient and effective.
                                                </p>
                                            </div>{/* /.testimonial-comment */}
                                            <div className="testimonial-person">
                                                <h2><a href="#">Sri Lakshmi traders</a></h2>
                                                <h4>salem</h4>
                                            </div>{/* /.testimonial-person */}
                                        </div>{/* /.testimonial-description */}
                                    </div>{/* /.single-testimonial-box */}
                                </div>{/* /.col */}
                                <div className="col-md-12 col-sm-8 col-xs-12">
                                    <div className="single-testimonial-box">
                                        <div className="testimonial-description">
                                            <div className="testimonial-info">
                                                <div className="testimonial-img">
                                                    <img src={c2} alt="image of clients person" />
                                                </div>{/* /.testimonial-img */}
                                            </div>{/* /.testimonial-info */}
                                            <div className="testimonial-comment">
                                                <p>
                                                    app made easy of having our daily sales completed in mins and managing credit is
                                                    very easy now without worring of missing out credits
                                                </p>
                                            </div>{/* /.testimonial-comment */}
                                            <div className="testimonial-person">
                                                <h2><a href="#">ARS fuels</a></h2>
                                                <h4>Mettur</h4>
                                            </div>{/* /.testimonial-person */}
                                        </div>{/* /.testimonial-description */}
                                    </div>{/* /.single-testimonial-box */}
                                </div>{/* /.col */}
                                <div className="col-md-12 col-sm-8 col-xs-12">
                                    <div className="single-testimonial-box">
                                        <div className="testimonial-description">
                                            <div className="testimonial-info">
                                                <div className="testimonial-img">
                                                    <img src={c3} alt="image of clients person" />
                                                </div>{/* /.testimonial-img */}
                                            </div>{/* /.testimonial-info */}
                                            <div className="testimonial-comment">
                                                <p>
                                                    after using fuelman app, we could able to manage all our day-day activities with
                                                    ease in less time. Able to generate credit bills, receipts, stock availability
                                                    in one app, instead of maintaining it in seperate note books
                                                </p>
                                            </div>{/* /.testimonial-comment */}
                                            <div className="testimonial-person">
                                                <h2><a href="#">velavan petroleum agencies</a></h2>
                                                <h4>Dharapuram</h4>
                                            </div>{/* /.testimonial-person */}
                                        </div>{/* /.testimonial-description */}
                                    </div>{/* /.single-testimonial-box */}
                                </div>{/* /.col */}
                            </OwlCarousel>{/* /.testimonial-carousel */}
                        </div>{/* /.row */}
                    </div>
                </section>}


            {show ?
                <div id="container" className="container">
                    <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>{alertHeading}</Alert.Heading>
                        <p>{message}</p>
                    </Alert>
                </div>

                : <section id="enquiry" className="enquiry">
                    <div id="container" className="container">
                        <div className="section-header">
                            <h2>Enquire</h2>
                        </div>
                        <div className="enquiry-enclose col-md-8">
                            <div className="col-md-5 enquiry-box" style={{ float: 'left' }}> <span className='errorMsg'>  {enquireErr.firstNameErr}</span>
                                <input type="text" name='firstName' id="firstName" className="form-control" placeholder="firstname" onChange={(e) => handleInputChange(e)} />
                            </div>
                            <div className="col-md-5 enquiry-box" style={{ float: 'right' }}> <span className='errorMsg'>  {enquireErr.lastNameErr}</span>
                                <input type="text" name='lastName' id="lastName" className="form-control" placeholder="lastname" onChange={(e) => handleInputChange(e)} />
                            </div>
                            <div className="col-md-5 enquiry-box" style={{ float: 'left' }}><span className='errorMsg'>  {enquireErr.mobileNoErr}</span>
                                <input type="text" name='mobileNo' id="mobileNo" className="form-control" placeholder="mobile no" onChange={(e) => handleInputChange(e)} />
                            </div>
                            <div className="col-md-5 enquiry-box" style={{ float: 'right' }}>
                                <button type="button" className='add-btn btn' style={{ float: 'right' }} onClick={(e) => createEnquire()}>Submit</button>
                            </div>
                        </div>
                    </div>
                </section>

            }
            {/*contact start  */}
            <footer id="contact" className="contact">
                <div className="container">
                    <div className="footer-top">
                        <div className="row">
                            <div className="col-md-5 col-sm-6 float-left">
                                <div className="single-footer-widget">
                                    <div className="footer-logo">
                                        <a href="#contact">Fuelman</a>
                                    </div>
                                    <p>
                                        2/124 samanthapuram, villakkethi, arachalur main road, erode, tamilnadu - 638109
                                    </p>
                                    <div className="footer-contact">
                                        <p>fuelmanapp@gmail.com</p>
                                        <p>+91 95000-98854</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-5 col-xs-12 float-right">
                                <div className="single-footer-widget floatRight">

                                    <div className="row ">
                                        <div className="col-md-12 col-xs-6">
                                            <div className="single-footer-widget">
                                                <div className="footer-logo">
                                                    <label>Brands we support</label>
                                                </div>
                                                <div style={{ padding: '0px' }}>
                                                    <ul style={{ padding: '0px' }}>
                                                        <li><a href="#contact">Indian Oil</a></li>
                                                        <li><a href="#contact">Bharath petroleum</a></li>
                                                        <li><a href="#contact">Hindustan petroleum</a></li>
                                                        <li><a href="#contact">Nayara energy</a></li>
                                                        <li><a href="#contact">Reliance</a></li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright">
                        <div className="row">
                            <div className="col-sm-6">
                                <p>
                                    &copy; copyright designed and developed by maargali technologies.
                                </p>{/*/p  */}
                            </div>
                            <div className="col-sm-6">
                                <div className="footer-social">
                                    <a href="#"><i className="fa fa-facebook"></i></a>
                                    <a href="#"><i className="fa fa-instagram"></i></a>
                                    <a href="#"><i className="fa fa-linkedin"></i></a>
                                    <a href="#"><i className="fa fa-pinterest-p"></i></a>
                                    <a href="#"><i className="fa fa-behance"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>{/*/.footer-copyright  */}
                </div>{/*/.container  */}
                <div id="scroll-Top">
                    <div className="return-to-top">
                        <i className="fa fa-angle-up " id="scroll-top" data-toggle="tooltip" data-placement="top" title=""
                            data-original-title="Back to Top" aria-hidden="true"></i>
                    </div>

                </div>
            </footer >

        </div >
    )
}

export default Home;