import React, { useState } from "react";
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import { Button, StylesProvider } from "@material-ui/core";
import VehilceOwnerDataService from "../../services/vehicle/VehicleOwnerService";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const AddVehilceOwner = ({ updateParent }) => {
  const axiosPrivate = useAxiosPrivate();
  const initialVehilceOwnerState = {
    id: null,
    name: '',
    mobile_no: '',
    transport_name: ''
  };
  const [vehicleowner, setVehilceOwner] = useState(initialVehilceOwnerState);
  const initialVhOwnerError = {
    mobileError: "",
    nameError: "",
    transportNameError: ""
  };
  const [vehicleOwnerError, setVehicleOwnerError] = useState(initialVhOwnerError);
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setVehilceOwner({ ...vehicleowner, [name]: value });
  };

  const handleValidation = () => {
    if (vehicleowner.name === null || vehicleowner.name === '') {
      setVehicleOwnerError({ ...vehicleOwnerError, 'nameError': "Please enter name" });
      return false;
    }
    if (vehicleowner.mobile_no === null || vehicleowner.mobile_no === '') {
      setVehicleOwnerError({ ...vehicleOwnerError, 'nameError': '', 'mobileError': "Please enter mobile number" });
      return false;
    } else if (vehicleowner.mobile_no.length !== 10) {
      setVehicleOwnerError({ ...vehicleOwnerError, 'nameError': '', 'mobileError': "Invalid: please enter '10' digit mobile number" });
      return false;
    }
    if (vehicleowner.transport_name === null || vehicleowner.transport_name == '') {
      setVehicleOwnerError({ ...vehicleOwnerError, 'nameError': '', 'mobileError': "", 'transportNameError': "Please enter transport name" });
      return false;
    }
    setVehicleOwnerError({ ...vehicleOwnerError, 'nameError': '', 'mobileError': '', 'transportNameError': "" });
    return true;
  };

  const saveVehilceOwner = () => {
    //if submitted then avoid calling this block again
    if (handleValidation()) {
      setSubmitted(true);
      var data = {
        name: vehicleowner.name,
        mobile_no: vehicleowner.mobile_no,
        transport_name: vehicleowner.transport_name
      };

      VehilceOwnerDataService.create(axiosPrivate, data)
        .then(response => {
          updateParent()
          newVehilceOwner()
          setSubmitted(false);
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully added");
        })
        .catch(e => {
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setShow(true);
          setSubmitted(false);
          console.log(e);
        });
    }
  };

  const newVehilceOwner = () => {
    setVehilceOwner(initialVehilceOwnerState);
    setSubmitted(false);
  };

  return (
    <div className="p-0">
      <div className="col-md-12">
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <div className="col-md-6 float-left">
          <label htmlFor="title">Name</label><span className='errorMsg'>  {vehicleOwnerError.nameError}</span>
          <input autoComplete="off"
            type="text"
            className="form-control"
            id="name"
            required
            value={vehicleowner.name}
            onChange={handleInputChange}
            name="name"
          />
        </div>
        <div className=" col-md-6 float-right">
          <label htmlFor="description">Mobile Number</label><span className='errorMsg'>  {vehicleOwnerError.mobileError}</span>
          <input autoComplete="off"
            type="number"
            className="form-control"
            id="mobile_no"
            required
            value={vehicleowner.mobile_no}
            onChange={handleInputChange}
            name="mobile_no"
          />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Transport Name</label><span className='errorMsg'>  {vehicleOwnerError.transportNameError}</span>
          <input autoComplete="off"
            type="text"
            className="form-control"
            id="transport_name"
            required
            value={vehicleowner.transport_name}
            onChange={handleInputChange}
            name="transport_name"
          />
        </div>
        <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
          <button
            color="primary"
            variant="contained"
            className="add-btn btn float-right"
            onClick={(e) => saveVehilceOwner(e)} disabled={submitted}
          >
            {submitted ? 'Adding...' : 'Add Vehicle Owner'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddVehilceOwner;
