
const getAll = (axiosPrivate, pageMO) => {
  return axiosPrivate.post("/fuelPrices", pageMO);
};

const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/fuelPrice/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/fuelPrice", data);
};

const update = (axiosPrivate, id, data) => {
  return axiosPrivate.put(`/fuelPrice/${id}`, data);
};

const remove = (axiosPrivate, id) => {
  return axiosPrivate.delete(`/fuelPrice/${id}`);
};

const removeAll = (axiosPrivate) => {
  return axiosPrivate.delete(`/fuelPrice`);
};

const findByDttmOfSale = (axiosPrivate, dttm_of_sale, id)  => {
   return axiosPrivate.get(`/fuelPriceFromDttmOfSale?dttm_of_sale=${dttm_of_sale}&id=${id}`); 
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByDttmOfSale
};
