import React, { useEffect, useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import { Button, Hidden, Snackbar, StylesProvider, Table } from "@material-ui/core";
import { Link } from "react-router-dom";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import SaveIcon from "@material-ui/icons/Save"
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AlertDialog from "../AlertDialog";

// Creating styles
const useStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    table: {
        minWidth: 650,
    },


});

const ETable = (props) => {
    // Creating style object
    const classes = useStyles();

    // setactive row to show/hide clear button
    const [activeRrow, setActiveRow] = useState();
    const [showClear, setShowClear] = useState(false);
    const [deleteBtnStatus, setDeleteBtnStatus] = useState(false);
    const [deleteIds, setDeleteIds] = useState([]);
    const [deleteCount, setDeleteCount] = useState(0);
    // Initial states

    const errorObj = {
        index: '',
        message: ''
    }
    const [errorMsg, setErrorMsg] = useState([]);
    const [isEdit, setEdit] = useState(false);
    const [disable, setDisable] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);
    const [dialogShow, setDialogShow] = useState(false);


    useEffect(() => {
        const obj = [];
        for (let i = 0; i < props.data.length; i++) {
            for (let j = 0; j < props.columns.length; j++) {
                var index = "" + i + j;
                obj.push({ 'index': index, 'message': '' });

            }
        }
        setErrorMsg(obj);
    }, [props.data.length])

    // Function For adding new row object
    const handleAdd = () => {
        props.addNewRec();
        setEdit(true);
        setDisable(false);
    };

    // Function to handle edit
    const handleEdit = (i) => {
        // If edit mode is true setEdit will
        // set it to false and vice versa
        setEdit(!isEdit);
        setDisable(false);
        props.onEdit();
    };

    const handleCancel = () => {
        setEdit(!isEdit);
        setDisable(true);
        setErrorMsg((data) =>
            data.map((obj, i) => {
                obj.message = '';
                return obj;
            })
        );
        props.onCancel();
    }

    const handleDeleteCheck = (event, data) => {
        props.data.map(obj => {
            if (obj.id === data.id) {
                return event.target.checked ? data.isChecked = true : data.isChecked = false;
            }
        });
        let addDeleteObjs = (event.target.checked ? deleteIds.concat(data) : deleteIds.filter(obj => obj.id !== data.id));
        setDeleteIds(addDeleteObjs);
        setDeleteCount(addDeleteObjs.length);
    }

    const [isAllDeleted, setIsAllDeleted] = useState(false);
    const callDeletehandler = () => {
        setDialogShow(false);
        setDeleteBtnStatus(true);
        props.onDelete(deleteIds);
        resetDeleteAction();
    }
    const resetDeleteAction = () => {
        setDeleteCount(0);
        setDeleteIds([]);
        setIsAllDeleted(false);
        setDeleteBtnStatus(false);
        props.data.map(obj => {
            obj.isChecked = false;
            return obj;
        });
    }



    function handleDelete() {
        if (deleteCount > 0) {
            setDialogShow(true);
        }
    }

    const handleDeleteAllCheck = (event) => {
        let deleteAllIds = [];
        if (event.target.checked) {
            setIsAllDeleted(true);
            deleteAllIds = props.data.map(obj => {
                obj.isChecked = true;
                return obj;
            });
        } else {
            setIsAllDeleted(false);
            props.data.map(obj => {
                obj.isChecked = false;
                return obj;
            });
        }
        setDeleteIds(deleteAllIds);
        setDeleteCount(deleteAllIds.length);
    }

    // Function to handle save
    const handleSave = (data, rowIndex) => {
        var validated = true;
        let list = [...errorMsg];
        props.columns.map((column, colIndex) => {
            if (!!column?.isMandatory && !column?.validator) {
                if (!data[column.dataField]) {
                    //  let copy = [...errorMsg];
                    // copy[rowIndex][colIndex] = 'please enter value';
                    //setErrorMsg(copy);
                    let index = "" + rowIndex + colIndex;
                    list.map((obj, i) => {
                        if (obj.index === index) {
                            obj.message = 'please enter value';
                            validated = false;
                            return obj;
                        }
                        return obj;
                    })
                }
            } else if (!!column?.validator) {
                let index = "" + rowIndex + colIndex;
                list.map((obj, i) => {
                    if (obj.index === index) {
                        let validate = column.validator(data[column.dataField]);
                        validated = validated && validate.isValid;
                        obj.message = validate.erroMsg;
                        return obj;
                    }
                    return obj;
                })
            }
        })
        if (validated) {
            setErrorMsg((data) =>
                data.map((obj, i) => {
                    obj.message = '';
                    return obj;
                })
            );
            props.onSave(data, rowIndex);
        } else {
            setErrorMsg(list);
        }

    };


    // Showing delete confirmation to users
    const handleConfirm = () => {
        setShowConfirm(true);
    };

    const handleRemove = (rowIndex) => {
        console.log('actual remove   index is ' + rowIndex)
        setActiveRow(rowIndex);
        setShowConfirm(true);
    }
    // Handle the case of delete confirmation where
    // user click yes delete a specific row of id:i
    const handleRemoveClick = (i) => {
        console.log('remve index is ' + activeRrow)
        props.removeRec(activeRrow);
        setShowConfirm(false);
    };

    const enableShowClear = (index) => {
        setActiveRow(index);
        console.log('show clear  index is ' + index)
        setShowClear(true);
    }

    const hideShowClear = (index) => {
        setActiveRow(index);
        console.log('hide show clear  index is ' + index)
        setShowClear(false);
    }

    // to render input based on column type
    const typeHelper = (row, column, rowIndex, colIndex) => {

        var index = "" + rowIndex + colIndex;
        var errMsg = errorMsg.filter(obj => {
            if (obj.index === index) {
                return obj;
            }
        })
        if (column.type === 'text') {
            return <td><span style={{ width: '80%' }}><label className='errorMsg'>{errMsg[0]?.message}</label></span><input type='text' style={{ width: '80%' }} value={row[column.dataField]} onChange={(e) => column.onChange(e, rowIndex)}></input></td>
        } else if (column.type === 'select') {
            return <td >{!column?.rowFormatter ? row[column.dataField] : column.rowFormatter(row[column.dataField])}</td>;
        }

    }

    // Handle the case of delete confirmation
    // where user click no
    const handleNo = () => {
        setShowConfirm(false);
    };

    const yesBtnHandler = {
        name: 'Yes',
        callAction: callDeletehandler
    };

    const noBtnHandler = {
        name: 'No',
        callAction: () => {
            console.log('inside no btn handler')
        }
    };


    const yesBtnHandlerForRemoveItem = {
        name: 'Yes',
        callAction: handleRemoveClick
    };

    const noBtnHandlerForRemoveItem = {
        name: 'No',
        callAction: handleNo
    };

    return (
        <div className="col-md-12 p-0">
            {dialogShow && (
                <AlertDialog openDialog={true} dialogShowStateVar={setDialogShow} dialogTitle={"Delete Action!!!"}
                    message={"Delete action cant be reverted back, are you sure want to contiue?"}
                    noBtnHandler={noBtnHandler} yesBtnHandler={yesBtnHandler} />
            )}

            <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div className="col-md-12 p-0">
                        {isEdit ? (
                            <div className="floatRight col-md-12 p-0" style={{ height: 'auto' }}>

                                <h5 className="ml-3 float-right">  <Link className="addNewHeading" style={{ padding: '5px' }}
                                    onClick={(e) => handleAdd()}

                                >
                                    Add

                                </Link></h5>
                                <h5 className="ml-3 float-right">  <Link className="cancelHeading" style={{ padding: '5px' }}
                                    onClick={(e) => handleCancel()}

                                >
                                    Cancel

                                </Link></h5>
                            </div>
                        ) : (

                            <div className="floatRight col-md-12 p-0" style={{ maxHeight: 'auto' }}>
                                    <div className="float-left col-md-10 p-0" >
                                        <button
                                            className="delete-btn btn ml-0 float-left"
                                            variant="contained"
                                            type="button" onClick={handleDelete} disabled={deleteBtnStatus}
                                        >
                                            {deleteBtnStatus ? 'Deleting... [' + deleteCount + ']' : 'Delete [' + deleteCount + ']'}
                                        </button></div>
                                <div className="float-right mb-0 col-md-1 p-0 mt-2" >
                                    <h5 className="ml-2 float-right mb-0">  <Link className="addNewHeading" style={{ padding: '5px' }}
                                        onClick={(e) => handleAdd()}

                                    >
                                        Add

                                    </Link></h5></div>
                                <div className="float-right col-md-1 mb-0 p-0 mt-2">
                                    <h5 className="ml-2 float-right  mb-0" >  <Link className="editHeading" style={{ padding: '5px' }}
                                        onClick={(e) => handleEdit()}

                                    >
                                        Edit

                                    </Link></h5></div>
                            </div>
                        )}
                    </div>
                </div>

                <Table
                    className="fmanTable">
                    <thead>
                        <tr>
                            {!isEdit && (
                                <th> <input type='checkbox' checked={isAllDeleted} onClick={(e) => handleDeleteAllCheck(e)}></input></th>)}
                            {props?.columns.map(column => {
                                return <th style={{ textAlign: 'left' }}>{column.text}</th>
                            }
                            )
                            }
                            {isEdit && (
                                <th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {props?.data.map((row, rowIndex) => {
                            return (
                                <>
                                    <tr onMouseEnter={!showConfirm && ((e) => enableShowClear(rowIndex))} onMouseLeave={!showConfirm && ((e) => hideShowClear(rowIndex))}>
                                        {isEdit ? (
                                            <>
                                                {props?.columns.map((column, colIndex) => {
                                                    return typeHelper(row, column, rowIndex, colIndex)
                                                })
                                                }
                                                <td style={{ width: '10%' }}><Button onClick={(e) => handleSave(row, rowIndex)}>
                                                    <SaveIcon titleAccess="save" /></Button>
                                                </td>{(rowIndex === activeRrow && !row?.id) && <div style={{ width: '48px', height: '48px', padding: '0px' }} className={!showClear ? 'clearActionHidden' : 'clearActionVisible'} ><Button className='iconBtn' onClick={(e) => handleRemove(rowIndex)} style={{ top: '15%', right: '15%', width: '100%' }}><ClearIcon className='icon' titleAccess="remove item" style={{ color: '#FFFFFF', width: '100%' }} /></Button></div>}

                                            </>
                                        ) : (
                                            <><td style={{ textAlign: 'center' }} ><input type='checkbox' checked={row.isChecked} onClick={(e) => handleDeleteCheck(e, row)}></input></td>
                                                {props?.columns.map(column => {

                                                    return <td>
                                                        {row[column.dataField]}
                                                    </td>
                                                })
                                                }
                                            </>
                                        )}

                                    </tr>
                                </>
                            );
                        })}
                    </tbody>
                </Table>
                <div>
                    <AlertDialog openDialog={showConfirm} dialogShowStateVar={setDialogShow} dialogTitle={"Confirm remove action!!!"}
                        message={"Are you sure want to remove?"}
                        noBtnHandler={noBtnHandlerForRemoveItem} yesBtnHandler={yesBtnHandlerForRemoveItem} />

                </div>
            </>
        </div>
    );
}
export default ETable;