import 'bootstrap/dist/css/bootstrap.min.css';
import { format } from 'date-fns-tz';
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import DailyFuelPriceDataService from "../../services/dailySales/DailyFuelPriceService";
import FuelTypeDataService from "../../services/products/FuelTypeService";
import FuelStockDataService from "../../services/stock/FuelStockService";
import { Button } from "@material-ui/core";
import { StylesProvider } from "@material-ui/core/styles";
import NumberInput from '../common/NumberInput';
import useAxiosPrivate from '../../auth/useAxiosPrivate';

const AddFuelStock = ({ updateParent }) => {
  const axiosPrivate = useAxiosPrivate();
  const [addedDTTM, setAddedDTTM] = useState(new Date());
  const initialFuelStockState = {
    id: "",
    volume_added: 0,
    added_dttm: new Date(),
    fuelPrice: "",
    commission: "",
    fuelTypeMO: {
      id: "",
      type: "",
      name: ""
    },
    volumeErrror: "",
    fuelPriceError: "",
    addedDttmError: "",
    commissionError: "",
    fuelTypeError: ""
  };
  const [fuelStock, setFuelStock] = useState(initialFuelStockState);
  const [submitted, setSubmitted] = useState(false);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [fuelTypeSelected, setFuelTypeSelected] = useState('DEFAULT');
  const initialFuelPriceState = {
    id: "",
    fuel_type: {
      id: "",
      type: ""
    },
    price: ""
  }
  const [fuelPriceMO, setFuelPriceMO] = useState(initialFuelPriceState);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    retrieveFuelTypes();
  }, []);

  const handleInputChange = (value, name) => {
    setShow(false);
    setFuelStock({ ...fuelStock, [name]: value });
  };


  const handleSelectChange = event => {
    setShow(false);
    setAddedDTTM("");
    const selectedIndex = event.target.options.selectedIndex - 1;
    let locFuelType = fuelTypes[selectedIndex];
    setFuelStock({ ...fuelStock, fuelPrice: 0, volume_added: "", fuelTypeMO: locFuelType });
    setFuelTypeSelected(locFuelType === undefined ? 'DEFAULT' : locFuelType.id);
    console.log(event.target.value);
  };

  const handleDttmOfSale = date => {
    if (!!date) {
      setShow(false);
      setAddedDTTM(date);
      setFuelStock({ ...fuelStock, fuelPrice: 0, 'volumeErrror': '', 'fuelPriceError': '', 'addedDttmError': '', added_dttm: addedDTTM });
      setMessage("Fetching price value for this date time range ....");
      setAlertHeading("Info");
      setAlertVariant("info");
      setShow(true);
      var formattedDttm = new String(format(date, "dd MM yyyy H:mm:ss", { timeZone: "Asia/Kolkata" }));
      DailyFuelPriceDataService.findByDttmOfSale(axiosPrivate, formattedDttm, fuelStock.fuelTypeMO.id)
        .then(response => {
          setFuelStock({ ...fuelStock, fuelPrice: response.data.price });
          setMessage("Found price value for this date time range");
          setAlertHeading("Success");
          setAlertVariant("success");
          setShow(true);
        }).catch(e => {
          if (e.response && e.response.data) {
            setMessage(e.response.data);
            setAlertHeading("Error!");
            setAlertVariant("danger");
            setShow(true);
            console.log(e.response.data); // some reason error message
          }
          console.log(e);
        });
    }

  };


  const retrieveFuelTypes = () => {
    FuelTypeDataService.getAll(axiosPrivate)
      .then(response => {
        setFuelTypes(response.data);
        setSubmitted(false);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleValidation = () => {
    if (!fuelStock.fuelTypeMO.id || fuelStock.fuelTypeMO.id === 'DEFAULT') {
      setFuelStock({ ...fuelStock, "fuelTypeError": "Please select fuel type" });
      return false;
    }
    if (!fuelStock?.volume_added) {
      setFuelStock({ ...fuelStock, "fuelTypeError": '', 'volumeErrror': "Please enter volume added" });
      return false;
    }
    if (isNaN(fuelStock.volume_added)) {
      setFuelStock({ ...fuelStock, "fuelTypeError": '', 'volumeErrror': "Please enter valid volume in numbers" });
      return false;
    }
    if (!addedDTTM) {
      setFuelStock({ ...fuelStock, "fuelTypeError": '', 'volumeErrror': '', 'addedDttmError': "Please select added date and time" });
      return false;
    }
    if (!fuelStock?.fuelPrice) {
      setFuelStock({ ...fuelStock, "fuelTypeError": '', 'volumeErrror': '', 'addedDttmError': '', 'fuelPriceError': "Please enter fuel price" });
      return false;
    }
    if (isNaN(fuelStock.fuelPrice)) {
      setFuelStock({ ...fuelStock, "fuelTypeError": '', 'volumeErrror': '', 'addedDttmError': '', 'fuelPriceError': "Please enter valid fuel price" });
      return false;
    }
    if (!fuelStock?.commission) {
      setFuelStock({ ...fuelStock, "fuelTypeError": '', 'volumeErrror': '', 'addedDttmError': '', 'fuelPriceError': "", 'commissionError': "Please enter fuel sales commision" });
      return false;
    }
    if (isNaN(fuelStock.commission)) {
      setFuelStock({ ...fuelStock, "fuelTypeError": '', 'volumeErrror': '', 'addedDttmError': '', 'fuelPriceError': "", 'commissionError': "Please enter valid commision value" });
      return false;
    }

    setFuelStock({ ...fuelStock, "fuelTypeError": '', 'volumeErrror': '', 'fuelPriceError': '', 'addedDttmError': '', 'commissionError': '' });
    return true;
  };


  const saveFuelStock = (e) => {
    setSubmitted(true);
    //if submitted then avoid calling this block again
    if (handleValidation()) {
      fuelPriceMO.price = fuelStock.fuelPrice;
      var data = {
        id: fuelStock.id,
        volume_added: fuelStock.volume_added,
        added_dttm: format(addedDTTM, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" }),
        fuelTypeMO: fuelStock.fuelTypeMO,
        fuelPriceMO: fuelPriceMO,
        commission: fuelStock.commission
      };
      setMessage("Adding.....");
      setAlertHeading("Info");
      setAlertVariant("info");
      setShow(true);
      FuelStockDataService.create(axiosPrivate, data)
        .then(response => {
          updateParent();
          newFuelStock();
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully added");
        })
        .catch(e => {
          setMessage(e.response.data);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setShow(true);
          console.log(e);
        });
    }
    setSubmitted(false);
  };

  const newFuelStock = () => {
    setFuelStock(initialFuelStockState);
    setFuelTypeSelected('DEFAULT');
    setAddedDTTM(new Date());
    setSubmitted(false);
  };

  return (
    <div className="p-0">

      <div className="col-md-12">
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <div className="col-md-6 float-left">
          <label htmlFor="title">Fuel Type</label> <span className='errorMsg'>  {fuelStock.fuelTypeError}</span>
          <select className="form-control" defaultValue={'DEFAULT'} value={fuelTypeSelected} onChange={(e) => handleSelectChange(e)} name="fuelTypeMO" id="fuelTypeMO">
            <option value="DEFAULT">Select Fuel Type...</option>
            {fuelTypes.map((item, index) => (
              <option
                key={index}
                value={item.id}
              >
                {item.type} - {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6 float-right">
          <label htmlFor="volume_added">New Volume</label> <span className='errorMsg'>  {fuelStock.volumeErrror}</span>
          <NumberInput autoComplete="off" id="volume_added" name="volume_added" isDecScaleEnabled={true} decimalScale={2}
            value={fuelStock.volume_added} className="form-control"
            onChange={(value) => handleInputChange(value, "volume_added")}></NumberInput>
        </div>

        <div className="col-md-6 adminFormReducedPadding">
          <label htmlFor="description">Date Added</label><span className='errorMsg'>  {fuelStock.addedDttmError}</span>
          <DatePicker selected={addedDTTM} placeholderText="Select a date" timeInputLabel="Time:" name="added_dttm" id="added_dttm" className="form-control" onChange={date => handleDttmOfSale(date)} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput />
        </div>

        <div className="col-md-6 adminForm">
          <label htmlFor="volume">Fuel Price</label><span className='errorMsg'>  {fuelStock.fuelPriceError}</span>
          <NumberInput autoComplete="off" id="fuelPrice" name="fuelPrice" isDecScaleEnabled={true} decimalScale={2}
            value={fuelStock.fuelPrice} className="form-control"
            onChange={(value) => handleInputChange(value, "fuelPrice")}></NumberInput>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="commission">Commission</label><span className='errorMsg'>  {fuelStock.commissionError}</span>
          <NumberInput autoComplete="off" id="commission" name="commission" isDecScaleEnabled={true} decimalScale={2}
            value={fuelStock.commission} className="form-control"
            onChange={(value) => handleInputChange(value, "commission")}></NumberInput>
        </div>
        <div className="col-md-6 adminForm">

        </div>
        <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
          <button
            color="primary"
            variant="contained"
            className="add-btn btn float-right"
            onClick={(e) => saveFuelStock(e)} disabled={submitted}
          >
            {submitted ? 'Adding....' : 'Add Stock'}
          </button></div>
      </div>


    </div>
  );
};

export default AddFuelStock;
