
import React, { useState, useEffect } from "react";
import ETable from "../../common/editableTable/ETable";
import PaymentModeService from "../../../services/admin/PaymentModeService";
import { Alert } from "react-bootstrap";
import Model from "../../common/editableTable/Model";
import { useRef } from "react";
import Constants from "../../../Constants";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const PaymentMode = () => {
    const axiosPrivate = useAxiosPrivate();
    const [paymentModes, setPaymentModes] = useState([]);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [modelVariant, setModelVariant] = useState();
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const autoRef = useRef(null);

    const paymentMode = {
        mode: '',
        description: ''
    }

    const validate = {
        isValid: true,
        erroMsg: ''
    }

    useEffect(() => {
        getAllPaymentModes();

    }, [])

    const columns = [
        {
            dataField: 'mode',
            text: 'Payment Mode',
            sort: true,
            type: 'text',
            value: '',
            isMandatory: true,
            validator: (value) => {
                return !value ? { isValid: false, erroMsg: 'Please enter payment mode' } : { isValid: true, erroMsg: '' }
            },
            onChange: (e, index) => {
                let locPaymentModes = [];
                locPaymentModes = paymentModes.map((old, i) => {
                    if (i === index) {
                        return { ...old, ['mode']: e.target.value.toUpperCase() };
                    }
                    return old;
                });
                setPaymentModes(locPaymentModes);
            }

        }, {
            dataField: 'terminalAccId',
            text: 'Terminal/Acc No',
            sort: true,
            type: 'text',
            value: '',
            validator: (value) => {
                return !value ? { isValid: false, erroMsg: 'Please enter valid terminal or acc no' } : { isValid: true, erroMsg: '' }
            },
            onChange: (e, index) => {
                let locPaymentModes = [];
                locPaymentModes = paymentModes.map((old, i) => {
                    if (i === index) {
                        return { ...old, ['terminalAccId']: e.target.value };
                    }
                    return old;
                });
                setPaymentModes(locPaymentModes);
            }
        }, {
            dataField: 'description',
            text: 'Description',
            sort: true,
            type: 'text',
            value: '',
            validator: (value) => {
                return value?.length > 20 ? { isValid: false, erroMsg: 'Please enter character less than 20' } : { isValid: true, erroMsg: '' }
            },
            onChange: (e, index) => {
                let locPaymentModes = [];
                locPaymentModes = paymentModes.map((old, i) => {
                    if (i === index) {
                        return { ...old, ['description']: e.target.value };
                    }
                    return old;
                });
                setPaymentModes(locPaymentModes);
            }
        }
    ]

    const getAllPaymentModes = () => {
        setShow(false);

        PaymentModeService.getAllPaymentModes(axiosPrivate).
            then(response => {
                setPaymentModes(response.data)
            }).
            catch(e => {
                setShow(true);
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setMessage(e.response ?  e.response.data : Constants.ERROR_MSG + " payment mode details");
            })
    }


    const savePaymentMode = (data, index) => {
        setShow(false);
        PaymentModeService.savePaymentMode(axiosPrivate, data).
            then(response => {
                let paymentModeLst = [];
                paymentModeLst = paymentModes.map((prev, i) => {
                    if (index === i) {
                        return { ...prev, ['id']: response.data.id }
                    }
                    return prev
                })
                setPaymentModes(paymentModeLst);
                setModelVariant('success')
                setMessage('Record Saved Successfully');
                autoRef.current.setModelOpen(true);

            }).
            catch(e => {
                setShow(true);
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setMessage(e.response.data);
            })
    }

    const deletePaymentModes = (data) => {
        setShow(false);
        PaymentModeService.deletePaymentModes(axiosPrivate, data).
            then(response => {
                getAllPaymentModes();
                setModelVariant('success')
                setMessage('Record(s) Deleted Successfully');
                autoRef.current.setModelOpen(true);

            }).
            catch(e => {
                setShow(true);
                setAlertHeading("Error!");
                setAlertVariant("danger");
                setMessage(e.response.data);
            })
    }



    const [snackState, setSnackState] = useState({
        vertical: '20%',
        horizontal: '0px',
    });
    const { vertical, horizontal } = snackState;
    const style = {
        marginLeft: '30%',
    }

    return (
        <div className='col-md-8' style={{ marginLeft: '5%' }}>
            <div className="col-md-10 p-0 mt-3 float-right p-0">
                <h4>Payment Mode</h4>
                <Model style={style} message={message} ref={autoRef} hideDuration={3000} variant={modelVariant}></Model>
                {show &&
                    <div className="col-md-12 float-right p-0" >
                        <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{message}</p>
                        </Alert></div>}
            </div>
            <div className="col-md-10 p-0 mt-3 float-right">



                <ETable data={paymentModes} columns={columns}
                    addNewRec={() => {
                        setPaymentModes(paymentModes => [...paymentModes, paymentMode]);
                    }}
                    removeRec={(index) => {
                        const list = [...paymentModes];
                        list.splice(index, 1);
                        setPaymentModes(list);
                    }}
                    onSave={(data, index) => {
                        savePaymentMode(data, index);
                    }}
                    onCancel={() => {
                        autoRef.current.setModelOpen(false);
                        getAllPaymentModes();
                    }}
                    onDelete={(data) => {
                        deletePaymentModes(data);
                    }}
                    onEdit={() => {
                    }}
                ></ETable>
            </div></div >
    )
}

export default PaymentMode;