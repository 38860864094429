import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import "./assets/css/App.css";
import "./assets/css/table.css"


//admin
//ro details 
import RODetails from "./components/admin/roSettings/RODetails";
import BankDetails from "./components/admin/roSettings/BankDetails";
import SalesPersonList from './components/admin/roSettings/SalesPersonList';
import PaymentMode from './components/admin/roSettings/PaymentMode';
import Denominations from './components/admin/roSettings/Denomination';
import CashDiffLimit from './components/admin/roSettings/CashDiffLimit'
//machinery settings
import Pumplist from "./components/admin/machinery/PumpList";
import NozzleList from "./components/admin/machinery/NozzleList";
//machinery settings

//users
import AppUserList from "./components/admin/manageUsers/AppUserList";
import UserRoleList from "./components/admin/manageUsers/UserRoleList";
import ROHeader from "./components/common/ROHeader";
//users
//admin
//products
// fuel type
import AddFuelType from "./components/products/AddFuelType";
import EditFuelType from "./components/products/EditFuelType";
import FuelTypeList from "./components/products/FuelTypeList";
//lube type
import AddLubeType from "./components/products/AddLubricantType";
import LubeTypeList from "./components/products/LubricantTypeList";
import EditLubeType from "./components/products/EditLubricantType";
import PackageTypes from "./components/products/PackageTypes"
import Logout from "./components/access/Logout";
import { StylesProvider } from "@material-ui/core";
import FMLogo from "./components/common/FMLogo";
//products

function Admin() {
  const rediretToApp = () => {
    window.location.href = "/fmApp/dailySalesList";
  }

 

  return (
      <div>
        <Navbar className='navbar' variant="dark" expand="lg" sticky="top">
          <Navbar.Brand href="#home" className='p-0' style={{marginTop:'-13px'}}><FMLogo  height='30'/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <NavDropdown title="Products" id="basic-nav-dropdown">
                <NavDropdown.Item href="/admin/fuelTypeList">Fuels</NavDropdown.Item>
                <NavDropdown.Item href="/admin/lubeTypeList">Lubricants</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/admin/packageTypes">Package Types</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Machinery Settings" id="basic-nav-dropdown">
                <NavDropdown.Item href="/admin/pumps">Pumps</NavDropdown.Item>
                <NavDropdown.Item href="/admin/nozzles">Nozzles</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="RO Settings" id="basic-nav-dropdown">
                <NavDropdown.Item href="/admin/roDetails">RO Details</NavDropdown.Item>
                <NavDropdown.Item href="/admin/bankDetails">Bank Details</NavDropdown.Item>
                <NavDropdown.Item href="/admin/cashDiffLimit">Cash Diff Limit</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/admin/paymentMode">Payment Mode</NavDropdown.Item>
                <NavDropdown.Item href="/admin/denominations">Denominations</NavDropdown.Item>

              </NavDropdown>
              <NavDropdown title="Manage Users" id="basic-nav-dropdown">
                <NavDropdown.Item href="/admin/appUsers">App Users</NavDropdown.Item>
                <NavDropdown.Item href="/admin/userRoles">User Role</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="/admin/salesPerson">Sales Person</NavDropdown.Item>
              </NavDropdown>
              <div><Logout className="float-left" /></div>
            </Nav>
            <div> <StylesProvider injectFirst>  <Link className="adminLink" onClick={rediretToApp}>Go to App</Link></StylesProvider></div>
            <div> <ROHeader></ROHeader></div>
          </Navbar.Collapse>
        </Navbar>

        <div className="container mt-3">
          <Switch>
            //fueltype//
            <Route exact path={"/admin/fmApp/fuelTypeList"} component={FuelTypeList} />
            <Route exact path="/admin/addFuelType" component={AddFuelType} />
            <Route path="/admin/fuelTypeList/:id" component={EditFuelType} />
            //lube type//
            <Route exact path={"/admin/addLubricantType"} component={AddLubeType} />
            <Route exact path={"/admin/lubeType/:id/:isDisable"} component={EditLubeType} />
            <Route exact path={"/admin/lubeTypeList"} component={LubeTypeList} />
            //package types//
            <Route exact path={"/admin/packageTypes"} component={PackageTypes} />
            //ro details//
            <Route exact path={["/admin", "/admin/roDetails"]} component={RODetails} />
            <Route exact path={"/admin/bankDetails"} component={BankDetails} />
            <Route exact path={"/admin/paymentMode"} component={PaymentMode} />
            <Route exact path={"/admin/denominations"} component={Denominations} />
            <Route exact path={"/admin/cashDiffLimit"} component={CashDiffLimit} />
            <Route exact path={"/admin/fuelTypeList"} component={FuelTypeList} />
            <Route exact path={"/admin/lubeTypeList"} component={LubeTypeList} />
            <Route exact path={"/admin/pumps"} component={Pumplist} />
            <Route exact path={"/admin/nozzles"} component={NozzleList} />
            <Route exact path={"/admin/salesPerson"} component={SalesPersonList} />
            <Route exact path={"/admin/appUsers"} component={AppUserList} />
            <Route exact path={"/admin/userRoles"} component={UserRoleList} />
            //ro details//
          </Switch>
        </div>
      </div>
  );
}

export default Admin;
