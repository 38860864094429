
import React from "react";
import fmlogo from '../../assets/logo/fm-logo-wo-caption.png'

const FMLogo = (props) => {
    return (
        <div style={{top:'0px'}} className="p-0">
            <img src={fmlogo} alt="..." height={props.height} style={{marginTop: '0'}}/>
        </div>
    )
}
export default FMLogo;