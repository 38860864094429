import { format } from 'date-fns-tz';
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import LubricantTypeDataService from "../../services/products/LubricantTypeService";
import LubricantStockDataService from "../../services/stock/LubricantStockService";
import { Button } from "@material-ui/core";
import NumberInput from '../common/NumberInput';
import Autocomplete from '../common/Autocomplete';
import useAxiosPrivate from '../../auth/useAxiosPrivate';

const AddLubricantStock = ({ updateParent }) => {
  const axiosPrivate = useAxiosPrivate();
  const initialLubricantStockState = {
    id: null,
    lubricantTypeMO: "",
    unit: 0,
    quantity: 0,
    price: 0,
    invoice_no: "",
    totalQuantity: 0,
    unitError: "",
    quantityError: "",
    totQuantityError: "",
    billDttmError: "",
    invoiceNoError: "",
    lubricantTypeError: ""
  };
  const [lubricantTypes, setLubricantTypes] = useState([]);
  const initialLubricantType = {
    id: "",
    name: "",
    type: ""
  }
  const [lubricantType, setLubricantType] = useState(initialLubricantType);
  const [lubricantstock, setLubricantStock] = useState(initialLubricantStockState);
  const initialPackageType = {
    type: ""
  }
  const [packageType, setPackageType] = useState(initialPackageType);
  const [addedDTTM, setAddedDTTM] = useState(new Date());
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    retrieveLubricantTypes();
  }, []);

  const retrieveLubricantTypes = () => {
    LubricantTypeDataService.getAll(axiosPrivate)
      .then(response => {
        setLubricantTypes(response.data);
        response.data.map((item, index) => {
        })
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleInputChange = (value, name) => {
    setLubricantStock({ ...lubricantstock, [name]: value });
  };

  const handleSelectChange = event => {
    setShow(false);
    setLubricantType(lubricantTypes[event.target.value]);
    setLubricantStock({ ...lubricantstock, 'unit': 0, 'quantity': 0 });
    setPackageType(lubricantTypes[event.target.value].packageTypeMO);
    console.log(event.target.value);
  };

  useEffect(() => {
    let totalQuantity = lubricantstock.unit * lubricantstock.quantity;
    setLubricantStock({ ...lubricantstock, 'totalQuantity': totalQuantity });
  }, [lubricantstock.quantity, lubricantstock.unit]);

  const handleDttmOfSale = date => {
    setShow(false);
    setAddedDTTM(date);
  }

  const handleValidation = () => {
    if (!lubricantType.id) {
      setLubricantStock({ ...lubricantstock, 'lubricantTypeError': "Please select lubricant type" });
      return false;
    }
    if (!addedDTTM) {
      setLubricantStock({ ...lubricantstock, 'lubricantTypeError': "", 'billDttmError': "Please enter bill date" });
      return false;
    }
    if (!lubricantstock?.invoice_no) {
      setLubricantStock({ ...lubricantstock, 'lubricantTypeError': "", 'billDttmError': '', 'invoiceNoError': "Please enter invoice number" });
      return false;
    }
    if (parseInt(lubricantstock.unit) === 0 || lubricantstock.unit === '') {
      setLubricantStock({ ...lubricantstock, 'lubricantTypeError': "", 'invoiceNoError': '', 'billDttmError': '', 'unitError': "Please enter unit" });
      return false;
    }
    if (parseInt(lubricantstock.quantity) === 0 || lubricantstock.quantity === '') {
      setLubricantStock({ ...lubricantstock, 'lubricantTypeError': "", 'invoiceNoError': '', 'billDttmError': '', 'unitError': '', 'quantityError': "Please enter quantity" });
      return false;
    }
    if (parseInt(lubricantstock.totalQuantity) === 0 || !lubricantstock.totalQuantity) {
      setLubricantStock({ ...lubricantstock, 'lubricantTypeError': "", 'invoiceNoError': '', 'billDttmError': '', 'unitError': '', 'quantityError': '', 'totQuantityError': "total quantity should be greater than '0'" });
      return false;
    }
    setLubricantStock({ ...lubricantstock, 'lubricantTypeError': "", 'invoiceNoError': '', 'unitError': '', 'quantityError': '', 'billDttmError': '', 'totQuantityError' :'' });
    return true;
  };

  const saveLubricantStock = (e) => {
    setSubmitted(true);
    //if submitted then avoid calling this block again
    if (handleValidation()) {
      setShow(false);
      var data = {
        lubricantTypeMO: lubricantType,
        unit: lubricantstock.unit,
        quantity: lubricantstock.quantity,
        invoice_no: lubricantstock.invoice_no,
        totalQuantity: lubricantstock.totalQuantity,
        bill_dttm: format(addedDTTM, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" }),
      };
      LubricantStockDataService.create(axiosPrivate, data)
        .then(response => {
          updateParent();
          newLubricantStock();
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully added");
        })
        .catch(e => {
          setMessage(e.response);
          setAlertHeading("Error!");
          setAlertVariant("danger");
          setShow(true);
          console.log(e);
        });
    }
    setSubmitted(false);
  };

  const newLubricantStock = () => {
    setLubricantStock(initialLubricantStockState);
    setLubricantType(initialLubricantType)
    setAddedDTTM(new Date());
    setSubmitted(false);
  };

  return (
    <div className="p-0">
      <div className='col-md-12'>

        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <div className="col-md-6 float-left">
          <label htmlFor="title">Lube Type</label><span className='errorMsg'>  {lubricantstock.lubricantTypeError}</span>

          <Autocomplete items={lubricantTypes}
            searchField={'name'}
            renderItem={(item) =>
              <>
                {item.type} - {item.name}
              </>

            }
            onChange={(event, value) => { setLubricantType({ ...lubricantType, ['type']: value }) }}
            onSelect={(value, item) => {
              setLubricantType([])
              setLubricantType(item ? item : undefined)
              setPackageType(item ? item.packageTypeMO : undefined)
            }}
            value={(lubricantType?.type)} />
        </div>
        <div className="col-md-6 float-right">
          <label htmlFor="description">Bill Date</label><span className='errorMsg'>  {lubricantstock.billDttmError}</span>
          <DatePicker selected={addedDTTM} placeholderText="Select a date" timeInputLabel="Time:" name="bill_dttm" id="bill_dttm" className="form-control" onChange={date => handleDttmOfSale(date)} dateFormat="dd/MM/yyyy h:mm aa" showTimeInput />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="invoice_no">Invoice no</label><span className='errorMsg'>  {lubricantstock.invoiceNoError}</span>
          <input autoComplete="off"
            type="text"
            className="form-control"
            id="invoice_no"
            required
            value={lubricantstock.invoice_no}
            onChange={(e) => handleInputChange(e.target.value, 'invoice_no')}
            name="invoice_no"
          />
        </div>

        <div className="col-md-6 adminForm">
          <label htmlFor="title">Unit <i>{packageType?.type ? " in " + packageType.type : ""}  </i></label>
          <span className='errorMsg'>  {lubricantstock.unitError}</span>
          <NumberInput autoComplete="off" id="unit" name="unit" isDecScaleEnabled={true} decimalScale={2}
            value={lubricantstock.unit} className="form-control"
            onChange={(value) => handleInputChange(value, "unit")}></NumberInput>

        </div>

        <div className="col-md-6 adminForm">
          <label htmlFor="description">Quantity <i>{packageType?.unit ? " in " + packageType.unit+"(s)/"+packageType.type : ""}  </i></label>
          <span className='errorMsg'>  {lubricantstock.quantityError}</span>
          <NumberInput autoComplete="off" id="quantity" name="quantity" isDecScaleEnabled={true} decimalScale={2}
            value={lubricantstock.quantity} className="form-control"
            onChange={(value) => handleInputChange(value, "quantity")}></NumberInput>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Total Quantity <i>{packageType?.unit ? " in " + packageType.unit : ""}  </i></label>
          <span className='errorMsg'>  {lubricantstock.totQuantityError}</span>
          <NumberInput autoComplete="off" id="totalQuantity" name="totalQuantity" isDecScaleEnabled={true} decimalScale={2}
            value={lubricantstock.totalQuantity} className="form-control"
            onChange={(value) => handleInputChange(value, "totalQuantity")}></NumberInput>
        </div>
        <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
          <button
            color="primary"
            variant="contained"
            className="add-btn btn float-right"
            type="button"
            onClick={(e) => saveLubricantStock(e)} disabled={submitted}
          >
            {submitted ? 'Adding....' : 'Add Stock'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddLubricantStock;
