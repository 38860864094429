

const getAll = (axiosPrivate) => {
  return axiosPrivate.get("/collectionMode");
};


const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/collectionMode/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/saveCollections", data);
};


export default {
  get,
  getAll,
  create
};
