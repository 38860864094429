import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import LubricantBrandDataService from "../../services/products/LubricantBrandService";
import PackageTypeDataService from "../../services/products/PackageTypeService";
import LubricantTypeDataService from "../../services/products/LubricantTypeService";
import { StylesProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import NumberInput from "../common/NumberInput";
import Autocomplete from "../common/Autocomplete";
import { Modal } from "react-bootstrap";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const EditLubricantType = ({ items, data, updateParent, updateParentOnDelete, priceUpdate }) => {
  const axiosPrivate = useAxiosPrivate();
  const initialLubricantTypeState = {
    id: null,
    name: "",
    type: "",
    is_deleted: "",
    lubricantBrand: "",
    packageTypeMO: "",
    nameError: "",
    typeError: "",
    brandError: "",
    packageTypeError: "",
    purchasePriceError: "",
    sellingPriceError: ""
  };
  const [currentLubricantType, setCurrentLubricantType] = useState(initialLubricantTypeState);
  const [lubricantTypes, setLubricantTypes] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const initialLubeBrand = {
    id: "",
    name: ""
  }
  const [lubricantBrand, setLubricantBrand] = useState(initialLubeBrand);
  const [packageTypes, setPackageTypes] = useState([]);
  const initialPackageType = {
    id: "",
    type: "",
    unit: ""
  }
  const [packageType, setPackageType] = useState(initialPackageType);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);

  const [isPriceUpdate, setIsPriceUpdate] = useState(0)
  const [submitted, setSubmitted] = useState(false);
  const [updateSubmitted, setUpdateSubmitted] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  useEffect(() => {
    setCurrentLubricantType(data);
    setPackageType(data.packageTypeMO);
    setLubricantTypes(items)
    setIsPriceUpdate(priceUpdate)
    if (priceUpdate === 0) {
      retrievePackageTypes();
    }
  }, []);

  const retrievePackageTypes = () => {
    PackageTypeDataService.getAll(axiosPrivate)
      .then(response => {
        setPackageTypes(response.data);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setCurrentLubricantType({ ...currentLubricantType, [name]: value });
  };

  const handleInputChangeWithNameValue = (name, value) => {
    setCurrentLubricantType({ ...currentLubricantType, [name]: value });
  };

  const handleValidation = () => {
    if (!currentLubricantType?.name) {
      setCurrentLubricantType({ ...currentLubricantType, 'nameError': "Please enter name" });
      return false;
    }
    if (!currentLubricantType?.type) {
      setCurrentLubricantType({ ...currentLubricantType, 'nameError': '', 'typeError': "Please enter lube type" });
      return false;
    }
    if (!currentLubricantType?.lubricantBrand) {
      setCurrentLubricantType({ ...currentLubricantType, 'nameError': '', 'typeError': '', 'brandError': "Please select/enter lube brand" });
      return false;
    }
    if (!packageType?.id) {
      setCurrentLubricantType({ ...currentLubricantType, 'nameError': '', 'typeError': '', 'brandError': '', 'packageTypeError': "Please select package type" });
      return false;
    }
    if (!currentLubricantType?.purchasePrice) {
      setCurrentLubricantType({ ...currentLubricantType, 'nameError': '', 'typeError': '', 'brandError': '', 'packageTypeError': "", 'purchasePriceError': "Please enter purchase price" });
      return false;
    }
    if (!currentLubricantType?.sellingPrice) {
      setCurrentLubricantType({ ...currentLubricantType, 'nameError': '', 'typeError': '', 'brandError': '', 'packageTypeError': "", 'purchasePriceError': "", 'sellingPriceError': "Please enter selling price" });
      return false;
    }
    setCurrentLubricantType({ ...currentLubricantType, 'nameError': '', 'typeError': '', 'brandError': '', 'packageTypeError': '', 'purchasePriceError': "", 'sellingPriceError': "" });
    return true;
  };


  const priceValidation = () => {
    if (currentLubricantType.purchasePrice === "" || currentLubricantType.purchasePrice === 'undefined') {
      setCurrentLubricantType({ ...currentLubricantType, 'purchasePriceError': "Please enter purchase price" });
      return false;
    }
    if (currentLubricantType.sellingPrice === "" || currentLubricantType.sellingPrice === 'undefined') {
      setCurrentLubricantType({ ...currentLubricantType, 'purchasePriceError': "", 'sellingPriceError': "Please enter selling price" });
      return false;
    }
    setCurrentLubricantType({ ...currentLubricantType, 'purchasePriceError': "", 'sellingPriceError': "" });
    return true;
  };

  const updateLubricantType = () => {
    if (handleValidation()) {
      setUpdateSubmitted(true)
      setShow(false);
      currentLubricantType.packageTypeMO = packageType;
      LubricantTypeDataService.update(axiosPrivate, currentLubricantType.id, currentLubricantType)
        .then(response => {
          console.log(response.data);
          setShow(true);
          setUpdateSubmitted(false)
          updateParent();
          setAlertHeading("Success");
          setAlertVariant("success");
          setMessage("Lubricant type updated successfully!");
        })
        .catch(e => {
          setShow(true);
          setUpdateSubmitted(false)
          setAlertHeading("Error !!");
          setAlertVariant("danger");
          setMessage("Error while updating lubricant type");
          console.log(e);
        });
    }
  };


  const updateLubricantPrice = () => {
    if (priceValidation()) {
      setSubmitted(true);
      setShow(false);
      LubricantTypeDataService.updatePrice(axiosPrivate, currentLubricantType.id, currentLubricantType)
        .then(response => {
          setSubmitted(false);
          setCurrentLubricantType(response.data);
          updateParent();
          setAlertHeading("Success");
          setAlertVariant("success");
          setMessage("New price updated successfully!");
          setShow(true);
        })
        .catch(e => {
          setShow(true);
          setSubmitted(false);
          setAlertHeading("Error !!");
          setAlertVariant("danger");
          setMessage(e.response.data);
          console.log(e);
        });
    }
  };
  const deleteLubricantType = () => {
    setDeleteSubmitted(true);
    LubricantTypeDataService.remove(axiosPrivate, currentLubricantType.id)
      .then(response => {
        setDeleteSubmitted(false);
        updateParentOnDelete()
      })
      .catch(e => {
        setDeleteSubmitted(false);
        setShow(true);
        setAlertHeading("Error !!");
        setAlertVariant("danger");
        setMessage(e.response.data);
      });
  };

  return (
    <div className="p-0">
      <div className="col-md-12 float-right">
        {show &&
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        }
        {isPriceUpdate === 0 && <>
          <div className="col-md-6 float-left">
            <label htmlFor="description">Name</label> <span className='errorMsg'>  {currentLubricantType.nameError}</span>
            <input disabled={isPriceUpdate === 1 ? true : false}
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={currentLubricantType.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="col-md-6 float-left">
            <label htmlFor="title">Type</label><span className='errorMsg'>  {currentLubricantType.typeError}</span>
            <input disabled={isPriceUpdate === 1 ? true : false}
              type="text"
              className="form-control"
              id="type"
              name="type"
              value={currentLubricantType.type}
              onChange={handleInputChange}
            />
          </div>

          <div className="col-md-6 adminForm">
            <label htmlFor="title">Brand</label><span className='errorMsg'>  {currentLubricantType.brandError}</span>
            <Autocomplete items={lubricantTypes}
              searchField={'lubricantBrand'}
              renderItem={(item) =>
                <>
                  {item.lubricantBrand}
                </>
              }
              onChange={(event, value) => { setCurrentLubricantType({ ...currentLubricantType, ['lubricantBrand']: value }) }}
              onSelect={(value, item) => {
                setCurrentLubricantType({ ...currentLubricantType, ['lubricantBrand']: item ? item.lubricantBrand : '' })
              }}
              value={currentLubricantType.lubricantBrand} />
          </div>
          <div className="col-md-6 adminForm">
            <label htmlFor="title">Package Type</label><span className='errorMsg'>  {currentLubricantType.packageTypeError}</span>
            <Autocomplete items={packageTypes}
              searchField={'type'}
              renderItem={(item) =>
                <>
                  {item.type}
                </>
              }
              onChange={(event, value) => { setPackageType({ ...packageType, ['type']: value }) }}
              onSelect={(value, item) => {
                setPackageType(item ? item : '')
              }}
              value={packageType.type} />
          </div> </>}
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Purchase Price <i>{packageType.unit ? 'per' : ''} {packageType.unit}</i></label>
          <span className='errorMsg'>  {currentLubricantType.purchasePriceError}</span>
          <NumberInput className="form-control" autoComplete="off" id="purchasePrice" name="purchasePrice" isDecScaleEnabled={true}
            decimalScale={2} value={currentLubricantType.purchasePrice}
            onChange={(value) => {
              handleInputChangeWithNameValue("purchasePrice", value);
            }}></NumberInput>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="description">Selling Price <i>{packageType.unit ? 'per' : ''} {packageType.unit}</i></label>
          <span className='errorMsg'>  {currentLubricantType.sellingPriceError}</span>
          <NumberInput className="form-control" autoComplete="off" id="sellingPrice" name="sellingPrice" isDecScaleEnabled={true}
            decimalScale={2} value={currentLubricantType.sellingPrice}
            onChange={(value) => {
              handleInputChangeWithNameValue("sellingPrice", value);
            }}></NumberInput>
        </div>
          {parseInt(isPriceUpdate) === 0 ? (<div className="col-md-12 float-left adminForm"> <div className="pr-0 mr-0 float-right">  <button
            className="update-btn btn ml-0 float-right"
            variant="contained" type="button"
            onClick={updateLubricantType} disabled={updateSubmitted}
          >{updateSubmitted ? 'Updating....' : 'Update'}
          </button>
          </div><div className="float-right mr-3" >
              <button
                variant="contained"
                className="delete-btn btn float-right"
                disabled={deleteSubmitted}
                onClick={deleteLubricantType}
              >
                {deleteSubmitted ? 'Deleting...' : 'Delete'}
              </button>
            </div>
          </div>) : (<div className="col-md-12 float-left adminForm"><div className="pr-0 col-md-6 mr-0 float-right">  <button
            className="add-btn btn ml-0 float-right"
            variant="contained" type="button"
            onClick={(e) => updateLubricantPrice()} disabled={submitted}
          >{submitted ? 'Adding....' : 'Add New Price'}
          </button>
          </div></div>)}
      </div>
    </div>
  );
};

export default EditLubricantType;
