import * as React from 'react';
import { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { StylesProvider } from '@material-ui/core';

export default function AlertDialog({ openDialog, dialogShowStateVar, dialogTitle, message, yesBtnHandler, noBtnHandler }) {

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    dialogShowStateVar(false);
    if (!!noBtnHandler?.callAction) {
      return noBtnHandler.callAction()
    }
  };

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleOk = () => {
    setOpen(false);
    if (!!yesBtnHandler?.callAction) {
      return yesBtnHandler.callAction()
    }
  };



  const [buttonFocus, setButtonFocus] = useState({ backgroundColor: 'grey' });
  const setOnFocus = () => {
    setButtonFocus({ backgroundColor: 'yellow' });
  }

  return (
    <div>
      <StylesProvider injectFirst>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ color: 'black' }} id="alert-dialog-title">
            {dialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText className='errorMsg warn-content' id="alert-dialog-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button  onFocus={setOnFocus} className='alertYes alertDialogBtn'
              onClick={handleOk}>{yesBtnHandler?.name}</button>
            <button  className='alertNo alertDialogBtn' onClick={handleClose}>
              {noBtnHandler?.name}
            </button>
          </DialogActions>
        </Dialog>
      </StylesProvider>
    </div>
  );
}
