import React, { useEffect, useState, useRef } from "react";
import { Alert, Button } from "react-bootstrap";
import FuelPriceDataService from "../../services/dailySales/DailyFuelPriceService";
import FMTable from "../common/FMTable";
import Constants from "../../Constants";
import { CSSTransition } from "react-transition-group";
import EditDailyFuelPrice from "./EditDailyFuelPrice";
import { Link } from "react-router-dom";
import AddDailyFuelPrice from "./AddDailyFuelPrice";
import { StylesProvider } from "@material-ui/core";
import Loader from "../common/Loader";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const FuelPriceList = () => {
  const [loading, setLoading] = useState(true);
  const [fuelPrices, setFuelPrices] = useState([]);
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const [pagination, setPagination] = useState({
    pageSelected: 1,
    sizePerPage: 10,
    filterMOs: []
  });
  const [pageMO, setPageMO] = useState({
    pageableObj: [],
    totalElements: 0,
    pagination: pagination
  });

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const columns = [{
    dataField: 'fuel_type.type',
    text: 'Fuel Type',
    type: 'text',
    sort: true
  }, {
    dataField: 'price',
    text: 'Price ',
    type: 'text',
    sort: true,
    placeholder: ' > or < or ='

  }, {
    dataField: 'date_of_sale',
    text: 'Date of Sale',
    type: 'text',
    sort: true,
    headerStyle: (column, colIndex) => {
      return { width: '40%' };
    }
  }];



  const updateParent = () => {
    setFuelPrices([])
    handleTableChange([], 1, pagination.sizePerPage);
  }

  const updateParentDeleteMode = () => {
    setFuelPrices([])
    handleTableChange(pagination.filterMOs, pagination.pageSelected, pagination.sizePerPage);
  }

  const updateParentEditMode = () => {
    handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
  }

  const expandRow = {
    renderer: row => <EditDailyFuelPrice data={row} updateParent={updateParentEditMode}  updateParentOnDelete={updateParentDeleteMode}/>
  }

  useEffect(() => {
    handleTableChange([], pagination.pageSelected, pagination.sizePerPage);
  },[]);


  const handleTableChange = (filters, page, sizePerPage, actionType) => {
    pagination.pageSelected = page;
    pagination.sizePerPage = sizePerPage;
    console.log(filters)
    pagination.filterMOs = filters;
    retrieveFuelPrices(pagination, actionType);
  }

  const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();

  const retrieveFuelPrices = (pagination, actionType) => {
    setShow(false);
    if (actionType === "pagination" || actionType == 'clearFilter') {
      handleToggle()
    }
    setTableDataRetreivalMsg(Constants.LOADING_DATA);
    FuelPriceDataService.getAll(axiosPrivate, pagination)
      .then(response => {
        setFuelPrices(response.data.pageableObj);
        setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
        setLoading(false);
        handleClose();
        setTableDataRetreivalMsg(null);
      })
      .catch(e => {
        setMessage(e.response ? e.response.data : Constants.ERROR_MSG + " fuel price details");
        setAlertHeading("Error!");
        setAlertVariant("danger");
        setShow(true);
        setTableDataRetreivalMsg(null);
        console.log(e);
      });
  };


  const [showButton, setShowButton] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const nodeRef = useRef(null);

  return (
    <div className="list row">
      <div className="col-md-12">
        <div className="row"><h4>Fuel Price List</h4>{showButton && (
          <h5 className="ml-3">  <Link className="addNewHeading"
            onClick={() => setShowMessage(true)}

          >
            Add Fuel Price

          </Link></h5>
        )
        }</div>
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}

        <div className="row">

          <CSSTransition
            in={showMessage}
            nodeRef={nodeRef}
            timeout={500}
            classNames="newBox"
            unmountOnExit
            onEnter={() => setShowButton(false)}
            onExited={() => setShowButton(true)}
          >
            <div className="addNewBox"
              ref={nodeRef}
            ><div className="col-md-12 p-0">
                <div className="float-left col-md-10"><h5 style={{ marginLeft: '15px', marginTop: '5px' }}>Add Fuel Price</h5> </div>
                <div className="col-md-2 float-right p-0">
                  <StylesProvider injectFirst>
                    <Button className="float-right addNewClose"
                      onClick={() => setShowMessage(false)}
                    >
                      X
                    </Button></StylesProvider>
                </div>
              </div>
              <div className="p-0  ml-0" style={{ height: 'auto' }}>
                <AddDailyFuelPrice updateParent={updateParent} />
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className="row mt-3">
          <Loader openDialog={open}></Loader>
          <FMTable columns={columns} dataRetrievalMsg={tableDataRetreivalMsg}  dataList={fuelPrices} pageMO={pageMO} handlePagination={handleTableChange} expandRow={expandRow} />
        </div>
      </div>
    </div>
  );
};

export default FuelPriceList;
