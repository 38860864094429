import { Table } from "@material-ui/core";
import { Button } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import DailyLubeSalesDataService from '../../../services/dailySales/DailyLubeSalesService';
import NumberInput from "../../common/NumberInput";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

export const LubeSales = props => {
  const [dateOfSaleId, setDateOfSaleId] = useState();
  const [dailyLubricantSalesMOs, setDailyLubricantSalesMOs] = useState([]);
  const [totalLubeSaleAmount, setTotalLubeSaleAmount] = useState(0);
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (props.location?.state?.id) {
      setDateOfSaleId(props.location.state.id);
      retrieveLubeComponent(props.location.state.id);
    }
  }, [props.location?.state?.id]);

  function isArrayObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const retrieveLubeComponent = (id) => {
    DailyLubeSalesDataService.get(axiosPrivate, id)
      .then(response => {
        setDailyLubricantSalesMOs(response.data.dailyLubricantSalesMOs);
        setTotalLubeSaleAmount(response.data.totalSaleAmount);
      })
      .catch(e => {
        setMessage(e.response.data);
        console.log(e);
      });
  }

  const onChange = (value, row, rowIndex, columnId) => {
    setDailyLubricantSalesMOs(old =>
      old.map((dailyLubeSalesMO, index) => {
        if (index === rowIndex && columnId === 'shiftOpeningStock') {
          dailyLubeSalesMO.shiftOpeningStock = parseFloat(value);
          dailyLubeSalesMO.saleVolume = parseFloat(dailyLubeSalesMO.shiftOpeningStock) - parseFloat(dailyLubeSalesMO.shiftClosingStock);
          dailyLubeSalesMO.saleAmount = parseFloat(dailyLubeSalesMO.saleVolume) * dailyLubeSalesMO.lubricantTypeMO.sellingPrice;
        } if (index === rowIndex && columnId === 'shiftClosingStock') {
          dailyLubeSalesMO.shiftClosingStock = parseFloat(value);
          dailyLubeSalesMO.saleVolume = parseFloat(dailyLubeSalesMO.shiftOpeningStock) - parseFloat(dailyLubeSalesMO.shiftClosingStock);
          dailyLubeSalesMO.saleAmount = parseFloat(dailyLubeSalesMO.saleVolume) * dailyLubeSalesMO.lubricantTypeMO.sellingPrice;
        }
        var totalLubeSaleAmt = 0;
        dailyLubricantSalesMOs.forEach((dailyLubeSalesMO, index) => {
          totalLubeSaleAmt = parseFloat(totalLubeSaleAmt) + parseFloat(dailyLubeSalesMO.saleAmount);
          setTotalLubeSaleAmount(totalLubeSaleAmt);
        });
        return dailyLubeSalesMO
      }))
  };

  const invokePrevious = () => {
    //navigation.next()
    props.history.push({ pathname: "/fmApp/dailyFuelDiscount", state: { id: dateOfSaleId } });
  }

  const handleValidation = () => {
    var isValid = true;
    dailyLubricantSalesMOs.forEach((dailyLubeSaleMO, index) => {
      if (dailyLubeSaleMO.saleVolume > dailyLubeSaleMO.openingStock) {
        setMessage("Total Sale volume for " + dailyLubeSaleMO.lubricantTypeMO.type + " should be less than or equal to available stock " + dailyLubeSaleMO.openingStock + " " + dailyLubeSaleMO.lubricantTypeMO.packageTypeMO.unit + "(s)");
        isValid = false;
        return false;
      } else if (dailyLubeSaleMO.saleVolume < 0) {
        setMessage("Total Sale volume for " + dailyLubeSaleMO.lubricantTypeMO.type + " can't be less than 0 sale. Negative sale " + dailyLubeSaleMO.saleVolume + " is not allowed");
        isValid = false;
        return false;
      } else if (index === 0) {
        isValid = true;
        return true;
      }
    })
    return isValid;
  }

  const saveAndProceed = () => {
    if (handleValidation()) {
      if (submitted) {
        return
      }
      setSubmitted(true);
      var dailyLubeCompMO = {
        dailyLubricantSalesMOs: dailyLubricantSalesMOs,
        dailySaleDateMO: {
          id: dateOfSaleId,
        }
      };
      DailyLubeSalesDataService.create(axiosPrivate, dailyLubeCompMO)
        .then(response => {
          //  console.log(response.data);
          props.history.push({ pathname: "/fmApp/dailyCreditSummary", state: { id: dateOfSaleId } });

        })
        .catch(e => {
          setMessage(e.response.data);
          console.log(e);
        });
    }
  }


  return (
    <div className="sales-form list row">
      <div className="col-md-12">
        <h3>Lubricant Sales</h3>
        <div><span className='errorMsg'>  {message}</span></div>
        <div>
          <Table className="fmanTable">
            {(!isArrayObjectEmpty(dailyLubricantSalesMOs)) ? (
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Lube Type</th>
                  <th>Selling Price</th>
                  <th>Available Stock</th>
                  <th>Shift Open Stock</th>
                  <th>Shift Close Stock</th>
                  <th>Sale Volume</th>
                  <th>Sale Amount</th>
                </tr>
              </thead>)
              : (<div></div>)}
            <tbody>
              {dailyLubricantSalesMOs.map((row, index) => {
                return <tr>
                  <td>{index + 1}</td>
                  <td>{row.lubricantTypeMO.name}</td>
                  <td>{parseFloat(row.lubricantTypeMO.sellingPrice)}</td>
                  <td>{row.openingStock} <i> {row.lubricantTypeMO.packageTypeMO.unit + 's'}</i></td>
                  <td>
                    <NumberInput autoComplete="off" style={{ textAlign: 'right' }} id="shiftOpeningStock" name="shiftOpeningStock" isDecScaleEnabled={true} decimalScale={2} value={!row.shiftOpeningStock ? 0 : row.shiftOpeningStock}
                      onChange={(value) => onChange(value, row, index, 'shiftOpeningStock')}></NumberInput>
                  </td>
                  <td>
                    <NumberInput autoComplete="off" style={{ textAlign: 'right' }} id="shiftClosingStock" name="shiftClosingStock" isDecScaleEnabled={true} decimalScale={2} value={!row.shiftClosingStock ? 0 : row.shiftClosingStock}
                      onChange={(value) => onChange(value, row, index, 'shiftClosingStock')}></NumberInput>
                  </td>
                  <td style={{ textAlign: "right" }}>{isNaN(parseFloat(row.saleVolume)) ? 0 : parseFloat(row.saleVolume).toFixed(2)}</td>
                  <td style={{ textAlign: "right" }}>{isNaN(parseFloat(row.saleAmount)) ? 0 : parseFloat(row.saleAmount).toFixed(2)}</td>
                </tr>
              })}
              <tr>
                <td colSpan={7}><span style={{ float: 'right' }}>Total Amount</span></td>
                <td style={{ textAlign: "right" }} name='totalLubeSaleAmount'>{isNaN(parseFloat(totalLubeSaleAmount)) ? 0 : parseFloat(totalLubeSaleAmount).toFixed(2)}</td>
              </tr>
            </tbody>
          </Table>
          <span><i className='errorMsg'>** only lubricants which has stock more than '0' will be displayed here</i></span>
        </div>
        <div className="col-md-12 p-0 mt-5 mb-5 float-left">
          <button
            color="primary"
            variant="contained"
            className="next-btn btn float-right"
            onClick={(e) => saveAndProceed(e)} disabled={submitted}
          >
            {submitted ? 'SAVING....' : 'NEXT'}
          </button>
          <button
            color="secondary"
            variant="contained"
            className="back-btn btn float-right"
            style={{ marginRight: "1rem" }}
            onClick={(e) => invokePrevious(e)}
          >
            BACK
          </button>

        </div>
      </div>
    </div>
  );
};
export default LubeSales;
