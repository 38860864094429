import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import { Button, StylesProvider } from "@material-ui/core";
import VehicleOwnerDataService from "../../services/vehicle/VehicleOwnerService";
import VehicleDataService from "../../services/vehicle/VehicleService";
import VehicleTypeDataService from "../../services/vehicle/VehicleTypeService";
import Autocomplete from "../common/Autocomplete";
import useAxiosPrivate from "../../auth/useAxiosPrivate";

const AddVehicle = ({ updateParent }) => {
  const axiosPrivate = useAxiosPrivate();
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [show, setShow] = useState(false);
  const initialVehicleState = {
    id: null,
    vhType: {
      id: "",
      type: "",
      name: ""
    },
    vhOwner: {
      id: "",
      name: ""
    },
    vhRegNo: ""
  };
  const [vehicle, setVehicle] = useState(initialVehicleState);
  const initialVehicleErrorState = {
    vhTypeError: "",
    vhOwnerError: "",
    vhRegNoError: ""
  };
  const [vehicleError, setVehicleError] = useState(initialVehicleErrorState);
  const [submitted, setSubmitted] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vhOwners, setVehicleOwners] = useState([]);

  useEffect(() => {
    retrieveVehicleTypes();
    retrieveVehicleOwner();
  }, []);


  const retrieveVehicleTypes = () => {
    VehicleTypeDataService.getAllVehicleTypes(axiosPrivate)
      .then(response => {
        setVehicleTypes(response.data);
        setSubmitted(false);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const retrieveVehicleOwner = () => {
    VehicleOwnerDataService.getAll(axiosPrivate)
      .then(response => {
        setVehicleOwners(response.data);
        setSubmitted(false);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleInputChange = event => {
    console.log(event);
    event.target.value = event.target.value.toUpperCase();
    const { name, value } = event.target;
    setVehicle({ ...vehicle, [name]: value });
  };

  const handleValidation = () => {

    if (!vehicle.vhOwner.id) {
      setVehicleError({ ...vehicleError, 'vhTypeError': '', 'vhOwnerError': "Please select vehicle owner" });
      return false;
    }
    if (!vehicle.vhType.id) {
      setVehicleError({ ...vehicleError, 'vhOwnerError': '', 'vhTypeError': "Please select vehicle type" });
      return false;
    }
    if (!vehicle?.vhRegNo) {
      setVehicleError({ ...vehicleError, 'vhTypeError': '', 'vhOwnerError': '', 'vhRegNoError': "Please enter vehicle number" });
      return false;
    }
    setVehicleError({ ...vehicleError, 'vhTypeError': '', 'vhOwnerError': '', 'vhRegNoError': '' });
    return true;
  };

  const saveVehicle = (e) => {

    //if submitted then avoid calling this block again
    if (handleValidation()) {
      setShow(false);
      setMessage("adding ....");
      setSubmitted(true);
      var data = {
        vhType: vehicle.vhType,
        vhRegNo: vehicle.vhRegNo,
        vhOwner: vehicle.vhOwner
      };

      console.log(data);
      VehicleDataService.create(axiosPrivate, data)
        .then(response => {
          updateParent();
          setVehicle(initialVehicleState);
          setSubmitted(false);
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully added");
        }).catch(e => {
          if (e.response && e.response.data) {
            setMessage(e.response.data);
            setAlertHeading("Error!");
            setAlertVariant("danger");
            setSubmitted(false);
            setShow(true);
            console.log(e.response.data); // some reason error message
          }
          console.log(e);
        });
    }

  };



  return (
    <div className="p-0">

      <div className="col-md-12">
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <div className="col-md-6 float-left">
          <label htmlFor="vehicle owner">Vehicle Owner</label><span className='errorMsg'>  {vehicleError.vhOwnerError}</span>
          <Autocomplete items={vhOwners}
            searchField={'name'}
            renderItem={(item) =>
              <>
                {item.name} - {item.transport_name}
              </>

            }
            onChange={(event, value) => { setVehicle({ ...vehicle, ['vhOwner.name']: value }) }}
            onSelect={(value, item) => {
              setVehicle({ ...vehicle, ['vhOwner']: item ? item : undefined })
            }}
            value={vehicle.vhOwner?.name} />
        </div>
        <div className="col-md-6 float-right">
          <label htmlFor="title">Vehilce Type</label><span className='errorMsg'>  {vehicleError.vhTypeError}</span>
          <Autocomplete items={vehicleTypes}
            searchField={'type'}
            renderItem={(item) =>
              <>
                {item.type}
              </>

            }
            onChange={(event, value) => { setVehicle({ ...vehicle, ['vhType.type']: value }) }}
            onSelect={(value, item) => {
              setVehicle({ ...vehicle, ['vhType']: item ? item : undefined })
            }}
            value={vehicle.vhType?.type} />
        </div>

        <div className="col-md-6 adminForm">
          <label htmlFor="vhRegNo">Vehicle Number</label><span className='errorMsg'>  {vehicleError.vhRegNoError}</span>
          <input
            type="text"
            className="form-control"
            id="vhRegNo"
            required
            value={vehicle.vhRegNo}
            onChange={handleInputChange}
            name="vhRegNo"
          />
        </div>
        <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
          <button
            color="primary"
            variant="contained"
            className="add-btn btn float-right"
            onClick={(e) => saveVehicle(e)} disabled={submitted}
          >
            {submitted ? 'Adding...' : 'Add Vehicle'}
          </button>
        </div>
      </div>

    </div>
  );
};

export default AddVehicle;