
const getAll = (axiosPrivate, page) => {
  return axiosPrivate.post("/vehicleTypes", page);
};

const getAllVehicleTypes = (axiosPrivate) => {
  return axiosPrivate.get("/vehicleTypes");
};
const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/vehicleType/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/vehicleType", data);
};

const update = (axiosPrivate, id, data) => {
  return axiosPrivate.put(`/vehicleType/${id}`, data);
};

const deleteVehicleType = (axiosPrivate, id) => {
  return axiosPrivate.put(`/deleteVehicleType/${id}`);
};

const removeAll = (axiosPrivate) => {
  return axiosPrivate.delete(`/vehicleType`);
};


export default {
  getAll,
  getAllVehicleTypes,
  get,
  create,
  update,
  deleteVehicleType,
  removeAll,
};
