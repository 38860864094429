
const getAll = (axiosPrivate) => {
  return axiosPrivate.get("/vehicleowners");
};

const getAllVehicleOwners = (axiosPrivate, page) => {
  return axiosPrivate.post("/vehicleowners", page);
};

const get = (axiosPrivate, id) => {
  return axiosPrivate.get(`/vehicleOwner/${id}`);
};

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/vehicleowner", data);
};

const update = (axiosPrivate, id, data) => {
  return axiosPrivate.put(`/vehicleOwner/${id}`, data);
};

const deleteVehOwner = (axiosPrivate, id, data) => {
  return axiosPrivate.put(`/deleteVehicleOwner/${id}`, data);
};

export default {
  getAll,
  getAllVehicleOwners,
  get,
  create,
  update,
  deleteVehOwner
};
