import 'bootstrap/dist/css/bootstrap.min.css';
import { format } from 'date-fns-tz';
import addDays from "date-fns/addDays";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import React, { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import DailyFuelPriceDataService from "../../services/dailySales/DailyFuelPriceService";
import FuelTypeDataService from "../../services/products/FuelTypeService";
import { Button } from "@material-ui/core";
import NumberInput from '../common/NumberInput';
import useAxiosPrivate from '../../auth/useAxiosPrivate';

const AddDailyFuelPrice = ({updateParent}) => {
  const [message, setMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [fuelTypeSelected, setFuelTypeSelected] = useState('DEFAULT');
  const [show, setShow] = useState(false);
  const initialDailyFuelPriceState = {
    id: null,
    fuel_type: {
      id: "",
      type: "",
      name: ""
    },
    price: "",
    date_of_sale: "",
    from_dttm: "",
    to_dttm: ""
  };
  const [dailyFuelPrice, setDailyFuelPrice] = useState(initialDailyFuelPriceState);
  const [submitted, setSubmitted] = useState(false);
  const [fuelTypes, setFuelTypes] = useState([]);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    retrieveFuelTypes();
  }, []);

  const retrieveFuelTypes = () => {
    FuelTypeDataService.getAll(axiosPrivate)
      .then(response => {
        setFuelTypes(response.data);
        setSubmitted(false);
        console.log(response.data);
      })
      .catch(e => {
        console.log(e);
      });
  };


  const handleSelectChange = event => {
    const selectedIndex = event.target.options.selectedIndex - 1;
    var locFuelType = fuelTypes[selectedIndex];
    dailyFuelPrice.fuel_type = locFuelType;
    setFuelTypeSelected(locFuelType === undefined ? 'DEFAULT' : locFuelType.id);
    console.log(event.target.value);
  };

  const handleInputChange = (value, name) => {
    setDailyFuelPrice({ ...dailyFuelPrice, [name]: value });
  };

  const initialFuelPriceError = {
    priceError: "",
    typeError: "",
    dateOfSaleError: "",
    fromDttmError: "",
    toDttmError: ""
  };
  const [fuelPriceError, setFuelPriceError] = useState(initialFuelPriceError);

  const handleValidation = () => {
    if (!dailyFuelPrice.fuel_type.id || dailyFuelPrice.fuel_type.id === 'DEFAULT') {
      setFuelPriceError({ ...fuelPriceError, "priceError": '', "typeError": "Please select fuel type" });
      return false;
    }
    if (dailyFuelPrice.price === "") {
      setFuelPriceError({ ...fuelPriceError, "priceError": "Please enter price" });
      return false;
    }
    if (dailyFuelPrice.date_of_sale === "" || dailyFuelPrice.date_of_sale === undefined || dailyFuelPrice.date_of_sale === null) {
      setFuelPriceError({ ...fuelPriceError, "priceError": '', "typeError": "", "dateOfSaleError": "please enter date of sale" });
      return false;
    }
    if (dailyFuelPrice.from_dttm === "" || dailyFuelPrice.from_dttm === undefined || dailyFuelPrice.from_dttm === null) {
      setFuelPriceError({ ...fuelPriceError, "priceError": '', "typeError": "", "dateOfSaleError": "", "fromDttmError": "please enter from date and time" });
      return false;
    }
    if (dailyFuelPrice.to_dttm === "" || dailyFuelPrice.to_dttm === undefined || dailyFuelPrice.to_dttm === null) {
      setFuelPriceError({ ...fuelPriceError, "priceError": '', "typeError": "", "dateOfSaleError": "", "fromDttmError": "", "toDttmError": "please enter to date and time" });
      return false;
    }
    setFuelPriceError({ ...fuelPriceError, "typeError": '', "priceError": '', "dateOfSaleError": "", "fromDttmError": "", "toDttmError": "" });
    return true;
  };

  const saveDailyFuelPrice = (e) => {
    if (handleValidation()) {
      setSubmitted(true);
      setMessage("adding ...");
      setAlertHeading("Info!");
      setAlertVariant("info");
      setShow(true);
      var data = {
        fuel_type: dailyFuelPrice.fuel_type,
        price: dailyFuelPrice.price,
        date_of_sale: format(dailyFuelPrice.date_of_sale, "dd.MM.yyyy", { timeZone: "Asia/Kolkata" }),
        from_dttm: format(dailyFuelPrice.from_dttm, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" }),
        to_dttm: format(dailyFuelPrice.to_dttm, "dd.MM.yyyy H:mm:ss", { timeZone: "Asia/Kolkata" })
      };

      console.log(data);
      DailyFuelPriceDataService.create(axiosPrivate, data)
        .then(response => {
          setSubmitted(false);
          updateParent(response.data);
          setSubmitted(false);
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
          setAlertHeading("Success!!");
          setAlertVariant("success");
          setMessage("Successfully added");
          setDailyFuelPrice(initialDailyFuelPriceState)
          setFuelTypeSelected('DEFAULT')
        })
        .catch(e => {
          if (e.response && e.response.data) {
            setSubmitted(false);
            setMessage(e.response.data);
            setAlertHeading("Error!");
            setAlertVariant("danger");
            setShow(true);
            console.log(e.response.data); // some reason error message
          }
          console.log(e);
        });
    }

  };

  const newDailyFuelPrice = () => {
    setShow(false);
    setSubmitted(false);
    setDailyFuelPrice({
      ...dailyFuelPrice, id: null,
      fuel_type: "",
      price: "",
      date_of_sale: new Date(),
      from_dttm: setHours(setMinutes(new Date(), 0), 6),
      to_dttm: setHours(setMinutes(addDays(new Date(), 1), 59), 5)
    });
  };

  return (

    <div className="p-0">

      <div className="col-md-12 float-right">
        {show && (
          <Alert variant={alertVariant} onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{alertHeading}</Alert.Heading>
            <p>{message}</p>
          </Alert>

        )}
        <div className=" col-md-6 float-left">
          <label htmlFor="title">Fuel Type</label><span className='errorMsg'>  {fuelPriceError.typeError}</span>
          <select className="form-control" defaultValue={'DEFAULT'} value={fuelTypeSelected}  onChange={handleSelectChange} name="fuel_type" id="fuel_type">
            <option value="DEFAULT">Select Fuel Type...</option>
            {fuelTypes.map((item, index) => (
              <option
                key={index}
                value={item.id}
              >
                {item.type} - {item.name}
              </option>
            ))}
          </select>
        </div>

        <div className=" col-md-6 float-right">
          <label htmlFor="price">Price</label><span className='errorMsg'>  {fuelPriceError.priceError}</span>
          <NumberInput autoComplete="off" id="price" name="price" isDecScaleEnabled={true} decimalScale={2}
            value={dailyFuelPrice.price} className="form-control"
            onChange={(value) => handleInputChange(value, 'price')}></NumberInput>
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="Date of sale">Date of Sale</label><span className='errorMsg'>  {fuelPriceError.dateOfSaleError}</span>
          <DatePicker autoComplete="off" selected={dailyFuelPrice.date_of_sale} name="date_of_sale" id="date_of_sale" className="form-control" onChange={date => setDailyFuelPrice({ ...dailyFuelPrice, 'date_of_sale': date, 'from_dttm': setHours(setMinutes(date, 0), 6), 'to_dttm': setHours(setMinutes(addDays(date, 1), 59), 5) })} dateFormat="dd/MM/yyyy" />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="Date of sale">From Date Time</label><span className='errorMsg'>  {fuelPriceError.fromDttmError}</span>
          <DatePicker autoComplete="off" selected={dailyFuelPrice.from_dttm ? new Date(dailyFuelPrice.from_dttm) : null} timeInputLabel="Time:" name="from_dttm" id="from_dttm" className="form-control" onChange={date => setDailyFuelPrice({ ...dailyFuelPrice, 'from_dttm': date })} dateFormat="dd/MM/yyyy h:mm aa" minDate={new Date(dailyFuelPrice.from_dttm)}
            maxDate={new Date(dailyFuelPrice.from_dttm)} showDisabledMonthNavigation showTimeInput />
        </div>
        <div className="col-md-6 adminForm">
          <label htmlFor="Date of sale">To Date Time</label><span className='errorMsg'>  {fuelPriceError.toDttmError}</span>
          <DatePicker autoComplete="off" selected={dailyFuelPrice.to_dttm ? new Date(dailyFuelPrice.to_dttm) : null} timeInputLabel="Time:" name="to_dttm" id="to_dttm" className="form-control" onChange={date => setDailyFuelPrice({ ...dailyFuelPrice, 'to_dttm': date })} dateFormat="dd/MM/yyyy h:mm aa" minDate={new Date(dailyFuelPrice.to_dttm)}
            maxDate={addDays(new Date(dailyFuelPrice.to_dttm), 1)} showDisabledMonthNavigation showTimeInput />
        </div>

        <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
          <button
            color="primary"
            variant="contained"
            className="add-btn btn float-right"
            onClick={(e) => saveDailyFuelPrice(e)} disabled={submitted}
          >
            {submitted ? 'Adding...' : 'Add Fuel Price'}
          </button>
        </div>

      </div>
    </div>
  );
};

export default AddDailyFuelPrice;