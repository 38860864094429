
const getRetailOutlet = (axiosPrivate) => {
  return axiosPrivate.get("/getRetailOutlet");
};

const getBankDetails = (axiosPrivate) => {
  return axiosPrivate.get("/getBankDetails");
};

const saveBankDetails = (axiosPrivate, data) => {
  return axiosPrivate.post("/saveBankDetails", data);
}

const create = (axiosPrivate, data) => {
  return axiosPrivate.post("/saveRetailOutlet", data);
};

  // formData.append("logo", data.logo);
const uploadLogo = (axiosPrivate, data, id) => {
  let formData = new FormData();
  formData.append("file", data);
  formData.append("id", id);
  return axiosPrivate.post("/uploadLogo", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  });
};

const getCashDiffLimit = (axiosPrivate) => {
  return axiosPrivate.get("/getCashDiffLimit");
};

const saveCashDiffLimit = (axiosPrivate, data) => {
  return axiosPrivate.post("/saveCashDiffLimit", data);
};

export default {
  getRetailOutlet,
  create,
  uploadLogo,
  getCashDiffLimit,
  saveCashDiffLimit,
  getBankDetails,
  saveBankDetails,
};
