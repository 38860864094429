import React from "react";
import { Link, useLocation } from "react-router-dom";

const InfoPage = props => {

    const { state } = props.location.state
    const location = useLocation();


    return (
        <div className="info-page mt-5">
            <h4>{location.state.infoContent} !</h4>
            {(location.state.addRouter !== undefined) ? (
                <Link style={{ fontWeight: 'bold' }} href="#" className="btn btn-success mr-2" to={location.state.addRouter}>
                    Add
                </Link>

            ) : (<div></div>)}

            <Link style={{ fontWeight: 'bold' }} href="#" className="btn btn-warning" to={location.state.listRouter}>
                return to List
            </Link>
        </div>
    )

}
export default InfoPage;