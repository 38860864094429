import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import UserService from "../../../services/admin/UserService";
import { Button, IconButton, Input, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PasswordBox from "../../common/PasswordBox";
import useAxiosPrivate from "../../../auth/useAxiosPrivate";

const EditAppUser = ({ data, updateParent }) => {
    const axiosPrivate = useAxiosPrivate();
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [show, setShow] = useState(false);
    const [userRoleSelected, setUserRoleSelected] = useState(data.userRoleMO.id);

    const initialAppUserState = {
        id: '',
        userName: '',
        userRoleMO: {
            id: ''
        },
        userPassword: ''
    }
    const [appUser, setAppUser] = useState(data);
    const [userRoles, setUserRoles] = useState([]);
    const handleInputChange = event => {
        const { name, value } = event.target;
        setAppUser({ ...appUser, [name]: value });
    };

    const [appUserError, setAppUserError] = useState({
        userNameError: '',
        userRoleError: '',
        userPasswordError: '',
        confirmPasswordError: ''
    })

    useEffect(() => {
        setAppUser({ ...appUser, ['confirmPassword']: data.userPassword });
    }, data)

    const handleValidation = () => {
        if (!appUser?.userName) {
            setAppUserError({ ...appUserError, "userNameError": "Please enter user name" });
            return false;
        }
        if (!appUser?.userRoleMO.id || appUser.userRoleMO.id === 'DEFAULT') {
            setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": "Please select fuel type" });
            return false;
        }
        if (!appUser?.userPassword) {
            setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": "", "userPasswordError": "please enter password" });
            return false;
        } if (appUser.userPassword.length < 8) {
            setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": "", "userPasswordError": "password length should be equal or greater than 8" });
            return false;
        } if (!appUser?.confirmPassword) {
            setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": "", "userPasswordError": "", "confirmPasswordError": "please enter confirm password" });
            return false;
        } if (appUser.confirmPassword.length < 8) {
            setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": "", "userPasswordError": "", "confirmPasswordError": "confirm password length should be equal or greater than 8" });
            return false;
        } if (appUser.userPassword !== appUser.confirmPassword) {
            setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": "", "userPasswordError": "", "confirmPasswordError": "confirm password doent match with password" });
            return false;
        }
        setAppUserError({ ...appUserError, "userNameError": '', "userRoleError": '', "userPasswordError": '', "confirmPasswordError": '' });
        return true;
    }

    useEffect(() => {
        retrieveUserRoles();
    }, []);

    const retrieveUserRoles = () => {
        UserService.getAllUserRoles(axiosPrivate)
            .then(response => {
                const userRoles = response.data;
                setUserRoles(userRoles);
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const handleSelectChange = event => {
        const selectedIndex = event.target.options.selectedIndex - 1;
        var locUserRole = userRoles[selectedIndex];
        setAppUser({ ...appUser, "userRoleMO": locUserRole });
        setUserRoleSelected(locUserRole === undefined ? 'DEFAULT' : locUserRole.id);
    };

    const updateAppUser = () => {
        setShow(false);
        if (handleValidation()) {
            setSubmitted(true);
            UserService.updateAppUser(axiosPrivate, appUser)
                .then(response => {
                    updateParent(response.data);
                    setSubmitted(false);
                    setShow(true);
                    setTimeout(() => {
                        setShow(false);
                    }, 3000);
                    setAlertHeading("Success!!");
                    setAlertVariant("success");
                    setMessage("Successfully added");
                }).catch(e => {
                    setShow(true);
                    setSubmitted(false);
                    setAlertHeading("Error!");
                    setAlertVariant("danger");
                    setMessage(e.response.data);
                })
        }
    }

    return (
        <div className="p-0">
            <div className="col-md-12 float-right">
                {show &&
                    <div className="col-md-12 float-left">
                        <Alert style={{ width: 'auto' }} variant={alertVariant} onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>{alertHeading}</Alert.Heading>
                            <p>{message}</p>
                        </Alert></div>}
                <div className="col-md-6 float-left">
                    <label htmlFor="title">User Name</label><span className='errorMsg'>  {appUserError.userNameError}</span>
                    <input
                        type="text"
                        className="form-control"
                        id="userName"
                        required
                        value={appUser.userName}
                        autoComplete="off"
                        onChange={handleInputChange}
                        name="userName"
                    />
                </div>
                <div className="form-group col-md-6 float-left">
                    <label htmlFor="title">User Role</label><span className='errorMsg'>  {appUserError.userRoleError}</span>
                    <select className="form-control" defaultValue={'DEFAULT'} value={userRoleSelected} onChange={handleSelectChange} name="fuel_type" id="fuel_type">
                        <option value="DEFAULT">Select User Role...</option>
                        {userRoles.map((item, index) => (
                            <option
                                key={index}
                                value={item.id}>
                                {item.role}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-6 float-left">
                    <label htmlFor="title">Password</label><span className='errorMsg'>  {appUserError.userPasswordError}</span>
                    <PasswordBox value={appUser.userPassword} onChange={(e) => {
                        setAppUser({ ...appUser, ['userPassword']: e.target.value });
                    }} />
                </div>
                <div className="col-md-6 float-left">
                    <label htmlFor="title">Confirm Password</label><span className='errorMsg'>  {appUserError.confirmPasswordError}</span>
                    <PasswordBox value={appUser.confirmPassword} onChange={(e) => {
                        setAppUser({ ...appUser, ['confirmPassword']: e.target.value });
                    }} />

                </div>
                <div className="col-md-12 float-left adminForm" style={{ paddingBottom: '10px' }}>
                    <button 
                        color="primary"
                        variant="contained"
                        className="update-btn btn float-right"
                        onClick={(e) => updateAppUser(e)} disabled={submitted}
                    >
                        {submitted ? 'Updating...' : 'Update'}
                    </button>
                </div>
            </div>
        </div>
    )
}
export default EditAppUser;