import React, { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ReportService from "../../services/report/ReportService";
import CreditTransactionDataService from "../../services/credit/CreditTransactionService";
import VehicleOwnerDataService from "../../services/vehicle/VehicleOwnerService";
import { Download } from 'react-bootstrap-icons';
import Autocomplete from "../common/Autocomplete";
import FMTable from "../common/FMTable";
import Loader from "../common/Loader";
import useAxiosPrivate from "../../auth/useAxiosPrivate";
import { Button } from 'react-bootstrap';
import Constants from "../../Constants";

const CreditBill = () => {
    const axiosPrivate = useAxiosPrivate();
    const [creditTransactionEntries, setCreditTransactionEntries] = useState([]);
    const [pagination, setPagination] = useState({
        pageSelected: 1,
        sizePerPage: 10,
        filterMOs: []
    });

    const [pageMO, setPageMO] = useState({
        pageableObj: [],
        totalElements: 0,
        pagination: pagination
    });
    const [vehicleOwners, setVehicleOwnerList] = useState([]);
    const initialVehilceOwnerState = {
        name: "",
    }
    const [vehicleOwner, setVehicleOwner] = useState(initialVehilceOwnerState);
    const [value, setValue] = useState(-1);

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(!open);
    };

    
    // return anything from formatter to render into column
    // we will return a button component

    const linkFormatter = (id) => {
        return (
            <Button onClick={(e) => generateCreditBill(e, id)} type="button" className="ml-3 background-transparent" style={{ border: '0' }}>
               {value == id ? 'Downloading...' : <Download color="#303F9F" title='download' size={20} />} 
            </Button>
        );
    };

    const columns = [{
        dataField: 'billNo',
        text: 'Bill No',
        type: 'text',
        sort: true
    }, {
        dataField: 'vhOwner.name',
        text: 'Customer Name',
        type: 'text',
        sort: true
    }, {
        dataField: 'debit',
        text: 'Debit',
        type: 'text',
        sort: true,

    }, {
        dataField: 'credited_dttm',
        text: 'Credited DTTM',
        type: 'text',
        sort: true,
    }, {
        dataField: 'credit',
        text: 'Credit',
        type: 'text',
        sort: true,

    }, {
        dataField: "id",
        type: 'text',
        text: "Download",
        rowFormatter: linkFormatter
    }];
   

    useEffect(() => {
        retrieveVehicleOwner();
        retrieveCreditReceipts(pagination);
    }, []);

    const retrieveVehicleOwner = () => {
        VehicleOwnerDataService.getAll(axiosPrivate)
            .then(response => {
                setVehicleOwnerList(response.data);
                console.log(response.data);
            })
            .catch(e => {
            });
    };


    const generateCreditBill = (e, id) => {
        setValue(id)
        ReportService.generateCreditReceipt(axiosPrivate, id)
            .then(response => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([response.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                   `credit_Receipt_report.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                setValue(-1)
            })
            .catch(e => {
                setValue(-1)
            });
    };
    const [tableDataRetreivalMsg, setTableDataRetreivalMsg] = useState();

    const retrieveCreditReceipts = (pagination) => {
        setTableDataRetreivalMsg(Constants.LOADING_DATA);
        CreditTransactionDataService.getAllCreditReceipt(axiosPrivate, pagination.pageSelected, pagination.sizePerPage)
            .then(response => {
                setCreditTransactionEntries(response.data.pageableObj);
                setTableDataRetreivalMsg(null);
                setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
                console.log(response.data);
            })
            .catch(e => {
                setTableDataRetreivalMsg(null);
                console.log(e);
            });
    };

    const retrieveCreditReceiptsForVhOwnerId = (pagination, vhOwnerId) => {
        setTableDataRetreivalMsg(Constants.LOADING_DATA);
        CreditTransactionDataService.getCreditReceiptsForVhOwnerId(axiosPrivate, pagination.pageSelected, pagination.sizePerPage, vhOwnerId)
            .then(response => {
                setCreditTransactionEntries(response.data.pageableObj);
                setPageMO({ ...pageMO, ['pageableObj']: response.data.pageableObj, ['totalElements']: response.data.totalElements, ['pagination']: pagination });
                setTableDataRetreivalMsg(null);
                console.log(response.data);
            })
            .catch(e => {
                setTableDataRetreivalMsg(null);
                console.log(e);
            });
    };

    const handleTableChange = (filters, page, sizePerPage,  actionType) => {
        pagination.pageSelected = page;
        pagination.sizePerPage = sizePerPage;
        setCreditTransactionEntries([]);
        setTableDataRetreivalMsg(Constants.LOADING_DATA);
        if (vehicleOwner) {
            const vhOwnerId = vehicleOwner.id;
            retrieveCreditReceiptsForVhOwnerId(pagination, vhOwnerId);
        } else {
            retrieveCreditReceipts(pagination);
        }

    }


    return (
        <div className="list row">

            <div className="col-md-12 mt-3">
                <div>
                    <h4>Receipt</h4>
                </div>
                <div className="p-0 mt-3" style={{ width: '50%' }}>
                    <label htmlFor="title">Select Vehicle Owner</label>
                    <Autocomplete items={vehicleOwners}
                        searchField={'name'}
                        renderItem={(item) =>
                            <>
                                {item.name}
                            </>

                        }
                        onSelect={(value, item) => {
                            setVehicleOwner(item ? item : undefined);
                            { item ? retrieveCreditReceiptsForVhOwnerId(pagination, item.id) : retrieveCreditReceipts(pagination) }
                        }}
                        value={vehicleOwner?.name} />
                </div>
                <div className="pt-5">
                    <Loader openDialog={open}></Loader>
                    <FMTable columns={columns} dataRetrievalMsg={tableDataRetreivalMsg}  showFilter={false} handlePagination={handleTableChange}  pageMO={pageMO} dataList={creditTransactionEntries} />
                </div>


            </div>

        </div>
    );
};

export default CreditBill;
